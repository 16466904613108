import React from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { sum } from 'ramda';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { styled } from '@mui/material/styles';

import { DateFormatContext } from '../../common/datepicker';
import { daysOut } from '../../common/datepicker/dateUtils';
import { LoadingContainer } from '../../common/loader';
import { ASTableLegends, ASTable } from '../../common/table';
import {
    ESOTableRow,
    Row,
    Sorting,
    Pagination,
    OnChange,
    OnChangeParams,
    Direction
} from '../../common/table/types';
import {
    prepareEventTablePage,
    sellableCapacity,
    openCapacity,
    soldPercentage,
    holdsCapacity,
    totalSold,
    valueOccupancy
} from './utils';
import { EventListData, PriceCategory } from './types';
import { settingsStore } from 'stores/settings';
import {
    getInventoryLast5DaysLoader,
    getLast5DaysOfSales,
    last5daysOfSalesCache,
    Last5DaysData
} from 'utils/dataloaders/last5daysLoader';
import { Maybe } from 'utils/maybe';
import { inventoryCache, inventoryLoader } from 'utils/dataloaders/inventoryLoader';
import {
    ASC,
    DESC,
    TRANSACTIONS_ITEMCOUNT,
    TRANSACTIONS_OCCURRED_AT,
    TRANSACTIONS_PRODUCT_REF,
    TRANSACTIONS_PRODUCT_TIME_BEGINS
} from 'utils/common/constants';
import { HeaderHandlerProps } from 'views/dashboards/EventStatusOverview/utils';

interface StatsTableProps {
    events: EventListData[];
    loading?: boolean;
    topHeaders?: JSX.Element;
    headersHandler: ({}: HeaderHandlerProps) => any[];
    styledTable: any;
    tableLegends: boolean;
}

const StatsTable = ({
    events,
    loading,
    topHeaders,
    headersHandler,
    tableLegends,
    styledTable
}: StatsTableProps) => {
    const { tenantTimezone, featureFlags } = React.useContext(settingsStore);
    const [statsLoading, setStatsLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
    const [orderBy, setOrderBy] = React.useState<keyof ESOTableRow>('daysOut');
    const [orderDirection, setOrderDirection] = React.useState<Direction>(ASC);

    const { formatDateTime, getTenantTimezoneTimeOfDay } = React.useContext(DateFormatContext);

    const { t } = useTranslation();
    const theme = useTheme();

    const [eventsData, setEventsData] = React.useState<Row[]>([]);

    const [tableData, setTableData] = React.useState<Row[]>([]);

    React.useEffect(() => {
        setTableData(prepareEventTablePage(eventsData, orderBy, orderDirection, page, rowsPerPage));
    }, [eventsData, orderBy, orderDirection, page, rowsPerPage]);

    React.useEffect(() => {
        setStatsLoading(true);
        setEventsData(
            events.map((item) =>
                setSellableCapacity(
                    setSalesVelocity(
                        item,
                        last5daysOfSalesCache[String(item[TRANSACTIONS_PRODUCT_REF])]
                    ),
                    inventoryCache[String(item[TRANSACTIONS_PRODUCT_REF])]
                )
            )
        );
        Promise.all(
            events.map((item) =>
                Promise.all([
                    inventoryLoader.load(String(item[TRANSACTIONS_PRODUCT_REF])),
                    daysOut(item[TRANSACTIONS_PRODUCT_TIME_BEGINS]) < 0
                        ? getLast5DaysOfSales(tenantTimezone).load(
                              `${item[TRANSACTIONS_PRODUCT_REF]}||${item[TRANSACTIONS_PRODUCT_TIME_BEGINS]}`
                          )
                        : getInventoryLast5DaysLoader(tenantTimezone).load(
                              String(item[TRANSACTIONS_PRODUCT_REF])
                          )
                ])
            )
        )
            .then((inventoryAndLast5Data) =>
                setEventsData(
                    events.map((item, index) => {
                        const [inv, last5] = inventoryAndLast5Data[index];
                        return setSellableCapacity(setSalesVelocity(item, last5), inv);
                    })
                )
            )
            .finally(() => {
                setStatsLoading(false);
            });
    }, [events]);

    const priceCategory = (inventory: any, item: Row) => {
        if (!featureFlags.showPriceCategoryInventory) {
            return [];
        }

        const priceCategories: { [name: string]: PriceCategory } = inventory.price_categories;

        const productRef = item[TRANSACTIONS_PRODUCT_REF];

        const priceCategoriesArray: PriceCategory[] =
            Object.values(priceCategories) ||
            []
                .map((elem: PriceCategory) => {
                    if (elem.price_category) {
                        return elem;
                    }
                    return;
                })
                .filter((a) => a !== undefined);

        priceCategoriesArray.forEach((elem: PriceCategory) => {
            elem.generatedId = productRef;
            if (!elem.items_for_sale && elem.sold && elem.sold > 0) {
                elem.priceCategoryPercentage = 'noInventory';
            } else if (!elem.items_for_sale && !elem.sold) {
                elem.priceCategoryPercentage = 'noData';
            } else {
                elem.priceCategoryPercentage =
                    (Number(elem.sold || 0) / Number(elem.items_for_sale || 0)) * 100;
            }
        });

        const sortedCategoriesByPercentage: PriceCategory[] = priceCategoriesArray
            .sort((a: PriceCategory, b: PriceCategory) => {
                if (a.price_category < b.price_category) {
                    return -1;
                }
                if (a.price_category > b.price_category) {
                    return 1;
                }
                return 0;
            })
            .slice(0, 5);

        const priceCategorySquares = sortedCategoriesByPercentage.map(
            (e: PriceCategory, i: number) => {
                const itemsForSale = e.items_for_sale || 0;
                const priceCategory = e.price_category || t('unknown');

                let soldPercentage;

                if (typeof e.priceCategoryPercentage === 'string') {
                    soldPercentage = e.priceCategoryPercentage;
                } else {
                    soldPercentage = Math.round(Number(e.priceCategoryPercentage));
                }

                //@ts-ignore
                if (soldPercentage <= 100 && soldPercentage > 95) {
                    return (
                        <PriceCategoryWrapper key={`${priceCategory}-almost-sold-out`}>
                            <PriceCategorySquare
                                color={theme.colors.legendFour}
                                data-tip
                                data-for={`${i}${priceCategory}${e.generatedId}`}
                            />
                            <StyledTooltip
                                id={`${i}${priceCategory}${e.generatedId}`}
                                place="top"
                                effect="solid"
                                backgroundColor={theme.colors.white}
                                textColor={theme.colors.black}>
                                {`${priceCategory}: ${soldPercentage}% ${t('oF')} ${itemsForSale} ${t(
                                    'ticketsSold'
                                )}`}
                            </StyledTooltip>
                        </PriceCategoryWrapper>
                    );
                    //@ts-ignore
                } else if (soldPercentage <= 95 && soldPercentage > 75) {
                    return (
                        <PriceCategoryWrapper key={`${priceCategory}-sold-75-95`}>
                            <PriceCategorySquare
                                color={theme.colors.legendThree}
                                data-tip
                                data-for={`${i}${priceCategory}${e.generatedId}`}
                            />
                            <StyledTooltip
                                id={`${i}${priceCategory}${e.generatedId}`}
                                place="top"
                                effect="solid"
                                backgroundColor={theme.colors.white}
                                textColor={theme.colors.black}>
                                {`${priceCategory}: ${soldPercentage}% ${t('oF')} ${itemsForSale} ${t(
                                    'ticketsSold'
                                )}`}
                            </StyledTooltip>
                        </PriceCategoryWrapper>
                    );
                    //@ts-ignore
                } else if (soldPercentage <= 75 && soldPercentage > 50) {
                    return (
                        <PriceCategoryWrapper key={`${priceCategory}-sold-50-75`}>
                            <PriceCategorySquare
                                color={theme.colors.legendTwo}
                                data-tip
                                data-for={`${i}${priceCategory}${e.generatedId}`}
                            />
                            <StyledTooltip
                                id={`${i}${priceCategory}${e.generatedId}`}
                                place="top"
                                effect="solid"
                                backgroundColor={theme.colors.white}
                                textColor={theme.colors.black}>
                                {`${priceCategory}: ${soldPercentage}% ${t('oF')} ${itemsForSale} ${t(
                                    'ticketsSold'
                                )}`}
                            </StyledTooltip>
                        </PriceCategoryWrapper>
                    );
                    //@ts-ignore
                } else if (soldPercentage <= 50 && soldPercentage >= 0) {
                    return (
                        <PriceCategoryWrapper key={`${priceCategory}-sold-0-50`}>
                            <PriceCategorySquare
                                color={theme.colors.legendOne}
                                data-tip
                                data-for={`${i}${priceCategory}${e.generatedId}`}
                            />
                            <StyledTooltip
                                id={`${i}${priceCategory}${e.generatedId}`}
                                place="top"
                                effect="solid"
                                backgroundColor={theme.colors.white}
                                textColor={theme.colors.black}>
                                {`${priceCategory}: ${soldPercentage}% ${t('oF')} ${itemsForSale} ${t(
                                    'ticketsSold'
                                )}`}
                            </StyledTooltip>
                        </PriceCategoryWrapper>
                    );
                    //@ts-ignore
                } else if (soldPercentage > 100) {
                    return (
                        <PriceCategoryWrapper key={`${priceCategory}-oversold-more-100`}>
                            <PriceCategorySquareWrapper
                                data-tip
                                data-for={`${i}${priceCategory}${e.generatedId}`}>
                                <ASTableLegends
                                    color={theme.colors.legendFive}
                                    border={false}
                                    icon="!"
                                    iconColor={theme.colors.white}
                                    margin={false}
                                />
                            </PriceCategorySquareWrapper>
                            <StyledTooltip
                                id={`${i}${priceCategory}${e.generatedId}`}
                                place="top"
                                effect="solid"
                                backgroundColor={theme.colors.white}
                                textColor={theme.colors.black}>
                                {`${priceCategory}: ${t('oversold')}`}
                            </StyledTooltip>
                        </PriceCategoryWrapper>
                    );
                } else if (soldPercentage.toString() === 'noInventory') {
                    return (
                        <PriceCategoryWrapper key={`${priceCategory}-noInventory`}>
                            <PriceCategorySquareWrapper
                                data-tip
                                data-for={`${i}${priceCategory}${e.generatedId}`}>
                                <ASTableLegends
                                    color={theme.colors.white}
                                    border={true}
                                    icon="!"
                                    iconColor={theme.colors.black}
                                    margin={false}
                                />
                            </PriceCategorySquareWrapper>
                            <StyledTooltip
                                id={`${i}${priceCategory}${e.generatedId}`}
                                place="top"
                                effect="solid"
                                backgroundColor={theme.colors.white}
                                textColor={theme.colors.black}>
                                {`${priceCategory}: ${t('sold')} ${e.sold} (${t('noInventory')})`}
                            </StyledTooltip>
                        </PriceCategoryWrapper>
                    );
                } else {
                    return (
                        <PriceCategoryWrapper key={`${priceCategory}-noData`}>
                            <PriceCategorySquareWrapper
                                data-tip
                                data-for={`${i}${priceCategory}${e.generatedId}`}>
                                <ASTableLegends
                                    color={theme.colors.legendSix}
                                    border={true}
                                    icon="X"
                                    iconColor={theme.colors.text}
                                    margin={false}
                                />
                            </PriceCategorySquareWrapper>
                            <StyledTooltip
                                id={`${i}${priceCategory}${e.generatedId}`}
                                place="top"
                                effect="solid"
                                backgroundColor={theme.colors.white}
                                textColor={theme.colors.black}>
                                {`${priceCategory}: ${t('noData')}`}
                            </StyledTooltip>
                        </PriceCategoryWrapper>
                    );
                }
            }
        );
        return priceCategorySquares;
    };

    const setSellableCapacity = (item: Row, data: any): Row => ({
        ...item,
        sellableCapacity: Maybe(data)
            .map((inventory) => sellableCapacity(inventory))
            .getOrElse(`${t('N/A')}`),
        open: Maybe(data)
            .map((inventory) => openCapacity(inventory))
            .getOrElse(`${t('N/A')}`),
        soldPercentage: Maybe(data)
            .map((inventory) => soldPercentage(inventory))
            .getOrElse(`${t('N/A')}`),
        holds: Maybe(data)
            .map((inventory) => holdsCapacity(inventory))
            .getOrElse(`${t('N/A')}`),
        complementary: Maybe(data)
            .map((inventory) => inventory.complementary)
            .getOrElse(`${t('N/A')}`),
        totalSold: Maybe(data)
            .map((inventory) => totalSold(inventory))
            .getOrElse(`${t('N/A')}`),
        priceCategory: Maybe(data)
            .map((inventory) => priceCategory(inventory, item))
            .getOrElse(`${t('N/A')}`),
        reserved: Maybe(data)
            .map((inventory) => {
                const reserved = inventory.reserved || 0;
                return reserved;
            })
            .getOrElse(`${t('N/A')}`),
        valueOccupancy: Maybe(data)
            .map((inventory) => valueOccupancy(inventory))
            .getOrElse(`${t('N/A')}`)
    });

    const setSalesVelocity = (item: EventListData, data: Last5DaysData[] | null): Row => {
        const salesLast5Days = Maybe(data)
            .map((data) =>
                data.reduce(
                    (previous: { [key: number]: number }, value) => {
                        const diff =
                            daysOut(item[TRANSACTIONS_PRODUCT_TIME_BEGINS]) < 0
                                ? differenceInCalendarDays(
                                      new Date(value[TRANSACTIONS_OCCURRED_AT]),
                                      new Date(String(item[TRANSACTIONS_PRODUCT_TIME_BEGINS]))
                                  )
                                : differenceInCalendarDays(
                                      new Date(value[TRANSACTIONS_OCCURRED_AT]),
                                      new Date()
                                  );
                        previous[diff] = Number(value[TRANSACTIONS_ITEMCOUNT] || 0);
                        return previous;
                    },
                    {} as { [key: number]: number }
                )
            )
            .getOrElse({} as { [key: number]: number });
        return {
            ...item,
            today: salesLast5Days[0] || 0,
            lastDay: salesLast5Days[-1] || 0,
            last2Days: salesLast5Days[-2] || 0,
            last3Days: salesLast5Days[-3] || 0,
            last4Days: salesLast5Days[-4] || 0,
            soldLast5: sum(Object.values(salesLast5Days))
        };
    };

    const sorting: Sorting = {
        orderBy,
        orderDirection
    };

    const pagination: Pagination = {
        page,
        rowsPerPage,
        rowsPerPageOptions: [25, 50, 100],
        totalRowCount: events.length
    };

    const onchangePage: OnChange = ({
        newPage = page,
        newRowsPerPage = rowsPerPage,
        newOrderBy = orderBy,
        newOrderDirection = orderDirection
    }: OnChangeParams) => {
        setTableData(
            prepareEventTablePage(
                events,
                newOrderBy as keyof ESOTableRow,
                newOrderDirection,
                newPage,
                newRowsPerPage
            )
        );

        if (newPage !== page) {
            setPage(newPage);
        }
        if (newRowsPerPage !== rowsPerPage) {
            setRowsPerPage(newRowsPerPage);
        }
        if (newOrderBy !== orderBy) {
            setOrderBy(newOrderBy as keyof ESOTableRow);
        }
        if (newOrderDirection !== orderDirection) {
            setOrderDirection(newOrderDirection);
        }
    };

    const headers = headersHandler({
        t,
        formatDateTime,
        theme,
        tenantTimezone,
        getTenantTimezoneTimeOfDay,
        showPriceCategoryInventory: featureFlags.showPriceCategoryInventory,
        hideHoldAndReserved: featureFlags.hideHoldAndReserved
    });

    return (
        <TableWrapper>
            <ASTable
                headers={headers}
                data={tableData}
                showPagination={true}
                sorting={sorting}
                pagination={pagination}
                onChange={onchangePage}
                topHeaders={topHeaders}
                tableLegends={tableLegends}
                headerbold={400}
                styledTable={styledTable}
            />
            {loading || (statsLoading && <LoadingContainer size={60} />)}
        </TableWrapper>
    );
};

export { StatsTable };

const TableWrapper = styled('div')`
    position: relative;
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 18px;
        background-color: ${({ theme }) => theme.colors.segmentBuilderScrollBar};
        background: linear-gradient(
            to bottom,
            ${({ theme }) => theme.colors.segmentBuilderScrollBar} 592px,
            ${({ theme }) => theme.colors.white} 10px
        );
        border: 1px solid ${({ theme }) => theme.colors.segmentBuilderScrollBarThumb};
        display: block;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.segmentBuilderScrollBarThumb};
        border: 1px solid ${({ theme }) => theme.colors.frame};
        border-radius: 10px;
    }
`;

const PriceCategorySquareWrapper = styled('div')`
    display: table-cell;
`;

const PriceCategorySquare = styled('span')<{ border?: boolean; color?: string }>`
    display: inline-block;
    align-items: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    background: ${({ color }) => color};
    margin-right: ${({ theme }) => theme.space.half};
    border: ${({ border, theme }) => (border ? `1px solid ${theme.colors.border}` : 'none')};
`;

const PriceCategoryWrapper = styled('div')`
    display: inline-block;
`;

const StyledTooltip = styled(ReactTooltip)`
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.12);
`;
