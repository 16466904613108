import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { styled } from '@mui/material/styles';

const Chart = ({ options }: { options: Highcharts.Options }) => (
    <HighChartWrapper>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </HighChartWrapper>
);

const HighChartWrapper = styled('div')`
    margin-bottom: ${({ theme }) => theme.space.single};
`;

export { Chart };
