import {
    PRODUCTS_PERFORMER_ENTITY_REF_FLAT,
    PRODUCTS_PERFORMER_LABEL_FLAT,
    PRODUCTS_PERFORMER_THUMBNAIL,
    PRODUCTS_PRODUCTION_REF,
    PRODUCTS_PRODUCTION_THUMBNAIL,
    TRANSACTIONS_CURRENCY,
    TRANSACTIONS_PRODUCTION_LABEL
} from 'utils/common/constants';

export const toplistObj: {
    [key in 'production' | 'performer']: {
        label: typeof TRANSACTIONS_PRODUCTION_LABEL | typeof PRODUCTS_PERFORMER_LABEL_FLAT;
        ref: typeof PRODUCTS_PRODUCTION_REF | typeof PRODUCTS_PERFORMER_ENTITY_REF_FLAT;
        thumbnail: typeof PRODUCTS_PRODUCTION_THUMBNAIL | typeof PRODUCTS_PERFORMER_THUMBNAIL;
        currency: typeof TRANSACTIONS_CURRENCY;
    };
} = {
    production: {
        label: TRANSACTIONS_PRODUCTION_LABEL,
        ref: PRODUCTS_PRODUCTION_REF,
        thumbnail: PRODUCTS_PRODUCTION_THUMBNAIL,
        currency: TRANSACTIONS_CURRENCY
    },
    performer: {
        label: PRODUCTS_PERFORMER_LABEL_FLAT,
        ref: PRODUCTS_PERFORMER_ENTITY_REF_FLAT,
        thumbnail: PRODUCTS_PERFORMER_THUMBNAIL,
        currency: TRANSACTIONS_CURRENCY
    }
};

export const previousPeriodObj: {
    [key in 'production' | 'performer']: {
        ref: typeof PRODUCTS_PRODUCTION_REF | typeof PRODUCTS_PERFORMER_ENTITY_REF_FLAT;
        currency: typeof TRANSACTIONS_CURRENCY;
    };
} = {
    production: {
        ref: PRODUCTS_PRODUCTION_REF,
        currency: TRANSACTIONS_CURRENCY
    },
    performer: {
        ref: PRODUCTS_PERFORMER_ENTITY_REF_FLAT,
        currency: TRANSACTIONS_CURRENCY
    }
};
