import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ASButtonProps {
    background?: string;
    disabled?: boolean;
    fontcolor?: string;
    onClick: (event: any) => void;
    className?: string;
    padding?: string;
    width?: string;
    fontSize?: number;
    name?: string;
    activeButton?: string;
    border?: boolean;
    borderSize?: string;
    borderColor?: string;
    activeBorderColor?: string;
    id?: string;
    borderRadius?: string;
    height?: string;
    backgroundHover?: string;
    borderHover?: string;
    backgroundActive?: string;
    fontColorHover?: string;
    fontColorActive?: string;
    iconFill?: string;
    iconStroke?: string;
    iconFillHover?: string;
    iconStrokeHover?: string;
    iconFillActive?: string;
    iconStrokeActive?: string;
    disabledBackground?: string;
    disabledBorderColor?: string;
    disabledFontcolor?: string;
    children?: React.ReactNode;
}
const ASButton: React.FC<ASButtonProps> = ({
    background,
    disabled,
    fontcolor,
    onClick,
    children,
    className,
    padding,
    width,
    fontSize,
    name,
    activeButton,
    border,
    borderSize,
    borderColor,
    activeBorderColor,
    id,
    borderRadius,
    height,
    backgroundHover,
    borderHover,
    backgroundActive,
    fontColorHover,
    fontColorActive,
    iconFill,
    iconStroke,
    iconFillHover,
    iconStrokeHover,
    iconFillActive,
    iconStrokeActive,
    disabledBackground,
    disabledBorderColor,
    disabledFontcolor
}) => {
    return (
        <StyledButton
            disableRipple
            background={background}
            disabled={disabled}
            fontcolor={fontcolor}
            onClick={onClick}
            className={className}
            padding={padding}
            fontSize={fontSize}
            width={width}
            name={name}
            activeButton={activeButton}
            border={border}
            borderSize={borderSize}
            borderColor={borderColor}
            activeBorderColor={activeBorderColor}
            id={id}
            borderRadius={borderRadius}
            height={height}
            backgroundHover={backgroundHover}
            borderHover={borderHover}
            backgroundActive={backgroundActive}
            fontColorHover={fontColorHover}
            fontColorActive={fontColorActive}
            iconFill={iconFill}
            iconStroke={iconStroke}
            iconFillHover={iconFillHover}
            iconStrokeHover={iconStrokeHover}
            iconFillActive={iconFillActive}
            iconStrokeActive={iconStrokeActive}
            disabledBackground={disabledBackground}
            disabledBorderColor={disabledBorderColor}
            disabledFontcolor={disabledFontcolor}>
            {children}
        </StyledButton>
    );
};

type StyleProps = {
    fontSize?: number;
    background?: string;
    fontcolor?: string;
    padding?: string;
    width?: string;
    name?: string;
    activeButton?: string;
    border?: boolean;
    borderSize?: string;
    borderColor?: string;
    activeBorderColor?: string;
    borderRadius?: string;
    height?: string;
    backgroundHover?: string;
    borderHover?: string;
    backgroundActive?: string;
    fontColorHover?: string;
    fontColorActive?: string;
    iconFill?: string;
    iconStroke?: string;
    iconFillHover?: string;
    iconStrokeHover?: string;
    iconFillActive?: string;
    iconStrokeActive?: string;
    disabledBackground?: string;
    disabledBorderColor?: string;
    disabledFontcolor?: string;
};

const StyledButton = styled(Button)<StyleProps>`
    &.MuiButton-text {
        text-transform: none;
        letter-spacing: 0.1px;
        font-size: ${({ fontSize }) => (fontSize ? fontSize : '16')}px;
    }
    padding: ${({ padding }) => (padding ? padding : '0px')};
    display: flex;
    min-width: ${({ width }) => (width ? width : '110px')};
    height: ${({ height }) => (height ? height : '50px')};
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizes.header};
    color: ${({ fontcolor, theme }) => (fontcolor ? fontcolor : theme.colors.white)};
    background-color: ${({ background, disabled, disabledBackground, theme }) =>
        disabled ? disabledBackground : background ? background : theme.colors.primaryButton};
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}` : '2px')};
    border: ${({
        borderColor,
        borderSize,
        activeBorderColor,
        name,
        activeButton,
        disabled,
        disabledBorderColor
    }) =>
        borderSize
            ? `${borderSize} solid ${
                  disabled
                      ? disabledBorderColor
                          ? disabledBorderColor
                          : 'none'
                      : borderColor
                        ? name && name === activeButton && activeBorderColor
                            ? activeBorderColor
                            : borderColor
                        : 'none'
              }`
            : `2px solid ${
                  disabled
                      ? disabledBorderColor
                          ? disabledBorderColor
                          : 'none'
                      : borderColor
                        ? name && name === activeButton && activeBorderColor
                            ? activeBorderColor
                            : borderColor
                        : 'none'
              }`};
    path {
        fill: ${({ iconFill }) => iconFill && iconFill};
        stroke: ${({ iconStroke }) => iconStroke && iconStroke};
    }
    &:hover {
        background-color: ${({ backgroundHover, theme }) =>
            backgroundHover ? backgroundHover : theme.colors.primaryButtonHover};
        border: ${({ borderHover }) => borderHover && `2px solid ${borderHover}`};
        color: ${({ fontColorHover }) => fontColorHover && fontColorHover};
        path {
            fill: ${({ iconFillHover }) => iconFillHover && iconFillHover};
            stroke: ${({ iconStrokeHover }) => iconStrokeHover && iconStrokeHover};
        }
    }
    &:active {
        background-color: ${({ backgroundActive, theme }) =>
            backgroundActive ? backgroundActive : theme.colors.primaryButtonClicked};
        border: ${({ borderHover, activeBorderColor, theme }) =>
            activeBorderColor
                ? `2px solid ${activeBorderColor}`
                : borderHover
                  ? `2px solid ${borderHover}`
                  : theme.colors.primaryButtonClicked};
        color: ${({ fontColorActive }) => fontColorActive && fontColorActive};
        path {
            fill: ${({ iconFillActive }) => iconFillActive && iconFillActive};
            stroke: ${({ iconStrokeActive }) => iconStrokeActive && iconStrokeActive};
        }
    }
`;

export { ASButton, ASButtonProps };
