import React from 'react';
import { useTheme } from '@mui/material/styles';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { CANCEL_ICON } from 'assets/iconConstants';
import { styled } from '@mui/material/styles';
import { ASIconButton } from '../../common/button';

const ClearSearchInputButton = ({
    searchString,
    onClickReset,
    styledFor
}: {
    searchString: string;
    onClickReset: () => void;
    styledFor?: string;
}) => {
    const theme = useTheme();
    if (searchString === '') {
        return null;
    }

    return (
        <ClearSearchButton
            id="search-clear-button"
            onClick={onClickReset}>
            {styledFor === 'segmentBuilder' ? (
                <CustomSvgIcon
                    size={0.4}
                    iconName={CANCEL_ICON}
                    fill={theme.colors.segmentBuilderText}
                />
            ) : (
                <CustomSvgIcon
                    size={0.5}
                    iconName={CANCEL_ICON}
                />
            )}
        </ClearSearchButton>
    );
};

const ClearSearchButton = styled(ASIconButton)`
    grid-area: button;
    margin-right: ${({ theme }) => theme.space.single};
`;

export { ClearSearchInputButton };
