import React from 'react';

import { DateFormatContext } from '../common/datepicker';
import { TileProps } from './types';
import { getDateTime, getLabel, getLocation, getHref, getArchetype } from './utils';

import { SearchResultsTile } from './SearchResultsTile';
import { settingsStore } from 'stores/settings';

const EventTile = ({ hit, styledFor, index }: TileProps) => {
    const { getTenantTimezoneTimeOfDay, formatDateTimeLong } = React.useContext(DateFormatContext);
    const { tenantTimezone } = React.useContext(settingsStore);
    return (
        <SearchResultsTile
            thumbnail={hit.thumbnail}
            label={getLabel(hit)}
            left={[
                getDateTime(hit, getTenantTimezoneTimeOfDay, formatDateTimeLong, tenantTimezone)
            ]}
            right={[getLocation(hit), getArchetype(hit)]}
            href={getHref(hit)}
            styledFor={styledFor}
            index={index}
        />
    );
};

export { EventTile };
