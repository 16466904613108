import { ASTheme } from 'themes/styled';

export const deepBlue: ASTheme = {
    name: 'Activity Stream',
    colors: {
        activitystreamRed: '#c93748',
        background: '#f2f8f9',
        text: '#2b2a2d',
        textFaded: '#C3C3C3',
        secondary: '#D1EDF8',
        scrollBar: '#93C1CC',
        scrollBarBackground: '#C7E2E9',
        scrollBarBackground2: '#e8f0fe',
        highlight: '#93C1CC',
        textLink: '#459FB6',
        alert: '#e8ae92',
        selectedBullets: '#ed7942',
        dividers: '#dce2e4',
        dividersLight: '#e4e2e2',
        muted: '#e6f2f5',
        mapDeselected: '#CCC',
        defaultFill: '#e6f2f5',
        deselectedChartTwo: '#FBE3D8',
        chart1: '#86AFCD',
        chart2: '#7BDDD6',
        chart3: '#FCD47C',
        chart4: '#F29D9F',
        chart5: '#CDC1CF',
        chart6: '#B4E2B4',
        chart7: '#BED5E6',
        chart8: '#CCEBE7',
        chart9: '#F1D9B4',
        chart10: '#EAB6B7',
        chart11: '#DFDAE0',
        chart12: '#C0EFC5',
        chart13: '#86AFCD',
        chart14: '#7BDDD6',
        chart15: '#FCD47C',
        chart16: '#F29D9F',
        chart17: '#CDC1CF',
        chart18: '#B4E2B4',
        chart19: '#BED5E6',
        chart20: '#CCEBE7',
        chart21: '#F1D9B4',
        chart22: '#EAB6B7',
        chart23: '#DFDAE0',
        chart24: '#C0EFC5',
        chart25: '#86AFCD',
        chart26: '#7BDDD6',
        chart27: '#FCD47C',
        chart28: '#F29D9F',
        chart29: '#CDC1CF',
        chart30: '#B4E2B4',
        chart31: '#BED5E6',
        chart32: '#CCEBE7',
        chart33: '#F1D9B4',
        chart34: '#EAB6B7',
        chart35: '#DFDAE0',
        chart36: '#C0EFC5',
        chart37: '#86AFCD',
        chart38: '#7BDDD6',
        chart39: '#FCD47C',
        chart40: '#F29D9F',
        chart41: '#CDC1CF',
        chart42: '#B4E2B4',
        chart43: '#BED5E6',
        chart44: '#CCEBE7',
        chart45: '#F1D9B4',
        chart46: '#EAB6B7',
        chart47: '#DFDAE0',
        chart48: '#C0EFC5',
        chart49: '#FCD47C',
        chart50: '#F29D9F',
        openChartBar: '#93C1CC',
        soldChartBar: '#C4D292',
        heldChartBar: '#E8AE92',
        killedChartBar: '#B88498',
        reservedChartBar: '#debb94',
        deletedChartBar: '#e39aa2',
        oversoldCountChartBar: '#FF0000',
        border: '#9da8c5',
        white: '#fff',
        black: '#000',
        danger: '#e8ae92',
        shadowImage: '#94C1CC',
        positive: '#a6c142',
        legendOne: '#c4d292',
        legendTwo: '#a6c142',
        legendThree: '#e8ae92',
        legendFour: '#d97040',
        legendFive: '#c93748',
        legendSix: '#fff',
        kpiCustomer: '#f3c7cb',
        kpiCustomerIcon: '#DD4850',
        kpiCommunication: '#c8ebe7',
        kpiCommunicationIcon: '#54BDB2',
        kpiTransaction: '#b4c3d5',
        kpiTransactionIcon: '#003873',
        kpiProduct: '#fce6c2',
        kpiProductIcon: '#F7AE29',
        filterGroupCustomer: '#F69D9F',
        filterGroupCommunication: '#7BDDD6',
        filterGroupTransaction: '#86AFCD',
        filterGroupProduct: '#FCD47C',
        sidebarColor: '#003873',
        sidebarBorder: '#004889',
        sidebarMenuHover: '#004889',
        sidebarIconFillHover: '#fff',
        hamburgerMenuBg: '#003464',
        hamburgerMenuFonts: '#fff',
        hamburgerMenuHover: '#004889',
        profilePicBg: '#BEDDE5',
        toplistIconPrimary: '#D1EDF8',
        toplistIconSecondary: '#86AFCD',
        toplistIconFill: '#2b2a2d',
        toplistTileHeader: '#003873',
        toplistTileHover: '#004889',
        breadcrumbs: '#D1EDF8',
        toplistNoCustomerImage: '#003873',
        toplistNoEventImage: '#003873',
        toplistNoDonorImage: '#c93748',
        urlGeneratorInputFill: '#D0E4EA',
        userInviteTitle: '#333333',
        selectArrowIcon: '#333333',
        campaignSetupPrimary: '#D9EBF0',
        campaignSetupSecondary: '#93C1CC',
        searchTopBorder: '#459fb6',
        frame: '#d8d8d8',
        BouncedChartBar: '#93C1CC',
        DeliveredChartBar: '#C4D292',
        inventoryDivider: '#e2e7e9',
        popupBg: '#D9D9D9',
        popupBorder: '#9FA6AF',
        timelineIconGrey: '#c7c7c7',
        soldInPeriodTableHeader: '#9EA9C6',
        segmentBuilderMenuItemActive: '#44b3db',
        segmentBuilderMenuItemChecked: '#c7e8f4',
        segmentBuilderText: '#7F7F7F',
        segmentBuilderTextLink: '#ef8131',
        segmentBuilderCancelIcon: '#808080',
        segmentBuilderBackground: '#f2f2f2',
        segmentBuilderScrollBarThumb: '#ebebeb',
        segmentBuilderScrollBar: '#f6f6f6',
        segmentBuilderCheckboxChecked: '#aac34b',
        segmentBuilderCheckboxFrame: '#c0c0c0',
        segmentBuilderBorder: '#e8e8e8',
        segmentBuilderNextButtonDisabled: '#bfbfbf',
        segmentBuilderNextButtonDisabledText: '#dfdfdf',
        segmentBuilderPreviousButtonFrame: '#e6e6e6',
        segmentBuilderExportButtonFrame: '#c4c4c4',
        segmentBuilderChipBorder: '#BFD278',
        segmentBuilderChipBackground: '#fcffef',
        lightBlue: '#44B3DB',
        lightGreen: '#acc54f',
        success: '#aac34b',
        failure: '#c93748',
        dropzoneFolder: '#CFE57B',
        optInOuterDiv: '#fafafa',
        optInInnerDiv: '#e6e6e6',
        dragActive: '#0c0fef',
        dropzoneBackground: '#fcffef',
        segmentManagementColumn: '#f5f5f5',
        segmentManagementColumnHover: '#ececec',
        segmentManagementDelete: '#FADFE1',
        segmentManagementDeleteIcon: '#E75560',
        showHideIcon: '#C0C0C0',
        disabled: '#93C1CC',
        dropdownArrowInactive: '#C5C5C5',
        primaryButton: '#459FB6',
        primaryButtonHover: '#4291A6',
        primaryButtonClicked: '#3992B2',
        secondaryButtonHover: '#EF8131',
        borderThick: '#94c2cb',
        checked: '#c4d292',
        underlineUnactiveStep: '#CCD6EB',
        campaignButtonHoverBorder: '#ABABAB',
        textDisabled: '#B6B6B6',
        asterisk: '#f4a76f',
        errorBackground: '#FAE3E1',
        warningInputBorder: '#EF8131',
        tertiaryButtonBorderHover: '#ABABAB',
        tertiaryButtonBackground: '#F5F5F5',
        tertiaryButtonBackgroundHover: '#EEEEEE',
        tertiaryButtonBorder: '#DCE2E4',
        tertiaryButtonBackgroundDisabled: '#FFFFFF',
        calendarDoW: '#B7B7B7',
        activeDayCalendar: '#7BC9E5',
        emailCampaignRedBorder: '#DB2A36',
        succesHover: '#B3C27E',
        timeSelectWatchBackground: '#F3FCFF',
        disabledButtonFontColor: '#bfbfbf',
        campaignSetupInputButtonBorder: '#e6e6e6',
        campaignManagementBackground: '#F1F2F5',
        campaignManagementHeaderBackground: '#EEF3FA',
        navigationButtonHover: '#3EA2C6',
        campaignManagementTableRowHover: '#F5F7F9',
        campaignManagementTableIcon: '#9D9D9D',
        campaignSuccessStatus: '#E0E7C7',
        campaignFailedStatus: '#FBD0D0',
        campaignSkippedStatus: '#F1DEB4',
        campaignScheduledStatus: '#FBDED0',
        campaignManagementRowBottomBorder: '#F1F2F5',
        warning: '#fef2eb',
        campaignStatsStyledTooltipBackground: '#F8F8F8',
        campaignStatsStyledTooltipBorder: '#9CC0CA',
        placeholderColor: '#9D9D9D',
        deleteCampaignBackground: '#F6CACD',
        deleteCampaignIconColor: '#E66A73',
        mobileIconInactive: '#959496',
        uplifts: '#DB9AB3',
        darkerSpinner: '#71a8b6'
    },
    fonts: {
        primaryFont: 'Roboto'
    },
    space: {
        half: '7px',
        single: '14px',
        double: '28px',
        triple: '42px',
        menu: '44px',
        menuDouble: '88px'
    },
    fontSizes: {
        big: '24px',
        header: '16px',
        header2: '18px',
        medium: '14px',
        normal: '13px',
        small: '12px'
    },
    borderRadius: {
        small: '2px'
    },
    buttonSizeWidth: {
        largeButtonWidth: '152px',
        smallButtonWidth: '137px'
    },
    buttonSizeHeight: {
        largeButtonHeight: '50px',
        smallButtonHeight: '43px'
    },
    variables: {
        '--color-danger': '#da171a',
        '--icon-colorOne': '#459fb6',
        '--icon-colorTwo': '#ed7942',
        '--icon-toplistNoEventImage': '#003873'
    }
};
