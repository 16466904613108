/** @jsx jsx */
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Highcharts from 'highcharts';
import { compose, pathOr } from 'ramda';
import { styled } from '@mui/material/styles';

import { css, jsx } from '@emotion/react';
import { LoadingContainer } from '../common/loader';
import LastUpdatedAtDate from '../LastUpdatedAtDate';
import { TabletOrNotebook, Desktop } from '../responsive/MediaQuery';
import { PortletTitle } from '../responsive/Portlet';
import { ColumnChart } from './ColumnChart';
import { mapPeriodSelectorDOWData } from './mappers';
import { PeriodSelectorChart } from './PeriodSelectorChart';
import { getPeriodSelectorDataQuery, getPeriodSelectorDOWDataQuery } from './queries';
import { PeriodSelectorChartProps } from './type';
import { settingsStore } from 'stores/settings';
import { DimensionData, Operator } from 'utils/common/types';
import { cubejsApi } from 'utils/api/CubeAPI';

export const PeriodSelectorChartsContainer = ({
    dimension,
    metric,
    measures,
    salesTime,
    datePickerPeriod,
    timePeriod,
    periodSelectorPeriod,
    baseFilters,
    filters,
    setPeriodSelectorPeriod,
    onFilter,
    granularity
}: PeriodSelectorChartProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { featureFlags, tenantTimezone } = React.useContext(settingsStore);
    const [loading, setLoading] = React.useState(false);
    const [loadingDOW, setLoadingDOW] = React.useState(false);
    const [operator] = React.useState<Operator>('equals');
    const chartColor = theme.colors.chart1;
    const [rawPeriodSelectorData, setRawPeriodSelectorData] = React.useState<DimensionData[]>([]);
    const [periodSelectorData, setPeriodSelectorData] = React.useState<any>([]);
    const [rawPeriodSelectorDOWData, setRawPeriodSelectorDOWData] = React.useState<DimensionData[]>(
        []
    );
    const [periodSelectorDOWData, setPeriodSelectorDOWData] = React.useState<
        Highcharts.SeriesColumnOptions[]
    >([]);

    React.useEffect(() => {
        setLoading(true);
        cubejsApi
            .load(
                getPeriodSelectorDataQuery(
                    measures,
                    salesTime,
                    datePickerPeriod,
                    granularity,
                    filters,
                    baseFilters,
                    tenantTimezone
                )
            )
            .then(
                compose(
                    setRawPeriodSelectorData,
                    pathOr<DimensionData[]>([], ['loadResponse', 'results', '0', 'data'])
                )
            )
            .finally(() => setLoading(false));
    }, [salesTime, datePickerPeriod, granularity, filters, baseFilters]);

    React.useEffect(() => {
        setLoadingDOW(true);
        cubejsApi
            .load(
                getPeriodSelectorDOWDataQuery(
                    dimension,
                    measures,
                    salesTime,
                    timePeriod,
                    filters,
                    baseFilters,
                    tenantTimezone
                )
            )
            .then(
                compose(
                    setRawPeriodSelectorDOWData,
                    pathOr<DimensionData[]>([], ['loadResponse', 'results', '0', 'data'])
                )
            )
            .finally(() => setLoadingDOW(false));
    }, [timePeriod, salesTime, filters]);

    React.useEffect(() => {
        setPeriodSelectorDOWData(
            mapPeriodSelectorDOWData(
                t,
                dimension,
                metric,
                filters,
                operator,
                chartColor
            )(rawPeriodSelectorDOWData)
        );
    }, [rawPeriodSelectorDOWData, filters]);

    const onColumnClick = React.useRef((event: Highcharts.SeriesClickEventObject) => {
        const filterValue = event.point.options.custom?.filterValue;
        const breadcrumbValue = event.point.options.custom?.breadcrumbValue;

        onFilter({
            type: 'dimensionClick',
            operator: operator,
            dimension,
            value: {
                value: filterValue,
                label: breadcrumbValue
            }
        });
    });

    React.useEffect(() => {
        setPeriodSelectorData(
            rawPeriodSelectorData.map((data) => [+new Date(data[salesTime]), Number(data[metric])])
        );
    }, [rawPeriodSelectorData, metric]);

    const columnChartOptions: Highcharts.Options = {
        chart: {
            type: 'column',
            height: 100
        },
        xAxis: {
            type: 'category',
            labels: {
                formatter() {
                    return t(String(this.value).toLowerCase()).charAt(0).toUpperCase();
                }
            }
        },
        yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            plotLines: [
                {
                    value: 0,
                    width: 1,
                    color: theme.colors.border,
                    zIndex: 10
                }
            ],
            labels: {
                enabled: false
            },
            title: {
                text: undefined
            }
        },
        plotOptions: {
            series: {
                cursor: 'pointer'
            }
        },
        tooltip: {
            enabled: false
        }
    };

    if (featureFlags.disablePeriodSelector) {
        return <React.Fragment />;
    }

    const top = (
        <div
            css={css`
                display: flex;
                justify-content: space-between;
            `}>
            <PortletTitle>{t('periodSelector')}</PortletTitle>
            <LastUpdatedAtDate />
        </div>
    );

    return (
        <div>
            <TabletOrNotebook>
                {top}
                <ChartsWrapper>
                    <PeriodSelectorChart
                        data={periodSelectorData}
                        setPeriodSelectorPeriod={setPeriodSelectorPeriod}
                        periodSelectorPeriod={periodSelectorPeriod}
                        granularity={granularity}
                    />
                    <ColumnChart
                        series={periodSelectorDOWData}
                        onColumnClick={onColumnClick}
                        columnOptions={columnChartOptions}
                        metric={metric}
                    />
                    {loading || (loadingDOW && <LoadingContainer />)}
                </ChartsWrapper>
            </TabletOrNotebook>

            <Desktop>
                {top}
                <ChartsWrapper>
                    <PeriodSelectorChart
                        data={periodSelectorData}
                        setPeriodSelectorPeriod={setPeriodSelectorPeriod}
                        periodSelectorPeriod={periodSelectorPeriod}
                        granularity={granularity}
                    />
                    <ColumnChart
                        series={periodSelectorDOWData}
                        onColumnClick={onColumnClick}
                        columnOptions={columnChartOptions}
                        metric={metric}
                    />
                    {loading || (loadingDOW && <LoadingContainer />)}
                </ChartsWrapper>
            </Desktop>
        </div>
    );
};
const ChartsWrapper = styled('div')`
    position: relative;
    display: grid;
    grid-template-columns: 5fr 1fr;
    column-gap: ${({ theme }) => theme.space.triple};
    background: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: 1px solid ${({ theme }) => theme.colors.frame};
`;
