import { AxiosError } from 'axios';
import React from 'react';
import { styled } from '@mui/material/styles';
import { AxiosErrorMessage } from '../../common/error';
import { sizes } from '../../responsive/MediaQuery';

const AxiosErrorComp = ({ error, show }: { error: AxiosError | null; show: boolean }) => {
    if (!error || !show) {
        return null;
    }

    return (
        <ResultsWrapper>
            <AxiosErrorMessage
                error={error}
                show={show}
            />
        </ResultsWrapper>
    );
};

export { AxiosErrorComp };

const ResultsWrapper = styled('div')`
    display: grid;
    position: absolute;
    top: ${({ theme }) => theme.space.menu};
    left: -44px;
    min-width: 100vw;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};

    @media (min-width: ${sizes.desktop.minWidth}px) {
        min-width: 380px;
        width: 100%;
        left: 0px;
    }
`;
