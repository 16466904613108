import { LoadingContainer } from 'components/common/loader';
import React from 'react';
import { styled } from '@mui/material/styles';

export const PlaceholderLayout = () => {
    return (
        <LayoutWrapper>
            <Top />
            <Body>
                <LoadingContainer noBackgroundColor />
            </Body>
        </LayoutWrapper>
    );
};

const LayoutWrapper = styled('main')`
    display: grid;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.background};
    min-height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: ${({ theme }) => theme.space.menu} auto;
    grid-gap: ${({ theme }) => theme.space.double};
    grid-template-areas:
        'top'
        'body';
`;

const Top = styled(`div`)`
    height: ${({ theme }) => theme.space.menu};
    background-color: ${({ theme }) => theme.colors.white};
`;

const Body = styled(`div`)`
    height: 100%;
    display: flex;
    justify-content: center;
`;
