import { TimeDimensionGranularity } from '@cubejs-client/core';
import { ASCubeFilter, ASCubeQuery } from './common/types';
import { DateRange } from 'components/common/datepicker';
import { TRANSACTIONS_CURRENCY } from 'utils/common/constants';
import { formatCubeQueryDate } from 'components/common/datepicker/dateUtils';
import { FullDimension } from 'stores/dimensionData';

export const getDimensionQuery = (
    dimension: string,
    measures: string[],
    salesTime: string | undefined,
    timePeriod: DateRange | undefined,
    filters: ASCubeFilter[],
    baseFilters: ASCubeFilter[],
    tenantTimezone: string,
    granularity?: TimeDimensionGranularity
): ASCubeQuery => ({
    dimensions: [dimension],
    measures,
    timeDimensions:
        timePeriod && salesTime
            ? [
                  {
                      dimension: salesTime,
                      dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [
                          string,
                          string
                      ],
                      granularity: granularity
                  }
              ]
            : undefined,
    filters: filters.filter((filter) => filter.dimension !== dimension).concat(baseFilters),
    timezone: tenantTimezone
});

export const getCustomersCountQuery = (
    dimension: FullDimension,
    measures: string[],
    salesTime: string | undefined,
    timePeriod: DateRange | undefined,
    filters: ASCubeFilter[],
    baseFilters: ASCubeFilter[],
    tenantTimezone: string,
    granularity?: TimeDimensionGranularity
): ASCubeQuery => ({
    dimensions: [],
    measures: measures,
    timeDimensions:
        timePeriod && salesTime
            ? [
                  {
                      dimension: salesTime,
                      dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [
                          string,
                          string
                      ],
                      granularity: granularity
                  }
              ]
            : undefined,
    filters: filters.filter((filter) => filter.dimension !== dimension).concat(baseFilters),
    timezone: tenantTimezone
});

export const getCurrencyDataQuery = (): ASCubeQuery => ({
    dimensions: [TRANSACTIONS_CURRENCY]
});

export const getBarDataQuery = (
    dimension: string,
    measures: string[],
    salesTime: string,
    timePeriod: DateRange,
    baseFilters: ASCubeFilter[],
    filters: ASCubeFilter[],
    tenantTimezone: string
): ASCubeQuery => ({
    dimensions: [dimension],
    measures,
    timeDimensions: [
        {
            dimension: salesTime,
            dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string]
        }
    ],
    filters: filters.filter((filter) => filter.dimension !== dimension).concat(baseFilters),
    timezone: tenantTimezone
});

export const getColumnChartQuery = (
    dimension: FullDimension,
    measures: string[],
    salesTime: string,
    timePeriod: DateRange,
    baseFilters: ASCubeFilter[],
    filters: ASCubeFilter[],
    tenantTimezone: string,
    granularity?: TimeDimensionGranularity,
    eventOccurredAt?: string
): ASCubeQuery => ({
    dimensions: [dimension],
    measures,
    timeDimensions: eventOccurredAt
        ? [
              {
                  dimension: salesTime,
                  dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string]
              },
              {
                  dimension: eventOccurredAt,
                  granularity: granularity
              }
          ]
        : [
              {
                  dimension: salesTime,
                  dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [
                      string,
                      string
                  ],
                  granularity: granularity
              }
          ],
    filters: filters.concat(baseFilters),
    timezone: tenantTimezone
});
