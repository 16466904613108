export const CUSTOMER = 'Customer';
export const PRODUCTION = 'ProductGroup:Production';
export const PERFORMER = 'Performer';
export const DONATION = 'Organization:Fund';
export const MERCHANDISE = 'Product:Merchandise';
export const EVENT = 'Product:Event';
export const ORDER = 'Order';
export const CAMPAIGN = 'Campaign';
export const ORGANIZER = 'Supplier:Organizer';
export const PROMOTER = 'Supplier:Promoter';
export const VENUE = 'Location:Venue';

export type SEARCHABLE_TYPE =
    | typeof CUSTOMER
    | typeof PRODUCTION
    | typeof PERFORMER
    | typeof DONATION
    | typeof MERCHANDISE
    | typeof EVENT
    | typeof ORDER
    | typeof CAMPAIGN
    | typeof ORGANIZER
    | typeof PROMOTER
    | typeof VENUE;

export const SELECTABLE = 'Selectable';
export const REGULAR = 'Regular';
export const CLICKABLE = 'Clickable';
export const MULTISELECT = 'MultiSelect';

export type TILE_TYPE = typeof REGULAR | typeof SELECTABLE | typeof MULTISELECT | typeof CLICKABLE;

export interface GlobalSearchQuery {
    presentation_label?: string;
    presentation_label_exact?: string;
    email?: string;
    archetype_and_variant?: SEARCHABLE_TYPE;
    archetype_and_variant_list?: string;
    country?: string;
    city?: string;
    timed_begins_year?: number;
    timed_begins_month?: number;
    page: number;
    size?: number;
    from_date?: string;
}

export interface SearchResults {
    hits: Hit[];
    page: Page;
    _links: Links;
    aggregation: Aggregation;
}

export interface Links {
    self: First;
    next: First;
    previous?: First;
    first: First;
    last: First;
}

export interface First {
    href: string;
}

export interface Aggregation {
    key: string;
    buckets: Bucket[];
}

export interface Hit {
    entity_reference?: string;
    archetype_and_variant?: string;
    presentation_label?: string;
    subtenant?: string;
    partition?: string;
    country?: string;
    city?: string;
    email?: string;
    timed_begins?: string;
    thumbnail?: string;
}

export interface Page {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
}

export interface Bucket {
    name: string;
    nameCount: number;
}
