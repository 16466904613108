import { USER_MANAGEMENT_API } from '../urls';
import { ASRequest } from '../request';
import { User, ASInvite, AppMetadata } from './types';

export const userMgmtApi = {
    getCurrentUserAppMetadata: async function () {
        const results = await ASRequest.request<AppMetadata>({
            method: 'get',
            url: `${USER_MANAGEMENT_API}/user/app_metadata`
        });

        return results.data;
    },
    getUsers: async function () {
        const results = await ASRequest.request<User[]>({
            method: 'get',
            url: `${USER_MANAGEMENT_API}/users`
        });

        return results.data;
    },
    sendInvite: async function (data: ASInvite) {
        await ASRequest.request<User[]>({
            method: 'post',
            url: `${USER_MANAGEMENT_API}/user/invite`,
            data
        });
    },
    editUser: async function (data: ASInvite) {
        await ASRequest.request<User[]>({
            method: 'post',
            url: `${USER_MANAGEMENT_API}/user/edit`,
            data
        });
    },
    getUser: async function () {
        const results = await ASRequest.request<User>({
            method: 'get',
            url: `${USER_MANAGEMENT_API}/user`
        });

        return results.data;
    }
};
