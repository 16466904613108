import ReactTooltip from 'react-tooltip';
import { sizes } from './responsive/MediaQuery';
import { styled } from '@mui/material/styles';
import { ASLink } from './common/link/ASLink';

export const TooltipWrapper = styled('div')`
    display: inline-block;
    width: 100%;
`;

export const StyledTooltip = styled(ReactTooltip)`
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.12);
    position: relative;
    left: -20px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.text};
    border-color: ${({ theme }) => theme.colors.dividers};
`;

export const TooltipItemWrapper = styled('div')`
    display: inline-block;
    text-overflow: ellipsis;
    width: 100%;
`;

export const TableStyledASLink = styled(ASLink)`
    width: 100%;
`;

type ImageProps = { thumbnail: string };

export const Image = styled('div')<ImageProps>`
    grid-area: pic;
    display: flex;
    flex-shrink: 0;
    align-self: center;
    width: 112px;
    height: 112px;
    background: ${({ thumbnail, theme }) =>
        thumbnail ? `url(${thumbnail}) no-repeat center center ` : theme.colors.shadowImage};
    background-size: cover;

    @media (max-width: ${sizes.notebook.maxWidth}px) {
        width: 60px;
        height: 60px;
        align-self: flex-start;
    }

    border-radius: ${({ theme }) => theme.borderRadius.small};
`;
