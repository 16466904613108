import React from 'react';
import { Label, Value } from '../components/commonStyled';
import { FilterType } from '../GlobalSearch';
import { styled } from '@mui/material/styles';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { CANCEL_ICON } from 'assets/iconConstants';

interface FitlerItemProps {
    onClickRemove: () => void;
    name: FilterType;
    value: string;
    className?: string;
    selected: boolean;
    styledFor?: string;
}

const FilterItem = ({
    onClickRemove,
    name,
    value,
    selected,
    className,
    styledFor
}: FitlerItemProps) => (
    <React.Fragment>
        {styledFor === 'segmentBuilder' ? (
            <SegmentBuilderFilter
                onClick={() => onClickRemove()}
                className={className}
                selected={selected}>
                <SegmentBuilderLabel>{name}</SegmentBuilderLabel>
                <SegmentBuilderValueWrapper>
                    <SegmentBuilderValue>{value}</SegmentBuilderValue>
                    <FilterIcon>
                        <CustomSvgIcon
                            iconName={CANCEL_ICON}
                            size={0.5}
                        />
                    </FilterIcon>
                </SegmentBuilderValueWrapper>
            </SegmentBuilderFilter>
        ) : (
            <Filter
                onClick={() => onClickRemove()}
                className={className}
                selected={selected}>
                <Label>{name}</Label>
                <Value>{value}</Value>

                <FilterIcon>
                    <CustomSvgIcon
                        iconName={CANCEL_ICON}
                        size={0.5}
                    />
                </FilterIcon>
            </Filter>
        )}
    </React.Fragment>
);

type FilterProps = {
    selected: boolean;
};

const Filter = styled('div')<FilterProps>`
    display: flex;
    flex-direction: row;
    padding: ${({ theme }) => theme.space.half};
    margin: 0 ${({ theme }) => `-${theme.space.half}`};

    color: ${({ theme }) => theme.colors.textFaded};

    background-color: ${({ theme, selected }) => (selected ? theme.colors.secondary : '')};
`;

const FilterIcon = styled('div')`
    margin-left: ${({ theme }) => theme.space.half};
`;

const SegmentBuilderFilter = styled('div')<FilterProps>`
    display: flex;
    flex-direction: row;
    padding: ${({ theme }) => theme.space.half};
    margin: 0 ${({ theme }) => `-${theme.space.half}`};
    color: ${({ theme }) => theme.colors.textFaded};
`;

const SegmentBuilderValueWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    border: 1px solid ${({ theme }) => theme.colors.segmentBuilderMenuItemActive};
    padding: 0 10px;
`;

const SegmentBuilderValue = styled('div')`
    color: ${({ theme }) => theme.colors.segmentBuilderCancelIcon};
    font-size: ${({ theme }) => theme.fontSizes.small};
`;

const SegmentBuilderLabel = styled('div')`
    color: ${({ theme }) => theme.colors.segmentBuilderCancelIcon};
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin-right: ${({ theme }) => theme.space.half};
`;

export { FilterItem };
