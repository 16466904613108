import React from 'react';

import { TileProps } from './types';
import { getArchetype, getEmail, getLabel, getLocation } from './utils';
import { SearchResultsTile } from './SearchResultsTile';

const SupplierTile = ({ hit, styledFor, index }: TileProps) => (
    <SearchResultsTile
        thumbnail={hit.thumbnail}
        label={getLabel(hit)}
        left={[getEmail(hit)]}
        right={[getArchetype(hit), getLocation(hit)]}
        href={'#'}
        styledFor={styledFor}
        index={index}
    />
);

export { SupplierTile };
