const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const DAYS_IN_WEEK = 7;
const DAYS_IN_MONTH = 30;
const DAYS_IN_YEAR = 365;

export const SECOND_INTERVAL = 1000; // 1s = 1000 milliseconds
export const MINUTE_INTERVAL = SECONDS_IN_MINUTE * SECOND_INTERVAL;
export const HOUR_INTERVAL = MINUTES_IN_HOUR * MINUTE_INTERVAL;

export const DAY_INTERVAL = HOURS_IN_DAY * HOUR_INTERVAL;
export const WEEK_INTERVAL = DAYS_IN_WEEK * DAY_INTERVAL;
export const MONTH_INTERVAL = DAYS_IN_MONTH * DAY_INTERVAL;
export const YEAR_INTERVAL = DAYS_IN_YEAR * DAY_INTERVAL;
