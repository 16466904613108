import React from 'react';
import { styled } from '@mui/material/styles';
import { sizes } from '../../responsive/MediaQuery';

export interface Aspect {
    label: string;
    value: number | string | JSX.Element;
}

const TileAspect = ({ label, value }: Aspect) => {
    return (
        <Wrapper>
            <Label>{label}</Label>
            <Value>{value}</Value>
        </Wrapper>
    );
};

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
    border-right: 1px solid ${({ theme }) => theme.colors.dividers};
    padding-right: 13px;
    padding-left: ${({ theme }) => theme.space.single};
    line-height: 1;
    height: 34px;
    row-gap: ${({ theme }) => theme.space.half};

    &:last-child {
        border: none;
        padding-right: ${({ theme }) => theme.space.half};
    }
    &:first-of-type {
        padding-left: 0;
    }

    @media (min-width: ${sizes.tablet.minWidth}px) and (max-width: ${sizes.notebook.maxWidth}px) {
        height: auto;
    }

    @media (max-width: ${sizes.notebook.maxWidth}px) {
        padding-left: 0;
    }
`;

const Label = styled('p')`
    font-size: ${({ theme }) => theme.fontSizes.small};
    text-align: end;

    @media (max-width: ${sizes.desktop.minWidth}px) {
        font-size: 11px; // Using 11 pixels instead of 12 in mobile solves a lot of alignment issues.
        text-align: start;
    }
`;

const Value = styled('p')`
    font-size: ${({ theme }) => theme.fontSizes.header};
    font-weight: bold;
    width: 100%;
    text-align: end;

    @media (max-width: ${sizes.desktop.minWidth}px) {
        text-align: start;
    }
`;

export { TileAspect };
