import * as React from 'react';
import { styled } from '@mui/material/styles';
import { IconType } from 'assets/iconsTypes';

export type CustomIconProps = {
    iconName: IconType | string;
    fill?: string;
    hoverfill?: string;
    size?: number;
    maxSize?: boolean;
    width?: string;
    height?: string;
    opacity?: number;
    stroke?: string;
};

const CustomSvgIcon: React.FunctionComponent<CustomIconProps> = ({
    fill,
    size,
    hoverfill,
    maxSize,
    iconName,
    width = '100%',
    height = '100%',
    opacity = 1,
    stroke
}) => {
    const [menuIcon, setIcon] = React.useState<string | null>(null);
    const componentMounted = React.useRef(true);
    React.useEffect(() => {
        if (iconName) {
            componentMounted.current = true;
            import(`assets/svg/${iconName}.svg`)
                .then((icon) => {
                    if (componentMounted.current) {
                        setIcon(icon.default);
                    }
                })
                .catch((error) => console.error(error));
        }
        return () => {
            componentMounted.current = false;
        };
    }, [iconName]);
    return (
        <CustomSvgIconStyle
            width={width}
            height={height}
            hoverfill={hoverfill}
            fill={fill}
            size={size}
            maxSize={maxSize}
            opacity={opacity}
            stroke={stroke}>
            {menuIcon ? menuIcon : null}
        </CustomSvgIconStyle>
    );
};

export type IconProps = {
    fill?: string;
    hoverfill?: string;
    size?: number;
    maxSize?: boolean;
    width?: string;
    height?: string;
    opacity?: number;
    stroke?: string;
};

const CustomSvgIconStyle = styled('span')<IconProps>`
    display: flex;
    justify-content: center;
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    align-items: center;
    opacity: ${({ opacity }) => opacity};
    path {
        fill: ${({ fill, theme }): string => fill ?? theme.colors.text};
        stroke: ${({ stroke }) => stroke};
    }
    &:hover {
        path {
            fill: ${({ hoverfill, fill, theme }) => hoverfill ?? fill ?? theme.colors.text};
        }
    }
    svg {
        width: ${({ size, maxSize }): string => getSize(size, maxSize)};
        height: ${({ size, maxSize }): string => getSize(size, maxSize)};
    }
`;

const getSize = (size?: number, maxSize?: boolean) => {
    if (maxSize) {
        return '100%';
    } else if (size) {
        return `${size}em`;
    } else {
        return '1em';
    }
};

type TileStyleProps = {
    expanded: boolean;
};

const IconWithRotation = styled('span')<TileStyleProps>`
    transform: ${({ expanded }) => (expanded ? 'rotate(135deg)' : 'rotate(-45deg)')};
    transition: transform 0.1s ease-in;
`;

export { CustomSvgIcon, IconWithRotation };
