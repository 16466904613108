import * as React from 'react';
import { ASLabel } from '../common/label';

interface Props {
    title?: string;
}

const ChartTitle = ({ title }: Props) => (
    <ASLabel
        bold
        title={title}
        fontSize={0.8}
    />
);

export { ChartTitle };
