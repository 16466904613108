import { useEffect, useState } from 'react';
import { sizes } from 'components/responsive/MediaQuery';

const useCheckIfMobile = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return width <= (sizes.mobile.maxWidth ?? 768);
};

export const useCheckIfScreenWidthLessOrEqTo = (maxWidth: number) => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return width <= maxWidth;
};

export default useCheckIfMobile;
