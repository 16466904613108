import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';

export const pngExportHandler = (id: string, exportName: string) => {
    toPng(document.querySelector(`#${id}`) as HTMLElement).then(function (dataUrl) {
        const image = new Image();
        image.onload = function () {
            const pageWidth = image.naturalWidth;
            const pageHeight = image.naturalHeight;

            // Create a new PDF with the same dimensions as the image.
            const pdf = new jsPDF({
                orientation: pageHeight > pageWidth ? 'portrait' : 'landscape',
                unit: 'px',
                format: [pageHeight, pageWidth]
            });

            // Add the image to the PDF with dimensions equal to the internal dimensions of the page.
            pdf.addImage(
                dataUrl,
                'PNG',
                0,
                0,
                pdf.internal.pageSize.getWidth(),
                pdf.internal.pageSize.getHeight(),
                exportName,
                'FAST'
            );

            // Save the PDF with the filename specified here:
            pdf.save(exportName);
        };
        image.src = dataUrl;
    });
};
