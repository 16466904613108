import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';
import { State } from 'stores/settings';

const locizeOptions = {
    projectId: '28fc263b-3790-4e14-98d7-a4453c5806de',
    apiKey: '1a343a6b-8e01-4bf4-bfcf-6e443e8cc137',
    referenceLng: 'en-US',
    version: 'latest'
};

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'en-US',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        backend: locizeOptions,
        debug: process.env.NODE_ENV === 'development'
    });

const convertLanguage = (lng: string) => changeLng[lng] || lng;

const changeLng: { [key: string]: string } = {
    'en-GB': 'en-US',
    enUS: 'en-US'
};

const setGlobalLanguage = (
    language: string,
    dictonary: { [key: string]: string },
    setDateFnsLocale: (locale: string) => void,
    userSettings: State
) => {
    const lang = convertLanguage(language);
    setDateFnsLocale(lang);
    i18n.changeLanguage(lang);
    i18n.addResourceBundle(lang, 'translation', dictonary, true, true);
    userSettings.dispatch({ type: 'setLanguage', payload: language });
};

export { setGlobalLanguage };

export default i18n;
