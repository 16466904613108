import React from 'react';
import { Redirect } from 'react-router-dom';
import { settingsStore } from 'stores/settings';

const DefaultDashboard = () => {
    const { defaultDashboard } = React.useContext(settingsStore);
    const defaultDashRoute = defaultDashboard ? `/${defaultDashboard}` : '/sales_overview';
    const previousLocation = localStorage.getItem('previousLocation');
    if (previousLocation) {
        localStorage.removeItem('previousLocation');
        window.location.href = previousLocation;
        return <Redirect to={previousLocation} />;
    }

    return <Redirect to={defaultDashRoute} />;
};

export default DefaultDashboard;
