import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { IconType } from 'assets/iconsTypes';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { Operator } from 'utils/common/types';
import { settingsStore } from 'stores/settings';

export const FadedChartMenuItem = styled('span')`
    position: relative;
    margin-left: 7px;
    cursor: pointer;
`;

export const InvertChartMenuItem = styled('span')<{ invert: Operator; isDisabled: Boolean }>`
    position: relative;
    margin-left: ${({ theme }): string => theme.space.half};
    cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
    &:hover {
        path {
            fill: ${({ invert, theme }): string =>
                invert === 'equals' ? theme.colors.textLink : theme.colors.selectedBullets};
        }
        path:nth-of-type(2) {
            fill: ${({ invert, theme }): string =>
                invert === 'equals' ? theme.colors.selectedBullets : theme.colors.textLink};
        }
    }
`;

export const FadedChartMenuItemContainer = styled('span')`
    display: flex;
    padding-top: ${({ theme }) => theme.space.half};
`;

export const FadedChartMenuContainer = styled('div')`
    min-width: 0;
    position: relative;
`;

export const FrameWrapper = styled('div')`
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: 1px solid ${({ theme }) => theme.colors.frame};
`;

export const InvertChartAction = ({
    onClick,
    title,
    iconName,
    children,
    invert,
    disabled = false
}: InvertComponentActionProps) => {
    const { focusedSegments } = React.useContext(settingsStore);
    const { t } = useTranslation();

    const getTooltip = () => {
        if (focusedSegments) {
            if (title === 'in') {
                return t('inoutPinnedInFocusModeIn');
            } else {
                return t('inoutPinnedInFocusModeNotIn');
            }
        } else {
            return title;
        }
    };

    return (
        <InvertChartMenuItem
            onClick={(e) => {
                if (!disabled) {
                    onClick(e);
                }
            }}
            title={getTooltip()}
            role="button"
            tabIndex={0}
            onKeyDown={(event: any) => (event.keyCode === 13 ? onClick(event) : null)}
            invert={invert}
            isDisabled={disabled}>
            <CustomSvgIcon
                fill={disabled ? '#D3D3D3' : 'keepOriginalColors'}
                size={1.4}
                iconName={iconName}
            />
            {children}
        </InvertChartMenuItem>
    );
};

export const FadedChartAction = ({ onClick, title, iconName, children }: ComponentActionProps) => (
    <FadedChartMenuItem
        onClick={onClick}
        title={title}
        role="button"
        tabIndex={0}
        onKeyDown={(event: any) => (event.keyCode === 13 ? onClick(event) : null)}>
        <CustomSvgIcon
            fill={'keepOriginalColors'}
            size={1.4}
            iconName={iconName}
        />
        {children}
    </FadedChartMenuItem>
);

export interface ComponentActionProps {
    onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    title: string;
    iconName: IconType;
    children?: JSX.Element | JSX.Element[];
}

export interface InvertComponentActionProps extends ComponentActionProps {
    invert: Operator;
    disabled?: boolean;
}

export const TitleContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;
