import { StylesProvider } from '@mui/styles';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Bootstrap } from './Authentication/bootstrap/Bootstrap';
import './styles/global.scss';
import { LayoutTemplate } from './views/layoutTemplate';
import { SettingsProvider } from './stores/settings';
import { DimensionDataProvider } from './stores/dimensionData';
import { DynamicRoutesContextProvider } from 'Routing/DynamicRoutesContextProvider';
import { DateFormatContextProvider } from 'components/common/datepicker/DateFormat';

const App = (): React.ReactElement => (
    <StylesProvider injectFirst>
        <DynamicRoutesContextProvider>
            <SettingsProvider>
                <DateFormatContextProvider>
                    <DimensionDataProvider>
                        <Bootstrap>
                            <BrowserRouter>
                                <LayoutTemplate />
                            </BrowserRouter>
                        </Bootstrap>
                    </DimensionDataProvider>
                </DateFormatContextProvider>
            </SettingsProvider>
        </DynamicRoutesContextProvider>
    </StylesProvider>
);

export default App;
