import { TFunction } from 'i18next';
import { Table } from '@mui/material';
import React from 'react';
import { Row } from 'components/common/table/types';
import {
    TRANSACTIONS_PRODUCT_REF,
    TRANSACTIONS_PRODUCT_LABEL,
    TRANSACTIONS_PRODUCT_TIME_BEGINS,
    TRANSACTIONS_CURRENCY,
    PRODUCTS_INVENTORY_HOLDS,
    PRODUCTS_INVENTORY_CAPACITY,
    PRODUCTS_INVENTORY_KILLS,
    CUSTOMERS_IS_FIRST_TIME_BUYER,
    TRANSACTIONS_ITEMCOUNT,
    TRANSACTIONS_REVENUE,
    CUSTOMERS_DISTINCT_COUNT,
    TRANSACTIONS_VENUE_LABEL,
    TRANSACTIONS_VENUE_REF
} from 'utils/common/constants';
import { formatCurrencyNoDecimals } from 'utils/formatting/currency';
import {
    formatDecimalNumber,
    formatDecimalPercent,
    formatPercent,
    formatWholeNumber
} from 'utils/formatting/numbers';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { tableLinkHandler } from 'components/common/table/utils';
import { StyledTooltip, TooltipItemWrapper, TooltipWrapper } from 'components/commonStyles';
import { subtractTimezone } from 'components/common/datepicker/dateUtils';

export type HeaderHandlerProps = {
    t: TFunction;
    formatDateTime: (date: Date) => string;
    theme: Theme;
    tenantTimezone?: string;
    getTenantTimezoneTimeOfDay?: (date: string, timezone: string) => Date;
    showPriceCategoryInventory?: boolean;
    hideHoldAndReserved?: boolean;
};

export const headersHandler = ({
    t,
    formatDateTime,
    theme,
    tenantTimezone,
    getTenantTimezoneTimeOfDay,
    showPriceCategoryInventory,
    hideHoldAndReserved
}: HeaderHandlerProps) => {
    const headers = [
        {
            label: t('date'),

            column: 'timedBegins',
            transform: function ({ timedBegins }: { timedBegins: Date }) {
                if (getTenantTimezoneTimeOfDay && tenantTimezone) {
                    const tmpDate = getTenantTimezoneTimeOfDay(
                        timedBegins.toString(),
                        tenantTimezone
                    );
                    subtractTimezone(tmpDate);

                    return formatDateTime(tmpDate);
                }
            }
        },
        {
            label: t('event'),
            column: 'eventLabel',
            transform: function ({
                eventRef,
                eventLabel
            }: {
                eventRef: string;
                eventLabel: string;
            }) {
                if (eventLabel || eventRef) {
                    return (
                        <TooltipWrapper>
                            <TooltipItemWrapper
                                data-tip
                                data-for={eventRef}>
                                {tableLinkHandler({ ref: eventRef, label: eventLabel })}
                            </TooltipItemWrapper>
                            <StyledTooltip
                                id={eventRef}
                                place="top"
                                effect="solid"
                                backgroundColor={theme.colors.white}
                                textColor={theme.colors.black}
                                border={true}
                                borderColor={theme.colors.dividers}>
                                {eventLabel || eventRef}
                            </StyledTooltip>
                        </TooltipWrapper>
                    );
                } else {
                    return t('N/A');
                }
            }
        },
        {
            label: t('daysOut'),
            textAlign: 'right',
            column: 'daysOut',
            transform({ daysOut }: { daysOut: Row }) {
                return daysOut;
            }
        },
        {
            label: t('soldLastFive'),
            textAlign: 'right',
            column: 'soldLast5',
            transform({ soldLast5 }: { soldLast5: number }) {
                return formatWholeNumber(soldLast5);
            }
        },
        {
            label: t('totalSold'),
            textAlign: 'right',
            column: 'totalSold',
            transform({ totalSold }: { totalSold: number }) {
                if (totalSold > 0) {
                    return formatWholeNumber(totalSold);
                } else {
                    return t('N/A');
                }
            }
        },
        {
            label: t('sellableCapacity'),
            textAlign: 'right',
            column: 'sellableCapacity',
            transform({ sellableCapacity }: { sellableCapacity: number }) {
                if (sellableCapacity > 0) {
                    return formatWholeNumber(sellableCapacity);
                } else {
                    return t('N/A');
                }
            }
        },
        {
            label: t('soldPercentage'),
            textAlign: 'right',
            column: 'soldPercentage',
            transform({ soldPercentage }: { soldPercentage: number }) {
                if (soldPercentage > 0) {
                    return formatDecimalPercent(t)(1, 1)(soldPercentage);
                } else {
                    return t('N/A');
                }
            }
        },
        {
            label: t('total'),
            textAlign: 'right',
            column: 'totalRevenue',
            transform({ totalRevenue, currency }: { totalRevenue: number; currency: string }) {
                return formatCurrencyNoDecimals(currency, totalRevenue);
            }
        },
        ...(hideHoldAndReserved === false
            ? [
                  {
                      label: t('holds'),
                      textAlign: 'right',
                      column: 'holds',
                      transform({ holds }: { holds: number }) {
                          if (holds > 0) {
                              return formatWholeNumber(holds);
                          } else {
                              return t('N/A');
                          }
                      }
                  }
              ]
            : []),
        {
            label: t('complementary'),
            textAlign: 'right',
            column: 'complementary',
            transform({ complementary }: { complementary: number }) {
                if (complementary > 0) {
                    return formatWholeNumber(complementary);
                } else {
                    return t('N/A');
                }
            }
        },
        ...(hideHoldAndReserved === false
            ? [
                  {
                      label: t('res'),
                      column: 'reserved',
                      textAlign: 'right',
                      transform({ reserved }: { reserved: number }) {
                          if (reserved > 0) {
                              return formatWholeNumber(reserved);
                          } else {
                              return t('N/A');
                          }
                      }
                  }
              ]
            : []),
        {
            label: t('open'),
            textAlign: 'right',
            column: 'open',
            transform({ open }: { open: number }) {
                if (open > 0) {
                    return formatWholeNumber(open);
                } else {
                    return t('N/A');
                }
            }
        },
        // This next item will only be present in the table if 'showPriceCategoryInventory' is true:
        ...(showPriceCategoryInventory
            ? [
                  {
                      label: t('priceCat'),
                      column: 'priceCategory',
                      transform({ priceCategory }: { priceCategory: JSX.Element }) {
                          return priceCategory;
                      }
                  }
              ]
            : []),
        {
            label: t('averagePrice'),
            textAlign: 'right',
            column: 'averagePrice',
            transform(row: Row) {
                return formatDecimalNumber(row.averagePrice, 1, 1);
            }
        },
        {
            label: t('valueOccupancy'),
            textAlign: 'right',
            column: 'valueOccupancy',
            transform({ valueOccupancy }: { valueOccupancy: number }) {
                return formatDecimalPercent(t)(1, 1)(valueOccupancy);
            }
        },
        {
            label: t('ftb'),
            textAlign: 'right',
            column: 'firstTimeCustomerPercent',
            transform({ firstTimeCustomerPercent }: { firstTimeCustomerPercent: number }) {
                return formatPercent(firstTimeCustomerPercent * 100, t, 1);
            }
        }
    ];
    return headers;
};

export const tableDimensions = [
    TRANSACTIONS_PRODUCT_REF,
    TRANSACTIONS_PRODUCT_LABEL,
    TRANSACTIONS_PRODUCT_TIME_BEGINS,
    TRANSACTIONS_VENUE_LABEL,
    TRANSACTIONS_VENUE_REF,
    TRANSACTIONS_CURRENCY,
    PRODUCTS_INVENTORY_HOLDS,
    PRODUCTS_INVENTORY_CAPACITY,
    PRODUCTS_INVENTORY_KILLS,
    CUSTOMERS_IS_FIRST_TIME_BUYER
];

export const tableMeasures = [
    TRANSACTIONS_ITEMCOUNT,
    TRANSACTIONS_REVENUE,
    CUSTOMERS_DISTINCT_COUNT
];

export const getStyledTable = (
    showPriceCategoryInventory: boolean,
    hideHoldAndReserved: boolean
) => {
    const inventoryRowEnd = () => {
        let count = 10;

        if (showPriceCategoryInventory) {
            count += 1;
        }

        if (!hideHoldAndReserved) {
            count += 2;
        }

        return count;
    };

    return styled(Table)`
        th,
        td {
            border: none;
        }
        th:nth-child(3),
        td:nth-child(3),
        th:nth-child(7),
        td:nth-child(7),
        th:nth-child(8),
        td:nth-child(8),
        th:nth-child(${inventoryRowEnd()}),
        td:nth-child(${inventoryRowEnd()}) {
            border-right: ${({ theme }) => `${theme.space.single} solid ${theme.colors.white}`};
        }
    `;
};
