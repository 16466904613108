import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import { sizes, Desktop, Mobile, Notebook } from '../../responsive/MediaQuery';
import { StyledTooltip } from '../../commonStyles';
import { styled } from '@mui/material/styles';
import { DateFormatContext } from '../datepicker';
import { ASLink } from '../link';

interface TileSummaryProps {
    entityRef: string;
    date?: string;
    location: string;
    link: string;
    title: string;
    nextVisit?: string | null;
    isText?: boolean;
}

const TileSummary: React.FC<TileSummaryProps> = ({
    date,
    location,
    link,
    title,
    nextVisit,
    isText,
    entityRef
}) => {
    const { t } = useTranslation();
    const { formatEventDate } = React.useContext(DateFormatContext);
    const theme = useTheme();

    const missingPresentationLabel = title === entityRef;

    const renderDate = (date: string) => {
        const separator = (
            <>
                <DesktopSpan> - </DesktopSpan>
                <Mobile>
                    <></>
                </Mobile>
            </>
        );
        if (isNaN(+new Date(date))) {
            return <React.Fragment />;
        }
        return (
            <span>
                {formatEventDate(new Date(date), t)}
                {separator}
            </span>
        );
    };
    return (
        <EventView>
            <Summary>
                {title && title.length > 45 ? (
                    <div>
                        <div
                            data-tip
                            data-for={entityRef}>
                            {isText ? (
                                <TitleText
                                    missingPresentationLabel={missingPresentationLabel}
                                    href={link}
                                    text={title === '' ? t('unknown') : title}
                                />
                            ) : (
                                <TitleLink
                                    missingPresentationLabel={missingPresentationLabel}
                                    href={link}
                                    text={title === '' ? t('unknown') : title}
                                />
                            )}
                        </div>
                        <StyledTooltip
                            id={entityRef}
                            place="top"
                            effect="solid"
                            backgroundColor={theme.colors.white}
                            textColor={theme.colors.black}
                            border={true}
                            borderColor={theme.colors.dividers}>
                            {title}
                        </StyledTooltip>
                    </div>
                ) : isText ? (
                    <TitleText
                        missingPresentationLabel={missingPresentationLabel}
                        href={link}
                        text={title === '' ? t('unknown') : title}
                    />
                ) : (
                    <TitleLink
                        missingPresentationLabel={missingPresentationLabel}
                        href={link}
                        text={title === '' ? t('unknown') : title}
                    />
                )}

                <Desktop>
                    <TileInfo>
                        {date ? renderDate(date) : null}
                        {location && location.length > 40 ? (
                            <React.Fragment>
                                <Location
                                    data-tip
                                    data-for={location}>
                                    {location}
                                </Location>
                                <StyledTooltip
                                    id={location}
                                    place="top"
                                    effect="solid"
                                    backgroundColor={theme.colors.white}
                                    textColor={theme.colors.black}
                                    border={true}
                                    borderColor={theme.colors.dividers}>
                                    {location}
                                </StyledTooltip>
                            </React.Fragment>
                        ) : (
                            <Location>{location} </Location>
                        )}
                        {nextVisit ? <span>{nextVisit} </span> : null}
                    </TileInfo>
                </Desktop>
                <Notebook>
                    <TileInfo>
                        {date ? renderDate(date) : null}
                        <span>{location}</span>
                    </TileInfo>
                </Notebook>
                <Mobile>
                    <TileInfo>
                        {date ? renderDate(date) : null}
                        <span>{location}</span>
                    </TileInfo>
                </Mobile>
            </Summary>
        </EventView>
    );
};

const Summary = styled('div')`
    justify-content: space-around;
    display: block;
    flex-direction: column;
    height: 100%;
    max-width: 400px;
    @media (min-width: ${sizes.desktop.minWidth}px) {
    }
`;

const TitleLink = styled(ASLink)<{
    missingPresentationLabel: boolean;
}>`
    font-size: ${({ theme }) => theme.fontSizes.header};
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    @media (max-width: ${sizes.desktop.minWidth}px) {
        word-break: break-word;

        & .MuiTypography-root {
            z-index: 4;
        }
    }
`;

const TitleText = styled(ASLink)<{
    missingPresentationLabel: boolean;
}>`
    font-size: ${({ theme }) => theme.fontSizes.header};
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    & .MuiTypography-root {
        cursor: default;
        pointer-events: none;
    }

    @media (max-width: ${sizes.desktop.minWidth}px) {
        word-break: ${({ missingPresentationLabel }) =>
            missingPresentationLabel ? 'break-all' : 'break-word'};
    }
`;

const TileInfo = styled('div')`
    font-size: ${({ theme }) => theme.fontSizes.small};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

    @media (min-width: ${sizes.tablet.minWidth}px) and (max-width: ${sizes.notebook.maxWidth}px) {
        display: flex;
        flex-direction: column;
    }
`;

const EventView = styled('div')`
    display: flex;
    padding-bottom: ${({ theme }) => theme.space.half};
    width: 100%;
    justify-self: start;
    grid-auto-flow: column;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dividers};
    margin-top: ${({ theme }) => theme.space.half};

    @media (max-width: ${sizes.notebook.maxWidth}px) {
        margin-top: 3px;
    }

    @media (max-width: ${sizes.desktop.minWidth}px) {
        border-bottom: none;
    }

    @media (min-width: ${sizes.tablet.minWidth}px) and (max-width: ${sizes.notebook.maxWidth}px) {
        margin-top: 0;
    }
`;

const DesktopSpan = styled(Desktop)`
    display: inline;
`;

const Location = styled('span')``;

export { TileSummary };
