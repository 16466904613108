import { DimensionData } from 'utils/common/types';
import { EntityMetadata } from 'utils/dataloaders/entityLoader';

import { ResultSet } from '@cubejs-client/core';
import { Entity } from 'utils/dataloaders/entityLoader';
// import { AccInventoryTypes, GroupedDataByDateType, InventoryOverTimeData } from '../../../types';
import { addDays, addHours, addMonths, addWeeks, addYears } from 'date-fns';
import { compose, groupBy, pathOr, prop } from 'ramda';
import {
    INVENTORY_OVER_TIME_COUNT,
    INVENTORY_OVER_TIME_OCCURRED_AT,
    INVENTORY_OVER_TIME_STATUS
} from 'utils/common/constants';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';
import { AccInventoryTypes, GroupedDataByDateType, InventoryOverTimeData } from './types';

const orderedDistanceToVenue: { [key: string]: number } = {
    '0 - 10': 0,
    '10 - 25': 1,
    '25 - 50': 2,
    '50 - 100': 3,
    '100 - 200': 4,
    '> 200': 5,
    Unknown: 6
};

class DistanceToVenueComparator {
    dimension: string;
    constructor(dimension: string) {
        this.dimension = dimension;
    }

    comparator = ((left: DimensionData, right: DimensionData) => {
        if (
            orderedDistanceToVenue[prop(this.dimension, left)] <
            orderedDistanceToVenue[prop(this.dimension, right)]
        ) {
            return -1;
        }
        if (
            orderedDistanceToVenue[prop(this.dimension, left)] >
            orderedDistanceToVenue[prop(this.dimension, right)]
        ) {
            return 1;
        }

        return 0;
    }) as (left: DimensionData, right: DimensionData) => number;
}

const getEntityLabelOrID = (entity: EntityMetadata): string => {
    const label = entity?.aspects?.presentation?.label;
    const id = entity?.entity_ref;
    return label && label !== '' ? label : id;
};

const getInventoryOverTimeChartData = (
    inventoryData: void | ResultSet<any>,
    data: Entity[],
    tenantTimezone: string,
    t: (s: string) => string,
    theme?: { colors: any }
): InventoryOverTimeData[] => {
    const inventoryDataNotOpen: GroupedDataByDateType[] = pathOr(
        [],
        ['loadResponse', 'results', '0', 'data'],
        inventoryData
    ).filter((data) => data[INVENTORY_OVER_TIME_STATUS] !== 'open');

    const totalCapacity = data[0]?.entity?.aspects?.inventory?.items_for_sale || 0;

    const groupByDateString = groupBy(
        compose(
            (date: string) => new Date(utcToZonedTime(date, tenantTimezone)).toISOString(),
            prop(INVENTORY_OVER_TIME_OCCURRED_AT) as any
        )
    );

    const [startDate, endDate] =
        inventoryDataNotOpen.length === 0
            ? [null, null]
            : [
                  new Date(+moment(inventoryDataNotOpen[0][INVENTORY_OVER_TIME_OCCURRED_AT])),
                  new Date(
                      +moment(
                          inventoryDataNotOpen[inventoryDataNotOpen.length - 1][
                              INVENTORY_OVER_TIME_OCCURRED_AT
                          ]
                      )
                  )
              ];

    const groupedDataByDate = groupByDateString(inventoryDataNotOpen);
    const range =
        startDate === null ? [] : createTimebands('day', startDate, endDate, tenantTimezone);
    const accInventory: AccInventoryTypes = {
        oversoldCount: 0,
        open: 0,
        killed: 0,
        held: 0,
        reserved: 0,
        sold: 0
    };

    const actualTransformedData = range.map((date) => {
        let subtract = 0;

        (groupedDataByDate[date] || []).forEach((value: any) => {
            const key: 'sold' | 'open' | 'held' | 'killed' | 'reserved' | 'oversoldCount' =
                value[INVENTORY_OVER_TIME_STATUS];
            const statusCount = Number(value[INVENTORY_OVER_TIME_COUNT]);
            accInventory[key] = statusCount;
        });
        Object.entries(accInventory).forEach(([key, value]) => {
            if (key !== 'open' && key !== 'oversoldCount') {
                subtract += value;
            }
        });

        accInventory.open = totalCapacity - subtract;
        accInventory.oversoldCount = 0;
        if (accInventory.open < 0) {
            accInventory.oversoldCount = Math.abs(accInventory.open);
            accInventory.open = 0;
        }
        if (totalCapacity === 0) {
            accInventory.oversoldCount = 0;
        }

        return { ...accInventory, date };
    });

    const chartData = actualTransformedData.reduce(
        (prev, data) => {
            const date = data.date;
            Object.entries(data).map((entrie) => {
                if (entrie[0] !== 'date') {
                    prev[entrie[0]] = prev[entrie[0]] || { name: entrie[0], data: [] };
                    prev[entrie[0]].name = entrie[0];
                    prev[entrie[0]].data.push({ x: +new Date(date), y: Number(entrie[1]) });
                }
            });
            return prev;
        },
        {} as { [key: string]: { name: string; data: [{ x: number; y: number }] } }
    );

    const retval = Object.values(chartData)
        .filter((data) => !data.data.every(({ y }: { y: number }) => y === 0))
        .map((data) => ({
            type: 'areaspline',
            name: t(data.name),
            // @ts-ignore
            color: theme ? theme.colors[`${data.name}ChartBar`] : '',
            data: data.data,
            marker: {
                symbol: 'square'
            }
        }));

    return retval;
};

const createTimebands = (
    granularity: string,
    startDate: Date,
    endDate: Date,
    tenantTimezone: string
) => {
    let currentDate = startDate;
    const timeband = [];
    let rangeFunction = addMonths;
    switch (granularity) {
        case 'hour':
            rangeFunction = addHours;
            break;
        case 'day':
            rangeFunction = addDays;
            break;
        case 'week':
            rangeFunction = addWeeks;
            break;
        case 'month':
            rangeFunction = addMonths;
            break;
        case 'year':
            rangeFunction = addYears;
            break;
    }

    while (currentDate <= endDate) {
        timeband.push(new Date(utcToZonedTime(currentDate, tenantTimezone)).toISOString());
        currentDate = rangeFunction(new Date(currentDate), 1);
    }

    return timeband;
};

export { getInventoryOverTimeChartData, getEntityLabelOrID, DistanceToVenueComparator };
