import { AxiosError } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ASInput } from 'components/common/input/ASInput';
import { ClearSearchInputButton } from './components/ClearSearchInputButton';
import { Overlay } from './components/Overlay';
import { AxiosErrorComp } from './SearchResults/AxiosError';
import { SearchResultsComp } from './SearchResults/SearchResults';
import { getFilteredQuery } from './utils';
import useDebounce from 'utils/common/debounce';
import {
    SearchResults,
    GlobalSearchQuery,
    SEARCHABLE_TYPE,
    TILE_TYPE,
    REGULAR,
    Hit
} from 'utils/api/globalSearch/types';

import { styled } from '@mui/material/styles';
import { SEARCH_ICON, SEARCH_ICON_THIN } from 'assets/iconConstants';
import { FilterValue } from 'utils/common/types';
import { Tracking } from 'externals/tracking';
import { sizes } from 'components/responsive/MediaQuery';
import useCheckIfMobile from 'utils/mobile/checkIfMobile';

const DEBOUNCE_VALUE = 1000;
const PAGE_SIZE = 10;
const MIN_SEARCHSTRING_LENGTH = 3;

export type GlobalSearchProps = {
    globalSearchAPI: (query: GlobalSearchQuery, pageSize: number) => Promise<SearchResults>;
    archetypeAndVariantsLimit?: SEARCHABLE_TYPE[];
    tileType?: TILE_TYPE;
    selectedEntityCallback?: React.Dispatch<Hit>;
    useOverlay?: boolean;
    setSearchOverlay?: React.Dispatch<boolean>;
    resultListHeight?: string;
    fullBorder?: boolean;
    zIndex?: number;
    placeholder?: string;
    position?: 'absolute' | 'static';
    keepOpenOnBlur?: boolean;
    currentSelection?: FilterValue[];
    isSelected?: (value: string) => boolean;
    onlyRecentEvents?: boolean;
    iconFill?: string;
    labelColor?: string;
    styledFor?: string;
    inputColor?: string;
    id?: string;
    fullWidth?: boolean;
    borderRadius?: string;
    borderWidth?: string;
    borderColor?: string;
    height?: string;
};

export type FilterType = keyof GlobalSearchQuery;
export type Filters = Map<FilterType, string>;

const GlobalSearch = ({
    globalSearchAPI,
    archetypeAndVariantsLimit,
    tileType = REGULAR,
    selectedEntityCallback,
    useOverlay = true,
    setSearchOverlay,
    resultListHeight,
    fullBorder = false,
    placeholder = 'globalSearch',
    position = 'absolute',
    keepOpenOnBlur = false,
    currentSelection = [],
    isSelected,
    zIndex = 2000,
    onlyRecentEvents = false,
    iconFill,
    labelColor,
    styledFor,
    inputColor,
    id,
    fullWidth,
    borderRadius = 'none',
    borderWidth,
    borderColor,
    height
}: GlobalSearchProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [globalSearchQuery, setGlobalSearchQuery] = React.useState<GlobalSearchQuery>();
    const [showResultsOrError, setShowResultsOrError] = React.useState<boolean>(false);

    const [results, setResults] = React.useState<SearchResults | null>(null);
    const [errors, setErrors] = React.useState<AxiosError | null>(null);

    const [debounceValue, setDebounceValue] = useDebounce<string>('', DEBOUNCE_VALUE);
    const [searchString, setSearchString] = React.useState<string>('');

    const [aggregationFilters, setAggregationFilters] = React.useState<Filters>(new Map());

    const ref = React.useRef<HTMLDivElement>(null);

    const isMobile = useCheckIfMobile();
    const inputMarginTop = isMobile ? false : true;

    React.useEffect(() => {
        const handleClickOutsideDatePicker = (event: any) => {
            if (
                showResultsOrError &&
                ref.current &&
                !ref.current.contains(event.target) &&
                !keepOpenOnBlur
            ) {
                setShowResultsOrError(false);
            }
        };

        document.addEventListener('click', handleClickOutsideDatePicker, true);
        setSearchOverlay && setSearchOverlay(showResultsOrError);
    }, [showResultsOrError]);

    React.useEffect(() => {
        const search = async () => {
            if (globalSearchQuery) {
                setLoading(true);
                try {
                    const newResults = await globalSearchAPI(globalSearchQuery, PAGE_SIZE);
                    if (globalSearchQuery.page !== 0 && results) {
                        newResults.hits = [...results.hits, ...newResults.hits];
                    }
                    setResults(newResults);
                    setErrors(null);
                } catch (error) {
                    setResults(null);
                    setErrors(error as AxiosError);
                } finally {
                    setLoading(false);
                    setShowResultsOrError(true);
                }
            }
        };
        search();
    }, [globalSearchQuery, globalSearchAPI]);

    React.useEffect(() => {
        if (debounceValue.length >= MIN_SEARCHSTRING_LENGTH) {
            setPage(0);
            setGlobalSearchQuery(
                getFilteredQuery(
                    aggregationFilters,
                    debounceValue,
                    archetypeAndVariantsLimit,
                    onlyRecentEvents
                )
            );
            Tracking.trackGoal('Global search', {
                searchQuery: debounceValue
            });
        } else {
            setResults(null);
            setErrors(null);

            if (aggregationFilters.size <= 0) {
                setShowResultsOrError(false);
            }
        }
    }, [debounceValue]);

    const onLoadMore = () => {
        if (results && results.hits.length < results.page.totalElements) {
            const nextPage = page + 1;

            setPage(nextPage);
            setGlobalSearchQuery({ ...globalSearchQuery, page: nextPage });
        }
    };

    const onLoadLess = () => {
        if (results && results.hits.length < results.page.totalElements) {
            const nextPage = page - 1;

            setPage(nextPage);
            setGlobalSearchQuery({ ...globalSearchQuery, page: nextPage });
        }
    };

    const onSearchChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const searchString = event.target.value;
        setSearchString(searchString);
        setDebounceValue(searchString.trim());
    };

    const onChangeFilter = (filters: Filters) => {
        const filteredQuery: GlobalSearchQuery = getFilteredQuery(
            filters,
            searchString,
            archetypeAndVariantsLimit,
            onlyRecentEvents
        );

        setPage(0);
        setAggregationFilters(filters);

        if (searchString.length >= MIN_SEARCHSTRING_LENGTH) {
            setGlobalSearchQuery(filteredQuery);
        }
    };

    const resetSearchInput = () => {
        setSearchString('');
        setResults(null);
        setErrors(null);
        setShowResultsOrError(false);
        setAggregationFilters(new Map());
        setPage(0);
    };

    const onFocus = () => {
        if (results || errors) {
            setShowResultsOrError(true);
        }
    };

    return (
        <React.Fragment>
            {styledFor === 'segmentBuilder' ? (
                <SegmentBuilderOuterWrapper
                    zIndex={zIndex}
                    fullWidth={fullWidth}>
                    <SegmentBuilderWrapper
                        ref={ref}
                        zIndex={zIndex}
                        fullWidth={fullWidth}>
                        <GlobalSearchInput
                            placeholder={t(placeholder)}
                            iconName={SEARCH_ICON_THIN}
                            onChange={onSearchChange}
                            value={searchString}
                            fullWidth
                            onFocus={onFocus}
                            loading={loading}
                            iconFill={iconFill}
                            labelColor={labelColor}
                            size={1.3}
                            styledFor={styledFor}
                            inputColor={inputColor}
                            marginTop={inputMarginTop}
                        />
                        <ClearSearchInputButton
                            onClickReset={resetSearchInput}
                            searchString={searchString}
                            styledFor={styledFor}
                        />
                        <AxiosErrorComp
                            error={errors}
                            show={showResultsOrError}
                        />
                        <SearchResultsComp
                            position={position}
                            searchResults={results}
                            onChangeFilter={onChangeFilter}
                            onLoadMore={onLoadMore}
                            onLoadLess={onLoadLess}
                            show={showResultsOrError}
                            aggregationFilters={aggregationFilters}
                            loading={loading}
                            tileType={tileType}
                            selectedEntityCallback={selectedEntityCallback}
                            resetSearchInput={resetSearchInput}
                            resultListHeight={resultListHeight}
                            currentSelection={currentSelection}
                            isSelected={isSelected}
                            styledFor={styledFor}
                            page={page}
                        />
                    </SegmentBuilderWrapper>
                    <Overlay
                        show={showResultsOrError}
                        campaignShow={useOverlay}
                        styledFor={styledFor}
                    />
                </SegmentBuilderOuterWrapper>
            ) : (
                <OuterWrapper zIndex={zIndex}>
                    <Wrapper
                        ref={ref}
                        fullBorder={fullBorder}
                        zIndex={zIndex}
                        borderRadius={borderRadius}
                        borderWidth={borderWidth}
                        borderColor={borderColor}
                        height={height}>
                        <GlobalSearchInput
                            placeholder={t(placeholder)}
                            iconName={SEARCH_ICON}
                            onChange={onSearchChange}
                            value={searchString}
                            fullWidth
                            onFocus={onFocus}
                            loading={loading}
                            id={id}
                            marginTop={inputMarginTop}
                        />
                        <ClearSearchInputButton
                            onClickReset={resetSearchInput}
                            searchString={searchString}
                        />
                        <AxiosErrorComp
                            error={errors}
                            show={showResultsOrError}
                        />
                        <SearchResultsComp
                            position={position}
                            searchResults={results}
                            onChangeFilter={onChangeFilter}
                            onLoadMore={onLoadMore}
                            show={showResultsOrError}
                            aggregationFilters={aggregationFilters}
                            loading={loading}
                            tileType={tileType}
                            selectedEntityCallback={selectedEntityCallback}
                            resetSearchInput={resetSearchInput}
                            resultListHeight={resultListHeight}
                            currentSelection={currentSelection}
                            isSelected={isSelected}
                        />
                    </Wrapper>
                    <Overlay
                        show={showResultsOrError}
                        campaignShow={useOverlay}
                    />
                </OuterWrapper>
            )}
        </React.Fragment>
    );
};

const OuterWrapper = styled('div')<{ zIndex?: number }>`
    width: 100%;
    height: 100%;
    z-index: ${({ zIndex }) => zIndex};
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};

    @media (max-width: ${sizes.notebook.minWidth}px) {
        border-radius: 50px;
    }
`;

const GlobalSearchInput = styled(ASInput)`
    grid-area: input;
`;

const Wrapper = styled('div')<{
    fullBorder: boolean;
    zIndex?: number;
    borderRadius?: string;
    borderWidth?: string;
    borderColor?: string;
    height?: string;
}>`
    display: grid;
    grid-template-areas:
        'input button'
        'results results';
    grid-template-columns: auto ${({ theme }) => theme.space.double};
    align-items: center;
    width: 100%;
    height: ${({ height }) => (!height ? '100%' : height)};
    ${({ fullBorder }) => (fullBorder ? 'border' : 'border-left')}: ${({ borderWidth }) =>
        !borderWidth ? '1px' : borderWidth} solid ${({ theme, borderColor }) =>
        !borderColor ? theme.colors.dividers : theme.colors.campaignSetupInputButtonBorder};
    position: relative;
    z-index: ${({ zIndex }) => zIndex};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 'none')};
    @media (max-width: ${sizes.tablet.minWidth}px) {
        border: none;
    }
`;

export { GlobalSearch };

const SegmentBuilderWrapper = styled('div')<{ zIndex?: number; fullWidth?: boolean }>`
    display: grid;
    grid-template-areas:
        'input button'
        'results results';
    grid-template-columns: auto ${({ theme }) => theme.space.double};
    align-items: center;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '457px')};
    height: 100%;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: ${({ zIndex }) => zIndex};
    border: 1px solid ${({ theme }) => theme.colors.segmentBuilderBorder};
    border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const SegmentBuilderOuterWrapper = styled('div')<{ zIndex?: number; fullWidth?: boolean }>`
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '457px')};
    height: 100%;
    z-index: ${({ zIndex }) => zIndex};
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    margin: 0 auto;
`;
