import { Theme } from '@mui/material/styles';

export const getChartColors = (theme: Theme) => {
    const chartColors = Object.entries(theme.colors)
        .filter(([key]) => {
            return key.startsWith('chart');
        })
        .map((color) => {
            return color[1];
        });
    return chartColors;
};

export const getDefaultChartConfig = (): Highcharts.Options => {
    const properties: Highcharts.Options = {
        chart: {
            spacingBottom: 5,
            style: {
                fontFamily: `'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif`
            }
        },
        credits: {
            enabled: false
        },
        title: {
            text: undefined,
            margin: 0
        },
        exporting: {
            enabled: false
        },
        xAxis: {
            type: 'category'
        },
        legend: {
            enabled: false
        },
        tooltip: {
            hideDelay: 0,
            style: {
                fontFamily: `'Lato', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif`
            }
        },
        plotOptions: {
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    }
                }
            }
        },
        yAxis: {
            allowDecimals: false,
            title: {
                text: 'Tickets'
            },
            softMin: 0
        },
        series: []
    };

    return properties;
};
