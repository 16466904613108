import { PROVISIONING_API_URL } from '../urls';
import { ASRequest } from '../request';
import { FeatureFlag, MixpanelSettings } from 'stores/settings';

export interface TenantSettings {
    tenant: string;
    tenantDisplayName: string | null;
    tenantTimezone: string;
    env: string;
    settings: {
        label: string;
        locale: string;
        ASMap?: ASMapSettings;
        tenantIcon?: string;
        mixpanelSettings: MixpanelSettings;
        amountSpentBuckets?: string;
        sparkpostIpPool?: string;
    };
    featureFlags: FeatureFlag[];
    availableService: string[];
    availableDashboards: string[];
    excludeDashboardComponent: string[];
    dictionary: { [key: string]: string };
    subtenants?: Array<{ key: string; value: string }>;
    partitions?: Array<{ key: string; value: string }>;
    excludeSegments: string[];
    sparkpostSendingDomains: string[];
}

export interface CustomRole {
    name: string;
    availableDashboards: string[];
    observations: string[];
    excludeDashboardComponent: string[];
    featureFlags: FeatureFlagArray;
}

export type FeatureFlagArray = Array<{
    name: string;
    isEnabled: boolean;
}>;
export interface ASMapSettings {
    zoom?: number;
    latitude?: number;
    longitude?: number;
    zoomLevels: {
        HASC1?: ZoomLevelSettings;
        HASC2?: ZoomLevelSettings;
        HASC3?: ZoomLevelSettings;
        POSTAL?: ZoomLevelSettings;
    };
}

export interface ZoomLevelSettings {
    enabled: boolean;
    zoom?: number;
}

export interface UserPreferences {
    locale: string;
}

interface FacebookTokenRequest {
    checksum: string;
    code: string;
}

export const ProvisioningAPI = {
    getResolvedSettings: async function () {
        const results = await ASRequest.request<any>({
            method: 'get',
            url: `${PROVISIONING_API_URL}/tenants/resolved-settings`
        });

        return results.data;
    },

    updateUserPreferences: async function (data: UserPreferences) {
        return ASRequest.request({
            method: 'post',
            url: `${PROVISIONING_API_URL}/user_preferences`,
            data
        });
    },
    getUserPreferences: async function () {
        const results = await ASRequest.request<UserPreferences>({
            method: 'get',
            url: `${PROVISIONING_API_URL}/user_preferences`
        });

        return results.data;
    },

    updateTenantSettings: async function (data: TenantSettings) {
        return ASRequest.request({
            method: 'put',
            url: `${PROVISIONING_API_URL}/tenants/settings`,
            data
        });
    },
    getTenantSettings: async function () {
        const results = await ASRequest.request<TenantSettings>({
            method: 'get',
            url: `${PROVISIONING_API_URL}/tenants/settings`
        });

        return results.data;
    },

    createTenantSettings: async function (data: TenantSettings) {
        return ASRequest.request<TenantSettings>({
            method: 'post',
            url: `${PROVISIONING_API_URL}/tenants/settings`,
            data
        });
    },

    getRoles: async function () {
        return ASRequest.request<CustomRole[]>({
            method: 'get',
            url: `${PROVISIONING_API_URL}/tenants/roles`
        });
    },

    createRole: async function (data: CustomRole) {
        return ASRequest.request<CustomRole[]>({
            method: 'post',
            url: `${PROVISIONING_API_URL}/tenants/roles`,
            data
        });
    },

    updateRole: async function (data: CustomRole) {
        return ASRequest.request<CustomRole[]>({
            method: 'put',
            url: `${PROVISIONING_API_URL}/tenants/roles`,
            data
        });
    },

    storeFacebookToken: async function (data: FacebookTokenRequest) {
        return ASRequest.request<FacebookTokenRequest>({
            method: 'post',
            url: `${PROVISIONING_API_URL}/facebook/`,
            data
        });
    },

    updateApiKeys: async function (data: Record<string, object>) {
        return ASRequest.request<Record<string, object>>({
            method: 'patch',
            url: `${PROVISIONING_API_URL}/tenants/settings/api-keys`,
            data
        });
    }
};
