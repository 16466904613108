import auth0 from 'auth0-js';
import { SetUser, SetAuth0Error } from '../../types/authTypes';

const defaultAuth0Options: auth0.AuthOptions = {
    domain: 'activitystream.eu.auth0.com',
    clientID: 'bMcfEICLEcZ10kMVWzytBr4p5jNw0CQx',
    responseType: 'token id_token',
    scope: 'openid profile',
    audience: 'https://activitystream.com/service/core',
    redirectUri: `${window.location.origin}`
};

const authorizeOptions: auth0.AuthorizeOptions = {
    redirectUri: `${window.location.origin}`
};

const logoutOptions: auth0.LogoutOptions = {
    returnTo: `${window.location.origin}`
};

const webAuth = new auth0.WebAuth(defaultAuth0Options);

export const authorize = (): void => {
    webAuth.authorize(authorizeOptions);
};

const getUserInfo = (authResult: any, updateUser: SetUser, updateError: SetAuth0Error): void => {
    if (authResult && authResult.accessToken) {
        webAuth.client.userInfo(authResult.accessToken, function (err, user) {
            if (err) {
                updateError(err);
            }
            updateUser(user, authResult.accessToken);
        });
    }
};

export const checkSession = (setUser: SetUser, setError: SetAuth0Error): void => {
    webAuth.checkSession({}, function (err, authResult) {
        if (err) {
            setError(err);
            // authorize();  // can used to login again automatically, if we want.
        }

        getUserInfo(authResult, setUser, setError);
    });
};

export const logout = (): void => {
    webAuth.logout(logoutOptions);
};
