import { Just as _Just } from 'utils/maybe/Just';
import { Nothing as _Nothing } from 'utils/maybe/Nothing';
import { Maybe as _Maybe } from './Maybe';
import { curry } from 'ramda';

export const Just = <T>(value: T) => _Just.of(value);
export const Nothing = <T>() => new _Nothing<T>();

export const fromFalsey = <T>(value: T | void | null | undefined | false): _Maybe<T> =>
    value ? Just(value) : Nothing();

export const Maybe = <T>(value: T | null | void | undefined): _Maybe<T> =>
    value == null ? Nothing() : Just(value);

/* eslint-disable */
//TODO: revisit the Ramda types since there has been a version bump.
//@ts-ignore
export const find: Find = curry(<T>(predicate: (val: T) => any, list: T[]) =>
    Maybe(list.find(predicate))
);
/* eslint-enable */

interface Find {
    <T, V extends T>(predicate: (val: T) => val is V): (list: T[]) => _Maybe<V>;
    <T, V extends T>(predicate: (val: T) => val is V, list: T[]): _Maybe<V>;
    <T>(predicate: (val: T) => any): (list: T[]) => _Maybe<T>;
    <T>(predicate: (val: T) => any, list: T[]): _Maybe<T>;
}

export const last = <T>(arr: T[]) => Maybe(arr[arr.length - 1]);
