import React from 'react';
import { AuthContextInterface } from '../../types/authTypes';

const defaultValue: AuthContextInterface = {
    user: null,
    error: null,
    token: '',
    setUser: () => null,
    setError: () => null
};

export const AuthContext = React.createContext<AuthContextInterface>(defaultValue);
