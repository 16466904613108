import { Maybe } from 'utils/maybe';

export function getQueryParam<T>(
    param: string,
    defaultValue: T,
    _transform?: (value: string) => T
): T {
    const queryParams = new URLSearchParams(location.search);
    const transform = _transform || ((value) => value as unknown as T);
    return Maybe(queryParams.get(param)).map(transform).getOrElse(defaultValue);
}

export const updateQueryParam = (param: string) => (newValue: string) => {
    const queryParams = new URLSearchParams(location.search);
    if (!newValue) {
        queryParams.delete(param);
    } else {
        queryParams.set(param, newValue);
    }
    const search = queryParams.toString();
    history.replaceState(null, '', `${location.pathname}${search.length ? `?${search}` : ''}`);
};

export const clearQueryParam = () => {
    const queryParams = new URLSearchParams();
    queryParams.set('currentStep', 'template');
    queryParams.set('currentCube', 'template');
    queryParams.set('filters', '[]');
    queryParams.set('demographyFilters', '[]');
    const search = queryParams.toString();
    history.replaceState(null, '', `${location.pathname}${search.length ? `?${search}` : ''}`);
};
