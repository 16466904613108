import React from 'react';
import { Checkbox } from '@mui/material';
import { SearchResults } from 'utils/api/globalSearch/types';
import { styled } from '@mui/material/styles';
import { Buckets } from '../global-search/Filters/Buckets';
import { FilterItemsList } from '../global-search/Filters/FilterItemsList';
import { Filters } from '../global-search/GlobalSearch';
import { SearchResultsCount } from '../global-search/SearchResults/SearchResultsCount';

interface SearchResultsMetaData {
    searchResults: SearchResults | null;
    onChangeFilter: (filters: Filters) => void;
    aggregationFilters: Filters;
    styledFor?: string;
}

const SearchResultsFilters = ({
    searchResults,
    onChangeFilter,
    aggregationFilters,
    styledFor
}: SearchResultsMetaData) => (
    <Wrapper>
        <SearchResultsCount
            searchResults={searchResults}
            styledFor={styledFor}
        />
        <Buckets
            aggregation={searchResults?.aggregation}
            onClickBucket={onChangeFilter}
            aggregationFilters={aggregationFilters}
            styledFor={styledFor}
            muiCheckbox={Checkbox}
        />
        <FilterItemsList
            aggregationFilters={aggregationFilters}
            onRemoveFilter={onChangeFilter}
            styledFor={styledFor}
        />
    </Wrapper>
);

const Wrapper = styled('div')`
    display: flex;
    background-color: ${({ theme }) => theme.colors.white};

    flex-direction: column;
`;

export { SearchResultsFilters };
