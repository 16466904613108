/* eslint-disable @typescript-eslint/no-unused-vars */
import { Maybe, MCata } from 'utils/maybe/Maybe';

export class Nothing<T> implements Maybe<T> {
    public static of<T>(val?: T): Maybe<T> {
        return Nothing.NOTHING as Maybe<T>;
    }

    private static NOTHING = new Nothing();
    public readonly isNothing = true;
    public readonly isJust = false;

    public ap<A, B>(this: Nothing<(val: A) => B>, b: Maybe<A>) {
        return this as any as Maybe<B>;
    }

    public map<V>(project: (val: T) => V) {
        return this as any as Maybe<V>;
    }

    public chain<V>(project: (val: T) => Maybe<V>) {
        return this as any as Maybe<V>;
    }

    public filter(predicate: (val: T) => any) {
        return this;
    }

    public cata<TJust, TNothing>(cata: MCata<T, TJust, TNothing>) {
        return cata.Nothing();
    }

    public getOrElse<V>(value: V) {
        return value;
    }

    public getOrThrow(supplier: () => Error = () => new Error('getOrThrow from Nothing')): T {
        throw supplier();
    }

    public orElse<V>(maybeSupplier: () => Maybe<V>): Maybe<T | V> {
        return maybeSupplier();
    }

    public equals(other: Maybe<any>) {
        return other.isNothing;
    }
}
