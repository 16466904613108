import React, { useContext } from 'react';
import { isEmpty } from 'ramda';
import Highcharts from 'highcharts';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import { DateRange } from '../../common/datepicker/types';
import { ASButton } from '../../common/button';
import { DateFormatContext } from '../../common/datepicker';
import { Tile } from '../../common/tile';
import { Aspect } from '../../common/tile/TileAspect';
import { sizes } from '../../responsive/MediaQuery';
import { mapTicketStatusData, mapInventoryAspects, mapCampaignStatusData } from './utils';
import { InventoryData } from 'utils/dataloaders/inventoryLoader';
import { TemplateViewProps } from 'views/dashboards/Tools/CampaignManagement/types';
import { Entity, entityLoader } from 'utils/dataloaders/entityLoader';
import { filterValueCheck } from 'utils/filter/filterCheck';
import { ASCubeFilter, FilterEvent } from 'utils/common/types';
import { settingsStore } from 'stores/settings';

export interface TopListProp {
    title: string;
    location: string;
    date?: string;
    link?: string;
    aspects: Aspect[];
    inventoryAspects?: Aspect[];
    inventory?: InventoryData | null;
    entityText: string;
    entityRef: string;
    entityType?: string;
    thumbnail?: string;
    customThumbnail?: string;
    nextVisit?: string;
    tileEntityType?: string;
    eventTitle?: string;
    relatedEventName?: string;
    relatedEventThumbnail?: string;
    relatedEventEntityRef?: string;
    relatedEntities?: string[];
    emailCampaignAspects?: Aspect[];
}

const TopList = ({
    filters,
    filterDimension,
    items,
    limit,
    onFilter,
    setLimit,
    total,
    selectedTile,
    tileEntityType,
    salesTime,
    datePickerPeriod,
    customFlag,
    metric,
    setTemplateViewProps,
    setCampaignFetchFailing,
    isText,
    getTemplate
}: {
    filters: ASCubeFilter[];
    filterDimension: string;
    items: TopListProp[];
    limit: number;
    onFilter: React.Dispatch<FilterEvent>;
    setLimit: (limit: number) => void;
    total: number;
    selectedTile?: string;
    tileEntityType?: string;
    salesTime?: string;
    datePickerPeriod?: DateRange;
    customFlag?: string;
    metric?: string;
    setTemplateViewProps?: React.Dispatch<TemplateViewProps | undefined>;
    setCampaignFetchFailing?: React.Dispatch<boolean>;
    isText?: boolean;
    getTemplate?: (entityRef: string) => Promise<TemplateViewProps>;
}) => {
    const [selected, setSelected] = React.useState<string[]>([]);
    const { t } = useTranslation();

    const toplistRegex = /\..+Ref$/;

    React.useEffect(() => {
        const selectedFilters = filters.reduce(
            (previous: string[], filter) =>
                filter.dimension?.match(toplistRegex) && filter.values
                    ? [...previous, ...filter.values.map((value) => filterValueCheck(value))]
                    : previous,
            []
        );
        setSelected(selectedFilters);
    }, []);

    React.useEffect(() => {
        const selectedFilters = filters.reduce(
            (previous: string[], filter) =>
                filter.dimension?.match(toplistRegex) && filter.values
                    ? [...previous, ...filter.values.map((value) => filterValueCheck(value))]
                    : previous,
            []
        );
        setSelected((prevState) => {
            const nextState = prevState.filter((item) => selectedFilters.includes(item));
            return nextState;
        });
    }, [filters]);

    React.useEffect(() => {
        if (selectedTile?.length === 0) {
            return;
        }
        if (selectedTile && selectedTile.length > 0) {
            return setSelected([...selected, selectedTile]);
        }
    }, [selectedTile]);

    const onSelect = (hasFilter: boolean, entityRef: string) => {
        if (hasFilter) {
            setSelected([...selected, entityRef]);
        } else {
            setSelected([...selected.filter((k) => k !== entityRef)]);
        }
    };

    const EventTile = (item: TopListProp, index: number) => {
        const deselected: boolean = selected.length > 0 && !selected.includes(item.entityRef);

        return (
            <TileWrapper
                filters={filters}
                key={`${item.entityRef}${index}`}
                deselected={deselected}
                onSelect={onSelect}
                item={item}
                filterDimension={filterDimension}
                onFilter={onFilter}
                tileEntityType={tileEntityType}
                salesTime={salesTime as string}
                datePickerPeriod={datePickerPeriod as DateRange}
                customFlag={customFlag}
                metric={metric}
                setTemplateViewProps={setTemplateViewProps}
                setCampaignFetchFailing={setCampaignFetchFailing}
                isText={isText}
                getTemplate={getTemplate}
            />
        );
    };

    const onShowMore = () => {
        setLimit(limit + 10);
    };

    const renderShowMoreButton = () =>
        total > limit && <StyledASButton onClick={onShowMore}>{t('loadMore')}</StyledASButton>;

    return (
        <React.Fragment>
            <TopListContainer>{items.map(EventTile)}</TopListContainer>
            {renderShowMoreButton()}
            <div>
                {t('showing')} {Math.min(limit, total)} {t('of')} {total}
            </div>
        </React.Fragment>
    );
};

const TileWrapper = ({
    deselected,
    filters,
    onSelect,
    filterDimension,
    item,
    onFilter,
    tileEntityType,
    salesTime,
    datePickerPeriod,
    customFlag,
    metric,
    setTemplateViewProps,
    setCampaignFetchFailing,
    isText,
    getTemplate
}: {
    deselected: boolean;
    filters: ASCubeFilter[];
    onSelect: (hasFilter: boolean, i: string) => void;
    filterDimension: string;
    item: TopListProp;
    onFilter: React.Dispatch<FilterEvent>;
    tileEntityType?: string;
    salesTime: string;
    datePickerPeriod: DateRange;
    customFlag?: string;
    metric?: string;
    setTemplateViewProps?: React.Dispatch<TemplateViewProps | undefined>;
    setCampaignFetchFailing?: React.Dispatch<boolean>;
    isText?: boolean;
    getTemplate?: (entityRef: string) => Promise<TemplateViewProps>;
}) => {
    // Provide state and callback function to event tile for preview purposes
    const theme = useTheme();
    const { t } = useTranslation();
    const { featureFlags } = useContext(settingsStore);
    const [isExpanded, setExpanded] = React.useState(false);
    const [hasFilter, setHasFilter] = React.useState(false);
    const [nextVisit, setNextVisit] = React.useState<string | null>(null);
    const [expandLoading, setExpandLoading] = React.useState<boolean>(false);
    const [inventoryAspects, setInventoryAspects] = React.useState<Aspect[]>([]);
    const [chartData, setChartData] = React.useState<Highcharts.SeriesBarOptions[]>([]);
    const [chartCategories, setChartCategories] = React.useState<Array<string>>([]);
    const [entity, setEntity] = React.useState<Entity | undefined>(undefined);
    const { formatDateLong } = React.useContext(DateFormatContext);

    React.useEffect(() => {
        if (item.entityRef.includes('Customer') && item.nextVisit) {
            if (isFutureDate(new Date(item.nextVisit))) {
                setNextVisit(`${t('nextVisit')}: ${formatDateLong(new Date(item.nextVisit))}`);
            } else {
                setNextVisit(`${t('nextVisit')}: -`);
            }
        }
    }, []);

    React.useEffect(() => {
        if (filters.length) {
            const selectedFilters = filters.reduce(
                (previous: string[], filter) =>
                    filter.values
                        ? [...previous, ...filter.values.map((value) => filterValueCheck(value))]
                        : previous,
                []
            );
            setHasFilter(selectedFilters.includes(item.entityRef));
        } else {
            setHasFilter(false);
        }
    }, [filters]);

    React.useEffect(() => {
        if (isExpanded) {
            setExpandLoading(true);
            entityLoader
                .load(item.entityRef)
                .then((value) => setEntity(value))
                .finally(() => {
                    setExpandLoading(false);
                });
        }
    }, [isExpanded]);

    React.useEffect(() => {
        if (item.inventory && !isEmpty(entity)) {
            const mappedInventoryChartData: any = mapTicketStatusData(
                item.inventory,
                theme,
                t,
                featureFlags.showPriceCategoryInventory,
                featureFlags.hideHoldAndReserved
            );
            setChartData(mappedInventoryChartData[0]);
            setChartCategories(mappedInventoryChartData[1]);

            const mappedInventoryAspects: Aspect[] = mapInventoryAspects(
                item.inventory,
                theme,
                t,
                featureFlags.hideHoldAndReserved
            );
            setInventoryAspects(mappedInventoryAspects);
        } else if (item.emailCampaignAspects) {
            const mappedCampaignInventoryChartData: any = mapCampaignStatusData(
                item.emailCampaignAspects && item.emailCampaignAspects,
                theme,
                t
            );
            setChartData(mappedCampaignInventoryChartData[0]);
            setChartCategories(mappedCampaignInventoryChartData[1]);

            setInventoryAspects(item.emailCampaignAspects && item.emailCampaignAspects);
        }
    }, [entity, item]);

    const handleChange = () => {
        setExpanded(!isExpanded);
    };
    const handleFilterChange = () => {
        const breadcrumbValue = item.title;
        onSelect(!hasFilter, item.entityRef);
        if (hasFilter) {
            onFilter({
                type: 'remove',
                dimension: filterDimension,
                value: item.entityRef
            });
        } else {
            onFilter({
                type: 'add',
                dimension: filterDimension,
                value: { value: item.entityRef, label: breadcrumbValue }
            });
        }
    };

    return (
        <Tile
            aspects={item.aspects}
            inventoryAspects={inventoryAspects}
            date={item.date}
            expanded={isExpanded}
            expandLoading={expandLoading}
            chartData={chartData}
            chartCategories={chartCategories}
            entityRef={item.entityRef}
            entityType={item.entityType}
            entity={entity}
            hasFilter={hasFilter}
            handleFilterChange={handleFilterChange}
            handleChange={handleChange}
            link={
                item.link
                    ? item.link
                    : item.relatedEventEntityRef
                      ? `/activity-stream/EntityOverview/${item.relatedEventEntityRef}`
                      : `/activity-stream/EntityOverview/${item.entityRef}`
            }
            location={item.location}
            title={item.title}
            deselected={deselected && !hasFilter}
            thumbnail={item.thumbnail || item.relatedEventThumbnail || undefined}
            customThumbnail={item.customThumbnail || undefined}
            nextVisit={nextVisit}
            relatedEventEntityRef={item.relatedEventEntityRef}
            eventTitle={item.relatedEventName}
            tileEntityType={tileEntityType}
            salesTime={salesTime}
            datePickerPeriod={datePickerPeriod}
            inventory={item.inventory}
            customFlag={customFlag}
            metric={metric}
            setTemplateViewProps={setTemplateViewProps}
            setCampaignFetchFailing={setCampaignFetchFailing}
            isText={isText}
            getTemplate={getTemplate}
        />
    );
};

export { TopList };

const TopListContainer = styled('div')`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.single};
    min-width: 0;

    @media (max-width: ${sizes.desktop.minWidth}px) {
        display: block;
    }
`;

const StyledASButton = styled(ASButton)`
    height: ${({ theme }) => theme.space.triple};
    width: 100%;
    margin: ${({ theme }) => theme.space.single} auto;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.frame};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    color: ${({ theme }) => theme.colors.text};
    &:hover {
        background-color: ${({ theme }) => theme.colors.secondary};
    }
`;

export const isFutureDate = (date: Date) => date.getTime() > new Date().getTime();
