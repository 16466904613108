import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Spinner } from './Spinner';

interface LoadingConatinerProps {
    size?: number;
    fixedPosition?: boolean;
    top?: string;
    noBackgroundColor?: boolean;
    color?: string;
}

export const LoadingContainer = ({
    size,
    fixedPosition,
    top,
    noBackgroundColor,
    color
}: LoadingConatinerProps) => {
    const theme: Theme = useTheme();
    return (
        <Container
            fixedPosition={fixedPosition || false}
            top={top}
            noBackgroundColor={noBackgroundColor}>
            <Spinner
                size={size || 80}
                color={color || theme.colors?.chart1}
            />
        </Container>
    );
};

const Container = styled('div')<{
    fixedPosition: boolean;
    top?: string;
    noBackgroundColor?: boolean;
}>`
    position: ${(props) => (props.fixedPosition ? 'fixed' : 'absolute')};
    top: ${({ top }) => (top ? top : 0)};
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme, noBackgroundColor }) =>
        noBackgroundColor ? 'unset' : `${theme.colors?.muted}80`};
    z-index: 500;
    display: flex;
    justify-content: space-around;

    opacity: 0;

    animation: fadeIn 0s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;
