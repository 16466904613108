// This code takes the external react-hot-toast library and customizes it to Activity Stream's needs, look and feel.
// Exported items are:
//   - The Toaster component which should be put in the jsx.
//   - The toast component which contains methods to execute toasts.
import React from 'react';
import toast, { Toaster, ToastBar, ToastPosition, ToastOptions } from 'react-hot-toast';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { Theme } from '@mui/material/styles';

interface ToasterProps {
    svgIconName?: string;
    svgIconSize?: number;
    svgIconFill?: string;
    svgIconStroke?: string;
}

enum ToastType {
    success,
    failure
}

const toastStyle = (theme: Theme, toastType: ToastType): React.CSSProperties | undefined => ({
    borderTop: `1px solid ${theme.colors.dividers}`,
    borderBottom: `1px solid ${theme.colors.dividers}`,
    borderRight: `1px solid ${theme.colors.dividers}`,
    borderLeft: `5px solid ${
        toastType === ToastType.success ? theme.colors.success : theme.colors.failure
    }`,
    borderRadius: 0,
    fontSize: `${theme.fontSizes.medium}`,
    backgroundColor: `${theme.colors.white}`,
    padding: '10px',
    maxWidth: '360px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    width: 'fit-content'
});

const toastOptions = (
    theme: Theme,
    toastType: ToastType,
    position: ToastPosition | undefined,
    duration: number | undefined
): ToastOptions => ({
    position: `${position ?? 'bottom-center'}` as ToastPosition | undefined,
    duration: duration ?? 4000,
    style: toastStyle(theme, toastType)
});

interface ToastProps {
    message: string;
    theme: Theme;
    position?: ToastPosition;
    duration?: number;
}

// Customizing react-hot-toast's Toaster component:
const Component = ({ svgIconName, svgIconSize, svgIconFill, svgIconStroke }: ToasterProps) => (
    <Toaster>
        {(t) => (
            <ToastBar toast={t}>
                {({ message }) => (
                    <>
                        {svgIconName && (
                            <CustomSvgIcon
                                iconName={svgIconName}
                                size={svgIconSize ?? 1}
                                width="fit-content"
                                fill={svgIconFill ?? 'keepOriginalColors'}
                                stroke={svgIconStroke ?? 'keepOriginalColors'}
                            />
                        )}
                        {message}
                    </>
                )}
            </ToastBar>
        )}
    </Toaster>
);

// Customizing react-hot-toast's toast:
const methods = {
    success: ({ message, theme, position, duration }: ToastProps) => {
        toast.success(message, toastOptions(theme, ToastType.success, position, duration));
    },

    failure: ({ message, theme, position, duration }: ToastProps) => {
        toast.error(message, toastOptions(theme, ToastType.failure, position, duration));
    }
};

// Finally exporting the customizations, ending up using the original names from the react-hot-toast library:
export { Component as Toaster, methods as toast };
