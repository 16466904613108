import React from 'react';
import { compose, groupBy, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import { styled } from '@mui/material/styles';
import { LoadingContainer } from '../common/loader';
import { ASTable } from '../common/table';
import {
    CustomersTableProps,
    Row,
    Sorting,
    Pagination,
    OnChange,
    OnChangeParams,
    Direction,
    Customer,
    SectionRowSeat
} from '../common/table/types';
import { prepareCustomerTablePage, tableLinkHandler } from '../common/table/utils';
import { StyledTooltip } from '../commonStyles';
import {
    CUSTOMERS_COUNTRY,
    CUSTOMERS_CITY,
    CUSTOMERS_LABEL,
    DESC,
    TICKETS_ROW,
    TICKETS_SEAT,
    TICKETS_SECTION,
    TRANSACTIONS_BUYER_REF,
    TRANSACTIONS_ITEMCOUNT,
    TRANSACTIONS_REVENUE
} from 'utils/common/constants';
import { cubejsApi } from 'utils/api/CubeAPI';

import { formatCurrencyString } from 'utils/formatting/currency';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { SEATING } from 'assets/iconConstants';

import { DimensionData, OrderByType } from 'utils/common/types';
import { formatDecimalNumber, formatWholeNumber } from 'utils/formatting/numbers';
import { settingsStore } from 'stores/settings';
import { getCustomersTableData } from 'components/common/table/queries';
import { CSVLink } from 'react-csv';
import { ASIcon, Icons, IconSizes } from 'components/asComponents/ASIcon';

const CustomersTable = ({
    salesTime,
    metric,
    timePeriod,
    filters,
    baseFilters
}: CustomersTableProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { tenantTimezone } = React.useContext(settingsStore);

    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [orderBy, setOrderBy] = React.useState<OrderByType>('revenue');
    const [orderDirection, setOrderDirection] = React.useState<Direction>(DESC);
    const [rawData, setRawData] = React.useState<DimensionData[]>([]);
    const [customers, setCustomers] = React.useState<Customer[]>([]);
    const [tableData, setTableData] = React.useState<Row[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLoading(true);
        cubejsApi
            .load(
                getCustomersTableData(salesTime, timePeriod, filters, baseFilters, tenantTimezone)
            )
            .then(
                compose(
                    setRawData,
                    pathOr<DimensionData[]>([], ['loadResponse', 'results', '0', 'data'])
                )
            )
            .finally(() => {
                setLoading(false);
            });
    }, [salesTime, timePeriod, filters, metric, baseFilters]);

    const groupBySectionRowSeat = groupBy((data: any) => {
        const buyerRef = data[TRANSACTIONS_BUYER_REF];
        return buyerRef;
    });

    const grouped = groupBySectionRowSeat(rawData);
    const groupedValues: Array<DimensionData[]> = Object.values(grouped);

    const sortedCustomers = groupedValues.map((groupedValue: DimensionData[]) => {
        const customerSectionRowSeat: SectionRowSeat[] = [];
        let customerItemCount = 0;
        let customerTransactionRevenue = 0;

        groupedValue.map((value: DimensionData) => {
            const sectionRowSeat: SectionRowSeat = {
                section: String(value[TICKETS_SECTION]),
                row: String(value[TICKETS_ROW]),
                seat: String(value[TICKETS_SEAT])
            };

            customerSectionRowSeat.push(sectionRowSeat);
            customerItemCount += Number(value[TRANSACTIONS_ITEMCOUNT]);
            customerTransactionRevenue += Number(value[TRANSACTIONS_REVENUE]);
        });

        const customer: Customer = {
            buyerRef: String(groupedValue[0][TRANSACTIONS_BUYER_REF]),
            fullName:
                String(groupedValue[0][CUSTOMERS_LABEL]) === ''
                    ? t('anonymous')
                    : String(groupedValue[0][CUSTOMERS_LABEL]),
            location: String(groupedValue[0][CUSTOMERS_COUNTRY]),
            city: String(groupedValue[0][CUSTOMERS_CITY]),
            sectionRowSeat: customerSectionRowSeat,
            itemCount: customerItemCount,
            avgItemPrice: customerTransactionRevenue / customerItemCount,
            revenue: customerTransactionRevenue
        };

        return customer;
    });

    const downloadCsv = () => {
        const formattedSeatingPlan = customers.map((customer) => {
            const seating = customer.sectionRowSeat;
            const rows: string[] = [];
            const sections: string[] = [];
            const seats: string[] = [];

            seating.forEach((seat) => {
                rows.push(`(${seat.row})`);
                sections.push(`(${seat.section})`);
                seats.push(`(${seat.seat})`);
            });

            return {
                ...customer,
                seatingRows: rows.filter((item) => item !== '(null)').join(', '),
                seatingSection: sections.filter((item) => item !== '(null)').join(', '),
                seatingSeat: seats.filter((item) => item !== '(null)').join(', ')
            };
        });

        formattedSeatingPlan.sort((customerA, customerB) => customerB.revenue - customerA.revenue);

        const headers = [
            {
                label: 'Full Name',
                key: 'fullName'
            },
            {
                label: 'Ref',
                key: 'buyerRef'
            },
            {
                label: 'Location',
                key: 'location'
            },
            {
                label: 'City',
                key: 'city'
            },
            {
                label: 'Row',
                key: 'seatingRows'
            },
            {
                label: 'Section',
                key: 'seatingSection'
            },
            {
                label: 'Seat',
                key: 'seatingSeat'
            },
            {
                label: 'Items Sold',
                key: 'itemCount'
            },
            {
                label: 'Avg Item Price',
                key: 'avgItemPrice'
            },
            {
                label: 'Revenue',
                key: 'revenue'
            }
        ];

        return (
            <CSVLink
                data={formattedSeatingPlan}
                headers={headers}
                filename="customers.csv">
                <ASIcon
                    icon={Icons.CSV_EXPORT_ICON}
                    size={IconSizes.SMALL}
                />
            </CSVLink>
        );
    };

    React.useEffect(() => {
        setCustomers(sortedCustomers);
    }, [rawData, t]);

    React.useEffect(() => {
        const loadTableData = async () => {
            const data = await prepareCustomerTablePage(
                customers,
                orderBy,
                orderDirection,
                page,
                rowsPerPage
            );

            setTableData(data);
        };

        loadTableData();
    }, [customers, orderBy, orderDirection, page, rowsPerPage]);

    const sorting: Sorting = {
        orderBy,
        orderDirection
    };

    const pagination: Pagination = {
        page,
        rowsPerPage,
        rowsPerPageOptions: [5, 10, 25],
        totalRowCount: customers.length
    };

    const onchangePage: OnChange = async ({
        newPage = page,
        newRowsPerPage = rowsPerPage,
        newOrderBy = orderBy,
        newOrderDirection = orderDirection
    }: OnChangeParams) => {
        const data = await prepareCustomerTablePage(
            customers,
            orderBy,
            orderDirection,
            page,
            rowsPerPage
        );

        setTableData(data);

        if (newPage !== page) {
            setPage(newPage);
        }
        if (newRowsPerPage !== rowsPerPage) {
            setRowsPerPage(newRowsPerPage);
        }
        if (newOrderBy !== orderBy) {
            setOrderBy(newOrderBy as keyof Customer);
        }
        if (newOrderDirection !== orderDirection) {
            setOrderDirection(newOrderDirection);
        }
    };

    const headers = [
        {
            label: `${t('fullName')}`,
            column: 'fullName',
            transform: function ({ buyerRef, fullName }: { buyerRef: string; fullName: string }) {
                return tableLinkHandler({ ref: buyerRef, label: fullName });
            }
        },
        { label: `${t('location')}`, column: 'location' },
        { label: `${t('city')}`, column: 'city' },
        {
            label: `${t('seatInfo')}`,
            column: `seatInfo`,
            noSorting: true,
            transform({
                buyerRef,
                sectionRowSeat
            }: {
                buyerRef: string;
                sectionRowSeat: SectionRowSeat[];
            }) {
                return (
                    <div key={`${buyerRef}`}>
                        <IconWrapper
                            data-tip
                            data-for={`${buyerRef}`}>
                            <CustomSvgIcon
                                iconName={SEATING}
                                size={2}
                            />
                        </IconWrapper>
                        <StyledTooltip
                            id={`${buyerRef}`}
                            place="top"
                            effect="solid"
                            backgroundColor={theme.colors.white}
                            textColor={theme.colors.black}
                            border={true}
                            borderColor={theme.colors.dividers}>
                            <TooltipHeader>{t('sectionRowSeat')}</TooltipHeader>
                            {sectionRowSeat.map((el, i) => (
                                <div key={i}>{i < 10 && `${el.section}/${el.row}/${el.seat}`}</div>
                            ))}
                            {sectionRowSeat.length > 10 && t('over10TicketsPurchased')}
                        </StyledTooltip>
                    </div>
                );
            }
        },
        { label: `${t('itemCount')}`, column: 'itemCount' },
        {
            label: `${t('avgItemPrice')}`,
            column: 'avgItemPrice',
            transform: function ({ avgItemPrice }: { avgItemPrice: number }) {
                return `${formatCurrencyString(t('tenantCurrency'))}${formatDecimalNumber(
                    avgItemPrice,
                    1
                )}`;
            }
        },
        {
            label: `${t('revenue')}`,
            column: 'revenue',
            transform: function ({ revenue }: { revenue: number }) {
                return `${formatCurrencyString(t('tenantCurrency'))}${formatWholeNumber(revenue)}`;
            }
        },
        {
            label: `${t('lifetimeValue')}`,
            column: 'lifetimeValue',
            transform: function ({ lifetimeValue }: { lifetimeValue: number }) {
                return `${formatCurrencyString(t('tenantCurrency'))}${formatWholeNumber(lifetimeValue)}`;
            }
        }
    ];

    return (
        <TableWrapper>
            <CSVDownloadWrapper>{downloadCsv()}</CSVDownloadWrapper>
            <ASTable
                headers={headers}
                data={tableData}
                showPagination={true}
                sorting={sorting}
                pagination={pagination}
                onChange={onchangePage}
                loading={loading}
                fixedWidth={150}
            />
            {loading && <LoadingContainer />}
        </TableWrapper>
    );
};

export { CustomersTable };

const TableWrapper = styled('div')`
    position: relative;
`;

const CSVDownloadWrapper = styled('div')`
    align-items: center;
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 0rem 0.5rem;
`;

const IconWrapper = styled('div')`
    margin-right: 65%;
`;

const TooltipHeader = styled('div')`
    font-weight: bold;
`;
