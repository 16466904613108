import { IconType } from './iconsTypes';

export const DOLLAR_ICON: IconType = 'dollar';
export const RESET_FILTER_ICON: IconType = 'resetFilter';
export const IN_FILTER_ICON: IconType = 'inFilter';
export const NOT_IN_FILTER_ICON: IconType = 'notInFilter';
export const SEARCH_ICON: IconType = 'search';
export const EVENT_ICON: IconType = 'event';
export const TICKET_ICON: IconType = 'ticket';
export const TICKETS_ICON: IconType = 'tickets';
export const USERS_ICON: IconType = 'users';
export const AS_LOGO_ICON: IconType = 'as_logo';
export const BELL_ICON: IconType = 'bell';
export const NO_USER_PIC_ICON: IconType = 'no_user';
export const NO_EVENT_PIC_ICON: IconType = 'questionmark';
export const VOICE_SEARCH_ICON: IconType = 'voice-search';
export const CHECKMARK_ICON: IconType = 'checkmark';
export const ARROW_ICON: IconType = 'arrow-drop-down';
export const EVENT_VIEW_ICON: IconType = 'event_view';
export const CLOCK_ICON: IconType = 'clock';
export const STAR_ICON: IconType = 'star';
export const MENU_ICON: IconType = 'menu';
export const CALENDAR_ICON: IconType = 'calendar';
export const STOPWATCH_ICON: IconType = 'stopwatch';
export const LIVEINFO_ICON: IconType = 'liveinfo';
export const HASH_ICON: IconType = 'hash';
export const WAND_ICON: IconType = 'wand';
export const LIGHTBULB_ICON: IconType = 'lightbulb';
export const GEARS_ICON: IconType = 'gears';
export const WRENCH_ICON: IconType = 'wrench';
export const HELP_ICON: IconType = 'help';
export const EXPORT_ICON: IconType = 'export';
export const CANCEL_ICON: IconType = 'cancel';
export const EYE_SHOWN: IconType = 'eyeShown';
export const EYE_SHOWN_BIG: IconType = 'eyeShownBig';
export const EYE_OUTLINE: IconType = 'eyeOutline';
export const EYE_HIDDEN: IconType = 'eyeHidden';
export const FOCUSED_EYE: IconType = 'focusedEye';
export const UNFOCUSED_EYE: IconType = 'unfocusedEye';
export const BAG_ICON: IconType = 'bag';
export const EMAIL_ICON: IconType = 'as_email';
export const AS_TICKET_ICON: IconType = 'as_ticket';
export const COINS_ICON: IconType = 'as_coins';
export const PNG_EXPORT_ICON: IconType = 'pngExport';
export const PDF_EXPORT_ICON: IconType = 'pdfExport';
export const CSV_EXPORT_ICON: IconType = 'csvExport';
export const DESELECT_ALL: IconType = 'deselect_all';
export const DOT_ICON: IconType = 'dot';
export const HOME_ICON: IconType = 'home';
export const PHONE_HOME: IconType = 'phone_home';
export const PHONE_MOBILE: IconType = 'phone_mobile';
export const PHONE_WORK: IconType = 'phone_work';
export const HOUSING_ICON: IconType = 'housing';
export const INCOME_ICON: IconType = 'income';
export const BIRTHDAY_ICON: IconType = 'birth_date';
export const EDUCATION_ICON: IconType = 'education';
export const PRICE_TAG: IconType = 'price_tag';
export const MALE_ARROW: IconType = 'male_arrow';
export const CLICKS_ICON: IconType = 'clicks';
export const FEMALE_ARROW: IconType = 'female_arrow';
export const CLOSE_ICON = 'close_icon';
export const NO_EVENT_ICON: IconType = 'no_event_icon';
export const CLOSE_ICON_THIN: IconType = 'close_icon_thin';
export const SAVE: IconType = 'save';
export const SURVEY: IconType = 'survey';
export const PRE_EVENT_INFO: IconType = 'pre_event_info';
export const POST_EVENT_INFO: IconType = 'post_event_info';
export const REPEAT_NO_SHOWS: IconType = 'repeat_no_shows';
export const FIRST_TIME_BUYERS: IconType = 'first_time_buyers';
export const POST_EVENT_SURVEY: IconType = 'post_event_survey';
export const LARGE_PURCHUSE_FOLLOWUP: IconType = 'large_purchuse_followup';
export const TRAVELLER_INFOMAIL: IconType = 'traveller_infomail';
export const BRING_PASSIVES: IconType = 'bring_passives';
export const LAST_CHANCE_TO_BUY: IconType = 'last_chance_to_buy';
export const INFORMATIONAL: IconType = 'informational';
export const MARKETING: IconType = 'marketing';
export const CANCELLATION: IconType = 'cancellation';
export const SCROLLBAR_ARROW_UP: IconType = 'scrollbarArrowUp';
export const SCROLLBAR_ARROW_DOWN: IconType = 'scrollbarArrowDown';
export const OPEN_ENVELOPE: IconType = 'open_envelope';
export const REPLICATE: IconType = 'replicate';
export const SEATING: IconType = 'seating';
export const STAR_CHECKED: IconType = 'star_checked';
export const STAR_UNCHECKED: IconType = 'star_unchecked';
export const NEXT_STEP: IconType = 'next_step';
export const PREVIOUS_STEP: IconType = 'previous_step';
export const SEARCH_ICON_THIN: IconType = 'search_icon_thin';
export const FACEBOOK: IconType = 'facebook';
export const SEND: IconType = 'send';
export const SAVE_ICON: IconType = 'save_icon';
export const GOOGLE_ADS: IconType = 'google_ads';
export const CSV_ICON: IconType = 'csv_icon';
export const LINK_ICON: IconType = 'link_icon';
export const GOOGLE_ADS_CUSTOM = 'google_ads_custom_intent';
export const GOOGLE_ADS_DISPLAY = 'google_ads_display';
export const GOOGLE_ADS_SEARCH = 'google_ads_search';
export const GOOGLE_ADS_SHOPPING = 'google_ads_shopping';
export const GOOGLE_ADS_VIDEO = 'google_ads_video';
export const GOOGLE_ADS_OTHER = 'google_ads_other';
export const SAVE_SEGMENT_ICON = 'save_segment_icon';
export const PENCIL_ICON = 'pencil_icon';
export const FOLDER = 'folder';
export const CSV_FILE = 'csv_file';
export const ARROW_SNACKBAR = 'arrow_snackbar';
export const COPY_URL = 'copy_url';
export const SEGMENT_MANAGEMENT_CHECK_MARK = 'segment_management_check_mark';
export const TAG_ICON = 'add_tag_icon';
export const COPY_URL_ICON = 'copy_url_icon';
export const REFRESH_ICON = 'refresh_icon';
export const TRASH_BIN_ICON = 'trash_bin_icon';
export const BACK_ICON = 'back_icon';
export const PLUS = 'plus';
export const MINUS = 'minus';
export const DROP_DOWN_ARROW_ACTIVE = 'drop_down_arrow_active';
export const DROP_DOWN_ARROW = 'drop_down_arrow';
export const ADD_CROSS_ICON = 'add_cross_icon';
export const AMOUNT_SMALL_ICON = 'amount_small_icon';
export const MAIL_SMALL_ICON = 'mail_small_icon';
export const SEND_SMALL_ICON = 'send_small_icon';
export const SET_DEFAULT_TYPE = 'set_default_type';
export const ARROW_RIGHT = 'arrow_right';
export const ERROR_INPUT = 'error_input';
export const SUCCESS_INPUT = 'success_input';
export const CALENDAR_WHITE_ICON = 'calendar_white';
export const CHECKMARK_ICON_THIN = 'checkmark_thin';
export const QUESTIONMARK_ICON_THIN = 'questionmark_thin';
export const CHECKMARK_ICON_ROUNDED = 'checkmark_radius';
export const QUESTIONMARK_SOLID_ICON = 'questionmark_solid';
export const EXCLAMATIONMARK_SOLID_ICON = 'exclamationmark_solid';
export const ARROW_LEFT = 'arrow_left';
export const TIME_ICON = 'time_icon';
export const CHART_ICON = 'chart_icon';
export const DRAFT_ICON = 'draft_icon';
export const SENDING_ENVELOPE_ICON = 'sending_envelope';
export const EMAIL_ICON_INVERTED = 'as_email_inverted';
export const SCHEDULED_SEND_ICON = 'scheduled_send_icon';
export const WARNING_ICON = 'warning_icon';
export const INFO_ICON = 'info';
export const TRASH_BIN_ICON_SOLID = 'trash_bin_icon_solid';
export const TARGET_ICON = 'target';
