import React, { useRef } from 'react';
import { debounce } from 'lodash';
import { groupBy, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { differenceInDays, startOfDay, endOfDay } from 'date-fns';
import { styled } from '@mui/material/styles';
import { DateFormatContext, DateRange } from '../../common/datepicker';
import { LoadingContainer } from '../../common/loader';
import { getSoldInPeriodQuery } from './queries';
import { EventsSoldInPeriodTableProps, DataPoint, SoldInPeriodDimensionData } from './types';
import { cubejsApi } from 'utils/api/CubeAPI';

import { formatDecimalPercent } from 'utils/formatting/numbers';
import {
    INVENTORY_OVER_TIME_COUNT,
    INVENTORY_OVER_TIME_OCCURRED_AT,
    INVENTORY_OVER_TIME_STATUS
} from 'utils/common/constants';
import { DAY_INTERVAL } from 'utils/common/times';
import { InventoryData } from 'utils/dataloaders/inventoryLoader';
import { settingsStore } from 'stores/settings';

export const EventsSoldInPeriodTable = ({
    salesTime,
    datePickerPeriod,
    entityRef,
    expanded,
    inventory
}: EventsSoldInPeriodTableProps) => {
    const { tenantTimezone } = React.useContext(settingsStore);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [tableData, setTableData] = React.useState<DataPoint[]>([]);
    const { formatDateMonth } = React.useContext(DateFormatContext);
    const [dateRangePeriod, setDateRangePeriod] = React.useState<DateRange>([
        new Date('1970-01-01'),
        new Date('1970-01-01')
    ]);

    const [tableRangePeriod, setTableRangePeriod] = React.useState<DateRange>([
        new Date('1970-01-01'),
        new Date('1970-01-01')
    ]);
    const { t } = useTranslation();

    const tableHeaders = [t('period'), t('soldInPeriod'), t('sold'), t('soldPercentage')];

    const renderTableHeaders = () =>
        tableHeaders.map((item, index) => (
            <StyledTableHeader key={index}>{item}</StyledTableHeader>
        ));

    React.useEffect(() => {
        setDateRangePeriod(datePickerPeriod);
        setTableRangePeriod(() => {
            const endQueryDate = new Date(+new Date(datePickerPeriod[0]) - 1);
            const extendeddatePickerPeriod: DateRange = [new Date(0), endQueryDate];

            return extendeddatePickerPeriod;
        });
    }, []);

    const getData = useRef(
        debounce(
            (
                salesTime: string,
                tableRangePeriod: DateRange,
                entityRef: string,
                dateRangePeriodProp: DateRange,
                inventory: InventoryData | null | undefined
            ) => {
                setLoading(true);

                cubejsApi
                    .load(
                        getSoldInPeriodQuery(salesTime, tableRangePeriod, entityRef, tenantTimezone)
                    )
                    .then((response) => {
                        const responseData: SoldInPeriodDimensionData[] = pathOr(
                            [],
                            ['loadResponse', 'results', '0', 'data'],
                            response
                        );

                        const dashboardStartDate = new Date(dateRangePeriodProp[0]);
                        const dashboardEndDate = new Date(dateRangePeriodProp[1]);
                        const diffDays = Math.abs(
                            Number(dashboardEndDate) - Number(dashboardStartDate)
                        );

                        const durationOfDashboardPeriod = Math.ceil(diffDays / DAY_INTERVAL);

                        const tableEndDate = new Date(tableRangePeriod[1]);

                        const groupedRawData: DataPoint[] = Object.values(
                            responseData.reduce(
                                (
                                    r: { [key: string]: DataPoint | any },
                                    {
                                        [INVENTORY_OVER_TIME_OCCURRED_AT]: occurredAt,
                                        [INVENTORY_OVER_TIME_STATUS]: ticketStatus,
                                        [INVENTORY_OVER_TIME_COUNT]: itemCount
                                    }
                                ) => {
                                    r[occurredAt] = r[occurredAt] || {
                                        occurredAt,
                                        sold: 0,
                                        killed: 0,
                                        reserved: 0,
                                        held: 0,
                                        open: 0,
                                        items_for_sale: inventory?.items_for_sale
                                    };
                                    r[occurredAt][ticketStatus] =
                                        Number(r[occurredAt][ticketStatus]) + Number(itemCount);
                                    return r;
                                },
                                {}
                            )
                        );

                        const sortedData = groupedRawData.sort((a: DataPoint, b: DataPoint) => {
                            const aVal = new Date(String(a.occurredAt));
                            const bVal = new Date(String(b.occurredAt));
                            return Number(bVal) - Number(aVal);
                        });

                        const groupByPreviousPeriod = groupBy((dataPoint: DataPoint) => {
                            const occurredAt = dataPoint.occurredAt;
                            return String(
                                Math.ceil(
                                    (+new Date(dateRangePeriodProp[0]) -
                                        +new Date(String(occurredAt))) /
                                        (durationOfDashboardPeriod * DAY_INTERVAL)
                                )
                            );
                        });

                        const groupedData = groupByPreviousPeriod(sortedData);

                        const capacityHandler = (capacity: number) => {
                            if (!isFinite(capacity)) {
                                return 0;
                            }
                            return capacity;
                        };

                        // Previously, 'tableData' was reduced from this array: [7, 6, 5, 4, 3, 2, 1].
                        // The problem with that was that in some cases we needed values from f.ex. the key 8 or bigger, f.ex. if 7 didn't exist.
                        // That could cause the "Sold in Period" value for the last shown period in Sales Overview to be way too big.
                        //
                        // So the solution I chose was to work with the an array containing the entire list of keys so that we'd always have an
                        // earlier peroid to take the sold value from. I named this array 'allKeys'.
                        const allKeys = [];

                        const keys = Object.keys(groupedData).map((g) => Number(g));
                        const maxKey = keys[keys.length - 1];

                        for (let i = maxKey; i > 0; i--) {
                            allKeys.push(i);
                        }

                        let tableData = allKeys.reduce((p: DataPoint[], key) => {
                            const data: DataPoint[] = groupedData[key];
                            const firstDate = new Date(
                                Number(new Date(tableEndDate)) - diffDays * key
                            );
                            let endDate = new Date(
                                Number(new Date(tableEndDate)) - diffDays * (key - 1) - DAY_INTERVAL
                            );
                            if (key === 1) {
                                endDate = new Date(
                                    Number(new Date(tableEndDate)) - diffDays * (key - 1)
                                );
                            }
                            if (data) {
                                p.push(
                                    data.reduce(
                                        (p: DataPoint, v: DataPoint) =>
                                            Number(new Date(v.occurredAt[0])) >
                                            Number(new Date(p.occurredAt[0]))
                                                ? {
                                                      ...v,
                                                      occurredAt: [
                                                          startOfDay(firstDate),
                                                          endOfDay(endDate)
                                                      ]
                                                  }
                                                : p,
                                        {
                                            occurredAt: [new Date('1970-01-01')],
                                            sold: 0,
                                            held: 0,
                                            killed: 0,
                                            open: 0,
                                            reserved: 0,
                                            soldInPeriod: 0,
                                            capacity: '0%'
                                        }
                                    )
                                );
                            } else {
                                if (p.length) {
                                    const previousPoint = p[p.length - 1];
                                    p.push({
                                        ...previousPoint,
                                        occurredAt: [startOfDay(firstDate), endOfDay(endDate)],
                                        held: previousPoint.held,
                                        killed: previousPoint.killed,
                                        open: previousPoint.open,
                                        reserved: previousPoint.reserved,
                                        soldInPeriod: previousPoint.soldInPeriod,
                                        capacity: previousPoint.capacity
                                    });
                                } else {
                                    p.push({
                                        occurredAt: [startOfDay(firstDate), endOfDay(endDate)],
                                        sold: 0,
                                        held: 0,
                                        killed: 0,
                                        open: 0,
                                        reserved: 0,
                                        soldInPeriod: 0,
                                        capacity: '0%'
                                    }); //add in empty inventory since we're missing the first point.
                                }
                            }
                            return p;
                        }, []);

                        tableData = tableData
                            .sort((a: DataPoint, b: DataPoint) => {
                                const aVal = new Date(a.occurredAt[0]);
                                const bVal = new Date(b.occurredAt[0]);
                                return Number(bVal) - Number(aVal);
                            })
                            .map((obj: DataPoint, i: number, srcArray: DataPoint[]) => ({
                                ...obj,
                                sold:
                                    obj.sold === 0
                                        ? srcArray[i + 1] && srcArray[i + 1].sold
                                            ? srcArray[i + 1].sold
                                            : 0
                                        : obj.sold,
                                soldInPeriod:
                                    obj.sold === 0
                                        ? 0
                                        : obj.sold -
                                          (srcArray[i + 1] && srcArray[i + 1].sold
                                              ? srcArray[i + 1].sold
                                              : 0),
                                capacity: formatDecimalPercent(t)(1)(
                                    capacityHandler(
                                        obj.sold === 0
                                            ? srcArray[i + 1] && srcArray[i + 1].sold
                                                ? Number(srcArray[i + 1].sold) /
                                                  (Number(obj?.items_for_sale) &&
                                                      Number(obj?.items_for_sale) -
                                                          Number(obj.killed) -
                                                          Number(obj.reserved) -
                                                          Number(obj.held))
                                                : 0
                                            : Number(obj.sold) /
                                                  (Number(obj?.items_for_sale) &&
                                                      Number(obj?.items_for_sale) -
                                                          Number(obj.killed) -
                                                          Number(obj.reserved) -
                                                          Number(obj.held))
                                    )
                                )
                            }));

                        return setTableData(tableData);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            },
            400
        )
    ).current;

    React.useEffect(() => {
        if (expanded) {
            getData(
                INVENTORY_OVER_TIME_OCCURRED_AT,
                tableRangePeriod,
                entityRef,
                dateRangePeriod,
                inventory
            );
        }
    }, [salesTime, tableRangePeriod, expanded]);

    const selectedDateRangeInDays = differenceInDays(datePickerPeriod[1], datePickerPeriod[0]) + 1;

    const formatDatePeriod = (item: DataPoint) =>
        selectedDateRangeInDays <= 1
            ? `${formatDateMonth(new Date(item.occurredAt[0]))}.`
            : `${formatDateMonth(new Date(item.occurredAt[0]))}. - ${formatDateMonth(
                  new Date(item.occurredAt[1])
              )}.`;

    const soldInPeriodTableData = () =>
        tableData.slice(0, 6).map((item: DataPoint, index: number) => (
            <StyledTableRow key={index}>
                <StyledTableDataFirst>{formatDatePeriod(item)}</StyledTableDataFirst>
                <StyledTableData>{item.soldInPeriod}</StyledTableData>
                <StyledTableData>{item.sold}</StyledTableData>
                <StyledTableData>{item.capacity}</StyledTableData>
            </StyledTableRow>
        ));

    return (
        <StyledTable id="soldInPeriod">
            <thead>
                <StyledTableRow>{renderTableHeaders()}</StyledTableRow>
            </thead>
            <tbody>{soldInPeriodTableData()}</tbody>
            {loading && <LoadingContainer size={30} />}
        </StyledTable>
    );
};

const StyledTable = styled('table')`
    width: 100%;
`;

const StyledTableRow = styled('tr')`
    display: flex;
    width: calc(100% - 2em);
`;

const StyledTableHeader = styled('th')`
    -webkit-box-flex: 0;
    flex: 0 0 calc((100% - 6.4375em) / 5);
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.soldInPeriodTableHeader};
    font-weight: 400;
    text-align: right;

    &:first-of-type {
        -webkit-box-flex: 1;
        flex: 1 1;
        text-align: left;
    }
`;

const StyledTableDataFirst = styled('td')`
    -webkit-box-flex: 1;
    flex: 1 1;
    text-align: left;
`;
const StyledTableData = styled('td')`
    -webkit-box-flex: 0;
    flex: 0 0 calc((100% - 6.4375em) / 5);
    text-align: right;
`;
