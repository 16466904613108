import { DimensionData } from 'utils/common/types';
import { ASLoadResponse } from '../types';
import { zipObj } from 'ramda';
import { ResultSet } from '@cubejs-client/core';

export const mapDataToHeaders = (data: ResultSet<any>) => {
    // Not sure why the below is needed, however this is from a previous
    // implementation. If unnecessary, remove this function entirely.
    (
        data as unknown as { loadResponse: ASLoadResponse<DimensionData> }
    ).loadResponse.results.forEach((result) => {
        // Create new objects from data headers and map response data to them i.e. {[header]: [data], ...}
        result.data = result.data.map((d) =>
            zipObj(result.dataHeaders, d as unknown as readonly (string | number)[])
        );
    });

    return data;
};
