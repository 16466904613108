import React from 'react';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { sizes } from '../responsive/MediaQuery';

import { StyledTooltip } from '../commonStyles';
import { SuperTile } from '../tiles';
import { Hit, TILE_TYPE } from 'utils/api/globalSearch/types';
import { FilterValue } from 'utils/common/types';

interface SearchResultsListProps {
    hits?: Hit[];
    show: boolean;
    tileType?: TILE_TYPE;
    selectedEntityCallback?: React.Dispatch<Hit>;
    resetSearchInput?: () => void;
    resaultsListOff?: any;
    resultListHeight?: string;
    currentSelection: FilterValue[];
    isSelected?: (value: string) => boolean;
    styledFor?: string;
}

const SearchResultsList = ({
    hits,
    show,
    tileType,
    selectedEntityCallback,
    resetSearchInput,
    resultListHeight = '50vh',
    currentSelection,
    isSelected,
    styledFor
}: SearchResultsListProps) => {
    const theme = useTheme();
    if (!show || !hits || hits.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            {styledFor === 'segmentBuilder' ? (
                <SegmentBuilderList>
                    <SegmentBuilderListWrapper resultListHeight={resultListHeight}>
                        {hits.map((hit, index) => (
                            <div
                                key={index}
                                data-tip
                                data-for={index}>
                                <SuperTile
                                    hit={hit}
                                    key={index}
                                    index={index}
                                    tileType={tileType}
                                    selectedEntityCallback={selectedEntityCallback}
                                    resetSearchInput={resetSearchInput}
                                    currentSelection={currentSelection}
                                    isSelected={isSelected}
                                    styledFor={styledFor}
                                />
                                <StyledTooltip
                                    id={`${index}`}
                                    place="right"
                                    effect="solid"
                                    backgroundColor={theme.colors.white}
                                    textColor={theme.colors.black}
                                    border={true}
                                    borderColor={theme.colors.shadowImage}>
                                    {hit.presentation_label}
                                </StyledTooltip>
                            </div>
                        ))}
                    </SegmentBuilderListWrapper>
                </SegmentBuilderList>
            ) : (
                <ListWrapper resultListHeight={resultListHeight}>
                    {hits.map((hit, index) => (
                        <div
                            key={index}
                            data-tip
                            data-for={index}>
                            <SuperTile
                                hit={hit}
                                key={index}
                                index={index}
                                tileType={tileType}
                                selectedEntityCallback={selectedEntityCallback}
                                resetSearchInput={resetSearchInput}
                                currentSelection={currentSelection}
                                isSelected={isSelected}
                                styledFor={styledFor}
                            />
                            <StyledTooltip
                                id={`${index}`}
                                place="right"
                                effect="solid"
                                backgroundColor={theme.colors.white}
                                textColor={theme.colors.black}
                                border={true}
                                borderColor={theme.colors.shadowImage}
                                multiline={false}>
                                {hit.presentation_label}
                            </StyledTooltip>
                        </div>
                    ))}
                </ListWrapper>
            )}
        </React.Fragment>
    );
};

const ListWrapper = styled('div')<{ resultListHeight: string }>`
    overflow-y: auto;
    padding-top: ${({ theme }) => theme.space.half};
    padding-bottom: ${({ theme }) => theme.space.single};

    @media (min-width: ${sizes.desktop.minWidth}px) {
        max-height: ${({ resultListHeight }) => resultListHeight};
    }
`;

const SegmentBuilderListWrapper = styled('div')<{ resultListHeight: string }>`
    overflow-y: auto;
    width: 450px;
    padding-top: ${({ theme }) => theme.space.half};
    padding-bottom: ${({ theme }) => theme.space.single};

    @media (min-width: ${sizes.desktop.minWidth}px) {
        max-height: ${({ resultListHeight }) => resultListHeight};
    }
    &::-webkit-scrollbar {
        width: 18px;
        background-color: ${({ theme }) => theme.colors.segmentBuilderScrollBar};
        background: linear-gradient(
            to bottom,
            ${({ theme }) => theme.colors.segmentBuilderScrollBar} 492px,
            ${({ theme }) => theme.colors.white} 10px
        );
        border: 1px solid ${({ theme }) => theme.colors.segmentBuilderScrollBarThumb};
        display: block;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.segmentBuilderScrollBarThumb};
        border: 1px solid ${({ theme }) => theme.colors.frame};
        border-radius: 10px;
    }
`;

const SegmentBuilderList = styled('div')`
    width: 455px;
    z-index: 1001;
    background-color: ${({ theme }) => theme.colors.white};
`;

export { SearchResultsList };
