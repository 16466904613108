import axios, { AxiosResponse, AxiosError } from 'axios';
import { Maybe } from '../maybe';
import { BASE_API_URL } from './urls';

let alreadyRedirected = false;
const authResponseInterceptor = {
    response: {
        success: (response: AxiosResponse) => {
            if (response.headers['auth0-logout-url']) {
                location.href = `${response.headers['auth0-logout-url']}?returnTo=${encodeURI(
                    BASE_API_URL
                )}`;
            }
            return response;
        },
        error: (error: AxiosError) => {
            const { response, request } = error;
            if (
                (response && response.status === 401) ||
                (response &&
                    response.status === 403 &&
                    response.data === 'CSRF Token has been associated to this client')
            ) {
                const loginPath = Maybe(response.headers['as-login-location']).getOrElse(
                    '/oauth2/authorization/gateway'
                );
                const origin = new URL(request.responseURL).origin;
                if (!alreadyRedirected) {
                    localStorage.setItem('previousLocation', window.location.href);
                    window.location.href = `${origin}${loginPath}`;
                    alreadyRedirected = true;
                }
            }
            return Promise.reject(error);
        }
    }
};

const ASRequest = axios.create({
    baseURL: BASE_API_URL,
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
});
ASRequest.interceptors.response.use(
    authResponseInterceptor.response.success,
    authResponseInterceptor.response.error
);

export { ASRequest };
