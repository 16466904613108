import React from 'react';
import { enUS } from 'date-fns/locale';
import { DateFormatInterface } from './types';

const defaultValue: DateFormatInterface = {
    dateFnsLocale: enUS,
    setDateFnsLocale: () => '',
    formatDateTime: () => '',
    formatDateTimeFullHourAndMinute: () => '',
    formatDateTime24h: () => '',
    formatTime: () => '',
    formatDate: () => '',
    formatDateLong: () => '',
    formatDateTimeLong: () => '',
    formatEventDate: () => '',
    formatCampaignEventDate: () => '',
    formatDateMonthShort: () => '',
    getTenantTimezoneTimeOfDay: () => new Date(),
    getTenantTimezoneTimeOfDayStr: () => '',
    formatDateMonth: () => '',
    formatDOWDateTimeLong: () => '',
    formatChartDatetime: () => '',
    formatMonthDateYearShort: () => '',
    formatTooltipHeader: () => '',
    formatShortMonth: () => '',
    formatYear: () => '',
    formatTime24h: () => '',
    formatRTODateTime: () => '',
    formatDayMonthYearTimeShort: () => '',
    formatDateTimeShort: () => ''
};

export const DateFormatContext = React.createContext<DateFormatInterface>(defaultValue);
