import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { IconType } from 'assets/iconsTypes';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { ARROW_ICON } from 'assets/iconConstants';

export type Value = string | number | string[];

export interface MenuItem {
    value: Value;
    label: string | null;
    group?: string;
    visible?: boolean;
    segmentId?: boolean;
}

export interface SelectProps {
    menuItems: MenuItem[];
    menuLabel?: string;
    native?: boolean;
    handleChange: (item: MenuItem, event: React.MouseEvent) => void;
    value: Value;
    icon?: IconType | null;
    currencyIcon?: string;
    error?: any;
    border?: boolean;
    size?: 'small' | 'medium';
    borderColor?: string;
}

export interface ASMenuProps {
    menuItems: MenuItem[];
    menuLabel?: string;
    native?: boolean;
    handleChange: (item: MenuItem, event: React.MouseEvent) => void;
    value: Value;
    id: string;
    icon?: IconType | null;
    currencyIcon?: string;
    error?: any;
    border?: boolean;
    iconFillColor?: string;
    setIsMenuActive?: React.Dispatch<React.SetStateAction<boolean>>;
    isMenuActive?: boolean;
}

export const getMenuItem = (menuItems: MenuItem[], value: Value) =>
    menuItems.find((menuitem) => menuitem.value.toString() === value.toString());

const renderMenuItem = (item: MenuItem, key: number, native?: boolean, disabled?: boolean) => {
    if (native) {
        return (
            <option
                value={item.value}
                key={key}
                disabled={disabled}>
                {item.label}
            </option>
        );
    }

    return (
        <MenuItem
            value={item.value}
            key={key}
            disabled={disabled}>
            {item.label}
        </MenuItem>
    );
};

const ASSelect = ({
    menuItems,
    menuLabel: label,
    border,
    value,
    native = false,
    handleChange,
    error,
    size = 'small',
    borderColor
}: SelectProps) => {
    const renderMenuitems = (menuItems: MenuItem[], native: boolean) => {
        if (!menuItems) {
            return null;
        }

        return menuItems.map((item, index) => renderMenuItem(item, index, native));
    };

    const renderPlaceholder = (placeholder?: string, native?: boolean) => {
        if (!placeholder) {
            return null;
        }

        return renderMenuItem({ value: '', label: placeholder }, 0, native, true);
    };

    const onChange = (event: any) => {
        const selectedMenuItem = getMenuItem(menuItems, event.target.value);
        handleChange(selectedMenuItem ?? menuItems[0], event);
    };

    return (
        <ASSelectWrapper>
            {label ? <StyledLabel>{label}</StyledLabel> : null}
            <StyledSelect
                value={value}
                displayEmpty
                onChange={onChange}
                native={native}
                disableUnderline
                border={border}
                borderColor={borderColor}
                size={size}
                menuItems={menuItems}
                handleChange={handleChange}
                IconComponent={(props) => (
                    <StyledIcon {...props}>
                        <CustomSvgIcon iconName={ARROW_ICON} />
                    </StyledIcon>
                )}>
                {renderPlaceholder(label, native)}
                {renderMenuitems(menuItems, native)}
            </StyledSelect>
            {error ? <Error>{error}</Error> : null}
        </ASSelectWrapper>
    );
};

export { ASSelect };

const StyledSelect = styled(Select)<SelectProps>`
    border: ${({ border, theme, borderColor }) =>
        border ? `1px solid ${borderColor ? borderColor : theme.colors.border}` : ''};
    font-size: 0.8em;
    padding-left: 10px;
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    &.MuiOutlinedInput-root {
        height: 34px;
    }
    & fieldset {
        border: 0px;
    }
`;

const ASSelectWrapper = styled('div')`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
`;

const StyledLabel = styled('p')`
    font-size: 0.8em;
    width: 70px;
    margin-left: 10px;
`;

const Error = styled('p')`
    color: var(--color-danger);
    font-size: 0.8em;
    margin-top: 5px;
`;

const StyledIcon = styled('i')`
    margin: 1px 0px;
`;
