// An area spline chart for the Real Time Overview 2.0 dashboard.
import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { styled } from '@mui/material/styles';
import { LoadingContainer } from '../common/loader';
import { Serie } from './types';

interface Props {
    chartTitle: string;
    yAxisTitle: string;
    tooltipValueSuffix: string;
    categories: string[];
    series: Serie[];
    loading: boolean;
}

const AreaSplineChartRTO = ({
    chartTitle,
    yAxisTitle,
    tooltipValueSuffix,
    categories,
    series,
    loading
}: Props) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        setOptions({
            chart: {
                type: 'areaspline'
            },
            title: {
                text: chartTitle
            },
            legend: {},
            xAxis: {
                categories
            },
            yAxis: {
                title: {
                    text: yAxisTitle
                }
            },
            tooltip: {
                shared: true,
                valueSuffix: ` ${tooltipValueSuffix}`
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    fillOpacity: 0.5
                },
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            series
        });
    }, [categories, series, chartTitle, yAxisTitle, tooltipValueSuffix]);

    return (
        <Wrapper>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            {loading && <LoadingContainer />}
        </Wrapper>
    );
};

const Wrapper = styled('div')`
    position: relative;
`;

export { AreaSplineChartRTO };
