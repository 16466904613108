import React from 'react';
import { clamp } from 'ramda';
import { styled } from '@mui/material/styles';
import { sizes } from '../../responsive/MediaQuery';

type CapacityProps = { ofTotal?: string; height?: string };

type ProgressBarProps = {
    percentage?: any;
    height?: any;
};

const ProgressBar = ({ percentage, height }: ProgressBarProps) => (
    <Bar height={height}>
        <Capacity
            ofTotal={clamp(0, 100, percentage)}
            height={height}
        />
    </Bar>
);

const Bar = styled('div')<CapacityProps>`
    position: absolute;
    bottom: -4px;
    right: ${({ theme }) => theme.space.single};
    left: ${({ theme }) => theme.space.half};
    background-color: ${({ theme }) => theme.colors.chart2};
    height: ${({ height }) => height};
    margin-bottom: ${({ height }) => `-${height}`};

    @media (max-width: ${sizes.desktop.minWidth}px) {
        display: none;
    }
`;

const Capacity = styled('div')<CapacityProps>`
    height: ${({ height }) => height};
    background-color: ${({ theme }) => theme.colors.chart1};
    width: ${({ ofTotal }) => `${ofTotal}`};
`;

export { ProgressBar };
