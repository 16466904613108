import React from 'react';
import { DynamicRoutesInterface } from './routingTypes';

const defaultValue: DynamicRoutesInterface = {
    dashboardGroups: [],
    services: [],
    routes: [],
    setDynamicRoutes: () => null
};

export const DynamicRoutesContext = React.createContext<DynamicRoutesInterface>(defaultValue);
