import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { clamp, mergeDeepLeft } from 'ramda';
import { TooltipFormatterContextObject } from 'highcharts';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import { DateFormatContext } from '../common/datepicker';
import { LineChartProps } from './types';
import { tooltipFormatter } from 'utils/formatting/tooltips/tooltips';

const LineChart = ({
    series,
    lineChartOptions,
    chartCallback,
    metric,
    yoyLineChart
}: LineChartProps) => {
    const { t } = useTranslation();
    const chartRef: any = React.useRef(null);
    const theme = useTheme();
    const { formatTooltipHeader } = React.useContext(DateFormatContext);

    const [options, setOptions] = React.useState<Highcharts.Options>({
        chart: {
            type: 'line',
            style: {
                fontFamily: theme.fonts.primaryFont
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: null
            },
            labels: {
                style: {
                    color: theme.colors.text,
                    fontSize: theme.fontSizes.small
                }
            }
        },
        yAxis: {
            title: {
                text: null
            },
            softMin: 0,
            labels: {
                style: {
                    color: theme.colors.text,
                    fontSize: theme.fontSizes.small
                }
            }
        },
        legend: {
            align: 'left',
            itemStyle: {
                color: theme.colors.text,
                fontSize: theme.fontSizes.small,
                fontWeight: 'normal'
            }
        },
        tooltip: {
            shared: true,
            outside: true,
            useHTML: true,
            positioner(
                this: Highcharts.Tooltip,
                labelWidth,
                labelHeight,
                point: Highcharts.Point | Highcharts.TooltipPositionerPointObject
            ) {
                const chartPosition = this.chart.pointer.getChartPosition();
                const minX = chartPosition.left;
                const maxX = minX / 2 + this.chart.chartWidth - labelWidth;

                if ('plotX' in point) {
                    return {
                        x: clamp(minX, maxX, chartPosition.left + point.plotX),
                        y: chartPosition.top - labelHeight
                    };
                }
                return {
                    x: clamp(minX, maxX, chartPosition.left - labelWidth / 2),
                    y: chartPosition.top - labelHeight
                };
            },
            formatter(this: TooltipFormatterContextObject) {
                return tooltipFormatter(t, formatTooltipHeader)(
                    this,
                    '',
                    metric,
                    undefined,
                    undefined,
                    yoyLineChart
                );
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                marker: {
                    symbol: 'circle',
                    enabled: true,
                    fillColor: '#FFFFFF',
                    lineWidth: 2,
                    lineColor: undefined
                },
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                stickyTracking: false
            }
        },
        exporting: {
            enabled: false
        },

        series: []
    });

    React.useEffect(() => {
        const newSeries = {
            series
        };
        mergePassedOptions(newSeries);
        mergePassedOptions(lineChartOptions as Highcharts.Options);
    }, [series, lineChartOptions]);

    const mergePassedOptions = (extraOptions: Highcharts.Options) => {
        setOptions((options) => mergeDeepLeft(extraOptions, options) as Highcharts.Options);
    };

    const handleChartCallback = React.useRef(() => {
        chartCallback && chartCallback(chartRef);
    });

    return (
        <HighchartsReact
            ref={chartRef}
            constructorType={'chart'}
            highcharts={Highcharts}
            options={options}
            callback={handleChartCallback.current}
        />
    );
};

export { LineChart };
