import { formatCubeQueryDate } from 'components/common/datepicker/dateUtils';
import { ASCubeQuery } from 'utils/common/types';
import { ASCubeParams } from '../types';

const buildCubeQuery = (params: ASCubeParams): ASCubeQuery => {
    const { dimensions, measures, tenantTimezone, filters, sortBy, sortDirection, limit } = params;

    return {
        dimensions,
        measures,
        timeDimensions: getTimeDimensions(params),
        order:
            sortBy && sortDirection
                ? {
                      [sortBy]: sortDirection
                  }
                : undefined,
        filters,
        timezone: tenantTimezone,
        limit
    };
};

const getTimeDimensions = (params: ASCubeParams) => {
    const { salesTime, granularity, timePeriod, eventOccurredAt } = params;

    // Returning [] in case 'salesTime' is undefined, like f.ex. in CurrentInventory.tsx:
    if (!salesTime || !timePeriod) {
        return [];
    }

    return eventOccurredAt
        ? [
              {
                  dimension: salesTime,
                  dateRange: timePeriod
                      ? (timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string])
                      : undefined
              },
              {
                  dimension: eventOccurredAt,
                  granularity: granularity
              }
          ]
        : [
              {
                  dimension: salesTime,
                  dateRange: timePeriod
                      ? (timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string])
                      : undefined,
                  granularity: granularity
              }
          ];
};

export { buildCubeQuery };
