import React, { Component } from 'react';
import { AuthContextInterface } from '../../../types/authTypes';
import { AuthContext } from 'Authentication/AuthContext';

export class UserInfo extends Component {
    static contextType = AuthContext;
    render(this: { context: AuthContextInterface }): JSX.Element | null {
        const { user, token } = this.context;
        if (!user) {
            return null;
        }
        return (
            <div>
                <div>{user.name}</div>
                <div>{token}</div>
            </div>
        );
    }
}
