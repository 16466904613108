import { FilterValue } from 'utils/common/types';

export function filterValueCheck(filter: FilterValue): string {
    if (typeof filter === 'string') {
        return filter;
    } else if (typeof filter === 'number') {
        return String(filter);
    } else if (filter === null) {
        return filter;
    } else {
        return filter.value;
    }
}

export function filterLabelCheck(filter: FilterValue): string {
    if (typeof filter === 'string') {
        return filter;
    } else if (typeof filter === 'number') {
        return String(filter);
    } else {
        return filter.label;
    }
}
