import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterType, Filters } from '../GlobalSearch';
import { FilterItem } from './FilterItem';
import { styled } from '@mui/material/styles';

interface FilterItemsListProps {
    aggregationFilters: Filters;
    onRemoveFilter: (filters: Filters) => void;
    styledFor?: string;
}

const FilterItemsList = ({
    aggregationFilters,
    onRemoveFilter,
    styledFor
}: FilterItemsListProps) => {
    const { t } = useTranslation();
    const [toBeRemoved, setToBeRemoved] = React.useState<Set<FilterType>>(new Set());

    React.useEffect(() => {
        setToBeRemoved(new Set());
    }, [aggregationFilters]);

    const onClick = () => {
        if (toBeRemoved.size > 0) {
            const filters = new Map(aggregationFilters);
            toBeRemoved.forEach((key) => {
                filters.delete(key);
            });

            onRemoveFilter(filters);
        }
    };

    const onMouseEnter = (index: number, array: [FilterType, string][]) => {
        const selectedFiltersToRemove = new Set<FilterType>();

        for (let i = index; i < array.length; i++) {
            selectedFiltersToRemove.add(array[i][0]);
        }

        setToBeRemoved(selectedFiltersToRemove);
    };

    const listCurrentFilters = () =>
        [...aggregationFilters].map(([key, value], index, array) => (
            <span
                onMouseEnter={() => onMouseEnter(index, array)}
                onMouseLeave={() => setToBeRemoved(new Set())}
                key={index}>
                <FilterItemStyled
                    onClickRemove={() => onClick()}
                    name={t(key)}
                    value={t(value)}
                    selected={toBeRemoved.has(key)}
                    styledFor={styledFor}
                />
            </span>
        ));

    if (aggregationFilters.size === 0) {
        return null;
    }

    return <FilterItemsWrapper>{listCurrentFilters()}</FilterItemsWrapper>;
};

const FilterItemsWrapper = styled('div')`
    padding: ${({ theme }) => theme.space.single};
    padding-left: ${({ theme }) => theme.space.double};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const FilterItemStyled = styled(FilterItem)`
    margin-right: ${({ theme }) => theme.space.half};
    cursor: pointer;
`;

export { FilterItemsList };
