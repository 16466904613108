import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import './RadioButtonEmoji.scss';

interface Props {
    value: string;
    label: string;
    icon: string;
}

const RadioButtonEmoji = ({ value, label, icon }: Props) => {
    return (
        <div className="radio-wrapper">
            <FormControlLabel
                className="label"
                value={value}
                control={<Radio />}
                label={label}
            />
            <span>{icon}</span>
        </div>
    );
};

export { RadioButtonEmoji };
