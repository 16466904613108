export enum IconSizes {
    XSMALL = 'x-small',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    HUGE = 'huge'
}

export enum IconColors {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    DANGER = 'danger',
    SUCCESS = 'success',
    TRANSACTION = 'transaction',
    CUSTOMER = 'customer',
    PRODUCT = 'product',
    BLACK = 'black',
    WHITE = 'white'
}

export interface ASIconProps {
    icon: Icons;
    size?: IconSizes;
    color?: IconColors;
    onClick?: () => void;
    className?: string;
}

export enum Icons {
    ADD = 'add',
    ADD_CROSS_ICON = 'add_cross_icon',
    ALIGN_CENTER = 'align_center',
    ALIGN_LEFT = 'align_left',
    ALIGN_RIGHT = 'align_right',
    AMOUNT_SMALL_ICON = 'amount_small_icon',
    ARROW_CHEVRON_DOWN = 'arrow_chevron_down',
    ARROW_CHEVRON_UP = 'arrow_chevron_up',
    ARROW_DROP_DOWN = 'arrow-drop-down',
    ARROW_DROP_UP = 'arrow-drop-up',
    ARROW_LEFT = 'arrow_left',
    ARROW_RIGHT = 'arrow_right',
    ARROW_SNACKBAR = 'arrow_snackbar',
    ARROW_UP = 'arrow-up',
    AS_COINS = 'as_coins',
    AS_LOGO_ICON = 'as_logo',
    AS_TICKET_ICON = 'as_ticket',
    ATTACH_FILE = 'attach_file',
    BACK_ICON = 'back_icon',
    BAG_ICON = 'bag',
    BAR_CHART = 'bar-chart',
    BEEN_HERE = 'beenhere',
    BELL_ICON = 'bell',
    BIRTHDAY_ICON = 'birth_date',
    BOLD = 'bold',
    BRING_PASSIVES = 'bring_passives',
    CALENDAR_ICON = 'calendar',
    CALENDAR_WHITE_ICON = 'calendar_white',
    CAMPAIGN = 'campaign',
    CANCEL_ICON = 'cancel',
    CANCELLATION = 'cancellation',
    CHART_ICON = 'chart_icon',
    CHECKMARK_ICON = 'checkmark',
    CHECKMARK_ICON_ROUNDED = 'checkmark_radius',
    CHECKMARK_ICON_THIN = 'checkmark_thin',
    CLICKS_ICON = 'clicks',
    CLOCK_ICON = 'clock',
    CLOSE = 'close',
    CLOSE_ICON_THIN = 'close_icon_thin',
    COINS_ICON = 'as_coins',
    COPY_URL = 'copy_url',
    COPY_URL_ICON = 'copy_url_icon',
    CSV_EXPORT_ICON = 'csvExport',
    CSV_FILE = 'csv_file',
    CSV_ICON = 'csv_icon',
    DATABASE = 'database',
    DELETE = 'delete',
    DESELECT_ALL = 'deselect_all',
    DOLLAR_ICON = 'dollar',
    DOT_ICON = 'dot',
    DRAFT_ICON = 'draft_icon',
    DRAG = 'drag',
    DROP_DOWN_ARROW = 'drop_down_arrow',
    DROP_DOWN_ARROW_ACTIVE = 'drop_down_arrow_active',
    EDUCATION_ICON = 'education',
    EMAIL_ICON = 'as_email',
    EMAIL_ICON_INVERTED = 'as_email_inverted',
    ERROR_INPUT = 'error_input',
    EVENT_ICON = 'event',
    EVENT_VIEW_ICON = 'event_view',
    EXCLAMATIONMARK_SOLID_ICON = 'exclamationmark_solid',
    EXPORT_ICON = 'export',
    EYE_HIDDEN = 'eyeHidden',
    EYE_OUTLINE = 'eyeOutline',
    EYE_SHOWN = 'eyeShown',
    EYE_SHOWN_BIG = 'eyeShownBig',
    FACEBOOK = 'facebook',
    FEMALE_ARROW = 'female_arrow',
    FILE = 'file',
    FIRST_TIME_BUYERS = 'first_time_buyers',
    FOCUSED_EYE = 'focusedEye',
    FOLDER = 'folder',
    GEARS_ICON = 'gears',
    GOOGLE_ADS = 'google_ads',
    GOOGLE_ADS_CUSTOM = 'google_ads_custom_intent',
    GOOGLE_ADS_DISPLAY = 'google_ads_display',
    GOOGLE_ADS_OTHER = 'google_ads_other',
    GOOGLE_ADS_SEARCH = 'google_ads_search',
    GOOGLE_ADS_SHOPPING = 'google_ads_shopping',
    GOOGLE_ADS_VIDEO = 'google_ads_video',
    GROUP = 'group',
    HASH_ICON = 'hash',
    HELP_ICON = 'help',
    HOME_ICON = 'home',
    HOURGLASS = 'hourglass',
    HOUSING_ICON = 'housing',
    IN_FILTER_ICON = 'inFilter',
    INCOME_ICON = 'income',
    INFO_ICON = 'info',
    INFORMATIONAL = 'informational',
    ITALIC = 'italic',
    LARGE_PURCHUSE_FOLLOWUP = 'large_purchuse_followup',
    LAST_CHANCE_TO_BUY = 'last_chance_to_buy',
    LIGHTBULB_ICON = 'lightbulb',
    LINK_ICON = 'link_icon',
    LIVEINFO_ICON = 'liveinfo',
    LOCAL_ACTIVITY = 'local_activity',
    MAIL_SMALL_ICON = 'mail_small_icon',
    MALE_ARROW = 'male_arrow',
    MANAGE_SEARCH = 'manage_search',
    MARKETING = 'marketing',
    MENU_ICON = 'menu',
    MINUS = 'minus',
    NEXT_STEP = 'next_step',
    NO_EVENT_ICON = 'no_event_icon',
    NO_EVENT_PIC_ICON = 'questionmark',
    NO_USER_PIC_ICON = 'no_user',
    NOT_IN_FILTER_ICON = 'notInFilter',
    OPEN_ENVELOPE = 'open_envelope',
    PDF_EXPORT_ICON = 'pdfExport',
    PENCIL_ICON = 'pencil_icon',
    PERSON = 'person',
    PHONE_HOME = 'phone_home',
    PHONE_MOBILE = 'phone_mobile',
    PHONE_WORK = 'phone_work',
    PLUS = 'plus',
    PNG_EXPORT_ICON = 'pngExport',
    POST_EVENT_INFO = 'post_event_info',
    POST_EVENT_SURVEY = 'post_event_survey',
    PRE_EVENT_INFO = 'pre_event_info',
    PREVIEW = 'preview',
    PREVIOUS_STEP = 'previous_step',
    PRICE_TAG = 'price_tag',
    QUESTIONMARK_ICON_THIN = 'questionmark_thin',
    QUESTIONMARK_SOLID_ICON = 'questionmark_solid',
    REDO = 'redo',
    REFRESH_ICON = 'refresh_icon',
    REPEAT_NO_SHOWS = 'repeat_no_shows',
    REPLICATE = 'replicate',
    RESET_FILTER_ICON = 'resetFilter',
    SAVE = 'save',
    SAVE_AS = 'save_as',
    SAVE_SEGMENT_ICON = 'save_segment_icon',
    SCHEDULED_SEND_ICON = 'scheduled_send_icon',
    SCROLLBAR_ARROW_DOWN = 'scrollbarArrowDown',
    SCROLLBAR_ARROW_UP = 'scrollbarArrowUp',
    SEARCH_ICON = 'search',
    SEARCH_ICON_THIN = 'search_icon_thin',
    SEATING = 'seating',
    SEGMENT = 'segment',
    SEGMENT_MANAGEMENT_CHECK_MARK = 'segment_management_check_mark',
    SEND = 'send',
    SEND_SMALL_ICON = 'send_small_icon',
    SENDING_ENVELOPE_ICON = 'sending_envelope',
    SET_DEFAULT_TYPE = 'set_default_type',
    SETTINGS = 'settings',
    STAR = 'star',
    STAR_CHECKED = 'star_checked',
    STAR_ICON = 'star',
    STAR_UNCHECKED = 'star_unchecked',
    STOPWATCH_ICON = 'stopwatch',
    STRIKETHROUGH = 'strikethrough',
    SUCCESS_INPUT = 'success_input',
    SURVEY = 'survey',
    TAG_ICON = 'add_tag_icon',
    TARGET_ICON = 'target',
    TICKET = 'ticket',
    TICKET_ICON = 'ticket',
    TICKETS_ICON = 'tickets',
    TIME_ICON = 'time_icon',
    TRASH_BIN_ICON = 'trash_bin_icon',
    TRASH_BIN_ICON_SOLID = 'trash_bin_icon_solid',
    TRAVELLER_INFOMAIL = 'traveller_infomail',
    UNDERLINE = 'underline',
    UNDO = 'undo',
    UNFOCUSED_EYE = 'unfocusedEye',
    UPLOAD = 'upload',
    USER = 'user',
    USERS = 'users',
    VOICE_SEARCH_ICON = 'voice-search',
    WAND_ICON = 'wand',
    WARNING_ICON = 'warning_icon',
    WRENCH_ICON = 'wrench'
}
