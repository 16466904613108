import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

type StyledButtonProps = {
    background?: string;
    hoverbackground?: string;
    fill?: string;
    hoverfill?: string;
    selectedfill?: string;
    selected?: boolean;
};

interface IconButtonProps {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    background?: string;
    hoverbackground?: string;
    selected?: boolean;
    hoverfill?: string;
    selectedfill?: string;
    children?: React.ReactNode;
    className?: string;
    id?: string;
    disabled?: boolean;
}

const ASIconButton: React.FunctionComponent<IconButtonProps> = ({
    onClick,
    background,
    hoverbackground,
    selected,
    hoverfill,
    selectedfill,
    children,
    className,
    id,
    disabled = false
}: IconButtonProps) => (
    <StyledButton
        onClick={onClick}
        background={background}
        hoverbackground={hoverbackground}
        hoverfill={hoverfill}
        selectedfill={selectedfill}
        selected={selected}
        className={className}
        disableRipple={true}
        id={id}
        disabled={disabled}>
        {children}
    </StyledButton>
);
const StyledButton = styled(IconButton)<StyledButtonProps>`
    border-radius: 0px;
    padding: 0px;
    background-color: ${({ background }) => background};
    height: 100%;
    path {
        fill: ${({ fill, selected, selectedfill, theme }) =>
            fill || selected ? (selectedfill ?? theme.colors.white) : fill};
    }
    & > span {
        height: 100%;
    }
    &:hover {
        background-color: ${({ hoverbackground }) => (hoverbackground ? hoverbackground : '#fff')};
        path {
            fill: ${({ hoverfill, theme }) => hoverfill ?? theme.colors.text};
        }
    }
`;

// const applyBackgroundColor = (props: StyledButtonProps) => {
//   if (props.selected) {
//     return props.hoverbackground;
//   } else if (props.background) {
//     return props.background;
//   } else {
//     return '#fff';
//   }
// };

export { ASIconButton };
