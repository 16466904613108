import React from 'react';
import { sizes } from './MediaQuery';
import { styled } from '@mui/material/styles';

interface PortletProps {
    title: string;
    children?: React.ReactNode;
}

export const Portlet: React.FC<PortletProps> = ({ title, children }) => (
    <Wrapper>
        <PortletTitle>{title}</PortletTitle>
        <PortletBody>{children}</PortletBody>
    </Wrapper>
);

export const PortletTitle = styled(`span`)`
    display: inline-block;
    margin-left: 0;
    margin-bottom: ${({ theme }) => theme.space.half};
    font-size: ${({ theme }) => theme.fontSizes.header};
    font-weight: 500;
    width: 100%;

    @media (min-width: ${sizes.desktop.minWidth}px) {
        margin-left: ${({ theme }) => theme.space.single};
    }
`;

const PortletBody = styled(`div`)`
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.space.single};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: 1px solid ${({ theme }) => theme.colors.frame};
`;

const Wrapper = styled(`div`)``;
