import { curry } from 'ramda';
import { formatNumber } from './numbers';

/**
 * Formats a currency in the correct locale of the user's browser (if specified). Rounds to as few
 * decimal places as needed, 2 at maximum. If the number is whole we don't display the cents. If
 * the amount is lower than one cent (0.01) we indicate the amount is less than one cent instead of
 * rounding to zero.
 *
 * @param currency ISO 4217 currency code, "USD", "EUR", "GBP", "NOK", etc
 * @param amount 1337, -42.69, 0, 50, etc
 * @param maxDecimalPlaces
 * @param minDecimalPlaces
 * @return Examples: "$1337", "-€42.69", "£0", "50 NOK" etc
 */
const _formatCurrency = curry(function (
    minDecimalPlaces: number,
    maxDecimalPlaces: number,
    currency: any,
    amount: number
): string {
    // If we don't know the currency: gracefully fall back to just format the amount as a number
    if (currency === '' || currency === null || currency === undefined) {
        return formatNumber(amount, maxDecimalPlaces, minDecimalPlaces);
    }

    const negativeString = amount < 0 ? '-' : '';
    const amountIsLessThanOneCent = Math.abs(amount) < 0.01 && amount !== 0;

    // Because we want to be able to put the minus symbol before the currency symbol we use the
    // absolute amount then slap the - back into the front of the string later. If we don't do this
    // we would format negative dollars like this: "$-1337".
    let formattedAmount = formatNumber(Math.abs(amount), maxDecimalPlaces, minDecimalPlaces);
    if (amountIsLessThanOneCent && amount > 0) {
        formattedAmount = formatNumber(0.01, 2);
    }
    let formattedCurrency = formatKnownCurrency(currency, formattedAmount, negativeString);

    if (amountIsLessThanOneCent && amount > 0) {
        formattedCurrency = `< ${formattedCurrency}`;
    }

    return formattedCurrency;
});

export const formatKnownCurrency = (
    currency: string,
    formattedAmount: string,
    negativeString: string
) => {
    if (currency === 'USD') {
        return `$${negativeString}${formattedAmount}`;
    }
    if (currency === 'EUR') {
        return `€${negativeString}${formattedAmount}`;
    }
    if (currency === 'GBP') {
        return `£${negativeString}${formattedAmount}`;
    }
    if (currency === 'JPY') {
        return `¥${negativeString}${formattedAmount}`;
    }

    return `${negativeString}${formattedAmount} ${currency}`;
};
export const formatCurrencyString = (currency: string): string => {
    if (currency === 'USD') {
        return '$';
    }
    if (currency === 'EUR') {
        return '€';
    }
    if (currency === 'GBP') {
        return '£';
    }

    return `${currency} `;
};

/**
 * With as few decimal places as possible, maximum of 2.
 *
 * Normal usage examples:
 *   formatCurrency('USD', 1337.00) => '$1337'
 *   formatCurrency('EUR', -42.1337) => '-€42.13'
 *
 * Curried usage examples:
 *   formatCurrency('USD')(-1337) => '$1337'
 *   formatCurrency('EUR') => curried function that takes price as input
 */
export const formatCurrency = _formatCurrency(0, 0);

/** With exactly 2 decimals */
export const formatCurrency2Decimals = _formatCurrency(2, 2);

/** Rounds to the nearest whole number */
export const formatCurrencyNoDecimals = _formatCurrency(0, 0);

export const formatCurrencyOneDecimal = _formatCurrency(1, 1);
