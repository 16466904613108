import { TFunction } from 'i18next';
import { formatCurrency } from 'utils/formatting/currency';

export const addCurrencyAmountSpent = (label: string | number, t: TFunction): string => {
    const splitValues = String(label).split(' ');
    const [first, _spacer, last] = splitValues;
    if (splitValues.length === 1 && (first || '').includes('+')) {
        return `${formatCurrency(t('tenantCurrency'), Number(first.slice(0, first.indexOf('+'))))}+`;
    }
    const firstValue = formatCurrency(t('tenantCurrency'), Number(first));
    const secondValue = formatCurrency(t('tenantCurrency'), Number(last));

    return [firstValue, secondValue].join(' - ');
};

export const addDistanceMetric = (label: string | number, t: TFunction): string =>
    String(label).concat(` ${t('tenantDistanceMetric')}`);

//Transforms 0/1 to No/Yes
export const affirmativeTransform = (label: string | number, t: TFunction): string => {
    if (String(label) === '0') {
        return t('no');
    }
    if (String(label) === '1') {
        return t('yes');
    }
    return '';
};

export const transformEmailOpts = (label: string | number, t: TFunction): string => {
    switch (label) {
        case 'NOT_OPT_IN':
            return t('NOT_OPT_IN');
        case 'OPT_OUT':
            return t('OPT_OUT');
        case 'OPT_IN':
            return t('OPT_IN');
        case 'SUBSCRIBERS':
            return t('Subscribers');
        default:
            return t('unknown');
    }
};

export const transformGender = (label: string | number, t: TFunction): string => {
    switch (label) {
        case 'Female':
            return t('female');
        case 'Male':
            return t('male');
        case 'Unknown':
            return t('unknown');
        default:
            return t('unknown');
    }
};

export const transformCustomerCategoriesChartLabel = (label: string | number) => {
    const category = String(label);
    const stringArray = category.split(' ');
    for (let i = 0; i < stringArray.length; i++) {
        stringArray[i] = stringArray[i].charAt(0).toUpperCase() + stringArray[i].slice(1);
    }
    const transformedLabel = stringArray.join(' ');
    return transformedLabel;
};

export const transformCustomerTagsChartLabel = (label: string | number, t: TFunction) => {
    const tag = String(label);
    if (tag.startsWith('likes')) {
        const variant = tag.substring(tag.indexOf('-') + 1);
        return `${t('likes')} ${t(variant)}`;
    }
    return t(tag);
};

export const formatChartLabels = (
    dataPoint: string | number,
    t: TFunction,
    transformChartLabel?: (label: string | number, t: TFunction) => string
) => {
    if (dataPoint) {
        if (transformChartLabel) {
            return transformChartLabel(dataPoint, t);
        }
        return dataPoint;
    }
    return t('notSet');
};

export const distanceRoundedFormatter = (value: string | number): string => {
    if (String(value) === '0') {
        return '<10';
    }
    return String(value);
};

export const numberOfEventsPurchased = (value: string | number): string => {
    if (String(value) === '10') {
        return '10+';
    }
    return String(value);
};
