import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { MainRouter } from './MainRouter';
import { DynamicRoutesContext } from './DynamicRoutesContext';
import NotFound from 'views/dashboards/NotFound';
import DefaultDashboard from 'views/dashboards/DefaultDashboard';
import Logout from 'views/dashboards/Logout';
import { resetCubeCache } from 'utils/api/CubeAPICache';
import PersonalSettings from 'views/dashboards/PersonalSettings/PersonalSettings';
import { clearCubeCache } from 'services/cubejs';

export const Routes = () => {
    const { routes } = React.useContext(DynamicRoutesContext);
    const location = useLocation();

    React.useEffect(() => {
        resetCubeCache();
        clearCubeCache();
    }, [location]);

    return (
        <div>
            <Switch>
                {routes.map((route, index) => (
                    <MainRouter
                        key={index}
                        {...route}
                    />
                ))}
                <MainRouter
                    key="personalSettings"
                    path="/personal_settings"
                    component={PersonalSettings}
                />
                <MainRouter
                    key="logout"
                    path="/client/logout"
                    component={Logout}
                />
                <MainRouter
                    key="defaultDashboard"
                    path="/"
                    component={DefaultDashboard}
                />
                <MainRouter
                    key="404_not_found"
                    component={NotFound}
                />
            </Switch>
        </div>
    );
};
