import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Value } from '../components/commonStyled';
import { styled } from '@mui/material/styles';
import { SearchResults } from 'utils/api/globalSearch/types';

interface PageInfoProps {
    searchResults: SearchResults | null;
    styledFor?: string;
}

const SearchResultsCount = ({ searchResults, styledFor }: PageInfoProps) => {
    const { t } = useTranslation();

    if (!searchResults) {
        return null;
    }

    return (
        <React.Fragment>
            {styledFor === 'segmentBuilder' ? (
                <SegmentBuilderInfoWrapper>
                    <InfoItem>
                        <SegmentBuilderLabelAndValue>
                            {t('totalHits')} {searchResults.page.totalElements}
                        </SegmentBuilderLabelAndValue>
                    </InfoItem>
                    <InfoItem>
                        <SegmentBuilderLabelAndValue>
                            {t('showing')} {searchResults.hits.length}
                        </SegmentBuilderLabelAndValue>
                    </InfoItem>
                </SegmentBuilderInfoWrapper>
            ) : (
                <InfoWrapper>
                    <InfoItem>
                        <Label>{t('totalHits')}</Label>{' '}
                        <Value>{searchResults.page.totalElements}</Value>
                    </InfoItem>
                    <InfoItem>
                        <Label>{t('showing')}</Label> <Value>{searchResults.hits.length}</Value>
                    </InfoItem>
                </InfoWrapper>
            )}
        </React.Fragment>
    );
};

export { SearchResultsCount };

const InfoItem = styled('div')`
    display: flex;
    margin-right: ${({ theme }) => theme.space.single};
    white-space: nowrap;
    align-items: center;
`;

const InfoWrapper = styled('div')`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizes.normal};

    background-color: ${({ theme }) => theme.colors.background};

    padding: ${({ theme }) => theme.space.single};
    padding-left: ${({ theme }) => theme.space.double};
`;

const SegmentBuilderInfoWrapper = styled('div')`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizes.normal};

    background-color: ${({ theme }) => theme.colors.white};

    padding-left: ${({ theme }) => theme.space.single};
    padding-top: ${({ theme }) => theme.space.single};
    padding-bottom: ${({ theme }) => theme.space.half};
`;

const SegmentBuilderLabelAndValue = styled('div')`
    color: ${({ theme }) => theme.colors.segmentBuilderCancelIcon};
    margin-right: ${({ theme }) => theme.space.half};
    font-size: ${({ theme }) => theme.fontSizes.small};
    min-width: 80px;
    height: 20px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
`;
