import { DimensionData } from 'utils/common/types';

export const missingLabelValuesHandler = (
    data: any,
    min: string | number,
    max: string | number,
    dimension?: string,
    metric?: string
) => {
    const getValue = (value: any) => +value.toString().match(/\d+/)[0];
    const newMin = getValue(min);
    const newMax = getValue(max);
    let result;

    const getKey = (key: any) => ({ [newMin]: min, [newMax]: max })[key] || key;

    if (dimension && metric) {
        data = data.filter(
            (d: DimensionData) => d[dimension] !== null && d[dimension] !== undefined
        );
        const keys =
            data &&
            data.reduce((r: any, o: any) => ((r[getValue(getValue(o[dimension]))] = o), r), {});
        result = Array.from({ length: newMax - newMin + 1 }, (_, i) => ({
            ...(keys[newMin + i] || { [dimension]: newMin + i, [metric]: 0 }),
            [dimension]: getKey(`${newMin + i}`)
        }));
    } else {
        data = data.filter(
            (d: { key: string | undefined | null; value: number }) =>
                d.key !== null && d.key !== undefined
        );
        const keys = data && data.reduce((r: any, o: any) => ((r[getValue(o.key)] = o), r), {});
        result = Array.from({ length: newMax - newMin + 1 }, (_, i) => ({
            ...(keys[newMin + i] || { key: newMin + i, value: 0 }),
            key: getKey(`${newMin + i}`)
        }));
    }

    return result;
};
