import React from 'react';
import Highcharts, {
    ChartSelectionAxisContextObject,
    TooltipFormatterContextObject
} from 'highcharts';
import HighchartsCustomEvents from 'highcharts-custom-events';
import exporting from 'highcharts/modules/exporting';
import csvExporting from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
//@ts-ignore
HighchartsCustomEvents(Highcharts);
exporting(Highcharts);
csvExporting(Highcharts);
import { useTheme } from '@mui/material/styles';
import { mergeDeepLeft } from 'ramda';
import { useTranslation } from 'react-i18next';

import { DateFormatContext } from '../common/datepicker';
import { LinearChartProps } from './types';
import { tooltipFormatter } from 'utils/formatting/tooltips/tooltips';

const LinearChart = ({
    series,
    dimension,
    filters,
    chartCallback,
    metric,
    exportFileName,
    subtitleOne,
    onRangeSelection,
    id,
    timeRangeChart
}: LinearChartProps) => {
    const { t } = useTranslation();
    const chartRef: any = React.useRef(null);
    const theme = useTheme();
    const { formatTooltipHeader } = React.useContext(DateFormatContext);

    const [options, setOptions] = React.useState<Highcharts.Options>({
        chart: {
            type: 'column',
            zoomType: 'x',
            height: 180,
            spacing: [30, 14, 15, 14],
            style: {
                fontFamily: theme.fonts.primaryFont
            },
            events: {
                load(this: Highcharts.Chart) {
                    if (filters.some((e) => e.dimension === dimension)) {
                        this.showResetZoom();
                    }
                },
                selection(this: Highcharts.Chart, event: Highcharts.ChartSelectionContextObject) {
                    const xAxis: Array<ChartSelectionAxisContextObject> = event?.xAxis;
                    if (xAxis) {
                        const min = Math.round(xAxis[0].min);
                        const max = Math.round(xAxis[0].max);
                        //remove old band
                        this.xAxis[0].removePlotBand('plot-band-1');
                        //update plot band with selected region
                        this.xAxis[0].addPlotBand({
                            from: min,
                            to: max,
                            color: 'rgba(255,255,255,0)',
                            id: 'plot-band-1'
                        });

                        // Add/Remove zoom button
                        // @ts-ignore
                        if (this.resetZoomButton?.placed) {
                            // @ts-ignore
                            this.resetZoomButton.hide();
                        }
                        this.showResetZoom();
                        onRangeSelection.current([min, max]);
                    } else {
                        // @ts-ignore
                        this.resetZoomButton.hide();

                        this.xAxis[0].removePlotBand('plot-band-1');

                        onRangeSelection.current(null);
                    }
                    return false;
                }
            }
        },
        xAxis: {
            labels: {
                style: {
                    color: theme.colors.text,
                    fontSize: theme.fontSizes.small
                },
                formatter: function () {
                    return String(this.value);
                }
            }
        },
        yAxis: {
            title: {
                text: undefined
            },
            labels: {
                style: {
                    color: theme.colors.text,
                    fontSize: theme.fontSizes.small
                }
            }
        },
        credits: {
            enabled: false
        },
        title: {
            useHTML: true,
            text: `<div style="position: absolute; top: -20px; display: flex; justify-content: space-between; width: 230px;"><span style="max-width: 160px;">${
                subtitleOne || ''
            }</span></div>`,
            align: 'left',
            x: 25,
            y: 9,
            style: {
                fontSize: theme.fontSizes.normal,
                color: theme.colors.text,
                fontWeight: 'bold'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            column: {}
        },
        exporting: {
            enabled: false,
            filename: exportFileName
        },
        series
    });

    React.useEffect(() => {
        if (!filters.some((e) => e.dimension === dimension)) {
            chartRef.current.chart.resetZoomButton && chartRef.current.chart.resetZoomButton.hide();
        }
        const newSeries: Highcharts.Options = {
            series: series,
            xAxis: {
                labels: {
                    //@ts-ignore
                    events: {
                        mouseover: function (this: any) {
                            const pointPos = this.pos;
                            const seriesPoints = this.chart.series[0].points;
                            const tooltip = this.chart.tooltip;
                            tooltip.refresh(seriesPoints[pointPos]);
                        }
                    }
                }
            },
            title: {
                useHTML: true,
                text: `<div style="position: absolute; top: -20px;display: flex; justify-content: space-between; width: 230px;"><span style="max-width: 160px;">${
                    subtitleOne || ''
                }</span></div>`,
                align: 'left',
                x: 25,
                y: 9,
                style: {
                    fontSize: theme.fontSizes.normal,
                    color: theme.colors.text,
                    fontWeight: 'bold'
                }
            },
            tooltip: {
                shared: false,
                useHTML: true,
                outside: true,
                positioner(
                    this: Highcharts.Tooltip,
                    labelWidth,
                    labelHeight,
                    point: Highcharts.Point | Highcharts.TooltipPositionerPointObject
                ) {
                    const chartPosition = this.chart.pointer.getChartPosition();
                    if ('plotX' in point) {
                        return {
                            x: chartPosition.left + point.plotX - labelWidth / 2,
                            y: chartPosition.top - labelHeight
                        };
                    }
                    return {
                        x: chartPosition.left - labelWidth / 2,
                        y: chartPosition.top - labelHeight
                    };
                },
                formatter(this: TooltipFormatterContextObject) {
                    return tooltipFormatter(t, formatTooltipHeader)(
                        this,
                        '',
                        metric,
                        undefined,
                        timeRangeChart
                    );
                }
            }
        };
        mergePassedOptions(newSeries);
    }, [series]);

    const mergePassedOptions = (extraOptions: Highcharts.Options) => {
        setOptions((options) => mergeDeepLeft(extraOptions, options) as Highcharts.Options);
    };

    const handleChartCallback = React.useRef(() => {
        chartCallback && chartCallback(chartRef);
    });

    return (
        <div id={id}>
            <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={options}
                callback={handleChartCallback.current}
            />
        </div>
    );
};

export { LinearChart };
