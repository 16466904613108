import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import { compose, pathOr } from 'ramda';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import { BinaryOperator } from '@cubejs-client/core';
import { styled } from '@mui/material/styles';

import { DateRange } from '../common/datepicker/types';
import { FilterDrawer } from '../filters/FilterDrawer';
import { DateFormatContext } from '../common/datepicker';
import { LoadingContainer } from '../common/loader';
import { StyledPopper, FilterAutocomplete } from '../global-search/components/commonStyled';
import { Mobile, TabletToDesktop } from '../responsive/MediaQuery';
import {
    FadedChartAction,
    FadedChartMenuContainer,
    FadedChartMenuItemContainer,
    InvertChartAction
} from './fadedIcons';
import { ColumnChart } from './ColumnChart';
import { LinearChart } from './LinearChart';
import { cubejsApi } from 'utils/api/CubeAPI';
import { Maybe } from 'utils/maybe';
import { Operator, BasicDimensionProps, ASCubeFilter, DimensionData } from 'utils/common/types';

import {
    RESET_FILTER_ICON,
    IN_FILTER_ICON,
    NOT_IN_FILTER_ICON,
    SEARCH_ICON,
    PNG_EXPORT_ICON,
    CSV_EXPORT_ICON
} from 'assets/iconConstants';
import { AvailableDimensions, DimensionDataStore, FullDimension } from 'stores/dimensionData';
import { settingsStore } from 'stores/settings';
import { getCustomersCountQuery, getDimensionQuery } from 'utils/queries';
import { mapFilterChartData } from 'utils/mappers';
import { customerTagsDimensions } from 'utils/common/constants';
import { pngExportHandler } from 'views/utils/pngExportHandler';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { Tracking } from 'externals/tracking';
import { filterValueCheck } from 'utils/filter/filterCheck';
import { filterUnknown, getChartPNG, getFilterValues, transformDimension } from 'utils/chartUtils';
import { formatChartLabels } from 'utils/chartLabelFormatters';
import { BarChart } from './BarChart';

export const BasicDimension = ({
    metric,
    measures,
    salesTime,
    timePeriod,
    filters,
    baseFilters,
    onFilter,
    dimension,
    color,
    subtitleTwo,
    exportName,
    defaultExpanded = true,
    type = 'bar',
    timeRangeChart = true,
    titlePrefix = '',
    transformChartLabel,
    hideFilterDrawer = false,
    barChartOptions = {},
    doubleFilterDrawer = false,
    chartTotals = true,
    columnChartOptions = {
        xAxis: {
            type: 'category'
        },
        tooltip: {
            shared: false
        }
    },
    multipleChartOperator,
    queryParams = {}
}: BasicDimensionProps) => {
    const { t } = useTranslation();

    const dimensionData = React.useContext(DimensionDataStore);
    const { excludeDashboardComponent, featureFlags } = React.useContext(settingsStore);
    const actualDimension: FullDimension = transformDimension(dimension, featureFlags);
    const [_, dimensionName]: [string, AvailableDimensions] = actualDimension.split('.') as [
        string,
        AvailableDimensions
    ];

    const dimensionInfo = dimensionData[dimensionName];

    if (!transformChartLabel && dimensionInfo.labelFormatter) {
        transformChartLabel = dimensionInfo.labelFormatter;
    }

    const translatedDimension = t(dimensionInfo.label);
    const primaryColor = Array.isArray(color) ? color[0] : color;
    if (!subtitleTwo) {
        subtitleTwo = t([metric, metric.split('.')[1]]);
    }

    if (excludeDashboardComponent.includes(dimensionName)) {
        return <React.Fragment />;
    }

    return (
        <FadedChartMenuContainer>
            <FilterDrawer
                defaultExpanded={defaultExpanded}
                name={`${titlePrefix ? `${t(titlePrefix)} ` : ''}${translatedDimension}`}
                borderColor={primaryColor}
                hideFilterDrawer={hideFilterDrawer}
                doubleFilterDrawer={doubleFilterDrawer}>
                <BasicDimensionChart
                    metric={metric}
                    measures={measures}
                    salesTime={salesTime}
                    timePeriod={timePeriod}
                    filters={filters}
                    baseFilters={baseFilters}
                    onFilter={onFilter}
                    dimension={actualDimension}
                    color={color}
                    subtitleTwo={subtitleTwo}
                    exportName={exportName}
                    type={type}
                    timeRangeChart={timeRangeChart}
                    transformChartLabel={transformChartLabel}
                    barChartOptions={barChartOptions}
                    columnChartOptions={columnChartOptions}
                    chartTotals={chartTotals}
                    multipleChartOperator={multipleChartOperator}
                    queryParams={queryParams}
                />
            </FilterDrawer>
        </FadedChartMenuContainer>
    );
};

export const BasicDimensionChart = ({
    metric,
    measures,
    salesTime,
    timePeriod,
    filters,
    baseFilters,
    onFilter,
    dimension,
    color,
    subtitleTwo,
    exportName,
    type = 'bar',
    timeRangeChart = true,
    transformChartLabel,
    barChartOptions,
    columnChartOptions,
    multipleChartOperator,
    queryParams = {}
}: BasicDimensionProps) => {
    const { excludeDashboardComponent, tenantTimezone, focusedSegments, dispatch } =
        React.useContext(settingsStore);
    const { formatDate } = React.useContext(DateFormatContext);
    const { t } = useTranslation();
    const [_, dimensionName]: [string, AvailableDimensions] = dimension.split('.') as [
        string,
        AvailableDimensions
    ];
    const [loading, setLoading] = React.useState<boolean>(false);
    const [rawData, setRawData] = React.useState<DimensionData[]>([]);
    const [metricsCount, setMetricsCount] = React.useState<{ [key: string]: string }[]>([]);
    const [chartData, setChartData] = React.useState<Highcharts.Options['series']>([]);
    const [visibleFocusedDimensions, setVisibleFocusedDimensions] = React.useState<string[]>([]);

    const [operator, setOperator] = React.useState<Operator>(
        Maybe((filters || []).find((f) => f.dimension === dimension))
            .map((filter) => filter.operator as Operator)
            .getOrElse('equals')
    );

    const [searchElement, setSearchElement] = React.useState<Element | null>(null);
    const [exportGraph, setExportGraph] = React.useState<JSX.Element | null>(null);
    const theme = useTheme();
    const [chart, setChart] = React.useState<MutableRefObject<{ chart: Highcharts.Chart }>>();
    const dimensionData = React.useContext(DimensionDataStore);
    const dimensionInfo = dimensionData[dimensionName];
    const translatedDimension = t(dimensionInfo.label);
    const linearColors = Array.isArray(color) ? color : undefined;
    const exportFileName = `${exportName ? `${t(exportName)}-` : ''}${translatedDimension}-${
        timePeriod ? timePeriod.map((date) => formatDate(new Date(date))).join('-') : ''
    }`;

    const borderColor: string = typeof color === 'string' ? color : color[0];

    const chartId = translatedDimension.split('.').join('').split(' ').join('_');
    const getData = useRef(
        debounce(
            (
                dimension: FullDimension,
                measures: string[],
                salesTime: string | undefined,
                timePeriod: DateRange | undefined,
                filters: ASCubeFilter[],
                baseFilters: ASCubeFilter[]
            ) => {
                setLoading(true);
                Promise.all([
                    cubejsApi.load(
                        getDimensionQuery(
                            dimension,
                            measures,
                            salesTime,
                            timePeriod,
                            filters,
                            baseFilters,
                            tenantTimezone
                        ),
                        { queryParams }
                    ),
                    customerTagsDimensions.includes(dimension)
                        ? cubejsApi.load(
                              getCustomersCountQuery(
                                  dimension,
                                  measures,
                                  salesTime,
                                  timePeriod,
                                  filters,
                                  baseFilters,
                                  tenantTimezone
                              ),
                              { queryParams }
                          )
                        : Promise.resolve()
                ])
                    .then((response) => {
                        const rawChartData = compose(
                            setRawData,
                            (data: DimensionData[]) => data.slice(),
                            (data: DimensionData[]) => filterUnknown(dimension, dimensionInfo, data)
                        );
                        const totalsCount = pathOr(
                            [],
                            ['loadResponse', 'results', '0', 'data'],
                            response[1]
                        );
                        setMetricsCount(totalsCount);
                        rawChartData(
                            pathOr([], ['loadResponse', 'results', '0', 'data'], response[0])
                        );
                    })
                    .finally(() => setLoading(false));
            },
            500
        )
    ).current;

    useEffect(() => {
        if (multipleChartOperator) {
            setOperator(multipleChartOperator);
        }
    }, [multipleChartOperator]);

    //Fetch the data from cube whenever the salesTime, timePeriod or filters change.
    useEffect(() => {
        if (excludeDashboardComponent.includes(dimensionName)) {
            return;
        }

        getData(dimension, measures, salesTime, timePeriod, filters, baseFilters);
    }, [filters, salesTime, timePeriod, excludeDashboardComponent]);

    useEffect(() => {
        if (focusedSegments && chartData && chartData.length > 0) {
            const filterValues = getFilterValues(filters, dimension);

            const myVisibleFocusedDimensions = (chartData[0] as any).data
                .filter(
                    (d: any) =>
                        filterValues.includes(d.category) ||
                        filterValues.includes(d.custom?.filterValue)
                )
                .filter((d: any) => !d.custom?.hide)
                .map((d: any) => d.custom?.filterValue || d.category);

            setVisibleFocusedDimensions(myVisibleFocusedDimensions);
        } else {
            setVisibleFocusedDimensions([]);
        }
    }, [focusedSegments]);

    useEffect(() => {
        const mappedData = mapFilterChartData(
            type,
            t,
            theme,
            filters,
            dimension,
            metric,
            operator,
            translatedDimension,
            dimensionInfo,
            metricsCount,
            transformChartLabel,
            color as string,
            linearColors,
            focusedSegments,
            visibleFocusedDimensions
        );

        const myChartData = mappedData(rawData);
        setChartData(myChartData);

        // Remove focused state if the user just cleared the last filter:
        if (focusedSegments && filters.length === 0) {
            dispatch({ type: 'focusedSegments', payload: false });
            setVisibleFocusedDimensions([]);
            return;
        }
    }, [rawData, metric, focusedSegments, visibleFocusedDimensions]);

    React.useEffect(() => {
        if (type === 'bar') {
            const c: ChartWithPreviousDataLength | undefined = chart?.current?.chart;
            if (c) {
                if (c.previousDataLength != null && c.previousDataLength > rawData.length) {
                    if (c.xAxis && c.xAxis[0]) {
                        c.xAxis[0].setExtremes(0, 6, false, false);
                    }
                }
                c.previousDataLength = rawData.length;
            }
        }
    }, [rawData]);

    const onChartClick = useRef((event: Highcharts.SeriesClickEventObject) => {
        const filterValue = event.point.options.custom?.filterValue;
        const breadcrumbValue = event.point.options.custom?.breadcrumbValue;

        onFilter({
            type: 'dimensionClick',
            operator: operator,
            dimension,
            value: {
                value: filterValue,
                label: breadcrumbValue
            },
            color: borderColor
        });

        Tracking.trackGoal('Filtered by dimension', {
            posthogUniqueKey: dimensionInfo.label,
            dimension,
            chartName: t(dimensionInfo.label),
            filters: filterValueCheck(breadcrumbValue)
        });
    });

    const onRangeSelection = useRef((values: [number, number] | null) => {
        if (values) {
            onFilter({
                type: 'range',
                dimension,
                values,
                operator: operator === 'equals' ? 'range' : 'notInRange'
            });

            Tracking.trackGoal('Filtered by dimension', {
                posthogUniqueKey: dimensionInfo.label,
                dimension,
                chartName: t(dimensionInfo.label),
                filters: filterValueCheck(values.join(' - '))
            });
        } else {
            onFilter({ type: 'reset', dimension });
        }
    });

    const sortedDataForSearch = dimensionInfo.orderData
        ? dimensionInfo.orderData(rawData, dimensionInfo, dimension)
        : rawData;

    //Publish the operator whenever it changes so that other graphs can fetch their data again
    React.useEffect(() => {
        if (type === 'linear') {
            onFilter({
                dimension,
                type: 'operator',
                operator: (operator === 'equals' ? 'range' : 'notInRange') as BinaryOperator
            });
        } else {
            onFilter({ dimension, type: 'operator', operator });
        }

        // Since the function is initialized with the default value of operator which is 'equals'
        // we need to reinitialize it with the updated value
        onChartClick.current = (event: Highcharts.SeriesClickEventObject) => {
            const filterValue = event.point.options.custom?.filterValue;
            const breadcrumbValue = event.point.options.custom?.breadcrumbValue;

            onFilter({
                type: 'dimensionClick',
                operator: operator,
                dimension,
                value: {
                    value: filterValue,
                    label: breadcrumbValue
                },
                color: borderColor
            });

            Tracking.trackGoal('Filtered by dimension', {
                posthogUniqueKey: dimensionInfo.label,
                dimension,
                chartName: t(dimensionInfo.label),
                filters: filterValueCheck(breadcrumbValue)
            });
        };

        onRangeSelection.current = (values: [number, number] | null) => {
            if (values) {
                onFilter({
                    type: 'range',
                    dimension,
                    values,
                    operator: operator === 'equals' ? 'range' : 'notInRange'
                });

                Tracking.trackGoal('Filtered by dimension', {
                    posthogUniqueKey: dimensionInfo.label,
                    dimension,
                    chartName: t(dimensionInfo.label),
                    filters: filterValueCheck(values.join(' - '))
                });
            } else {
                onFilter({ type: 'reset', dimension });
            }
        };
    }, [operator]);

    const id = `search-menu-${dimension}`;
    const Search = (
        <StyledPopper
            id={id}
            anchorEl={searchElement}
            open={Boolean(searchElement)}
            marginTop={'12px'}>
            <FilterAutocomplete
                ref={(ref: HTMLElement) => {
                    Maybe(ref)
                        .chain((ref) => Maybe(ref.getElementsByTagName('input')[0]))
                        .map((input) => input.focus());
                }}
                options={sortedDataForSearch}
                getOptionLabel={(_option) => {
                    const option = _option as DimensionData;
                    return String(formatChartLabels(option[dimension], t, transformChartLabel));
                }}
                renderInput={(params) => (
                    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                        <div style={{ position: 'absolute', left: -20, top: 15 }}>
                            <CustomSvgIcon
                                fill={theme.colors.black}
                                size={1.2}
                                iconName={SEARCH_ICON}
                            />
                        </div>
                        <TextField
                            {...params}
                            label=""
                        />
                    </div>
                )}
                disablePortal={true}
                onBlur={(event) => {
                    event.preventDefault();
                    setSearchElement(null);
                }}
                onChange={(_, _value) => {
                    const value = _value as DimensionData | null;
                    if (value == null) {
                        return;
                    }
                    const filterValue = String(value[dimension]);
                    const breadcrumbValue = String(
                        formatChartLabels(value[dimension], t, transformChartLabel)
                    );
                    onFilter({
                        type: 'dimensionClick',
                        operator: operator,
                        dimension,
                        value: {
                            value: filterValue,
                            label: breadcrumbValue
                        },
                        color: borderColor
                    });

                    Tracking.trackGoal('Graph search filter applied', {
                        posthogUniqueKey: dimensionInfo.label,
                        dimension,
                        chartName: t(dimensionInfo.label),
                        filters: filterValueCheck(breadcrumbValue)
                    });
                }}
            />
        </StyledPopper>
    );

    const chartMenu = (
        <>
            <Mobile>
                <FadedChartMenuItemContainer>
                    {type !== 'linear' ? (
                        <FadedChartAction
                            aria-describedby={searchElement ? id : undefined}
                            aria-haspopup="true"
                            onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                setSearchElement(event.currentTarget);
                            }}
                            title={t('search')}
                            iconName={SEARCH_ICON}>
                            {Search}
                        </FadedChartAction>
                    ) : null}
                </FadedChartMenuItemContainer>
            </Mobile>
            <TabletToDesktop>
                <FadedChartMenuItemContainer>
                    {type !== 'linear' ? (
                        <FadedChartAction
                            aria-describedby={searchElement ? id : undefined}
                            aria-haspopup="true"
                            onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                setSearchElement(event.currentTarget);
                            }}
                            title={t('search')}
                            iconName={SEARCH_ICON}>
                            {Search}
                        </FadedChartAction>
                    ) : null}
                    <InvertChartAction
                        onClick={() => {
                            const newOperator = operator === 'equals' ? 'notEquals' : 'equals';
                            Tracking.trackGoal('Graph Operator changed', {
                                posthogUniqueKey: newOperator,
                                value: newOperator,
                                added: false
                            });
                            setOperator(newOperator);
                        }}
                        title={t(operator)}
                        iconName={operator === 'equals' ? IN_FILTER_ICON : NOT_IN_FILTER_ICON}
                        invert={operator}
                        disabled={focusedSegments ? true : false}
                    />
                    <FadedChartAction
                        onClick={() => {
                            Tracking.trackGoal('Graph filters reset', {
                                posthogUniqueKey: 'restGraphFilter'
                            });
                            onFilter({ type: 'reset', dimension });
                        }}
                        title={t('resetFilter')}
                        iconName={RESET_FILTER_ICON}
                    />
                    {exportGraph}
                </FadedChartMenuItemContainer>
            </TabletToDesktop>
        </>
    );

    React.useEffect(() => {
        if (chart) {
            setExportGraph(
                <React.Fragment>
                    <FadedChartAction
                        onClick={() => {
                            chart?.current?.chart.downloadCSV();
                        }}
                        title={t('exportGraphCSV')}
                        iconName={CSV_EXPORT_ICON}
                    />
                    <FadedChartAction
                        onClick={() => {
                            if (type === 'bar') {
                                getChartPNG(chart.current.chart, exportFileName);
                                return;
                            }

                            pngExportHandler(chartId, exportFileName);
                        }}
                        title={t('exportGraphPNG')}
                        iconName={PNG_EXPORT_ICON}
                    />
                </React.Fragment>
            );
        }
    }, [chart]);

    if (excludeDashboardComponent.includes(dimensionName)) {
        return <React.Fragment />;
    }

    const getChartByType = () => {
        switch (type) {
            case 'bar':
                return (
                    <BarChartWrapper id={chartId}>
                        <BarChart
                            key={dimension}
                            subtitleTwo={subtitleTwo}
                            series={chartData}
                            onBarClick={onChartClick}
                            chartCallback={setChart}
                            metric={metric}
                            barOptions={barChartOptions}
                            exportFileName={exportFileName}
                            /* We had an issue with click handler on bar labels where it wasn't applied until we had a state update e.g. another filter or date range changed
                 So we ended up using immutable true so that the graph would re-render instead of using chart.update */
                            immutable={customerTagsDimensions.includes(dimension)}
                        />
                    </BarChartWrapper>
                );
            case 'column':
                return (
                    <div id={chartId}>
                        <ColumnChart
                            key={dimension}
                            series={chartData}
                            chartCallback={setChart}
                            metric={metric}
                            exportFileName={exportFileName}
                            columnOptions={columnChartOptions}
                            onColumnClick={onChartClick}
                            timeRangeChart={timeRangeChart}
                        />
                    </div>
                );
            case 'linear':
                return (
                    <LinearChart
                        key={dimension}
                        dimension={dimension}
                        filters={filters}
                        series={chartData}
                        chartCallback={setChart}
                        metric={metric}
                        exportFileName={exportFileName}
                        onRangeSelection={onRangeSelection}
                        timeRangeChart={timeRangeChart}
                    />
                );
        }
    };

    return (
        <React.Fragment>
            <IconWrapper key={'chartMenu'}>{chartMenu}</IconWrapper>
            {getChartByType()}
            {loading && <LoadingContainer key={'loading'} />}
        </React.Fragment>
    );
};

interface ChartWithPreviousDataLength extends Highcharts.Chart {
    previousDataLength?: number;
}

const IconWrapper = styled('span')`
    position: absolute;
    right: 30px;
    top: 0px;
    display: flex;
    justify-content: space-around;
    font-size: 10px;
`;

const BarChartWrapper = styled('div')`
    position: relative;
`;
