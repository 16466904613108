import React, { Component } from 'react';
import { DynamicRoutesContext } from 'Routing/DynamicRoutesContext';
import { SetDynamicRoutes, DynamicRoutesInterface, Route } from './routingTypes';
import { AllRoutes } from './allRoutes';
import { toPairs, sort, ascend, propOr, compose, reduce, map } from 'ramda';

export class DynamicRoutesContextProvider extends Component<{ children: React.ReactNode }> {
    setDynamicRoutes: SetDynamicRoutes = (services, routes) => {
        const dashboardGroups = generateDashboardGroups(routes);
        this.setState({ services, routes, dashboardGroups });
    };

    state: DynamicRoutesInterface = {
        services: [],
        routes: [],
        dashboardGroups: [],
        setDynamicRoutes: this.setDynamicRoutes
    };

    render(): JSX.Element {
        return (
            <DynamicRoutesContext.Provider value={{ ...this.state }}>
                {this.props.children}
            </DynamicRoutesContext.Provider>
        );
    }
}

const generateDashboardGroups = compose(
    map(([dashboardGroup, routes]: [string, readonly unknown[]]) => [
        dashboardGroup,
        sort(ascend(propOr(Infinity, 'index')), routes)
    ]),
    toPairs,
    reduce((previous: AllRoutes, route: Route<any>) => {
        if (route.dashboardGroup) {
            previous[route.dashboardGroup] = previous[route.dashboardGroup] || [];
            previous[route.dashboardGroup].push(route);
        }
        return previous;
    }, {})
);
