import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import { formatWholeNumber } from 'utils/formatting/numbers';
import { InventoryBinaryRowChart } from '../charts';
import { Aspect } from '../common/tile/TileAspect';

interface ECODeliverabilityStatisticsProps {
    chartData: Highcharts.SeriesBarOptions[];
    chartCategories: Array<string>;
    inventoryAspects: Aspect[];
}

const ECODeliverabilityStatistics: React.FunctionComponent<ECODeliverabilityStatisticsProps> = ({
    chartData,
    chartCategories,
    inventoryAspects
}) => {
    const { t } = useTranslation();
    const statisticsHandler = () =>
        inventoryAspects.map((aspect, index) => {
            if (aspect.label === 'Sent') {
                return;
            }
            return (
                <Statistics key={index}>
                    <span>{t(aspect.label.toLowerCase())}</span>
                    <span>{formatWholeNumber(Number(aspect.value))}</span>
                </Statistics>
            );
        });
    return (
        <Wrapper>
            <BinaryRowWrapper>
                <SectionTitle>{t('deliverability')}</SectionTitle>
                <InventoryBinaryRowChart
                    series={chartData}
                    categories={chartCategories}
                />
            </BinaryRowWrapper>
            <StatisticTableWrapper>
                <SectionTitle>{t('statistics')}</SectionTitle>
                <StatisticTable>{statisticsHandler()}</StatisticTable>
            </StatisticTableWrapper>
        </Wrapper>
    );
};

const Wrapper = styled('div')`
    margin-top: -8px;
    & > div {
        border-bottom: 1px solid ${({ theme }) => theme.colors.inventoryDivider};
    }
    & > div:last-child {
        border-bottom: none;
    }
`;

const BinaryRowWrapper = styled('div')`
    padding-bottom: ${({ theme }) => theme.space.single};
`;

const StatisticTableWrapper = styled('div')`
    padding-top: ${({ theme }) => theme.space.single};
`;

const StatisticTable = styled('div')`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 90px;
    padding-right: ${({ theme }) => theme.space.single};
    padding-left: ${({ theme }) => theme.space.half};
    padding-top: ${({ theme }) => theme.space.half};
`;

const Statistics = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space.half};
`;

const SectionTitle = styled(`span`)`
    display: inline-block;
    margin-left: ${({ theme }) => theme.space.half};
    margin-bottom: ${({ theme }) => theme.space.half};
    font-size: ${({ theme }) => theme.fontSizes.header};
    font-weight: 500;
    width: 100%;
`;

export { ECODeliverabilityStatistics };
