export const capitalize = (str: string) =>
    `${String(str).charAt(0).toUpperCase()}${String(str).substr(1)}`;

type TextAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export const string2TextAlign = (str: string | undefined): TextAlign | undefined => {
    let retval: TextAlign;

    try {
        retval = str as TextAlign;
    } catch (error) {
        return 'left';
    }

    return retval;
};
