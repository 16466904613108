import React from 'react';
import { styled } from '@mui/material/styles';

interface Props {
    top?: JSX.Element;
    left?: JSX.Element;
}

const LeftLayout = ({ top, left }: Props) => (
    <LayoutWrapper>
        <TopWrapper>{top}</TopWrapper>
        <LeftWrapper>{left}</LeftWrapper>
    </LayoutWrapper>
);

const LayoutWrapper = styled('main')`
    display: grid;
    overflow: scroll-y;
    background-color: ${({ theme }) => theme.colors.background};
    min-height: 100vh;
    grid-template-columns: 3fr 5fr;
    grid-template-rows: auto;
    grid-template-areas:
        'top top'
        'left .';
`;

const TopWrapper = styled('header')`
    grid-area: top;
    height: 44px;
`;

const LeftWrapper = styled('div')`
    grid-area: left;
`;

export { LeftLayout };
