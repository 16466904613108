import useMediaQuery from '@mui/material/useMediaQuery';
import json2mq, { QueryObject } from 'json2mq';
import React from 'react';

// You are welcome to edit this as needed,
// but variables need to be accepted by @media in css
// https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries
export interface MediaQueryParams {
    minWidth?: number;
    maxWidth?: number;
    handheld?: boolean;
    screen?: boolean;
    orientation?: 'landscape' | 'portrait';
}

type Children = JSX.Element | string | null;

interface MediaProps {
    children: Children | Children[];
    className?: string;
}

// Additional size definitions can be added later as needed
// but try to keep it simple.
const mobile: MediaQueryParams = { maxWidth: 900 };
const tablet: MediaQueryParams = { minWidth: 901, maxWidth: 1230 };
const notebook: MediaQueryParams = { minWidth: 1231, maxWidth: 1560 };
const desktop: MediaQueryParams = { minWidth: 1561 };

const mobileOrTablet: MediaQueryParams = {
    minWidth: mobile.minWidth,
    maxWidth: tablet.maxWidth
};
const tabletOrNotebook: MediaQueryParams = {
    minWidth: tablet.minWidth,
    maxWidth: notebook.maxWidth
};
const notebookOrDesktop: MediaQueryParams = {
    minWidth: notebook.minWidth
};
const tabletToDesktop: MediaQueryParams = {
    minWidth: tablet.minWidth
};

const getJsxOrNull = (
    showInMedia: boolean,
    children: Children | Children[],
    className?: string
) => {
    if (showInMedia) {
        return <div className={className}>{children}</div>;
    }

    return null;
};

export const sizes = { mobile, tablet, notebook, desktop };

export const Desktop = ({ children, className }: MediaProps) => {
    const showInMedia = useMediaQuery(json2mq(desktop as QueryObject));

    return getJsxOrNull(showInMedia, children, className);
};

export const Tablet = ({ children, className }: MediaProps) => {
    const showInMedia = useMediaQuery(json2mq(tablet as QueryObject));

    return getJsxOrNull(showInMedia, children, className);
};

export const Notebook = ({ children, className }: MediaProps) => {
    const showInMedia = useMediaQuery(json2mq(notebook as QueryObject));

    return getJsxOrNull(showInMedia, children, className);
};

export const MobileOrTablet = ({ children, className }: MediaProps) => {
    const showInMedia = useMediaQuery(json2mq(mobileOrTablet as QueryObject));

    return getJsxOrNull(showInMedia, children, className);
};

export const TabletOrNotebook = ({ children, className }: MediaProps) => {
    const showInMedia = useMediaQuery(json2mq(tabletOrNotebook as QueryObject));

    return getJsxOrNull(showInMedia, children, className);
};

export const NotebookOrDesktop = ({ children, className }: MediaProps) => {
    const showInMedia = useMediaQuery(json2mq(notebookOrDesktop as QueryObject));

    return getJsxOrNull(showInMedia, children, className);
};

export const TabletToDesktop = ({ children, className }: MediaProps) => {
    const showInMedia = useMediaQuery(json2mq(tabletToDesktop as QueryObject));

    return getJsxOrNull(showInMedia, children, className);
};

export const Mobile = ({ children, className }: MediaProps) => {
    const showInMedia = useMediaQuery(json2mq(mobile as QueryObject));

    return getJsxOrNull(showInMedia, children, className);
};
