import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import { ASButton } from '../../common/button';
import { LoadingContainer } from '../../common/loader';
import { SearchResultsFilters } from '../../filters';
import { SearchResultsList } from '../../lists';
import { sizes } from '../../responsive/MediaQuery';
import { Filters } from '../GlobalSearch';
import { FilterValue } from 'utils/common/types';
import { Hit, SearchResults, TILE_TYPE } from 'utils/api/globalSearch/types';

const SearchResultsComp = ({
    searchResults,
    onChangeFilter,
    show,
    onLoadMore,
    onLoadLess,
    aggregationFilters,
    loading,
    tileType,
    selectedEntityCallback,
    resetSearchInput,
    resaultsListOff,
    resultListHeight,
    position,
    currentSelection,
    isSelected,
    styledFor,
    page
}: {
    searchResults: SearchResults | null;
    onChangeFilter: (filters: Filters) => void;
    show: boolean;
    onLoadMore: () => void;
    aggregationFilters: Filters;
    loading: boolean;
    tileType: TILE_TYPE;
    selectedEntityCallback?: React.Dispatch<Hit>;
    resetSearchInput?: () => void;
    resaultsListOff?: any;
    resultListHeight?: string;
    position: 'absolute' | 'static';
    currentSelection: FilterValue[];
    isSelected?: (value: string) => boolean;
    styledFor?: string;
    page?: number;
    onLoadLess?: () => void;
}) => {
    const { t } = useTranslation();

    const renderLoadMoreButton = () => {
        if (searchResults && searchResults.hits.length < searchResults.page.totalElements) {
            return (
                <React.Fragment>
                    {styledFor === 'segmentBuilder' ? (
                        <SegmentBuilderButtonsWrapper>
                            <SegmentBuilderShowMoreButton onClick={onLoadMore}>
                                {`${t('showMore')} (${
                                    searchResults.page.totalElements - searchResults.hits.length
                                } ${t('results')})`}
                            </SegmentBuilderShowMoreButton>
                            {page && page > 0 ? (
                                <SegmentBuilderShowLessButton onClick={onLoadLess}>
                                    {t('showLess')}
                                </SegmentBuilderShowLessButton>
                            ) : null}
                        </SegmentBuilderButtonsWrapper>
                    ) : (
                        <StyledASButton
                            id="search-loadmore-button"
                            onClick={onLoadMore}>
                            {t('loadMore')}
                        </StyledASButton>
                    )}
                </React.Fragment>
            );
        }

        return null;
    };

    if (!show) {
        return null;
    }

    const showList = searchResults !== null && searchResults.hits.length !== 0;

    return (
        <ResultsWrapper
            id="search-list-container"
            position={position}>
            {loading && (
                <LoadingContainer
                    key="Loader"
                    size={30}
                />
            )}
            <SearchResultsFilters
                searchResults={searchResults}
                onChangeFilter={onChangeFilter}
                aggregationFilters={aggregationFilters}
                styledFor={styledFor}
            />
            {styledFor === 'segmentBuilder'
                ? showList && (
                      <SegmentBuilderDividerWrapper>
                          <SegmentBuilderDivider />
                      </SegmentBuilderDividerWrapper>
                  )
                : showList && (
                      <DividerWrapper>
                          <Divider />
                      </DividerWrapper>
                  )}
            <SearchResultsList
                hits={searchResults?.hits}
                show={showList}
                tileType={tileType}
                selectedEntityCallback={selectedEntityCallback}
                resetSearchInput={resetSearchInput}
                resaultsListOff={resaultsListOff}
                resultListHeight={resultListHeight}
                currentSelection={currentSelection}
                isSelected={isSelected}
                styledFor={styledFor}
            />
            {renderLoadMoreButton()}
        </ResultsWrapper>
    );
};

export { SearchResultsComp };

const ResultsWrapper = styled('div')<{ position: 'absolute' | 'static' }>`
    display: grid;
    position: ${({ position }) => position};
    ${({ position }) => (position === 'static' ? 'grid-area: results;' : '')}
    ${({ position, theme }) => (position === 'absolute' ? `top: ${theme.space.menu};` : '')}
  ${({ position, theme }) => (position === 'absolute' ? `left: -${theme.space.menu};` : '')}
  background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    @media (min-width: ${sizes.desktop.minWidth}px) {
        min-width: 455px;
        left: 0px;
    }

    @media (min-width: ${sizes.tablet.minWidth}px) and (max-width: ${sizes.notebook.maxWidth}px) {
        width: 510px;
    }
`;

const DividerWrapper = styled('div')`
    width: 100%;
    padding: ${({ theme }) => theme.space.half} ${({ theme }) => theme.space.double};
`;

const Divider = styled('div')`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.dividers};
`;

const SegmentBuilderDividerWrapper = styled('div')`
    width: 100%;
    padding: ${({ theme }) => `${theme.space.half} 40px 0
    ${theme.space.single}`};
`;

const SegmentBuilderDivider = styled('div')`
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
`;

const StyledASButton = styled(ASButton)`
    margin: ${({ theme }) => theme.space.double};
    height: ${({ theme }) => theme.space.triple};
    width: calc(100% - (${({ theme }) => theme.space.double} * 2));
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 0px;
    color: ${({ theme }) => theme.colors.text};
`;

const SegmentBuilderShowMoreButton = styled('div')`
    width: 167px;
    height: 19px;
    color: ${({ theme }) => theme.colors.segmentBuilderCancelIcon};
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    margin: ${({ theme }) => theme.space.double};
    cursor: pointer;
`;

const SegmentBuilderButtonsWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
`;

const SegmentBuilderShowLessButton = styled('div')`
    width: 167px;
    height: 19px;
    color: ${({ theme }) => theme.colors.segmentBuilderCancelIcon};
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: right;
    margin: ${({ theme }) => theme.space.double};
    cursor: pointer;
`;
