import { useTranslation } from 'react-i18next';
import React from 'react';
import Highcharts, { Point } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@mui/material/styles';

import { DateFormatContext } from '../common/datepicker';
import { HighchartsInventoryBinaryRow, HighchartTooltipChart } from './types';
import { tooltipFormatter } from 'utils/formatting/tooltips/tooltips';

import useCheckIfMobile from 'utils/mobile/checkIfMobile';

export const InventoryBinaryRowChart = ({ series, categories }: HighchartsInventoryBinaryRow) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { formatTooltipHeader } = React.useContext(DateFormatContext);

    const isMobile = useCheckIfMobile();
    const tickInterval = isMobile ? 25 : 20;
    const labelFormat = isMobile ? '{value}%' : '{value} %';

    const [options] = React.useState<Highcharts.Options>({
        chart: {
            type: 'bar',
            height: categories.length === 1 ? 133 * categories.length : 60 * categories.length,
            style: {
                fontFamily: theme.fonts.primaryFont
            }
        },
        title: {
            text: undefined
        },
        xAxis: {
            categories,
            labels: {
                style: {
                    color: theme.colors.text,
                    fontSize: theme.fontSizes.small
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            outside: true,
            shared: true,
            useHTML: true,
            positioner(this: Highcharts.Tooltip, labelWidth, labelHeight, point) {
                const chartPosition = this.chart.pointer.getChartPosition();
                const chart = this.chart as HighchartTooltipChart;
                const chartContainerWidth = chart.containerWidth;
                return {
                    x:
                        chartPosition.left +
                        chartContainerWidth -
                        chart.plotWidth +
                        chart.plotWidth / 4 -
                        20,
                    y: !(point instanceof Point)
                        ? chartPosition.top +
                          (point as Highcharts.TooltipPositionerPointObject).plotY -
                          labelHeight -
                          10
                        : chartPosition.top - labelHeight
                };
            },
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                return tooltipFormatter(t, formatTooltipHeader)(this);
            }
        },
        yAxis: {
            title: {
                text: undefined
            },
            min: 0,
            max: 100,
            tickInterval: tickInterval,
            labels: {
                format: labelFormat,
                style: {
                    color: theme.colors.text,
                    fontSize: theme.fontSizes.small
                },
                padding: 20
            }
        },
        legend: {
            align: 'left',
            enabled: true,
            itemDistance: 5,
            margin: 14,
            y: -5,
            padding: 2,
            symbolRadius: 0,
            reversed: true,
            labelFormatter(this: Highcharts.Point | Highcharts.Series) {
                return t(this.name);
            },
            itemStyle: {
                color: theme.colors.text,
                fontSize: theme.fontSizes.small,
                fontWeight: 'normal'
            }
        },

        plotOptions: {
            series: {
                stacking: 'percent'
            },
            bar: {
                pointWidth: 20
            }
        },
        series: series.map((serie) => ({ ...serie, name: t((serie.name || '').toLowerCase()) })),

        exporting: {
            enabled: false
        }
    });

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};
