import React from 'react';
import { TableStyledASLink } from '../../commonStyles';
import { Row, EventStatistic, Direction } from './types';
import { Customer, EventInventory } from 'views/dashboards/PerformerOverview/types';
import { ASC, BASE_TRANSACTIONS_REVENUE } from 'utils/common/constants';

import { DimensionData, OrderByType } from 'utils/common/types';
import { lifetimeValueLoader } from 'utils/dataloaders/lifetimeValueLoader';

export const sortTableData = (data: Row[], orderBy: OrderByType, direction: Direction) => {
    const compareRows = (aRow: Row, bRow: Row) => {
        const a = aRow[orderBy];
        const b = bRow[orderBy];

        if (!a && a !== 0) {
            return -1;
        }
        if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b);
        }
        if (((a && b) || a === 0 || b === 0) && a < b) {
            return -1;
        }
        if (((a && b) || a === 0 || b === 0) && a > b) {
            return 1;
        }

        return 0;
    };

    return direction === ASC ? data.sort(compareRows) : data.sort(compareRows).reverse();
};

const addLifetimeValues = async (rows: Row[]): Promise<Row[]> => {
    let tempTable: Row[] = [];

    const lifetimeValues: any = await Promise.all(
        rows.map((item) => lifetimeValueLoader.load(item.buyerRef))
    );

    if (!lifetimeValues) {
        return [];
    }

    tempTable = rows.map((el, i) => {
        el.lifetimeValue = lifetimeValues[i][0][BASE_TRANSACTIONS_REVENUE];
        return el;
    });

    return tempTable;
};

export const prepareCustomerTablePage = async (
    data: Customer[],
    newOrderBy: OrderByType,
    newOrderDirection: Direction,
    newPage: number,
    newRowsPerPage: number
): Promise<Row[]> => {
    const preparedData = prepareCustomerTableData(data);
    const dataWithLifetimeValues = await addLifetimeValues(preparedData);

    return sortTableData(dataWithLifetimeValues, newOrderBy, newOrderDirection).slice(
        newPage * newRowsPerPage,
        newPage * newRowsPerPage + newRowsPerPage
    );
};

export const prepareCustomerTableData = (customers: Customer[]) =>
    customers.map(
        (customer) =>
            ({
                fullName: customer.fullName,
                buyerRef: customer.buyerRef,
                location: customer.location,
                city: customer.city,
                sectionRowSeat: customer.sectionRowSeat,
                itemCount: customer.itemCount,
                avgItemPrice: customer.avgItemPrice,
                revenue: customer.revenue
            }) as Row
    );

export const prepareEventTablePage = (
    data: EventInventory[],
    newOrderBy: keyof EventInventory,
    newOrderDirection: Direction,
    newPage: number,
    newRowsPerPage: number
): Row[] =>
    sortTableData(prepareEventTableData(data), newOrderBy, newOrderDirection).slice(
        newPage * newRowsPerPage,
        newPage * newRowsPerPage + newRowsPerPage
    );

export const prepareEventTableData = (events: EventInventory[]) =>
    events.map(
        (event) =>
            ({
                eventName: event.eventName,
                venue: event.venue,
                date: event.date,
                capacity: event.capacity,
                revenue: event.revenue,
                avgItemPrice: event.avgItemPrice,
                sellableCap: event.sellableCap,
                open: event.open,
                hold: event.hold,
                sold: event.sold
            }) as Row
    );

export const prepareCustomerViewTablePage = (
    data: EventStatistic[],
    newOrderBy: keyof EventStatistic,
    newOrderDirection: Direction,
    newPage: number,
    newRowsPerPage: number
): Row[] =>
    sortTableData(prepareCustomerViewTableData(data), newOrderBy, newOrderDirection).slice(
        newPage * newRowsPerPage,
        newPage * newRowsPerPage + newRowsPerPage
    );

export const prepareCustomerViewTableData = (customers: EventStatistic[]) =>
    customers.map(
        (customer) =>
            ({
                order: customer.order,
                date: customer.date,
                details: customer.details,
                quantity: customer.quantity,
                revenue: customer.revenue
            }) as Row
    );

export const tableLinkHandler = (link: { ref: string; label: string }) => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <TableStyledASLink
        href={`/activity-stream/EntityOverview/${link.ref}`}
        text={link.label}
    />
);

export const prepareTablePage = (
    data: DimensionData[],
    newOrderBy: any,
    newOrderDirection: Direction,
    newPage: number,
    newRowsPerPage: number
): Row[] =>
    sortTableData(data, newOrderBy, newOrderDirection).slice(
        newPage * newRowsPerPage,
        newPage * newRowsPerPage + newRowsPerPage
    );
