/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/order */
import React, { useState, useEffect, useContext } from 'react';
import { LeftLayout } from 'layouts/LeftLayout';
import { LayoutHeader } from 'layouts/LayoutHeader';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import { Toaster, toast } from 'components/common/popup';
import { RadioGroup } from '@mui/material';
import { ASButton } from 'components/common/button';
import { RadioButtonEmoji } from 'components/buttons';
import { ProvisioningAPI } from 'utils/api/provisioning/ProvisioningAPI';
import { CANCEL_ICON } from 'assets/iconConstants';
import { useTheme } from '@mui/material/styles';
import { settingsStore } from 'stores/settings';
import { DateFormatContext } from 'components/common/datepicker/DateFormatContext';
import { setGlobalLanguage } from 'utils/localization';
import { sizes } from 'components/responsive/MediaQuery';

const PersonalSettings = () => {
    const { t } = useTranslation();
    const title = t('personalSettings');
    const { dictionary } = useContext(settingsStore);

    const UK_LANG = 'en-GB';
    const US_LANG = 'en-US';
    const SPANISH_LANG = 'es';
    const GERMAN_LANG = 'de';
    // const JAPANESE_LANG = 'ja';

    const ICON_SIZE = 1;
    const theme = useTheme();

    const [locale, setLocale] = useState('');

    const userSettings = React.useContext(settingsStore);
    const { setDateFnsLocale } = React.useContext(DateFormatContext);

    useEffect(() => {
        const getUserPreferences = async () => {
            const tenantLanguage = userSettings.language;

            try {
                const userPreferences = await ProvisioningAPI.getUserPreferences();

                setLocale(userPreferences.locale);
            } catch (error: any) {
                // Default to the tenant language since the user hasn't selected a language yet:
                setLocale(tenantLanguage);
            }
        };

        getUserPreferences();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateUserPreferences = async (newLocale: string) => {
        try {
            await ProvisioningAPI.updateUserPreferences({ locale: newLocale });

            setGlobalLanguage(newLocale, dictionary, setDateFnsLocale, userSettings);

            setLocale(newLocale);
        } catch (error: any) {
            toast.failure({
                message: t('backendError'),
                theme
            });
        }
    };

    return (
        <div id="userSettings">
            <LeftLayout
                top={<LayoutHeader title={title} />}
                left={
                    <Wrapper>
                        <Toaster />
                        <HeaderStyled>{t('languages')}</HeaderStyled>
                        <BoardStyled>
                            <TextWrapper>
                                <LabelStyled>{t('chooseYourLanguage')}</LabelStyled>
                                <RadioGroup
                                    aria-labelledby="radio-buttons-group-label"
                                    defaultValue={UK_LANG}
                                    name="radio-buttons-group"
                                    value={locale}
                                    onChange={(e) => updateUserPreferences(e.target.value)}>
                                    <RadioButtonEmoji
                                        value={UK_LANG}
                                        label="English UK"
                                        icon="🇬🇧"
                                    />
                                    <RadioButtonEmoji
                                        value={US_LANG}
                                        label="English US"
                                        icon="🇺🇸"
                                    />
                                    <RadioButtonEmoji
                                        value={SPANISH_LANG}
                                        label="Espanol"
                                        icon="🇪🇸"
                                    />
                                    <RadioButtonEmoji
                                        value={GERMAN_LANG}
                                        label="Deutsch"
                                        icon="🇩🇪"
                                    />
                                    {/* <RadioButtonEmoji value={JAPANESE_LANG} label="日本" icon="🇯🇵" /> */}
                                </RadioGroup>
                            </TextWrapper>
                        </BoardStyled>
                        <Toaster
                            svgIconSize={ICON_SIZE}
                            svgIconName={CANCEL_ICON}
                        />
                    </Wrapper>
                }
            />
        </div>
    );
};

export default PersonalSettings;

const Wrapper = styled('div')`
    min-height: 100vh;

    @media (max-width: ${sizes.notebook.minWidth}px) {
        padding-top: ${({ theme }) => theme.space.triple};
    }
`;

const BoardStyled = styled('div')`
    margin: 10px 50px 50px 45px;
    padding: 10px;
    min-height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border-top: 2px solid ${({ theme }) => theme.colors.borderThick};
    border-right: 1px solid ${({ theme }) => theme.colors.frame};
    border-bottom: 1px solid ${({ theme }) => theme.colors.frame};
    border-left: 1px solid ${({ theme }) => theme.colors.frame};
`;

const HeaderStyled = styled('p')`
    font-size: ${({ theme }) => theme.fontSizes.header};
    font-weight: bold;
    margin: 25px 0 0 45px;
`;

const TextWrapper = styled('div')`
    font-size: 14px;
    padding: 0 10px 10px 10px;
    width: 200px;
`;

const LabelStyled = styled('label')`
    display: block;
    margin-top: ${({ theme }) => theme.space.single};
    margin-bottom: ${({ theme }) => theme.space.double};
`;

export const Button = styled(ASButton)`
    width: 179px;
    height: 56px;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: 1px solid ${({ theme }) => theme.colors.segmentBuilderPreviousButtonFrame};
    margin-right: ${({ theme }) => theme.space.triple};
`;

export const ButtonText = styled('span')<{
    color: string;
    fontSize?: string;
    height?: number;
    width?: string;
}>`
    width: ${({ width }) => (width ? width : '140px')};
    height: ${({ height }) => (height ? `${height}px` : '22px')};
    color: ${({ color }) => color};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
`;
