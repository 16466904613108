import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicRoutesContext } from 'Routing/DynamicRoutesContext';
import { Menu } from 'components/common/menu/SideBarMenu';
import { ascend } from 'ramda';
import { WAND_ICON } from 'assets/iconConstants';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

export const ToolsMobileMenu = () => {
    const { t } = useTranslation();
    const { dashboardGroups } = React.useContext(DynamicRoutesContext);
    const theme = useTheme();

    const toolsMenu = useRef<any>(null);

    const [toolsOpen, setToolsOpen] = React.useState(false);

    //with this we are able to close the menu if we click somewhere outside
    React.useEffect(() => {
        const closeOpenMenu = (e: MouseEvent) => {
            e.stopPropagation();
            if (toolsMenu.current && !toolsMenu.current.contains(e.target)) {
                setToolsOpen(false);
            }
        };
        document.addEventListener('mousedown', closeOpenMenu);
    });

    const menuItems = dashboardGroups
        .sort(ascend(([dashboardGroup]) => DashbarodGroupOrder.indexOf(dashboardGroup)))
        .filter((item) => item[0] === 'Tools')
        .map(([dashboardGroup, routes]) => {
            const menuItem: Partial<Menu> =
                getMenuItemByDashboardGroup[dashboardGroup as dashboardGroup];
            menuItem.links = routes.map((route) => ({
                href: route.path,
                label: t(route.label || ''),
                id: route.label || ''
            }));

            return menuItem as Menu;
        });

    const filterItems = (filterBy: string) => {
        return filterBy === 'campaignManagement';
    };

    const tools = menuItems.map((menuItem) => {
        return {
            ...menuItem,
            links: menuItem.links.filter((link) => filterItems(link.id))
        };
    });

    return (
        <Wrapper ref={toolsMenu}>
            <div onClick={() => setToolsOpen(!toolsOpen)}>
                <CustomSvgIcon
                    size={1.5}
                    fill={toolsOpen ? theme.colors.white : theme.colors.mobileIconInactive}
                    iconName={WAND_ICON}
                />
            </div>
            {toolsOpen && (
                <ToolsMenu>
                    {tools.map((tool) =>
                        tool.links.map((link) => {
                            return (
                                <StyledLink
                                    key={link.id}
                                    href={link.href}>
                                    {link.label}
                                </StyledLink>
                            );
                        })
                    )}
                </ToolsMenu>
            )}
        </Wrapper>
    );
};

const getMenuItemByDashboardGroup: { [key: string]: Partial<Menu> } = {
    Tools: {
        icon: WAND_ICON,
        title: 'tools'
    }
};

ToolsMobileMenu.defaultProps = {
    showOnMobile: true,
    dontShowOnDesktop: true
};

const DashbarodGroupOrder = Object.keys(getMenuItemByDashboardGroup);

type dashboardGroup = 'Tools';

const ToolsMenu = styled('div')`
    position: absolute;
    top: 45px;
    right: 0px;
    min-width: 200px;
    min-height: 50px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: 1px solid ${({ theme }) => theme.colors.frame};
    text-align: left;
    padding: ${({ theme }) => theme.space.single};
    box-shadow: 0px 4px 10px 0px #00000040;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledLink = styled('a')`
    font-size: ${({ theme }) => theme.fontSizes.header};
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    line-height: 15.6px;
`;

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    font-weight: 400;
`;
