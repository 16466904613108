import React from 'react';
import { styled } from '@mui/material/styles';
import { sizes } from '../components/responsive/MediaQuery';

interface Props {
    content?: JSX.Element;
    menu?: JSX.Element;
}

// (property) JSX.IntrinsicElements.div: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const MasterPageLayout = ({ content, menu }: Props) => (
    <LayoutWrapper>
        <Menu id="sidebarMenu">{menu}</Menu>
        <ContentWrapper>{content}</ContentWrapper>
    </LayoutWrapper>
);

const LayoutWrapper = styled('div')`
    display: grid;
    grid-template-areas:
        'menu'
        'content';
    grid-template-columns: 100%;
    grid-template-rows: ${({ theme }) => theme.space.menu} 1fr;
    background-color: ${({ theme }) => theme.colors.text};

    @media (min-width: ${sizes.tablet.minWidth}px) {
        grid-template-areas: 'menu content';
        grid-template-columns: ${({ theme }) => `${theme.space.menu} 1fr`};
        grid-template-rows: 100%;
    }
`;

const ContentWrapper = styled('div')`
    grid-area: content;
`;

const Menu = styled('div')`
    grid-area: menu;
    z-index: 1100;

    @media (min-width: ${sizes.tablet.minWidth}px) {
        position: fixed;
        background-color: ${({ theme }) => theme.colors.text};
        height: 100%;
    }

    @media (max-width: ${sizes.tablet.minWidth}px) {
        pointer-events: none;
    }
`;

export { MasterPageLayout };
