import React from 'react';
import { compose, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { styled } from '@mui/material/styles';
import { formatDate } from '../common/datepicker/dateUtils';
import { ASLink } from '../common/link';
import { LoadingContainer } from '../common/loader';
import { ASTable } from '../common/table';
import {
    Row,
    Sorting,
    Pagination,
    OnChange,
    OnChangeParams,
    Direction,
    EventInventory,
    ESOTableRow
} from '../common/table/types';

import { formatCurrencyString } from 'utils/formatting/currency';
import { cubejsApi } from 'utils/api/CubeAPI';
import { EntityMetadata } from 'utils/dataloaders/entityLoader';
import { InventoryData, inventoryLoader } from 'utils/dataloaders/inventoryLoader';
import {
    ASC,
    TRANSACTIONS_PRODUCT_LABEL,
    TRANSACTIONS_PRODUCT_REF,
    TRANSACTIONS_PRODUCT_TIME_BEGINS,
    TRANSACTIONS_VENUE_LABEL
} from 'utils/common/constants';
import { prepareEventTablePage } from 'components/common/table/utils';

const EventInventoryTable = ({ eventEntities }: { eventEntities: EntityMetadata[] }) => {
    const { t } = useTranslation();
    const eventEntitiesRef = eventEntities.map((event) => event.entity_ref);
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [orderBy, setOrderBy] = React.useState<keyof EventInventory>('venue');
    const [orderDirection, setOrderDirection] = React.useState<Direction>(ASC);
    const [rawEventLabels, setRawEventLabels] = React.useState<any>([]);
    const [rawEvents, setRawEvents] = React.useState<any>([]);
    const [events, setEvents] = React.useState<any>([]);
    const [tableData, setTableData] = React.useState<Row[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [rawData, setRawData] = React.useState<any>([]);

    React.useEffect(() => {
        setLoading(true);
        cubejsApi
            .load({
                dimensions: [
                    TRANSACTIONS_PRODUCT_REF,
                    TRANSACTIONS_PRODUCT_LABEL,
                    TRANSACTIONS_PRODUCT_TIME_BEGINS,
                    TRANSACTIONS_VENUE_LABEL
                ],
                filters: [
                    {
                        dimension: TRANSACTIONS_PRODUCT_REF,
                        operator: 'equals',
                        values: eventEntitiesRef
                    }
                ]
            })
            .then(compose(setRawData, pathOr([], ['loadResponse', 'results', '0', 'data'])))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    React.useEffect(() => {
        setRawEventLabels(
            rawData.map((data: any) => ({
                eventRef: data[TRANSACTIONS_PRODUCT_REF],
                eventName: (
                    <ASLink
                        href={`/activity-stream/EntityOverview/${data[TRANSACTIONS_PRODUCT_REF]}`}
                        text={data[TRANSACTIONS_PRODUCT_LABEL]}
                    />
                ),
                venue: data[TRANSACTIONS_VENUE_LABEL],
                date: formatDate(data[TRANSACTIONS_PRODUCT_TIME_BEGINS]) || t('N/A')
            }))
        );
    }, [rawData]);

    React.useEffect(() => {
        Promise.all(rawEventLabels.map((event: any) => inventoryLoader.load(event.eventRef))).then(
            (inventories: any) => {
                setRawEvents(
                    rawEventLabels.map((event: any, index: number) =>
                        setPercentSoldAspect(event, inventories[index], t)
                    )
                );
            }
        );
    }, [rawEventLabels]);

    React.useEffect(() => {
        setEvents(
            rawEvents.map((event: any) => ({
                eventName: event.eventName,
                venue: event.venue,
                date: event.date,
                capacity: event.inventoryAspects.capacity,
                revenue: event.inventoryAspects.revenue,
                avgItemPrice: event.inventoryAspects.avgItemPrice,
                open:
                    event.inventoryAspects.sellableCap - event.inventoryAspects.itemsSold ||
                    t('N/A'),
                sold: event.inventoryAspects.itemsSold
            }))
        );
    }, [rawEvents]);

    React.useEffect(() => {
        setTableData(prepareEventTablePage(events, orderBy, orderDirection, page, rowsPerPage));
    }, [events]);

    const sorting: Sorting = {
        orderBy,
        orderDirection
    };

    const pagination: Pagination = {
        page,
        rowsPerPage,
        rowsPerPageOptions: [5, 10, 25],
        totalRowCount: events.length
    };

    const onchangePage: OnChange = ({
        newPage = page,
        newRowsPerPage = rowsPerPage,
        newOrderBy = orderBy,
        newOrderDirection = orderDirection
    }: OnChangeParams) => {
        setTableData(
            prepareEventTablePage(
                events,
                newOrderBy as keyof EventInventory,
                newOrderDirection,
                newPage,
                newRowsPerPage
            )
        );

        if (newPage !== page) {
            setPage(newPage);
        }
        if (newRowsPerPage !== rowsPerPage) {
            setRowsPerPage(newRowsPerPage);
        }
        if (newOrderBy !== orderBy) {
            setOrderBy(newOrderBy as keyof EventInventory);
        }
        if (newOrderDirection !== orderDirection) {
            setOrderDirection(newOrderDirection);
        }
    };

    const headers = [
        { label: 'Date', column: 'date' },
        { label: 'Event Name', column: 'eventName' },
        { label: 'Venue', column: 'venue' },
        { label: 'Sold', column: 'sold' },
        { label: 'Capacity', column: 'capacity' },
        { label: 'Open', column: 'open' },
        { label: 'Revenue', column: 'revenue' },
        { label: 'Avg. Price', column: 'avgItemPrice' }
    ];

    return (
        <TableWrapper>
            <ASTable
                headers={headers}
                data={tableData}
                showPagination={true}
                sorting={sorting}
                pagination={pagination}
                onChange={onchangePage}
                loading={loading}
            />
            {loading && <LoadingContainer />}
        </TableWrapper>
    );
};

export { EventInventoryTable };

const setPercentSoldAspect = (item: EntityMetadata, inventory: InventoryData, t: TFunction) => ({
    ...item,
    inventoryAspects: {
        capacity: inventory.items_for_sale || t('N/A'),
        sellableCap:
            inventory.items_for_sale -
                (Number(inventory.killed || 0) + (Number(inventory.held) || 0)) || t('N/A'),
        revenue: `${formatCurrencyString(t('tenantCurrency'))}${Math.round(
            inventory.totalRevenue ?? 0
        )}`,
        avgItemPrice: `${formatCurrencyString(t('tenantCurrency'))}${Math.round(
            inventory.avgItemPrice ?? 0
        )}`,
        itemsSold: inventory.sold || t('N/A')
    }
});

const TableWrapper = styled('div')`
    position: relative;
`;
