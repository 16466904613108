import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Highcharts, { isNumber } from 'highcharts';
import React, { MutableRefObject, useRef } from 'react';
import { pathOr, compose, mergeDeepLeft } from 'ramda';
import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import { FilterDrawer } from '../filters/FilterDrawer';
import { DateRange } from '../common/datepicker/types';
import { DateFormatContext } from '../common/datepicker';
import { LoadingContainer } from '../common/loader';
import { StyledPopper, FilterAutocomplete } from '../global-search/components/commonStyled';
import {
    FadedChartAction,
    FadedChartMenuContainer,
    FadedChartMenuItemContainer,
    InvertChartAction
} from './fadedIcons';

import { BarAndTable } from './BarAndTable';
import { getBarDataQuery, getCustomersCountQuery } from 'utils/queries';
import { mapFilterChartData } from 'utils/mappers';
import { cubejsApi } from 'utils/api/CubeAPI';
import { AvailableDimensions, DimensionDataStore, FullDimension } from 'stores/dimensionData';
import { Operator, ASCubeFilter, DimensionData, BarChartAndTableProps } from 'utils/common/types';

import { Maybe } from 'utils/maybe';
import {
    CSV_EXPORT_ICON,
    IN_FILTER_ICON,
    NOT_IN_FILTER_ICON,
    PNG_EXPORT_ICON,
    RESET_FILTER_ICON,
    SEARCH_ICON
} from 'assets/iconConstants';
import { settingsStore } from 'stores/settings';

import { pngExportHandler } from 'views/utils/pngExportHandler';

import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { Tracking } from 'externals/tracking';
import { filterValueCheck } from 'utils/filter/filterCheck';
import { filterUnknown, getChartPNG } from 'utils/chartUtils';
import { customerTagsDimensions } from 'utils/common/constants';

import { formatChartLabels } from 'utils/chartLabelFormatters';

export const BarChartAndTableDimension = ({
    dimension,
    tableDimensions,
    metric,
    tableMeasures,
    tableColumns,
    salesTime,
    measures,
    timePeriod,
    filters,
    baseFilters,
    onFilter,
    dashboardName,
    titlePrefix = 'salesBy',
    color,
    transformChartLabel,
    hideFilterDrawer = false,
    doubleFilterDrawer = false,
    barChartOptions = {},
    multipleChartOperator,
    sortedData,
    preparedTableData,
    customBars
}: BarChartAndTableProps) => {
    const { excludeDashboardComponent, tenantTimezone, focusedSegments } =
        React.useContext(settingsStore);
    const { formatDate } = React.useContext(DateFormatContext);
    const { t } = useTranslation();
    const [_, dimensionName]: [string, AvailableDimensions] = dimension.split('.') as [
        string,
        AvailableDimensions
    ];
    const [loading, setLoading] = React.useState<boolean>(false);
    const [rawBarData, setRawBarData] = React.useState<DimensionData[]>([]);
    const [metricsCount, setMetricsCount] = React.useState<{ [key: string]: string }[]>([]);
    const [barData, setBarData] = React.useState<Highcharts.SeriesBarOptions[]>([]);
    const dimensionData = React.useContext(DimensionDataStore);
    const [csvTableData, setCsvTableData] = React.useState<DimensionData[]>([]);

    const [operator, setOperator] = React.useState<Operator>(
        Maybe(
            (filters || []).find(
                (f) => f.dimension === dimension || f.dimension === tableDimensions[0]
            )
        )
            .map((filter) => filter.operator as Operator)
            .getOrElse('equals')
    );

    const [searchElement, setSearchElement] = React.useState<Element | null>(null);
    const [exportGraph, setExportGraph] = React.useState<JSX.Element | null>(null);
    const theme = useTheme();
    const [chart, setChart] = React.useState<MutableRefObject<{ chart: Highcharts.Chart }>>();

    const dimensionInfo = dimensionData[dimensionName];
    const translatedDimension = t(dimensionInfo.label);
    const exportFileName = `${dashboardName}-${translatedDimension}-${timePeriod
        .map((date) => formatDate(new Date(date)))
        .join('-')}`;

    const chartId = translatedDimension.split('.').join('').split(' ').join('_');

    if (!transformChartLabel && dimensionInfo.labelFormatter) {
        transformChartLabel = dimensionInfo.labelFormatter;
    }

    const getBarData = useRef(
        debounce(
            (
                dimension: FullDimension,
                measures: string[],
                salesTime: string,
                timePeriod: DateRange,
                baseFilters: ASCubeFilter[],
                filters: ASCubeFilter[]
            ) => {
                setLoading(true);
                Promise.all([
                    cubejsApi.load(
                        getBarDataQuery(
                            dimension,
                            measures,
                            salesTime,
                            timePeriod,
                            baseFilters,
                            filters,
                            tenantTimezone
                        )
                    ),
                    customerTagsDimensions.includes(dimension)
                        ? cubejsApi.load(
                              getCustomersCountQuery(
                                  dimension,
                                  measures,
                                  salesTime,
                                  timePeriod,
                                  filters,
                                  baseFilters,
                                  tenantTimezone
                              )
                          )
                        : Promise.resolve()
                ])
                    .then((response) => {
                        const rawChartData = compose(
                            setRawBarData,
                            (data: DimensionData[]) => data.slice(),
                            (data: DimensionData[]) => filterUnknown(dimension, dimensionInfo, data)
                        );
                        rawChartData(
                            pathOr([], ['loadResponse', 'results', '0', 'data'], response[0])
                        );

                        const totalsCount = pathOr(
                            [],
                            ['loadResponse', 'results', '0', 'data'],
                            response[1]
                        );
                        setMetricsCount(totalsCount);
                    })
                    .finally(() => setLoading(false));
            }
        )
    ).current;

    React.useEffect(() => {
        if (multipleChartOperator) {
            setOperator(multipleChartOperator);
        }
    }, [multipleChartOperator]);

    React.useEffect(() => {
        if (excludeDashboardComponent.includes(dimensionName)) {
            return;
        }
        getBarData(dimension, measures, salesTime, timePeriod, baseFilters, filters);
    }, [salesTime, timePeriod, filters, baseFilters, excludeDashboardComponent]);

    React.useEffect(() => {
        setBarData(
            mapFilterChartData(
                'bar',
                t,
                theme,
                filters,
                dimension,
                metric,
                operator,
                translatedDimension,
                dimensionInfo,
                metricsCount,
                transformChartLabel,
                color
            )(rawBarData) as Highcharts.SeriesBarOptions[]
        );
    }, [rawBarData, metric, metricsCount]);

    const id = `search-menu-${dimension}`;
    const Search = (
        <StyledPopper
            id={id}
            anchorEl={searchElement}
            open={Boolean(searchElement)}
            marginTop={'5px'}>
            <FilterAutocomplete
                ref={(ref: HTMLElement) => {
                    Maybe(ref)
                        .chain((ref) => Maybe(ref.getElementsByTagName('input')[0]))
                        .map((input) => input.focus());
                }}
                options={rawBarData}
                getOptionLabel={(_option) => {
                    const option = _option as DimensionData;
                    return String(formatChartLabels(option[dimension], t, transformChartLabel));
                }}
                renderInput={(params) => (
                    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                        <div style={{ position: 'absolute', left: -20, top: 15 }}>
                            <CustomSvgIcon
                                fill={theme.colors.black}
                                size={1.2}
                                iconName={SEARCH_ICON}
                            />
                        </div>
                        <TextField
                            {...params}
                            label=""
                        />
                    </div>
                )}
                disablePortal={true}
                onBlur={(event) => {
                    event.preventDefault();
                    setSearchElement(null);
                }}
                onChange={(_, _value) => {
                    const value = _value as DimensionData | null;
                    if (value == null) {
                        return;
                    }
                    const filterValue = String(value[dimension]);
                    const breadcrumbValue = String(
                        formatChartLabels(value[dimension], t, transformChartLabel)
                    );
                    onFilter({
                        type: 'dimensionClick',
                        operator: operator,
                        dimension,
                        value: {
                            value: filterValue,
                            label: breadcrumbValue
                        },
                        color
                    });
                    Tracking.trackGoal('Filtered by dimension', {
                        posthogUniqueKey: dimensionInfo.label,
                        dimension,
                        chartName: t(dimensionInfo.label),
                        filters: filterValueCheck(breadcrumbValue)
                    });
                }}
            />
        </StyledPopper>
    );

    const chartMenu = (
        <FadedChartMenuItemContainer>
            <FadedChartAction
                aria-describedby={searchElement ? id : undefined}
                aria-haspopup="true"
                onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
                    setSearchElement(event.currentTarget)
                }
                title={t('search')}
                iconName={SEARCH_ICON}>
                {Search}
            </FadedChartAction>
            <InvertChartAction
                onClick={() => setOperator(operator === 'equals' ? 'notEquals' : 'equals')}
                title={t(operator)}
                iconName={operator === 'equals' ? IN_FILTER_ICON : NOT_IN_FILTER_ICON}
                invert={operator}
                disabled={focusedSegments ? true : false}
            />
            <FadedChartAction
                onClick={() => onFilter({ type: 'reset', dimension })}
                title={t('resetFilter')}
                iconName={RESET_FILTER_ICON}
            />
            {exportGraph}
        </FadedChartMenuItemContainer>
    );

    React.useEffect(() => {
        if (chart) {
            setExportGraph(
                <React.Fragment>
                    <FadedChartAction
                        onClick={() => {
                            const csvHeaders = `data:text/csv;charset=utf-8,${Object.keys(
                                csvTableData[0]
                            )
                                .map((key) => t(key))
                                .join(',')}`;
                            const csvData = csvTableData.reduce((p, v) => {
                                p += `\n${tableColumns(t)
                                    .map((tableColumn) => {
                                        const value = tableColumn.exportTransform
                                            ? tableColumn.exportTransform(v)
                                            : tableColumn.transform
                                              ? tableColumn.transform(v)
                                              : v[tableColumn.column];
                                        return `"${String(value).replace(/"/g, '""')}"`;
                                    })
                                    .join(',')}`;
                                return p;
                            }, csvHeaders);
                            const link = document.createElement('a');
                            link.setAttribute('href', csvData);
                            link.setAttribute(
                                'download',
                                `${exportFileName.replace(/ /g, '_').toLowerCase()}.csv`
                            );
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }}
                        title={t('exportGraphCSV')}
                        iconName={CSV_EXPORT_ICON}
                    />
                    <FadedChartAction
                        onClick={() => {
                            getChartPNG(chart.current.chart, exportFileName);
                        }}
                        title={t('exportGraphPNG')}
                        iconName={PNG_EXPORT_ICON}
                    />
                </React.Fragment>
            );
        }
    }, [chart, csvTableData]);

    const defaultBarChartOptions: Highcharts.Options = {
        chart: {
            marginLeft: 15,
            marginRight: 0,
            type: 'bar',
            height: 415
        },
        xAxis: {
            showEmpty: true,
            labels: {
                align: 'left',
                step: 1,
                enabled: true,
                x: 0,
                y: 4,
                style: { whiteSpace: 'nowrap' },
                useHTML: true,
                formatter() {
                    // @ts-ignore
                    const chartWidth =
                        // @ts-ignore
                        this.chart.containerWidth - this.chart.spacing[1] - this.chart.spacing[3];
                    const pointLabelValue = this.value;
                    if (isNumber(pointLabelValue)) {
                        return ``;
                    }
                    return `
              <div style="width: ${chartWidth}px; display: flex; justify-content: space-between; cursor: pointer; position: absolute; left: 0px;">
                  <span style="padding-left: ${theme.space.single}; max-width: ${chartWidth}px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; position: relative;">
                    ${pointLabelValue}
                  </span>
              </div>
          `;
                }
            }
        },
        tooltip: {
            enabled: true
        }
    };

    const mergedBarOptions = mergeDeepLeft(
        barChartOptions,
        defaultBarChartOptions
    ) as Highcharts.Options;

    if (excludeDashboardComponent.includes(dimensionName)) {
        return <React.Fragment />;
    }

    return (
        <FadedChartMenuContainer>
            <FilterDrawer
                name={`${t(titlePrefix)} ${translatedDimension}`}
                borderColor={color}
                chartMenu={chartMenu}
                hideFilterDrawer={hideFilterDrawer}
                doubleFilterDrawer={doubleFilterDrawer}>
                <BarAndTable
                    id={chartId}
                    filters={filters}
                    dimension={dimension}
                    onFilter={onFilter}
                    operator={operator}
                    barSeries={barData}
                    barOptions={mergedBarOptions}
                    metric={metric}
                    tableDimensions={tableDimensions}
                    tableMeasures={tableMeasures}
                    tableColumns={tableColumns}
                    salesTime={salesTime}
                    timePeriod={timePeriod}
                    baseFilters={baseFilters}
                    chartCallback={setChart}
                    sortedData={sortedData}
                    preparedTableData={preparedTableData}
                    customBars={customBars}
                    setCsvTableData={setCsvTableData}
                />
                {loading && <LoadingContainer />}
            </FilterDrawer>
        </FadedChartMenuContainer>
    );
};
