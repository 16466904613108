import React, { useRef } from 'react';
import { debounce } from 'lodash';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import { DateFormatContext } from '../../common/datepicker';
import { LoadingContainer } from '../../common/loader';
import {
    getStartOfAdvancePeriodData,
    getPlayedOffInPeriodData,
    getSalesInPeriodData
} from './queries';
import {
    ExpandListData,
    PlayedOffData,
    PreviousPeriodTableProps,
    ProductMapData,
    ReducedData,
    SalesInPeriodData,
    StartOfAdvanceData
} from './types';
import { cubejsApi } from 'utils/api/CubeAPI';

import {
    TRANSACTIONS_CURRENCY,
    TRANSACTIONS_ITEMCOUNT,
    TRANSACTIONS_OCCURRED_AT,
    TRANSACTIONS_PRODUCTION_LABEL,
    TRANSACTIONS_PRODUCT_TIME_BEGINS,
    TRANSACTIONS_REVENUE
} from 'utils/common/constants';
import { formatCurrencyString } from 'utils/formatting/currency';
import { DimensionData } from 'utils/common/types';
import { formatWholeNumber } from 'utils/formatting/numbers';
import { settingsStore } from 'stores/settings';
import { previousPeriodObj } from 'views/dashboards/AdvanceSalesOverview/utils';

export const PreviousPeriodTable = ({
    datePickerPeriod,
    entityRef,
    expanded,
    metric
}: PreviousPeriodTableProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const { formatMonthDateYearShort } = React.useContext(DateFormatContext);
    const [rawData, setRawData] = React.useState<ExpandListData>({} as ExpandListData);
    const [_error, setError] = React.useState<boolean>(false);

    const { excludeDashboardComponent, tenantTimezone } = React.useContext(settingsStore);

    const startDate = datePickerPeriod[0];
    const endDate = datePickerPeriod[1];

    const { t } = useTranslation();

    const tableHeaders = [t('date'), t('sold'), t('playedOff'), t('advance')];

    const renderTableHeaders = () =>
        tableHeaders.map((item, index) => (
            <StyledTableHeader key={index}>{item}</StyledTableHeader>
        ));

    const allSuccess = (
        startOfAdvanceData: DimensionData[],
        playedOffData: DimensionData[],
        salesInPeriodData: DimensionData[]
    ) => {
        if (startOfAdvanceData && playedOffData && salesInPeriodData) {
            return true;
        } else {
            return false;
        }
    };

    const entityObj = excludeDashboardComponent.includes(
        TRANSACTIONS_PRODUCTION_LABEL.split('.')[1]
    )
        ? previousPeriodObj.performer
        : previousPeriodObj.production;

    const periodDimensions = Object.values(entityObj);

    const getData = useRef(
        debounce(
            (
                periodDimensions: string[],
                memberDimension: string,
                startDate: Date,
                endDate: Date
            ) => {
                setLoading(true);
                Promise.all([
                    cubejsApi.load(
                        getStartOfAdvancePeriodData(
                            periodDimensions,
                            memberDimension,
                            startDate,
                            entityRef,
                            tenantTimezone
                        )
                    ),
                    cubejsApi.load(
                        getPlayedOffInPeriodData(
                            periodDimensions,
                            memberDimension,
                            startDate,
                            endDate,
                            entityRef,
                            tenantTimezone
                        )
                    ),
                    cubejsApi.load(
                        getSalesInPeriodData(
                            periodDimensions,
                            memberDimension,
                            startDate,
                            endDate,
                            entityRef,
                            tenantTimezone
                        )
                    )
                ])
                    .then(([startOfAdvance, playedOff, salesInPeriod]) => {
                        const startOfAdvanceData = pathOr(
                            [],
                            ['loadResponse', 'results', '0', 'data'],
                            startOfAdvance
                        );
                        const playedOffData = pathOr(
                            [],
                            ['loadResponse', 'results', '0', 'data'],
                            playedOff
                        );
                        const salesInPeriodData = pathOr(
                            [],
                            ['loadResponse', 'results', '0', 'data'],
                            salesInPeriod
                        );
                        if (!allSuccess(startOfAdvanceData, playedOffData, salesInPeriodData)) {
                            setRawData({} as ExpandListData);
                            setError(true);
                        } else {
                            setRawData({
                                startOfAdvanceData,
                                playedOffData,
                                salesInPeriodData
                            });
                        }
                    })
                    .finally(() => setLoading(false));
            }
        )
    ).current;

    React.useEffect(() => {
        if (expanded) {
            getData(periodDimensions, entityObj.ref, startDate, endDate);
        }
    }, [startDate, endDate, expanded]);

    const productMap = {};

    rawData.salesInPeriodData &&
        rawData.salesInPeriodData.reduce((p: ReducedData, salesInPeriodData: SalesInPeriodData) => {
            const occurredAt = salesInPeriodData[TRANSACTIONS_OCCURRED_AT];
            p[occurredAt] = p[occurredAt] || {};
            const soldTickets = Number(salesInPeriodData[TRANSACTIONS_ITEMCOUNT]) || 0;
            const soldRevenue = Number(salesInPeriodData[TRANSACTIONS_REVENUE]) || 0;
            const playedOffTickets = Number(p[occurredAt].playedOffTickets) || 0;
            const playedOffRevenue = Number(p[occurredAt].playedOffRevenue) || 0;
            const advanceTickets = Number(p[occurredAt].advanceTickets) || 0;
            const advanceRevenue = Number(p[occurredAt].advanceRevenue) || 0;
            const changeInPeriodTickets = soldTickets - playedOffTickets || 0;
            const changeInPeriodRevenue = soldRevenue - playedOffRevenue || 0;
            const eopAdvanceTickets = advanceTickets + changeInPeriodTickets || 0;
            const eopAdvanceRevenue = advanceRevenue + changeInPeriodRevenue || 0;
            const currency = salesInPeriodData[TRANSACTIONS_CURRENCY];

            p[occurredAt].occurredAt = p[occurredAt].occurredAt || '';
            p[occurredAt].occurredAt = occurredAt;

            p[occurredAt].currency = p[occurredAt].currency || '';
            p[occurredAt].currency = currency;

            p[occurredAt].soldTickets = p[occurredAt].soldTickets || 0;
            p[occurredAt].soldTickets += soldTickets;

            p[occurredAt].soldRevenue = p[occurredAt].soldRevenue || 0;
            p[occurredAt].soldRevenue += soldRevenue;

            p[occurredAt].playedOffTickets = p[occurredAt].playedOffTickets || 0;
            p[occurredAt].playedOffTickets += playedOffTickets;

            p[occurredAt].playedOffRevenue = p[occurredAt].playedOffRevenue || 0;
            p[occurredAt].playedOffRevenue += playedOffRevenue;

            p[occurredAt].eopAdvanceTickets = p[occurredAt].eopAdvanceTickets || 0;
            p[occurredAt].eopAdvanceTickets += eopAdvanceTickets;

            p[occurredAt].eopAdvanceRevenue = p[occurredAt].eopAdvanceRevenue || 0;
            p[occurredAt].eopAdvanceRevenue += eopAdvanceRevenue;
            return p;
        }, productMap);

    rawData.playedOffData &&
        rawData.playedOffData.reduce((p: ReducedData, playedOffData: PlayedOffData) => {
            const occurredAt = playedOffData[TRANSACTIONS_PRODUCT_TIME_BEGINS];
            p[occurredAt] = p[occurredAt] || {};
            const playedOffTickets = Number(playedOffData[TRANSACTIONS_ITEMCOUNT]) || 0;
            const playedOffRevenue = Number(playedOffData[TRANSACTIONS_REVENUE]) || 0;
            const advanceTickets = Number(p[occurredAt].advanceTickets) || 0;
            const advanceRevenue = Number(p[occurredAt].advanceRevenue) || 0;
            const soldTickets = Number(p[occurredAt].soldTickets) || 0;
            const soldRevenue = Number(p[occurredAt].soldRevenue) || 0;
            const changeInPeriodTickets = soldTickets - playedOffTickets || 0;
            const changeInPeriodRevenue = soldRevenue - playedOffRevenue || 0;
            const eopAdvanceTickets = advanceTickets + changeInPeriodTickets || 0;
            const eopAdvanceRevenue = advanceRevenue + changeInPeriodRevenue || 0;
            const currency = playedOffData[TRANSACTIONS_CURRENCY];

            p[occurredAt].occurredAt = p[occurredAt].occurredAt || '';
            p[occurredAt].occurredAt = occurredAt;

            p[occurredAt].currency = p[occurredAt].currency || '';
            p[occurredAt].currency = currency;

            p[occurredAt].playedOffTickets = p[occurredAt].playedOffTickets || 0;
            p[occurredAt].playedOffTickets += playedOffTickets;

            p[occurredAt].playedOffRevenue = p[occurredAt].playedOffRevenue || 0;
            p[occurredAt].playedOffRevenue += playedOffRevenue;

            p[occurredAt].soldTickets = p[occurredAt].soldTickets || 0;
            p[occurredAt].soldTickets += soldTickets;

            p[occurredAt].soldRevenue = p[occurredAt].soldRevenue || 0;
            p[occurredAt].soldRevenue += soldRevenue;

            p[occurredAt].eopAdvanceTickets = p[occurredAt].eopAdvanceTickets || 0;
            p[occurredAt].eopAdvanceTickets += eopAdvanceTickets;

            p[occurredAt].eopAdvanceRevenue = p[occurredAt].eopAdvanceRevenue || 0;
            p[occurredAt].eopAdvanceRevenue += eopAdvanceRevenue;
            return p;
        }, productMap);

    rawData.startOfAdvanceData &&
        rawData.startOfAdvanceData.reduce(
            (p: ReducedData, startOfAdvanceData: StartOfAdvanceData) => {
                const occurredAt = startOfAdvanceData[TRANSACTIONS_OCCURRED_AT];
                p[occurredAt] = p[occurredAt] || {};
                const advanceTickets = Number(startOfAdvanceData[TRANSACTIONS_ITEMCOUNT]) || 0;
                const advanceRevenue = Number(startOfAdvanceData[TRANSACTIONS_REVENUE]) || 0;
                const playedOffTickets = Number(p[occurredAt].playedOffTickets) || 0;
                const playedOffRevenue = Number(p[occurredAt].playedOffRevenue) || 0;
                const soldTickets = Number(p[occurredAt].soldTickets) || 0;
                const soldRevenue = Number(p[occurredAt].soldRevenue) || 0;
                const changeInPeriodTickets = soldTickets - playedOffTickets || 0;
                const changeInPeriodRevenue = soldRevenue - playedOffRevenue || 0;
                const eopAdvanceTickets = advanceTickets + changeInPeriodTickets || 0;
                const eopAdvanceRevenue = advanceRevenue + changeInPeriodRevenue || 0;
                const currency = startOfAdvanceData[TRANSACTIONS_CURRENCY];

                p[occurredAt].occurredAt = p[occurredAt].occurredAt || '';
                p[occurredAt].occurredAt = occurredAt;

                p[occurredAt].currency = p[occurredAt].currency || '';
                p[occurredAt].currency = currency;

                p[occurredAt].advanceTickets = p[occurredAt].advanceTickets || 0;
                p[occurredAt].advanceTickets += advanceTickets;

                p[occurredAt].advanceRevenue = p[occurredAt].advanceRevenue || 0;
                p[occurredAt].advanceRevenue += advanceRevenue;

                p[occurredAt].playedOffTickets = p[occurredAt].playedOffTickets || 0;
                p[occurredAt].playedOffTickets += playedOffTickets;

                p[occurredAt].playedOffRevenue = p[occurredAt].playedOffRevenue || 0;
                p[occurredAt].playedOffRevenue += playedOffRevenue;

                p[occurredAt].soldTickets = p[occurredAt].soldTickets || 0;
                p[occurredAt].soldTickets += soldTickets;

                p[occurredAt].soldRevenue = p[occurredAt].soldRevenue || 0;
                p[occurredAt].soldRevenue += soldRevenue;

                p[occurredAt].eopAdvanceTickets = p[occurredAt].eopAdvanceTickets || 0;
                p[occurredAt].eopAdvanceTickets += eopAdvanceTickets;

                p[occurredAt].eopAdvanceRevenue = p[occurredAt].eopAdvanceRevenue || 0;
                p[occurredAt].eopAdvanceRevenue += eopAdvanceRevenue;
                return p;
            },
            productMap
        );

    const productMapData: ProductMapData[] = Object.values(productMap);

    const sortedData: ProductMapData[] = productMapData.sort((a, b) => {
        const aVal = new Date(String(a.occurredAt));
        const bVal = new Date(String(b.occurredAt));
        return Number(bVal) - Number(aVal);
    });

    const diffTime = Math.abs(Number(endDate) - Number(startDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const soldInPeriodTicketsTableData = () =>
        sortedData.slice(0, diffDays).map((item: ProductMapData, index: number) => (
            <StyledTableRow key={index}>
                <StyledTableDataFirst>
                    {formatMonthDateYearShort(new Date(item.occurredAt))}
                </StyledTableDataFirst>
                <StyledTableData>{item.soldTickets}</StyledTableData>
                <StyledTableData>{item.playedOffTickets}</StyledTableData>
                <StyledTableData>{item.eopAdvanceTickets}</StyledTableData>
            </StyledTableRow>
        ));

    const soldInPeriodRevenueTableData = () =>
        sortedData.slice(0, diffDays).map((item: ProductMapData, index: number) => (
            <StyledTableRow key={index}>
                <StyledTableDataFirst>
                    {formatMonthDateYearShort(new Date(item.occurredAt))}
                </StyledTableDataFirst>
                <StyledTableData>
                    {formatCurrencyString(item.currency)}
                    {formatWholeNumber(item.soldRevenue)}
                </StyledTableData>
                <StyledTableData>
                    {formatCurrencyString(item.currency)}
                    {formatWholeNumber(item.playedOffRevenue)}
                </StyledTableData>
                <StyledTableData>
                    {formatCurrencyString(item.currency)}
                    {formatWholeNumber(item.eopAdvanceRevenue)}
                </StyledTableData>
            </StyledTableRow>
        ));

    return (
        <StyledTable id="previousPeriod">
            <thead>
                <StyledTableRow>{renderTableHeaders()}</StyledTableRow>
            </thead>
            <tbody>
                {metric === TRANSACTIONS_ITEMCOUNT
                    ? soldInPeriodTicketsTableData()
                    : soldInPeriodRevenueTableData()}
            </tbody>
            {loading && <LoadingContainer size={30} />}
        </StyledTable>
    );
};

const StyledTable = styled('table')`
    width: 100%;
`;

const StyledTableRow = styled('tr')`
    display: flex;
    width: calc(100% - 2em);
`;

const StyledTableHeader = styled('th')`
    -webkit-box-flex: 0;
    flex: 0 0 calc((100% - 6.4375em) / 5);
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.soldInPeriodTableHeader};
    font-weight: 400;
    text-align: right;

    &:first-of-type {
        -webkit-box-flex: 1;
        flex: 1 1;
        text-align: left;
    }
`;

const StyledTableDataFirst = styled('td')`
    -webkit-box-flex: 1;
    flex: 1 1;
    text-align: left;
`;
const StyledTableData = styled('td')`
    -webkit-box-flex: 0;
    flex: 0 0 calc((100% - 6.4375em) / 5);
    text-align: right;
`;
