import React, { MutableRefObject, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsCustomEvents from 'highcharts-custom-events';
import exporting from 'highcharts/modules/exporting';
import csvExporting from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
//@ts-ignore
HighchartsCustomEvents(Highcharts);
exporting(Highcharts);
csvExporting(Highcharts);
import { prop } from 'ramda';
import moment from 'moment';
import { formatWholeNumber } from 'utils/formatting/numbers';
import { styled } from '@mui/material/styles';

import { formatCurrencyNoDecimals } from 'utils/formatting/currency';
import { LoadingContainer } from '../common/loader';
import { BarChartRatioProps } from './types';

const BarChartRatio = ({
    series,
    title,
    chartCallback,
    exportFileName,
    categories,
    loading
}: BarChartRatioProps) => {
    const chartRef: any = React.useRef(null);

    const [options, setOptions] = React.useState<Highcharts.Options>({});

    useEffect(() => {
        setOptions({
            chart: {
                height: 400,
                type: 'bar'
            },
            title: {
                text: title
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                bar: {
                    pointWidth: 30,
                    stacking: 'percent',
                    dataLabels: {
                        enabled: true,
                        align: 'right',
                        formatter(this: any) {
                            const y = this.point.y ?? 0;
                            const revenue = this.point.revenue;
                            return `${formatWholeNumber(y)} ${
                                revenue ? `(${formatCurrencyNoDecimals('USD', revenue)})` : ''
                            }`;
                        }
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Tickets'
                }
            },
            xAxis: {
                title: {
                    text: ''
                },
                type: 'category',
                //@ts-ignore
                // sortCategories(categories: string[]) {
                //   return categories.sort((date1, date2) => +new Date(date1) - +new Date(date2));
                // },
                labels: {
                    formatter(this: any) {
                        return moment(new Date(moment(this.value).toISOString())).format('MMM DD');
                    }
                },
                scrollbar: {
                    enabled: true
                },
                min: 0,
                max: 4,
                categories
            },
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false,
                filename: exportFileName
            },

            series
        });
    }, [exportFileName, series, title, categories]);

    const handleChartCallback = React.useRef((chart: Highcharts.Chart) => {
        enableScrollWithMouseWheel(chart);
        chartCallback && chartCallback(chartRef as MutableRefObject<{ chart: Highcharts.Chart }>);
    });

    return (
        <Wrapper>
            <HighchartsReact
                ref={chartRef}
                constructorType={'chart'}
                highcharts={Highcharts}
                options={options}
                callback={handleChartCallback.current}
            />
            {loading && <LoadingContainer />}
        </Wrapper>
    );
};

const enableScrollWithMouseWheel = (chart: Highcharts.Chart) => {
    // Add the mousewheel event
    Highcharts.addEvent(
        chart.container,
        prop<any, Document>('onmousewheel', document) === undefined
            ? 'DOMMouseScroll'
            : 'mousewheel',
        function (event: MouseEvent) {
            const axis = chart.xAxis[0];
            const dataMin = Number(prop<any, Highcharts.Axis>('dataMin', axis) ?? 0);
            const dataMax = Number(prop<any, Highcharts.Axis>('dataMax', axis) ?? 0);
            const extr = axis.getExtremes();
            const e = chart.pointer.normalize(event);
            // Firefox uses e.detail, WebKit and IE uses wheelDelta
            let delta =
                e.detail || -(prop<any, Highcharts.PointerEventObject>('wheelDelta', e) / 120);
            delta = delta < 0 ? -1 : 1;
            const step = 1 * delta;

            const newMin = extr.min + step;
            const newMax = extr.max + step;

            if (
                e.chartX < chart.chartWidth &&
                e.chartY < chart.chartHeight &&
                newMin >= dataMin &&
                newMax <= dataMax
            ) {
                axis.setExtremes(newMin, newMax, true, false);
            }

            event && event.preventDefault && event.preventDefault();
            event && event.stopPropagation && event.stopPropagation();
            return false;
        }
    );
};

const Wrapper = styled('div')`
    position: relative;
`;

export { BarChartRatio };
