/* eslint-disable @typescript-eslint/naming-convention */
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    ASTableWithScrollProps,
    Direction,
    Row,
    TableBodyComponentProps,
    TableHeadsProps
} from './types';
import { ASC, DESC } from 'utils/common/constants';
import { SelectedSegment } from 'views/dashboards/Tools/SegmentManagement/types';

const TableBodyComponent = ({
    data,
    headers,
    selectedCell,
    dataLengthForScroll,
    tableBodyPaddingTop,
    bodyTableRowMarginBottom,
    tableRowHeight,
    rowTextFontSize,
    borderBottom,
    toBeScrolledNumber,
    tableHeight
}: TableBodyComponentProps) => (
    <ASTableBodyScroll
        dataLengthForScroll={dataLengthForScroll}
        tableBodyPaddingTop={tableBodyPaddingTop}
        toBeScrolledNumber={toBeScrolledNumber}
        tableHeight={tableHeight}>
        {data.map((row, index) => (
            <>
                <ASBodyTableRowScroll
                    key={index}
                    hover
                    selected={
                        selectedCell &&
                        selectedCell.some((selected: SelectedSegment) => selected.id === row.id)
                    }
                    bodyTableRowMarginBottom={row.showDetails ? '0' : bodyTableRowMarginBottom}
                    tableRowHeight={tableRowHeight}
                    borderBottom={borderBottom}
                    toBeScrolledNumber={toBeScrolledNumber}
                    dataLengthForScroll={dataLengthForScroll}
                    showBorder={row.showDetails}>
                    {headers.map(
                        ({
                            column,
                            transform,
                            textAlign,
                            paddingRight,
                            paddingLeft,
                            columnWidth,
                            rowTextWidth
                        }: {
                            column: string;
                            transform?: (row: Row) => JSX.Element;
                            textAlign?: string;
                            paddingRight?: string;
                            paddingLeft?: string;
                            columnWidth?: string;
                            rowTextWidth?: string;
                        }) => (
                            <ASBodyTableCellScroll
                                key={`${column}${index}`}
                                textAlign={textAlign}
                                paddingRight={paddingRight}
                                paddingLeft={paddingLeft}
                                columnWidth={columnWidth}>
                                <RowText
                                    rowTextWidth={rowTextWidth}
                                    rowTextFontSize={rowTextFontSize}>
                                    {transform ? transform(row) : row[column]}
                                </RowText>{' '}
                            </ASBodyTableCellScroll>
                        )
                    )}
                </ASBodyTableRowScroll>
                {row.showDetails && (
                    <DetailsRow bodyTableRowMarginBottom={bodyTableRowMarginBottom}>
                        {row.details}
                    </DetailsRow>
                )}
            </>
        ))}
    </ASTableBodyScroll>
);

const TableHeads = ({
    sorting,
    onChange,
    headers,
    tableHeadBackground,
    tableHeadHeight,
    tableHeadFontSize,
    sortLabelIcon,
    tableSortActive,
    sortIconStylesClasses,
    headerBorder
}: TableHeadsProps) => {
    if (!headers) {
        return null;
    }

    const onClickSort = (newOrderBy: string) => {
        let direction: Direction = ASC;

        if (sorting && sorting.orderBy === newOrderBy) {
            direction = sorting.orderDirection === ASC ? DESC : ASC;
        }

        onChange && onChange({ newOrderBy, newOrderDirection: direction });
    };

    return (
        <ASTableHeadScroll
            tableHeadBackground={tableHeadBackground}
            tableHeadHeight={tableHeadHeight}>
            <ASHeaderTableRowScroll>
                {headers.map((header, index) => (
                    <ASHeaderTableCellScroll
                        key={index}
                        textAlign={
                            header.headerTextAlign ? header.headerTextAlign : header.textAlign
                        }
                        tableHeadFontSize={tableHeadFontSize}
                        tableHeadHeaderWidth={header.headerWidth}
                        headerPaddingLeft={header.headerPaddingLeft}
                        headerPaddingRight={header.headerPaddingRight}
                        headerFontWeight={header.headerFontWeight}
                        headerBorder={headerBorder}>
                        {index === 0 && <TableSortLabel> </TableSortLabel>}
                        {!header.noSorting && sorting ? (
                            <TableSortLabel
                                active={
                                    tableSortActive
                                        ? tableSortActive
                                        : header.column === sorting.orderBy
                                }
                                direction={sorting.orderDirection}
                                onClick={() => onClickSort(header.column)}
                                classes={{
                                    icon: sortIconStylesClasses && sortIconStylesClasses.icon
                                }}
                                IconComponent={sortLabelIcon ? sortLabelIcon : ArrowDropDownIcon}>
                                {header.label}
                            </TableSortLabel>
                        ) : (
                            header.label
                        )}
                    </ASHeaderTableCellScroll>
                ))}
            </ASHeaderTableRowScroll>
        </ASTableHeadScroll>
    );
};

export const ASTableWithScroll = ({
    data,
    headers,
    sorting,
    onChange,
    selectedCell,
    dataLengthForScroll,
    tableHeadBackground,
    tableHeadHeight,
    tableHeadFontSize,
    sortLabelIcon,
    tableSortActive,
    sortIconStylesClasses,
    headerBorder,
    tableBodyPaddingTop,
    bodyTableRowMarginBottom,
    tableRowHeight,
    paddingLeft,
    rowTextFontSize,
    borderBottom,
    tableStyles,
    toBeScrolledNumber,
    tableHeight
}: ASTableWithScrollProps) => (
    <MainWrapper>
        <TableHeads
            sorting={sorting}
            headers={headers}
            onChange={onChange}
            tableHeadBackground={tableHeadBackground}
            tableHeadHeight={tableHeadHeight}
            tableHeadFontSize={tableHeadFontSize}
            sortLabelIcon={sortLabelIcon}
            tableSortActive={tableSortActive}
            sortIconStylesClasses={sortIconStylesClasses}
            headerBorder={headerBorder}
        />
        <TableContainer>
            <Table>
                <TableBodyComponent
                    headers={headers}
                    data={data}
                    selectedCell={selectedCell}
                    dataLengthForScroll={dataLengthForScroll}
                    tableBodyPaddingTop={tableBodyPaddingTop}
                    bodyTableRowMarginBottom={bodyTableRowMarginBottom}
                    tableRowHeight={tableRowHeight}
                    paddingLeft={paddingLeft}
                    rowTextFontSize={rowTextFontSize}
                    borderBottom={borderBottom}
                    tableStyles={tableStyles}
                    toBeScrolledNumber={toBeScrolledNumber}
                    tableHeight={tableHeight}
                />
            </Table>
        </TableContainer>
    </MainWrapper>
);

const MainWrapper = styled(Box)`
    max-height: 70vh;
    overflow-y: auto;
`;

const ASTableBodyScroll = styled(TableBody)<{
    dataLengthForScroll: number;
    tableBodyPaddingTop?: string;
    toBeScrolledNumber?: number;
    tableHeight?: string;
}>`
    display: block;
    height: ${({ tableHeight }) => (tableHeight ? tableHeight : '575px')};
    max-height: 70vh;
    overflow-y: auto;
    /* overflow-y: ${({ dataLengthForScroll, toBeScrolledNumber }) =>
        toBeScrolledNumber
            ? dataLengthForScroll > toBeScrolledNumber
                ? 'auto'
                : 'hidden'
            : dataLengthForScroll > 15
              ? 'auto'
              : 'hidden'}; */
    padding-top: ${({ theme, tableBodyPaddingTop }) =>
        tableBodyPaddingTop ? tableBodyPaddingTop : theme.space.half};
    padding-bottom: ${({ theme }) => theme.space.single};

    &::-webkit-scrollbar {
        width: 18px;
        background-color: ${({ theme }) => theme.colors.segmentBuilderScrollBar};
        background: linear-gradient(
            to bottom,
            ${({ theme }) => theme.colors.segmentBuilderScrollBar} 592px,
            ${({ theme }) => theme.colors.white} 10px
        );
        border: 1px solid ${({ theme }) => theme.colors.segmentBuilderScrollBarThumb};
        display: block;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.segmentBuilderScrollBarThumb};
        border: 1px solid ${({ theme }) => theme.colors.frame};
        border-radius: 10px;
    }
`;

const ASTableHeadScroll = styled(TableHead)<{
    tableHeadBackground?: string;
    tableHeadHeight?: string;
}>`
    display: table;
    width: 100%;
    table-layout: fixed;
    background-color: ${({ tableHeadBackground }) => tableHeadBackground && tableHeadBackground};
    height: ${({ tableHeadHeight }) => tableHeadHeight && tableHeadHeight};
`;

const ASHeaderTableRowScroll = styled(TableRow)`
    display: table-row;
    vertical-align: middle;
`;

const ASHeaderTableCellScroll = styled(TableCell)<{
    textAlign?: string | undefined;
    tableHeadFontSize?: string;
    tableHeadHeaderWidth?: string;
    headerPaddingLeft?: string;
    headerPaddingRight?: string;
    headerFontWeight?: number;
    headerBorder?: string;
}>`
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
    padding: ${({ theme }) => theme.space.half};
    font-size: ${({ theme, tableHeadFontSize }) =>
        tableHeadFontSize ? tableHeadFontSize : theme.fontSizes.normal};
    font-weight: ${({ headerFontWeight }) => (headerFontWeight ? headerFontWeight : 400)};
    white-space: nowrap;
    width: ${({ tableHeadHeaderWidth }) => tableHeadHeaderWidth && tableHeadHeaderWidth};
    padding-left: ${({ headerPaddingLeft }) => headerPaddingLeft && headerPaddingLeft};
    padding-right: ${({ headerPaddingRight }) => headerPaddingRight && headerPaddingRight};
    border: ${({ headerBorder }) => headerBorder && headerBorder};
`;

const DetailsRow = styled('tr')<{ bodyTableRowMarginBottom?: string }>`
    background-color: ${({ theme }) => theme.colors.kpiTransaction};
    padding-left: 10px;
    color: white;
    display: flex;
    position: static;

    margin-bottom: ${({ theme, bodyTableRowMarginBottom }) =>
        bodyTableRowMarginBottom ? bodyTableRowMarginBottom : theme.space.half};

    animation: heightenDown 0.2s;

    @keyframes heightenDown {
        from {
            height: 0px;
        }
        to {
            height: 64px;
        }
    }
`;

const ASBodyTableRowScroll = styled(TableRow)<{
    bodyTableRowMarginBottom?: string;
    tableRowHeight?: string;
    borderBottom?: string;
    toBeScrolledNumber?: number;
    dataLengthForScroll: number;
    showBorder?: boolean;
}>`
    border: ${({ theme, showBorder }) =>
        showBorder ? `1px solid ${theme.colors.dividers}` : 'initial'};
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-bottom: ${({ theme, bodyTableRowMarginBottom }) =>
        bodyTableRowMarginBottom ? bodyTableRowMarginBottom : theme.space.half};
    height: ${({ tableRowHeight }) => tableRowHeight && tableRowHeight};
    border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : 'none')};
    &:last-child {
        border-bottom: none;
    }
    padding-right: ${({ dataLengthForScroll, toBeScrolledNumber }) =>
        toBeScrolledNumber ? (dataLengthForScroll <= toBeScrolledNumber ? '18px' : '0px') : '0px'};

    &.Mui-selected,
    &.Mui-selected:hover {
        background-color: ${({ theme }) => theme.colors.white};
    }
    &:hover div path {
        fill: ${({ theme }) => theme.colors.text};
    }
    &.Mui-selected div path {
        fill: ${({ theme }) => theme.colors.text};
    }
`;

const ASBodyTableCellScroll = styled(TableCell)<{
    textAlign?: string;
    fixedWidth?: number;
    paddingRight?: string;
    paddingLeft?: string;
    columnWidth?: string;
}>`
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
    padding-right: ${({ textAlign, theme, paddingRight }) =>
        textAlign ? (paddingRight ? paddingRight : '50px') : `${theme.space.half}`};
    padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '16px')};
    color: ${({ theme }) => theme.colors.text};
    width: ${({ columnWidth }) => (columnWidth ? columnWidth : `150px`)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: none;
    height: 34px;

    & {
        padding-top: 0;
        padding-bottom: 0;
    }
`;

const RowText = styled('p')<{
    rowTextWidth?: string;
    rowTextFontSize?: string;
}>`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme, rowTextFontSize }) =>
        rowTextFontSize ? rowTextFontSize : theme.fontSizes.small};
    height: 34px;
    width: 100%;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${({ rowTextWidth }) => rowTextWidth && rowTextWidth};
    display: inline-block;
`;
