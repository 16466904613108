import { Direction, ESOTableRow, Row } from '../../common/table/types';
import { daysOut, currentDate } from '../../common/datepicker/dateUtils';

import {
    ASC,
    TRANSACTIONS_PRODUCT_TIME_BEGINS,
    TRANSACTIONS_PRODUCT_LABEL,
    TRANSACTIONS_PRODUCT_REF,
    TRANSACTIONS_REVENUE,
    TRANSACTIONS_AVERAGE_TICKET_PRICE,
    TRANSACTIONS_CURRENCY,
    TRANSACTIONS_VENUE_REF,
    TRANSACTIONS_VENUE_LABEL
} from 'utils/common/constants';

export interface Event {
    date: Date;
    eventLabel: string;
    currency: string;
    itemCount: string;
    productRef: string;
    revenue: string;
    daysOut: number;
}

export const prepareData = (events: any) =>
    events.map(
        (event: any) =>
            ({
                timedBegins: event[TRANSACTIONS_PRODUCT_TIME_BEGINS] || currentDate().toISOString(),
                eventRef: String(event[TRANSACTIONS_PRODUCT_REF]),
                eventLabel: String(
                    event[TRANSACTIONS_PRODUCT_LABEL] || event[TRANSACTIONS_PRODUCT_REF]
                ),
                venueRef: String(event[TRANSACTIONS_VENUE_REF]),
                venueLabel: String(event[TRANSACTIONS_VENUE_LABEL]),
                daysOut: daysOut(event[TRANSACTIONS_PRODUCT_TIME_BEGINS]),
                currency: event[TRANSACTIONS_CURRENCY],
                today: event.today,
                lastDay: event.lastDay,
                last2Days: event.last2Days,
                last3Days: event.last3Days,
                last4Days: event.last4Days,
                soldLast5: event.soldLast5,
                totalSold: event.totalSold,
                entityRef: event[TRANSACTIONS_PRODUCT_REF],
                soldPercentage: event.soldPercentage,
                totalRevenue: event[TRANSACTIONS_REVENUE],
                holds: event.holds,
                complementary: event.complementary,
                reserved: event.reserved,
                open: event.open,
                averagePrice: event[TRANSACTIONS_AVERAGE_TICKET_PRICE],
                valueOccupancy: event.valueOccupancy,
                firstTimeCustomerPercent: event.firstTimeCustomerPercent,
                sellableCapacity: event.sellableCapacity,
                priceCategory: event.priceCategory
            }) as ESOTableRow
    );

const sortMockData = (data: ESOTableRow[], orderBy: keyof ESOTableRow, direction: Direction) => {
    const compareRows = (aRow: Row, bRow: Row) => {
        const a = aRow[orderBy];
        const b = bRow[orderBy];
        if (!a && a !== 0) {
            return -1;
        }
        if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b);
        }
        if (((a && b) || a === 0 || b === 0) && a < b) {
            return -1;
        }
        if (((a && b) || a === 0 || b === 0) && a > b) {
            return 1;
        }

        return 0;
    };

    return direction === ASC ? data.sort(compareRows) : data.sort(compareRows).reverse();
};

export const prepareEventTablePage = (
    data: Row[],
    newOrderBy: keyof ESOTableRow,
    newOrderDirection: Direction,
    newPage: number,
    newRowsPerPage: number
): Row[] =>
    sortMockData(prepareData(data), newOrderBy, newOrderDirection).slice(
        newPage * newRowsPerPage,
        newPage * newRowsPerPage + newRowsPerPage
    );

export const sellableCapacity = (inventory: any) => {
    const sellableCapacity =
        inventory.items_for_sale - (inventory.held || 0) - (inventory.killed || 0);
    return sellableCapacity;
};
export const openCapacity = (inventory: any) => {
    const open = inventory.open || 0;
    return open;
};

export const soldPercentage = (inventory: any) => {
    const soldPercentage = inventory.sold / sellableCapacity(inventory);
    if (soldPercentage === Infinity) {
        return undefined;
    }
    return soldPercentage;
};

export const holdsCapacity = (inventory: any) => {
    const holds = inventory.held || 0;
    return holds;
};

export const totalSold = (inventory: any) => {
    const totalSold = inventory.sold;
    return totalSold;
};

export const valueOccupancy = (inventory: any) => {
    const priceCategories = Object.entries(inventory.price_categories).map((e) => e[1]);

    const theoreticalMaxRevenue: any = priceCategories.reduce(
        (previous: any, price_category: any) =>
            previous + price_category.maxItemPrice * price_category.sellableCapacity,
        0
    );

    const currentRevenue: any = priceCategories.reduce(
        (previous: any, price_category: any) => previous + price_category.totalRevenue,
        0
    );

    const valueOccupancy = currentRevenue / theoreticalMaxRevenue;

    return valueOccupancy;
};
