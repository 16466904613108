// A column chart for the Real Time Overview 2.0 dashboard.
import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { styled } from '@mui/material/styles';
import { LoadingContainer } from '../common/loader';
import { ChartSeriesData } from './types';
import { formatCurrencyNoDecimals } from 'utils/formatting/currency';

interface Props {
    title: string;
    data: ChartSeriesData[];
    mobile: boolean;
    seriesName: string;
    loading: boolean;
}

const ColumnChartRTO = ({ title, data, mobile, seriesName, loading }: Props) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        setOptions({
            chart: mobile
                ? {
                      type: 'bar',
                      height: 500,
                      width: 300,
                      style: {
                          borderRadius: '30px'
                      }
                  }
                : {
                      type: 'column',
                      height: 250
                  },
            title: {
                text: title,
                style: {
                    fontSize: mobile ? '12px' : '18px',
                    fontWeight: mobile ? 600 : 500
                },
                align: mobile ? 'left' : 'center'
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: ''
                },
                gridLineWidth: mobile ? 0 : 1
            },
            exporting: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    pointPadding: 0.4,
                    borderWidth: 0,
                    color: mobile ? '#C5DEF6' : '#E39AA2',
                    pointWidth: mobile ? 30 : 40,
                    borderRadius: mobile ? '12px' : '0'
                },
                bar: mobile
                    ? {
                          dataLabels: {
                              enabled: true,
                              align: 'left',
                              inside: true,
                              color: '#2B2A2D',
                              style: {
                                  fontSize: '16px'
                              },
                              formatter(this: any) {
                                  const y = this.point.y ?? 0;
                                  return `${formatCurrencyNoDecimals('USD', y)}`;
                              }
                          }
                      }
                    : {}
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat:
                    '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            },

            series: [
                {
                    name: seriesName,
                    data: data
                }
            ]
        });
    }, [title, data, mobile, seriesName]);

    return (
        <Wrapper>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            {loading && <LoadingContainer />}
        </Wrapper>
    );
};

const Wrapper = styled('div')`
    position: relative;
`;

export { ColumnChartRTO };
