import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type StyleProps = {
    fontSize?: number;
    bold?: boolean;
    className?: string;
};

interface ASLabelProps extends StyleProps {
    title?: string;
    fontSize?: number | undefined;
}

const ASLabel = ({ fontSize, bold, title, className }: ASLabelProps) => (
    <StyledLabel
        fontSize={fontSize}
        bold={bold}
        className={className}>
        {title}
    </StyledLabel>
);

export { ASLabel };

const StyledLabel = styled(Typography)<StyleProps>`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}em` : `${1.3}em`)};
    font-weight: ${({ bold }) => (bold ? 'bold' : '')};
`;
