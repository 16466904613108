import { pathOr, toLower } from 'ramda';
import DataLoader from 'dataloader';
import { subDays, startOfDay, endOfDay } from 'date-fns';
import { TimeDimensionGranularity } from '@cubejs-client/core';
import { cubejsApi } from 'utils/api/CubeAPI';
import {
    TRANSACTIONS_PRODUCT_REF,
    TRANSACTIONS_ITEMCOUNT,
    TRANSACTIONS_OCCURRED_AT,
    TRANSACTIONS_TYPE
} from 'utils/common/constants';
import { Maybe } from 'utils/maybe';
import { formatCubeQueryDate } from 'components/common/datepicker/dateUtils';

const KEYS_PER_REQUST = 2000;

export const splitKeys = (keys: string[]) => {
    const batchedKeys: string[][] = [];
    while (keys.length) {
        batchedKeys.push(keys.splice(0, KEYS_PER_REQUST));
    }

    return batchedKeys;
};

export const last5daysOfSalesCache: { [key: string]: Last5DaysData[] | null } = {};
export const last5pastEventDaysOfSalesCache: { [key: string]: Last5DaysData[] | null } = {};

const inventoryLast5DaysLoadersByTimezone: {
    [key: string]: DataLoader<string, Last5DaysData[] | null, string>;
} = {};
const last5DaysOfSalesByTimezone: {
    [key: string]: DataLoader<string, Last5DaysData[] | null, string>;
} = {};

export const getInventoryLast5DaysLoader = (timezone: string) => {
    if (!inventoryLast5DaysLoadersByTimezone[timezone]) {
        inventoryLast5DaysLoadersByTimezone[timezone] = createInventoryLast5DaysLoader(timezone);
    }
    return inventoryLast5DaysLoadersByTimezone[timezone];
};

export const getLast5DaysOfSales = (timezone: string) => {
    if (!last5DaysOfSalesByTimezone[timezone]) {
        last5DaysOfSalesByTimezone[timezone] = createLast5DaysOfSales(timezone);
    }
    return last5DaysOfSalesByTimezone[timezone];
};

const createInventoryLast5DaysLoader = (timezone: string) =>
    new DataLoader(
        (keys: readonly string[]) => {
            const batchedKeys: string[][] = splitKeys([...keys] as string[]);

            return Promise.all(
                batchedKeys.map((batch) =>
                    cubejsApi.load(
                        {
                            dimensions: [TRANSACTIONS_PRODUCT_REF],
                            measures: [TRANSACTIONS_ITEMCOUNT],
                            timeDimensions: [
                                {
                                    dimension: TRANSACTIONS_OCCURRED_AT,
                                    dateRange: [
                                        startOfDay(subDays(new Date(), 4)),
                                        endOfDay(new Date())
                                    ].map((date) => formatCubeQueryDate(date)) as [string, string],
                                    granularity: 'day' as TimeDimensionGranularity
                                }
                            ],
                            filters: [
                                {
                                    dimension: TRANSACTIONS_PRODUCT_REF,
                                    operator: 'equals',
                                    values: batch.slice()
                                },
                                {
                                    dimension: TRANSACTIONS_TYPE,
                                    operator: 'equals',
                                    values: ['purchased', 'cancelled']
                                }
                            ],
                            timezone
                        },
                        { method: 'POST' }
                    )
                )
            ).then((responses) => {
                let data: [] = [];
                responses.forEach((response) => {
                    data = data.concat(
                        pathOr([], ['loadResponse', 'results', '0', 'data'], response)
                    ) as [];
                });
                const productMap = data.reduce(
                    (previous, value: Last5DaysData) => {
                        previous[toLower(value[TRANSACTIONS_PRODUCT_REF])] =
                            previous[toLower(value[TRANSACTIONS_PRODUCT_REF])] || [];
                        previous[toLower(value[TRANSACTIONS_PRODUCT_REF])].push(value);
                        return previous;
                    },
                    {} as { [key: string]: Last5DaysData[] }
                );

                // data loader requires null entries to exist for keys not found on the server
                // so we map over the keys here to find their respective entries in the result
                // if they're not found we return null
                return keys.map(toLower).map((product) => {
                    const value = Maybe(productMap[product]).getOrElse(null);
                    last5daysOfSalesCache[product] = value;
                    return value;
                });
            });
        },
        {
            // entity refs are case insensitive
            cacheKeyFn: toLower
        }
    );

export const createLast5DaysOfSales = (timezone: string) =>
    new DataLoader(
        (keys: readonly string[]) => {
            const dates = keys
                .map((key) => new Date(key.split('||')[1]).getTime())
                .filter((e) => e);
            const maxDate = new Date(Math.max.apply(null, dates));
            const minDate = new Date(Math.min.apply(null, dates));
            const batchedKeys: string[][] = splitKeys([...keys] as string[]);

            return Promise.all(
                batchedKeys.map((batch) =>
                    cubejsApi.load(
                        {
                            dimensions: [TRANSACTIONS_PRODUCT_REF],
                            measures: [TRANSACTIONS_ITEMCOUNT],
                            timeDimensions: [
                                {
                                    dimension: TRANSACTIONS_OCCURRED_AT,
                                    dateRange: [
                                        startOfDay(subDays(new Date(minDate), 4)),
                                        endOfDay(new Date(maxDate))
                                    ].map((date) => formatCubeQueryDate(date)) as [string, string],
                                    granularity: 'day' as TimeDimensionGranularity
                                }
                            ],
                            filters: [
                                {
                                    dimension: TRANSACTIONS_PRODUCT_REF,
                                    operator: 'equals',
                                    values: batch.map((key) => key.split('||')[0]).slice()
                                },
                                {
                                    dimension: TRANSACTIONS_TYPE,
                                    operator: 'equals',
                                    values: ['purchased', 'cancelled']
                                }
                            ],
                            timezone
                        },
                        { method: 'POST' }
                    )
                )
            ).then((responses) => {
                let data: [] = [];
                responses.forEach((response) => {
                    data = data.concat(
                        pathOr([], ['loadResponse', 'results', '0', 'data'], response)
                    ) as [];
                });
                const productMap = data.reduce(
                    (previous, value: Last5DaysData) => {
                        previous[toLower(value[TRANSACTIONS_PRODUCT_REF])] =
                            previous[toLower(value[TRANSACTIONS_PRODUCT_REF])] || [];
                        previous[toLower(value[TRANSACTIONS_PRODUCT_REF])].push(value);
                        return previous;
                    },
                    {} as { [key: string]: Last5DaysData[] }
                );

                // data loader requires null entries to exist for keys not found on the server
                // so we map over the keys here to find their respective entries in the result
                // if they're not found we return null
                return keys.map(toLower).map((key) => {
                    const [product, date] = key.split('||');
                    const value = Maybe(productMap[product])
                        .map((data) =>
                            data.filter((data) => {
                                const occurredAt = Number(new Date(data[TRANSACTIONS_OCCURRED_AT]));
                                const startOfPeriod = Number(
                                    startOfDay(subDays(new Date(date), 4))
                                );
                                const endOfPeriod = Number(endOfDay(new Date(date)));
                                return occurredAt >= startOfPeriod && occurredAt <= endOfPeriod;
                            })
                        )
                        .getOrElse(null);
                    last5pastEventDaysOfSalesCache[product] = value;
                    return value;
                });
            });
        },
        {
            // entity refs are case insensitive
            cacheKeyFn: toLower
        }
    );

export interface Last5DaysData {
    [TRANSACTIONS_PRODUCT_REF]: string;
    [TRANSACTIONS_ITEMCOUNT]: number;
    [TRANSACTIONS_OCCURRED_AT]: string;
}
