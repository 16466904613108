import { TimeDimensionGranularity } from '@cubejs-client/core';
import { DateRange } from '../common/datepicker/types';
import { formatCubeQueryDate } from '../common/datepicker/dateUtils';
import { ASCubeFilter, ASCubeQuery } from 'utils/common/types';

export const getPeriodSelectorDataQuery = (
    measures: string[],
    salesTime: string,
    timePeriod: DateRange,
    granularity: TimeDimensionGranularity,
    filters: ASCubeFilter[],
    baseFilters: ASCubeFilter[],
    tenantTimezone: string
): ASCubeQuery => ({
    measures: measures,
    timeDimensions: [
        {
            dimension: salesTime,
            dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string],
            granularity: granularity
        }
    ],
    filters: filters.concat(baseFilters),
    timezone: tenantTimezone
});

export const getPeriodSelectorDOWDataQuery = (
    dimension: string,
    measures: string[],
    salesTime: string,
    timePeriod: DateRange,
    filters: ASCubeFilter[],
    baseFilters: ASCubeFilter[],
    tenantTimezone: string
): ASCubeQuery => ({
    dimensions: [dimension],
    measures: measures,
    timeDimensions: [
        {
            dimension: salesTime,
            dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string]
        }
    ],
    filters: filters.filter((filter) => filter.dimension !== dimension).concat(baseFilters),
    timezone: tenantTimezone
});
