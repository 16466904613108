import React from 'react';

import { TileProps } from './types';
import { getArchetype, getLabel, getHref } from './utils';
import { SearchResultsTile } from './SearchResultsTile';

const PerformerTile = ({ hit, styledFor, index }: TileProps) => (
    <SearchResultsTile
        thumbnail={hit.thumbnail}
        label={getLabel(hit)}
        left={[]}
        right={[getArchetype(hit)]}
        href={getHref(hit)}
        styledFor={styledFor}
        index={index}
    />
);

export { PerformerTile };
