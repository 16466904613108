import { useTheme } from '@mui/material/styles';
import React from 'react';
import Highcharts from 'highcharts/highstock';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { MetricTable } from '../tables';
import { BarChart } from './BarChart';
import { getChartColors } from './DefaultChartConfigs';

import { BarAndTableProps } from './types';
import { Maybe } from 'utils/maybe';

import { filterValueCheck } from 'utils/filter/filterCheck';
import { Tracking } from 'externals/tracking';
import { AvailableDimensions, DimensionDataStore } from 'stores/dimensionData';

const BarAndTable = ({
    dimension,
    filters,
    tableDimensions,
    tableMeasures,
    tableColumns,
    onFilter,
    operator,
    barSeries,
    barOptions = {},
    metric,
    id,
    salesTime,
    timePeriod,
    baseFilters,
    chartCallback,
    sortedData,
    preparedTableData,
    customBars,
    setCsvTableData
}: BarAndTableProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dimensionData = React.useContext(DimensionDataStore);
    const [coloredBar, setColoredBar] = React.useState<Highcharts.SeriesBarOptions[]>([]);
    const filterValues = Maybe(filters.find((filter) => filter.dimension === dimension))
        .chain((filter) => Maybe(filter.values))
        .map((values) => values.map((value) => filterValueCheck(value)))
        .getOrElse([] as string[]);

    const isPointOptionsObject = (
        dataPoint: number | Highcharts.PointOptionsObject | [string | number, number | null] | null
    ): dataPoint is Highcharts.PointOptionsObject => (dataPoint ? true : false);

    React.useEffect(() => {
        if (barSeries && barSeries[0]?.data) {
            barSeries[0]?.data.forEach((dataPoint, index: number) => {
                if (isPointOptionsObject(dataPoint) && !Array.isArray(dataPoint)) {
                    let color;
                    if (
                        filterValues.length === 0 ||
                        filterValues.includes(
                            dataPoint && String(dataPoint.custom?.filterValue)
                        ) ===
                            (filters[0].operator === 'equals')
                    ) {
                        color = getChartColors(theme)[index % 50];
                    } else {
                        color = `${getChartColors(theme)[index % 50]}80`;
                    }

                    dataPoint.color = color;
                }
            });
            setColoredBar(barSeries);
        }
    }, [barSeries, theme]);

    //Publish the operator whenever it changes so that other graphs can fetch their data again
    React.useEffect(() => {
        onFilter({ dimension, type: 'operator', operator });
        onFilter({ dimension: tableDimensions[0], type: 'operator', operator });
        // Since the function is initialized with the default value of operator which is 'equals'
        // we need to reinitialize it with the updated value
        barClickHandler.current = (event: Highcharts.SeriesClickEventObject) => {
            const filterValue = event.point.options.custom?.filterValue;
            const breadcrumbValue = event.point.options.custom?.breadcrumbValue;

            onFilter({
                type: 'dimensionClick',
                operator: operator,
                dimension,
                value: {
                    value: filterValue,
                    label: breadcrumbValue
                }
            });

            Tracking.trackGoal('Filtered by dimension', {
                posthogUniqueKey: dimensionData[dimension as AvailableDimensions].label as string,
                dimension,
                chartName: t(dimensionData[dimension as AvailableDimensions].label as string),
                filters: filterValueCheck(breadcrumbValue)
            });
        };
    }, [operator]);

    const barClickHandler = React.useRef((event: Highcharts.SeriesClickEventObject) => {
        const filterValue = event.point.options.custom?.filterValue;
        const breadcrumbValue = event.point.options.custom?.breadcrumbValue;

        onFilter({
            type: 'dimensionClick',
            operator: operator,
            dimension,
            value: {
                value: filterValue,
                label: breadcrumbValue
            }
        });

        Tracking.trackGoal('Filtered by dimension', {
            posthogUniqueKey: dimensionData[dimension as AvailableDimensions].label as string,
            dimension,
            chartName: t(dimensionData[dimension as AvailableDimensions].label as string),
            filters: filterValueCheck(breadcrumbValue)
        });
    });

    return (
        <Wrapper id={id}>
            <BarChart
                series={coloredBar}
                barOptions={barOptions}
                onBarClick={barClickHandler}
                metric={metric}
                chartCallback={chartCallback}
                customBars={customBars}
            />
            <MetricTable
                salesTime={salesTime}
                operator={operator}
                metric={metric}
                timePeriod={timePeriod}
                filters={filters}
                baseFilters={baseFilters}
                onFilter={onFilter}
                dimensions={tableDimensions}
                measures={tableMeasures}
                tableColumns={tableColumns}
                sortedData={sortedData}
                preparedTableData={preparedTableData}
                setCsvTableData={setCsvTableData}
            />
        </Wrapper>
    );
};

export { BarAndTable };

const Wrapper = styled('div')`
    display: grid;
    grid-template-columns: 1fr 2fr;
    background-color: ${({ theme }) => theme.colors.white};
`;
