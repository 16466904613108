import { NO_USER_PIC_ICON, EVENT_VIEW_ICON, NO_EVENT_ICON } from 'assets/iconConstants';

export const noTilePresentationImage = (item: string) => {
    switch (item) {
        case 'Customer':
            return NO_USER_PIC_ICON;
        default:
            return NO_EVENT_ICON;
    }
};

const entityRefs = ['Instance', 'Event', 'Campaign'];

export const tileViewImage = (item: string) => {
    const itemArchetype = item.split('/')[0];
    if (item.includes('Customer')) {
        return NO_USER_PIC_ICON;
    }
    if (entityRefs.includes(itemArchetype)) {
        return EVENT_VIEW_ICON;
    }
};
