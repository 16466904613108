import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';

import { styled } from '@mui/material/styles';
import { ASButton } from '../common/button';
import { CustomerTile } from './CustomerTile';
import { TileProps } from './types';
import { PerformerTile } from './PerformerTile';
import { EventTile } from './EventTile';
import { ProductionTile } from './ProductionTile';
import { SupplierTile } from './SupplierTile';
import { OrderTile } from './OrderTile';
import { DonationTile } from './DonationTile';
import { MerchandiseTile } from './MerchandiseTile';
import {
    Hit,
    CUSTOMER,
    PERFORMER,
    EVENT,
    PRODUCTION,
    ORDER,
    SELECTABLE,
    REGULAR,
    CLICKABLE,
    MULTISELECT,
    DONATION,
    MERCHANDISE,
    ORGANIZER,
    PROMOTER
} from 'utils/api/globalSearch/types';

const SuperTile = ({
    hit,
    tileType,
    selectedEntityCallback,
    resetSearchInput,
    currentSelection,
    styledFor,
    index
}: TileProps) => {
    const { t } = useTranslation();
    const [refresh, setRefresh] = React.useState<boolean>(false);
    const chooseRegularTileByArchetypeAndVariant = (hit: Hit) => {
        switch (hit.archetype_and_variant) {
            case CUSTOMER:
                return (
                    <CustomerTile
                        hit={hit}
                        styledFor={styledFor}
                        index={index}
                    />
                );
            case PERFORMER:
                return (
                    <PerformerTile
                        hit={hit}
                        styledFor={styledFor}
                        index={index}
                    />
                );
            case DONATION:
                return (
                    <DonationTile
                        hit={hit}
                        styledFor={styledFor}
                        index={index}
                    />
                );
            case MERCHANDISE:
                return (
                    <MerchandiseTile
                        hit={hit}
                        styledFor={styledFor}
                        index={index}
                    />
                );
            case EVENT:
                return (
                    <EventTile
                        hit={hit}
                        styledFor={styledFor}
                        index={index}
                    />
                );
            case PRODUCTION:
                return (
                    <ProductionTile
                        hit={hit}
                        styledFor={styledFor}
                        index={index}
                    />
                );
            case ORDER:
                return (
                    <OrderTile
                        hit={hit}
                        styledFor={styledFor}
                        index={index}
                    />
                );
            case ORGANIZER:
            case PROMOTER:
                return (
                    <SupplierTile
                        hit={hit}
                        styledFor={styledFor}
                        index={index}
                    />
                );
            default:
                return (
                    <EventTile
                        hit={hit}
                        styledFor={styledFor}
                        index={index}
                    />
                );
        }
    };

    const toSelectableTile = (element: JSX.Element) => (
        <Container>
            <CampaignTile>{element}</CampaignTile>
            <ConnectEntityButton
                onClick={() => {
                    if (selectedEntityCallback && hit.entity_reference) {
                        selectedEntityCallback(hit);
                        resetSearchInput && resetSearchInput();
                    }
                }}
                fontSize={13}>
                {t('connect')}
            </ConnectEntityButton>
        </Container>
    );

    const toClickableTile = (element: JSX.Element) => (
        <div
            onClick={() => {
                if (selectedEntityCallback && hit.entity_reference) {
                    selectedEntityCallback(hit);
                }
            }}>
            {element}
        </div>
    );

    const toMultiSelectTile = (element: JSX.Element) =>
        styledFor === 'segmentBuilder' ? (
            <SegmentBuilderMultiSelectContainer key={hit.entity_reference}>
                {element}
                <SegmentBuilderCheckBoxContainer>
                    <CheckBoxWrapper>
                        <StyledFormControlLabel
                            key={index}
                            control={
                                <StyledCheckbox
                                    disableRipple
                                    color="default"
                                    checkedIcon={<StyledCheckedIcon />}
                                    icon={<StyledIcon />}
                                    checked={Boolean(
                                        currentSelection &&
                                            currentSelection.find((filterValue) => {
                                                if (
                                                    typeof filterValue !== 'string' &&
                                                    typeof filterValue !== 'number'
                                                ) {
                                                    return (
                                                        filterValue.value === hit.entity_reference
                                                    );
                                                }
                                                return filterValue === hit.entity_reference;
                                            })
                                    )}
                                    onChange={() => {
                                        if (selectedEntityCallback && hit.entity_reference) {
                                            selectedEntityCallback(hit);
                                            setRefresh(!refresh);
                                        }
                                    }}
                                />
                            }
                            label=""
                        />
                    </CheckBoxWrapper>
                </SegmentBuilderCheckBoxContainer>
            </SegmentBuilderMultiSelectContainer>
        ) : (
            <MultiSelectContainer key={hit.entity_reference}>
                <CheckBoxContainer>
                    <CheckBoxWrapper>
                        <ASCheckbox
                            checked={Boolean(
                                currentSelection &&
                                    currentSelection.find((filterValue) => {
                                        if (
                                            typeof filterValue !== 'string' &&
                                            typeof filterValue !== 'number'
                                        ) {
                                            return filterValue.value === hit.entity_reference;
                                        }
                                        return filterValue === hit.entity_reference;
                                    })
                            )}
                            onClick={() => {
                                if (selectedEntityCallback && hit.entity_reference) {
                                    selectedEntityCallback(hit);
                                    setRefresh(!refresh);
                                }
                            }}
                        />
                    </CheckBoxWrapper>
                </CheckBoxContainer>
                {element}
            </MultiSelectContainer>
        );

    switch (tileType) {
        case MULTISELECT:
            return toMultiSelectTile(chooseRegularTileByArchetypeAndVariant(hit));
        case SELECTABLE:
            return toSelectableTile(chooseRegularTileByArchetypeAndVariant(hit));
        case CLICKABLE:
            return toClickableTile(chooseRegularTileByArchetypeAndVariant(hit));
        case REGULAR:
        default:
            return chooseRegularTileByArchetypeAndVariant(hit);
    }
};

const ConnectEntityButton = styled(ASButton)`
    height: 40px;
    width: 105px;
    margin-top: ${({ theme }) => theme.space.single};
    padding: ${({ theme }) => theme.space.half} 18px;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.normal};
    background-color: ${({ theme }) => theme.colors.toplistTileHover};
    border-radius: 0;
    &:active {
        outline-width: none;
        outline-style: solid;
        outline-color: ${({ theme }) => theme.colors.dividers};
        background-color: ${({ theme }) => theme.colors.dividers};
    }
`;

const CampaignTile = styled('div')`
    display: inline-block;
    pointer-events: none;
`;

const Container = styled('div')`
    display: grid;
    grid-template-columns: 8fr 2fr;
`;

const MultiSelectContainer = styled('div')`
    display: grid;
    grid-template-columns: 48px auto;
`;

const SegmentBuilderMultiSelectContainer = styled('div')`
    position: relative;
`;

const CheckBoxContainer = styled('div')`
    height: 100%;
    display: flex;
    justify-content: center;
`;

const CheckBoxWrapper = styled('div')`
    height: 20px;
    width: 20px;
`;

const SegmentBuilderCheckBoxContainer = styled('div')`
    position: absolute;
    top: 7px;
    right: 20px;
`;

const StyledCheckbox = styled(Checkbox)`
    &:hover {
        background-color: transparent;
    }
`;

const StyledCheckedIcon = styled('span')`
    border-radius: 2px;
    width: 16px;
    height: 16px;
    box-shadow:
        inset 0 0 0 1px ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame},
        inset 0 -1px 0 ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame};
    background-color: transparent;
    input:hover ~ & {
        background-color: transparent;
    }
    &:before {
        display: block;
        width: 8px;
        height: 8px;
        margin: 4px;
        background-color: ${({ theme }) => theme.colors.segmentBuilderCheckboxChecked};
        box-shadow: 0 0 4px ${({ theme }) => theme.colors.segmentBuilderCheckboxChecked};
        content: '';
    }
`;

const StyledIcon = styled('span')`
    border-radius: 2px;
    width: 16px;
    height: 16px;
    box-shadow:
        inset 0 0 0 1px ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame},
        inset 0 -1px 0 ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame};
    background-color: transparent;
    input:hover ~ & {
        background-color: transparent;
    }
`;

const ASCheckbox = styled(Checkbox)`
    color: ${({ theme }) => theme.colors.chart1} !important;
    &:hover {
        background-color: ${({ theme }) => theme.colors.chart1}40 !important;
    }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    &:hover {
        background-color: transparent;
    }
    .Mui-icon {
        border-radius: 2px;
        width: 16px;
        height: 16px;
        box-shadow:
            inset 0 0 0 1px ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame},
            inset 0 -1px 0 ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame};
        background-color: transparent;
        input:hover ~ & {
            background-color: transparent;
        }
    }
    .Mui-checkedIcon {
        background-color: transparent;
        &:before {
            display: block;
            width: 8px;
            height: 8px;
            margin: 4px;
            background-color: ${({ theme }) => theme.colors.segmentBuilderCheckboxChecked};
            box-shadow: 0 0 4px ${({ theme }) => theme.colors.segmentBuilderCheckboxChecked};
            content: '';
        }
        input:hover ~ & {
            background-color: transparent;
        }
    }
    .Mui-label {
        color: ${({ theme }) => theme.colors.segmentBuilderText};
        font-size: ${({ theme }) => theme.fontSizes.medium};
    }
`;

export { SuperTile };
