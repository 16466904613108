import { useTheme } from '@mui/material/styles';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomSvgIcon } from '../../../utils/CustomSvgIcon';
import { ASIconButton } from '../../common/button/ASIconButton';
import './HomeButton.scss';
import { AS_LOGO_ICON } from 'assets/iconConstants';
import { settingsStore } from 'stores/settings';
import { CDN_URL } from 'utils/api/urls';

const HomeButton = () => {
    const { tenantIcon } = useContext(settingsStore);
    const history = useHistory();
    const theme = useTheme();

    const goHome = () => {
        history.push('/');
    };

    if (tenantIcon !== AS_LOGO_ICON) {
        return (
            <ASIconButton
                className="iconButton"
                background={theme.colors.white}
                hoverbackground={theme.colors.white}
                hoverfill={theme.colors.white}
                onClick={goHome}
                id="homeIconButton">
                <img src={`${CDN_URL}/tenant-images/${tenantIcon}`} />
            </ASIconButton>
        );
    }

    return (
        <ASIconButton
            className="iconButton"
            background={theme.colors.white}
            hoverbackground={theme.colors.activitystreamRed}
            hoverfill={theme.colors.white}
            onClick={goHome}
            id="homeIconButton">
            <CustomSvgIcon
                maxSize
                fill={theme.colors.activitystreamRed}
                iconName={AS_LOGO_ICON}
            />
        </ASIconButton>
    );
};

export { HomeButton };
