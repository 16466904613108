import React from 'react';
import { useTheme } from '@mui/material/styles';
import { prop } from 'ramda';
import { styled } from '@mui/material/styles';
import { Desktop, Mobile, sizes } from '../responsive/MediaQuery';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { EYE_SHOWN } from 'assets/iconConstants';

import { settingsStore } from 'stores/settings';
import { Tracking } from 'externals/tracking';
import { FrameWrapper } from '../charts/fadedIcons';
import { ASIconButton } from '../common/button';

interface FilterDrawerProps {
    name?: string;
    borderColor: string;
    chartMenu?: any;
    defaultExpanded?: boolean;
    hideFilterDrawer?: boolean;
    doubleFilterDrawer?: boolean;
    children?: React.ReactNode;
}

export const FilterDrawer: React.FC<FilterDrawerProps> = ({
    name,
    children,
    borderColor,
    chartMenu,
    defaultExpanded = true,
    hideFilterDrawer = false,
    doubleFilterDrawer
}) => {
    const { excludeDashboardComponent } = React.useContext(settingsStore);
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);

    const toggleChartVisibility = () => {
        setExpanded(!expanded);

        Tracking.trackGoal('Chart preview icon clicked', {
            posthogUniqueKey: name,
            result: expanded ? 'Chart hidden' : 'Chart shown'
        });
    };

    const keys = (
        Array.isArray(children)
            ? children.map((child) => prop('key', child as React.ReactElement))
            : [prop('key', children as React.ReactElement)]
    ).filter(Boolean);

    if (
        keys.length &&
        keys.every((key) => excludeDashboardComponent.includes(String(key).split('.')[1]))
    ) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            {hideFilterDrawer ? null : (
                <React.Fragment>
                    <FilterDrawerContainer>
                        {name}
                        <Mobile>
                            <></>
                        </Mobile>
                        <Desktop>
                            <IconWrapper>
                                {expanded ? chartMenu : null}
                                <ExpandButton
                                    onClick={toggleChartVisibility}
                                    selected={expanded}
                                    selectedfill={theme.colors.text}
                                    hoverColor={
                                        expanded
                                            ? theme.colors.selectedBullets
                                            : theme.colors.textLink
                                    }>
                                    <CustomSvgIcon
                                        iconName={EYE_SHOWN}
                                        fill={
                                            expanded
                                                ? theme.colors.textLink
                                                : theme.colors.selectedBullets
                                        }
                                    />
                                </ExpandButton>
                            </IconWrapper>
                        </Desktop>
                    </FilterDrawerContainer>
                </React.Fragment>
            )}
            {doubleFilterDrawer ? (
                <ContentWrapper expanded={expanded}>{expanded && children}</ContentWrapper>
            ) : (
                <ChartWrapper borderColor={borderColor}>
                    <FrameWrapper>
                        <ContentWrapper expanded={expanded}>{expanded && children}</ContentWrapper>
                    </FrameWrapper>
                    <MarginBottom />
                </ChartWrapper>
            )}
        </React.Fragment>
    );
};

const ChartWrapper = styled('div')<{ borderColor: string }>`
    border-top: 2px solid ${({ borderColor }): string => borderColor};
`;

const MarginBottom = styled('div')`
    margin-bottom: ${({ theme }): string => theme.space.single};
`;

const ContentWrapper = styled('div')<{ expanded: boolean }>`
    /* transition: all 200ms ease-out; */
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    transition: opacity 1s ease-in-out;
`;

const IconWrapper = styled('span')`
    position: absolute;
    right: 0;
    top: -1px;
    display: flex;
    justify-content: space-around;
    font-size: 10px;
`;

const FilterDrawerContainer = styled('div')`
    max-width: calc(100% - 140px);
    font-weight: 500;
    background-color: transparent;
    padding-left: 0;
    padding-bottom: ${({ theme }): string => theme.space.half};

    @media (min-width: ${sizes.desktop.minWidth}px) {
        padding-left: ${({ theme }): string => theme.space.single};
    }

    @media (min-width: ${sizes.tablet.minWidth}px) and (max-width: ${sizes.notebook.maxWidth}px) {
        max-width: calc(100% - 70px);
    }
`;

const ExpandButton = styled(ASIconButton)<{ hoverColor?: string }>`
    padding: 5px;
    background-color: transparent;
    font-size: 18px;
    &:hover {
        path {
            fill: ${({ hoverColor }): string => (hoverColor ? hoverColor : '')};
        }
        background-color: transparent;
    }
`;
