/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Highcharts, { ChartSelectionAxisContextObject, PointOptionsType } from 'highcharts';
import HighchartsCustomEvents from 'highcharts-custom-events';
//@ts-ignore
HighchartsCustomEvents(Highcharts);
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@mui/material/styles';
import { mergeDeepLeft } from 'ramda';
import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear
} from 'date-fns';
import { TimeDimensionGranularity } from '@cubejs-client/core';
import { DimensionData } from 'utils/common/types';
import { DateRange } from 'components/common/datepicker';
import { dateUTCIntToDateStrGran } from 'components/common/datepicker/dateUtils';

type propType = {
    data: DimensionData[];
    setPeriodSelectorPeriod: (dateRange: DateRange | null) => void;
    periodSelectorPeriod: DateRange | null;
    granularity: TimeDimensionGranularity;
};

export const PeriodSelectorChart = ({
    data,
    setPeriodSelectorPeriod,
    periodSelectorPeriod,
    granularity
}: propType) => {
    const theme = useTheme();
    const chartRef: any = React.useRef();
    const plotBand1 = 'plot-band-1';
    const UNSELECTED_COLOR = theme.colors.chart1;
    const SELECTED_COLOR = theme.colors.chart2;
    const WEEK_STARTS_ON: { weekStartsOn: 0 | 2 | 1 | 3 | 5 | 6 | 4 | undefined } = {
        weekStartsOn: 1 // Let weeks start on mondays.
    };

    const selectRange = (myChart: Highcharts.Chart, min: number, max: number) => {
        //remove old band
        myChart.xAxis[0].removePlotBand(plotBand1);

        //update plot band with selected region
        myChart.xAxis[0].addPlotBand({
            from: min,
            to: max,
            color: 'rgba(255,255,255,0)',
            id: plotBand1
        });

        // Color (only) the selected bars:
        const coloredData: Array<PointOptionsType> = myChart.series[0].data.map(
            (point: Highcharts.Point) => {
                if (point.x >= min && point.x <= max) {
                    return { x: point.x, y: point.y, color: SELECTED_COLOR };
                }
                return { x: point.x, y: point.y, color: UNSELECTED_COLOR };
            }
        );
        myChart.series[0].setData(coloredData);

        let start = startOfDay(new Date(min));
        let end = endOfDay(new Date(max));

        const startSelectedBarValue = myChart.series[0].data.find(
            (point: Highcharts.Point) => point.x >= min
        );

        if (!startSelectedBarValue) {
            throw new Error('Error. Invalid starting chart point');
        }

        let endSelectedBarValue = startSelectedBarValue;
        for (const point of myChart.series[0].data) {
            if (point.x >= min && point.x <= max) {
                endSelectedBarValue = point;
            }
        }

        if (!endSelectedBarValue) {
            throw new Error('Error. Invalid ending chart point');
        }

        switch (granularity) {
            case 'year': {
                start = startOfYear(new Date(startSelectedBarValue.x));
                end = endOfYear(new Date(endSelectedBarValue.x));
                break;
            }
            case 'month': {
                start = startOfMonth(new Date(startSelectedBarValue.x));
                end = endOfMonth(new Date(endSelectedBarValue.x));
                break;
            }
            case 'week': {
                start = startOfWeek(new Date(startSelectedBarValue.x), WEEK_STARTS_ON);
                end = endOfWeek(new Date(endSelectedBarValue.x), WEEK_STARTS_ON);
                break;
            }
            case 'day': {
                start = startOfDay(new Date(startSelectedBarValue.x));
                end = endOfDay(new Date(endSelectedBarValue.x));
                break;
            }
            default: {
                break;
            }
        }

        if (start >= end) {
            start = startOfDay(end);
        }

        setPeriodSelectorPeriod([start, end]);
    };

    const [options, setOptions] = React.useState<Highcharts.Options>({
        chart: {
            type: 'column',
            zoomType: 'x',
            pinchType: 'x',
            height: 100
        },
        title: {
            text: undefined,
            align: 'center',
            verticalAlign: 'bottom',
            style: {
                fontSize: '12px'
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    if (this.chart.xAxis[0].userOptions.type === 'datetime' && granularity) {
                        return dateUTCIntToDateStrGran(this.value, 'UTC', granularity);
                    }
                    return String(this.value);
                }
            }
        },
        yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            plotLines: [
                {
                    value: 0,
                    width: 1,
                    color: theme.colors.border,
                    zIndex: 10
                }
            ],
            labels: {
                enabled: false
            },
            title: {
                text: null
            }
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        series: [
            {
                type: 'column',
                color: UNSELECTED_COLOR,
                data: []
            }
        ]
    });

    React.useEffect(() => {
        const coloredData: Array<PointOptionsType> = data.map((point: any) => {
            if (periodSelectorPeriod) {
                if (
                    point[0] >= periodSelectorPeriod[0].getTime() &&
                    point[0] <= periodSelectorPeriod[1].getTime()
                ) {
                    return { x: point[0], y: point[1], color: SELECTED_COLOR };
                }
            }

            return { x: point[0], y: point[1], color: UNSELECTED_COLOR };
        });
        const newSeries: Highcharts.Options = {
            series: [
                {
                    type: 'column',
                    data: coloredData
                }
            ]
        };

        mergePassedOptions(newSeries);
    }, [data]);

    React.useEffect(() => {
        if (periodSelectorPeriod) {
            return;
        }

        const coloredData: Array<PointOptionsType> = data.map((point: any) => ({
            x: point[0],
            y: point[1],
            color: UNSELECTED_COLOR
        }));

        const newSeries: Highcharts.Options = {
            series: [
                {
                    type: 'column',
                    data: coloredData
                }
            ]
        };

        mergePassedOptions(newSeries);
    }, [data, periodSelectorPeriod]);

    const numSelectedBars = (myChart: Highcharts.Chart): number => {
        let retval = 0;
        for (const point of myChart.series[0].data) {
            if (point.color === SELECTED_COLOR) {
                retval++;
            }
        }
        return retval;
    };

    const selectedBarClicked = (myChart: Highcharts.Chart, min: number, max: number) => {
        const point = myChart.series[0].data.find((p) => p.color === SELECTED_COLOR);
        if (!point || point.x < min || point.x > max) {
            return false;
        } else {
            return true;
        }
    };

    React.useEffect(() => {
        const newOptions: Highcharts.Options = {
            chart: {
                events: {
                    selection(
                        this: Highcharts.Chart,
                        event: Highcharts.ChartSelectionContextObject
                    ) {
                        const xAxis: Array<ChartSelectionAxisContextObject> = event?.xAxis;
                        if (xAxis) {
                            const min = xAxis[0].min;
                            const max = xAxis[0].max;
                            selectRange(this, min, max);
                        } else {
                            //Changing back colors
                            const initialColoredData: Array<PointOptionsType> =
                                this.series[0].data.map((point: Highcharts.Point) => ({
                                    x: point.x,
                                    y: point.y,
                                    color: UNSELECTED_COLOR
                                }));
                            this.series[0].setData(initialColoredData);

                            this.xAxis[0].removePlotBand(plotBand1);

                            setPeriodSelectorPeriod(null);
                        }
                        return false;
                    }
                }
            },
            xAxis: {
                labels: {
                    formatter: function () {
                        if (this.chart.xAxis[0].userOptions.type === 'datetime' && granularity) {
                            return dateUTCIntToDateStrGran(this.value, 'UTC', granularity);
                        }
                        return String(this.value);
                    }
                }
            },
            plotOptions: {
                column: {
                    point: {
                        events: {
                            click(event: Highcharts.SeriesClickEventObject) {
                                // Select a single period selector column:
                                let min = event.point.options.x;
                                if (!min) {
                                    return;
                                }

                                switch (granularity) {
                                    case 'year': {
                                        min = startOfYear(min).getTime();
                                        break;
                                    }
                                    case 'month': {
                                        min = startOfMonth(min).getTime();
                                        break;
                                    }
                                    case 'week': {
                                        min = startOfWeek(min, WEEK_STARTS_ON).getTime();
                                        break;
                                    }
                                    default: {
                                        min = startOfDay(min).getTime();
                                    }
                                }

                                let max = null;
                                switch (granularity) {
                                    case 'month': {
                                        max = endOfMonth(min).getTime();
                                        break;
                                    }
                                    case 'week': {
                                        max = endOfWeek(min, WEEK_STARTS_ON).getTime();
                                        break;
                                    }
                                    default: {
                                        max = endOfDay(min).getTime();
                                    }
                                }

                                const myChart = chartRef.current.chart;

                                if (
                                    numSelectedBars(myChart) === 1 &&
                                    selectedBarClicked(myChart, min, max)
                                ) {
                                    //remove old band
                                    myChart.xAxis[0].removePlotBand(plotBand1);

                                    // Unselect all bars:
                                    const coloredData: Array<PointOptionsType> =
                                        myChart.series[0].data.map((point: Highcharts.Point) => ({
                                            x: point.x,
                                            y: point.y,
                                            color: UNSELECTED_COLOR
                                        }));
                                    myChart.series[0].setData(coloredData);

                                    setPeriodSelectorPeriod(null);
                                } else {
                                    selectRange(chartRef.current.chart, min, max);
                                }
                            }
                        }
                    }
                }
            }
        };

        mergePassedOptions(newOptions);
    }, [granularity, setPeriodSelectorPeriod]);

    const mergePassedOptions = (extraOptions: Highcharts.Options) => {
        setOptions((options) => mergeDeepLeft(extraOptions, options) as Highcharts.Options);
    };

    return (
        <HighchartsReact
            ref={chartRef}
            options={options}
            highcharts={Highcharts}
            immutable={true}
        />
    );
};
