import React from 'react';
import { styled } from '@mui/material/styles';

type TableLegendsProps = {
    label?: string;
    color?: string;
    icon?: string;
    border?: boolean;
    iconColor?: string;
    margin?: boolean;
};

const ASTableLegends = ({
    label,
    color,
    icon,
    border,
    iconColor,
    margin = true
}: TableLegendsProps) => (
    <LegendWrapper margin={margin}>
        <TableLegend
            color={color}
            border={border}>
            <LabelIcon iconColor={iconColor}>{icon}</LabelIcon>
        </TableLegend>
        <Label>{label}</Label>
    </LegendWrapper>
);

export { ASTableLegends };

const LegendWrapper = styled('span')<{ margin: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${({ margin, theme }) => margin && theme.space.half};
    margin-right: ${({ margin, theme }) => margin && theme.space.half};
`;

const TableLegend = styled('span')<TableLegendsProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    background: ${({ color }) => color};
    margin-right: ${({ theme }) => theme.space.half};
    border: ${({ border, theme }) => (border ? `1px solid ${theme.colors.border}` : 'none')};
`;

const Label = styled('span')`
    font-size: ${({ theme }) => theme.fontSizes.small};
`;

const LabelIcon = styled('span')<TableLegendsProps>`
    font-size: 10px;
    text-align: center;
    padding-top: 1px;
    color: ${({ iconColor, theme }) => (iconColor ? iconColor : theme.colors.white)};
    cursor: default;
`;
