import moment from 'moment';
import { subMonths } from 'date-fns';
import { Filters } from './GlobalSearch';
import { GlobalSearchQuery, SEARCHABLE_TYPE } from 'utils/api/globalSearch/types';

export const getFilteredQuery = (
    filters: Filters,
    searchString?: string,
    searchableArchetypes?: SEARCHABLE_TYPE[],
    onlyRecentEvents?: boolean
) => {
    let filter: GlobalSearchQuery = { page: 0 };

    filters.forEach((value, key) => {
        filter = { ...filter, [key]: value };
    });

    if (searchableArchetypes && searchableArchetypes.length > 0 && !filter.archetype_and_variant) {
        filter.archetype_and_variant_list = searchableArchetypes.join(',');
    }

    if (filter.presentation_label_exact && filter.presentation_label_exact !== '') {
        filter.presentation_label = undefined;
    } else {
        filter.presentation_label = searchString;
    }
    if (onlyRecentEvents) {
        filter.from_date = moment(subMonths(new Date(), 6)).format('yyyy-MM-DD');
    }

    return filter;
};

export const isValueInFilters = (name: string, filters: Filters): boolean =>
    [...filters.values()].includes(name);
