import { Link } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { sizes } from '../../responsive/MediaQuery';

interface ASLinkProps {
    href: string;
    text: string | undefined;
    children?: React.ReactNode;
    underline?: boolean;
    className?: string;
    styledFor?: string;
    disabled?: boolean;
}

const ASLink = ({ href, text, underline = false, className, styledFor, disabled }: ASLinkProps) => (
    <Wrapper className={className}>
        {styledFor === 'segmentBuilder' ? (
            <SegmentBuilderStyledLink href={href}>{text}</SegmentBuilderStyledLink>
        ) : (
            <StyledLink
                href={href}
                disabled={disabled}>
                {text}
            </StyledLink>
        )}
        {underline ? <Underline /> : null}
    </Wrapper>
);

const Wrapper = styled('span')`
    display: flex;
    flex-direction: column;
    width: fit-content;
`;

const StyledLink = styled(Link)<{ disabled?: boolean }>`
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
    color: ${({ disabled, theme }) => (disabled ? theme.colors.textFaded : theme.colors.textLink)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }

    @media (max-width: ${sizes.desktop.minWidth}px) {
        white-space: normal;
    }
`;

const SegmentBuilderStyledLink = styled(Link)<{ href: string; children: string | undefined }>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.segmentBuilderText};
`;

const Underline = styled('span')`
    background-color: ${({ theme }) => theme.colors.textLink};
    margin-top: -5px;
    height: 1.2px;
`;

export { ASLink };
