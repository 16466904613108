import React from 'react';
import { ECOTileSummary } from './ECOTileSummary';
import { TileSummary } from '../common/tile/TileSummary';

interface SuperTileSummaryProps {
    entityRef: string;
    date?: string;
    location: string;
    link: string;
    title: string;
    nextVisit?: string | null;
    tileEntityType?: string;
    eventTitle?: string;
    isText?: boolean;
}

const SuperTileSummary = ({
    date,
    location,
    link,
    title,
    nextVisit,
    tileEntityType,
    eventTitle,
    isText,
    entityRef
}: SuperTileSummaryProps) => {
    const ChooseRegularTileByEntity = () => {
        switch (tileEntityType) {
            case 'EmailCampaign':
                return (
                    <ECOTileSummary
                        date={date}
                        link={link}
                        location={location}
                        title={title}
                        eventTitle={eventTitle}
                    />
                );
            default:
                return (
                    <TileSummary
                        entityRef={entityRef}
                        nextVisit={nextVisit}
                        date={date}
                        link={link}
                        location={location}
                        title={title}
                        isText={isText}
                    />
                );
        }
    };

    return <ChooseRegularTileByEntity />;
};

export { SuperTileSummary };
