import { Filter, Query } from '@cubejs-client/core';
import { formatCubeQueryDate } from 'components/common/datepicker/dateUtils';
import { flatten, omit } from 'ramda';
import { ASCubeFilter, ASCubeQuery, FilterValue } from 'utils/common/types';
import { filterValueCheck } from 'utils/filter/filterCheck';

// This function has been carried over from a previous implementation and is
// in need of some heavy refactoring and optimisation.
export const transformCubeQuery = (query: ASCubeQuery): Query => {
    // Remove properties that won't be utilised by the back end i.e. 'color'
    const newFilters = trimFilters(query.filters);
    const { filters, emptyStringFilters } = transformFilters(newFilters);

    emptyStringFilters.map((dimension) => {
        const filter = filters.find(
            (filter) => 'dimension' in filter && filter.dimension === dimension
        );

        if (filter) {
            'values' in filter && (filter as any).values.push('null');
        }
    });

    // Change FutureDate to an 'afterDate' filter since it makes the dashboard flow easier to handle
    // this edge case right before we do the queries.
    const timeDimensions = (query.timeDimensions = (query.timeDimensions || []).map(
        (timeDimension) => {
            if (
                timeDimension.dateRange &&
                formatCubeQueryDate(new Date((timeDimension.dateRange || [])[1] as string)) ===
                    formatCubeQueryDate(new Date('2222-12-31'))
            ) {
                const start = timeDimension.dateRange[0];
                filters.push({
                    dimension: timeDimension.dimension,
                    operator: 'afterDate',
                    values: [start]
                });
                return omit(['dateRange'], timeDimension);
            }
            return timeDimension;
        }
    ));
    query.timeDimensions = timeDimensions;

    return {
        ...query,
        filters: filters as Filter[]
    };
};

// Function to remove filters that aren't utilised in the backend such as 'color'.
const trimFilters = (filters: ASCubeFilter[] | undefined) => {
    if (!filters) {
        return [];
    }

    return filters.map((filter): ASCubeFilter => {
        if ('or' in filter || 'and' in filter) {
            return filter;
        }

        return {
            dimension: filter.dimension,
            member: filter.member,
            operator: filter.operator,
            values: filter.values
        };
    });
};

const transformFilters = (filters: ASCubeFilter[]) => {
    const emptyStringFilters: string[] = [];

    const transformedFilters = flatten(
        filters.map((filter) => {
            const transformedFilter = {
                ...omit(['dimension'], filter),
                member: filter.member || filter.dimension,
                ...(!('or' in filter) && {
                    values: (filter.values || []).map((value: FilterValue) => {
                        const transformedValue = filterValueCheck(value);

                        if (transformedValue == '' && filter.dimension) {
                            emptyStringFilters.push(filter.dimension);
                        }

                        return transformedValue;
                    })
                })
            };

            if (transformedFilter.member && transformedFilter.member.endsWith('Flat')) {
                return {
                    ...transformedFilter,
                    member: transformedFilter.member.slice(0, -4)
                };
            }

            const operator = String(transformedFilter.operator);
            if (filter.values?.length === 2) {
                if (operator === 'range') {
                    return [
                        {
                            dimension: filter.dimension,
                            operator: 'gte',
                            values: [String(filter.values[0])]
                        },
                        {
                            dimension: filter.dimension,
                            operator: 'lte',
                            values: [String(filter.values[1])]
                        }
                    ];
                }

                if (operator === 'notInRange') {
                    return {
                        or: [
                            {
                                dimension: filter.dimension,
                                operator: 'gt',
                                values: [String(filter.values[1])]
                            },
                            {
                                dimension: filter.dimension,
                                operator: 'lt',
                                values: [String(filter.values[0])]
                            }
                        ]
                    };
                }
            }

            return transformedFilter;
        })
    );

    return { filters: transformedFilters, emptyStringFilters };
};

export function getCookie(cname: string) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
