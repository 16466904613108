/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { ResultSet } from '@cubejs-client/core';
import { pathOr } from 'ramda';
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { utcToZonedTime } from 'date-fns-tz';
import { DateFormatContext } from './common/datepicker';
import { subtractTimezone } from './common/datepicker/dateUtils';
import { cubejsApi } from 'utils/api/CubeAPI';
import { ASCubeQuery } from 'utils/common/types';
import { BASE_TRANSACTIONS_OCCURRED_AT } from 'utils/common/constants';
import { settingsStore } from 'stores/settings';

const LastUpdatedAtDate = () => {
    const [lastUpdatedAt, setLastUpdatedAt] = useState<Date | null>(null);
    const { formatDateTimeShort } = React.useContext(DateFormatContext);
    const { tenantTimezone } = React.useContext(settingsStore);
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        (async () => {
            const query = {
                dimensions: [BASE_TRANSACTIONS_OCCURRED_AT],
                filters: [
                    {
                        // some tenants have transactions in the future...
                        dimension: BASE_TRANSACTIONS_OCCURRED_AT,
                        operator: 'beforeDate',
                        values: [new Date().toISOString()]
                    }
                ],
                order: {
                    [BASE_TRANSACTIONS_OCCURRED_AT]: 'desc'
                },
                limit: 1
            } as ASCubeQuery;
            const result = (await cubejsApi.load(query)) as ResultSet;
            const data = pathOr<string | null>(
                null,
                ['loadResponse', 'results', '0', 'data', '0', BASE_TRANSACTIONS_OCCURRED_AT],
                result
            );
            if (data) {
                // TODO configure clickhouse to return ISO date
                const date = new Date(data);
                subtractTimezone(date);
                setLastUpdatedAt(date);
            }
        })();
    }, []);
    return (
        <div
            css={css`
                margin-top: auto;
            `}>
            <div
                css={css`
                    display: flex;
                    white-space: nowrap;
                    margin-bottom: ${theme.space.half};
                    font-size: ${theme.fontSizes.medium};
                    text-align: end;
                    font-style: italic;
                    gap: 4px;
                    color: #838383;
                `}>
                {lastUpdatedAt ? (
                    <React.Fragment>
                        <span>{t('Last updated at')}</span>
                        <time
                            dateTime={lastUpdatedAt.toISOString()}
                            title={lastUpdatedAt.toISOString()}>
                            {formatDateTimeShort(utcToZonedTime(lastUpdatedAt, tenantTimezone))}
                        </time>
                    </React.Fragment>
                ) : (
                    <span>&nbsp;</span>
                )}
            </div>
        </div>
    );
};

export default LastUpdatedAtDate;
