import DataLoader from 'dataloader';
import { toLower } from 'ramda';
import { Maybe } from 'utils/maybe';
import { ASRequest } from 'utils/api/request';
import { ENTITIES_API_URL } from 'utils/api/urls';
import { splitKeys } from 'utils/dataloaders/inventoryLoader';

export const entityCache: { [key: string]: Entity | null } = {};

export const getEntityFromCache = (key: string, callback?: (entity: Entity) => void) => {
    const inventory = Maybe(entityCache[key]);
    return inventory.orElse(() => {
        entityLoader.load(key).then((response) => {
            if (callback) {
                callback(response);
            }
        });
        return inventory;
    });
};

export const entityLoader = new DataLoader(
    (keys: readonly string[]) => {
        const batchedKeys: string[][] = splitKeys([...keys] as string[]);

        return Promise.all(
            batchedKeys.map((batch) =>
                ASRequest.request<{ [key: string]: Entity }>({
                    url: ENTITIES_API_URL,
                    params: { entityRefs: batch.join(',') }
                })
            )
        ).then((responses) => {
            // data loader requires null entries to exist for keys not found on the server
            // so we map over the keys here to find their respective entries in the result
            // if they're not found we return null
            const data: { [key: string]: Entity } = {};
            responses.forEach((response) => {
                Object.assign(data, response.data);
            });

            return keys.map((key) => {
                const entity = data[key];
                entityCache[key] = entity;
                return entity;
            });
        });
    },
    {
        // entity refs are case insensitive
        cacheKeyFn: toLower
    }
);

export interface Entity {
    entity: EntityMetadata;
    traversedRelations?: EntityMetadata[];
}

export interface EntityMetadata {
    inventoryAspects?: any;
    stream_id?: string;
    entity_ref: string;
    archetype?: string;
    archetype_variant?: string;
    subtenant?: string;
    partition?: string;
    occurred_at?: string;
    aspects?: EntityAspects;
    properties?: object;
    relations?: EntityRelations[];
    delete_relations?: [];
    relatedEntities?: string[];
    groups?: Array<{
        name?: string;
        group_ref?: string;
        group_type?: string;
    }>;
}

export interface EntityAspects {
    address?: {
        main?: {
            address?: string;
            address2?: string;
            city?: string;
            postal_code?: string;
            country?: string;
            country_code?: string;
            state?: string;
            state_code?: string;
            hasc?: string;
        };
    };
    classification?: {
        categories?: string[];
        type?: string;
    };
    demography?: {
        gender?: string;
        birth_date?: string;
        housing?: string;
        education?: string;
        income?: string;
    };
    email?: {
        address?: string;
        is_anonymized?: boolean;
    };
    inventory?: InventoryAspects;
    phone?: {
        home?: string;
        work?: string;
        mobile?: string;
        is_anonymized?: boolean;
    };
    external_data?: {
        id?: string;
    };
    presentation?: EntityAspectsPresentation;
    scanning?: string;
    timed?: any;
    permission?: string;
    status?: string;
    subscription?: string;
    traffic_source?: string;
    campaign_metrics?: string;
    external_link?: string;
    client_device?: string;
    seat_location?: string;
    targeting?: string;
    ticket?: string;
    payment?: string;
}

export interface EntityAspectsPresentation {
    label?: string;
    description?: string;
    thumbnail?: string;
    details_url?: string;
    prefix?: string;
    is_anonymized?: boolean;
}

export interface InventoryAspects {
    items_for_sale: number;
    aggregate_holds: number;
    aggregate_kills: number;
    price_categories: Array<PriceCategoriesAspects>;
}

export interface PriceCategoriesAspects {
    price_category: string;
    items_for_sale: number;
    aggregate_holds: number;
    aggregate_kills: number;
}

export interface EntityRelations {
    name?: string;
    entity_ref: string;
    archetype?: string;
    archetype_variant?: string;
}
