import { AxiosError } from 'axios';
import React from 'react';
import { styled } from '@mui/material/styles';

const AxiosErrorMessage = ({ error, show }: { error: AxiosError<any> | null; show: boolean }) => {
    if (!error || !show) {
        return null;
    }

    const formatError = (errorMessage: string) => errorMessage.replace(/- |; /g, '\n\r');

    const data = error.response?.data as any;
    const errorCode = data?.errors?.errorCode;
    const status = data?.errors?.status;
    const message = data?.errors?.errorMessage;

    return (
        <React.Fragment>
            <ErrorMessage>
                <Header>
                    <span>{errorCode ? errorCode : error.code} </span>
                    <span>
                        {status
                            ? status
                            : `${error.response?.request.status} ${error.response?.request.statusText}`}
                    </span>
                </Header>
                <div>{message ? formatError(message) : error.message}</div>
            </ErrorMessage>
        </React.Fragment>
    );
};

export { AxiosErrorMessage };

const Header = styled('div')`
    font-weight: 600;
`;

const ErrorMessage = styled('div')`
    padding: ${({ theme }) => theme.space.single};
    background-color: ${({ theme }) => theme.colors.white};
    white-space: pre-wrap;
    font-size: ${({ theme }) => theme.fontSizes.small};
`;
