import { ThemeOptions } from '@mui/material';

export const activityStream: ThemeOptions = {
    name: 'Activity Stream',
    typography: {
        button: {
            textTransform: 'none'
        }
    },
    colors: {
        activitystreamRed: '#c93748',
        background: '#f2f8f9',
        text: '#2b2a2d',
        textFaded: '#C3C3C3',
        secondary: '#e6f2f5',
        scrollBar: '#93C1CC',
        scrollBarBackground: '#C7E2E9',
        scrollBarBackground2: '#e8f0fe',
        highlight: '#93C1CC',
        textLink: '#459FB6',
        alert: '#e8ae92',
        selectedBullets: '#ed7942',
        dividers: '#dce2e4',
        dividersLight: '#e4e2e2',
        muted: '#e6f2f5',
        mapDeselected: '#CCC',
        defaultFill: '#e6f2f5',
        deselectedChartTwo: '#FBE3D8',
        chart1: '#93C1CC',
        chart2: '#E8AE92',
        chart3: '#C4D292',
        chart4: '#B88498',
        chart5: '#DEBB94',
        chart6: '#E39AA2',
        chart7: '#AFA2DA',
        chart8: '#DFD595',
        chart9: '#A0E0B8',
        chart10: '#A2C0DA',
        chart11: '#F6CEA0',
        chart12: '#C69292',
        chart13: '#C6A2DA',
        chart14: '#B7BBCC',
        chart15: '#DFAB95',
        chart16: '#E0DCA0',
        chart17: '#A2DACD',
        chart18: '#F6A0A0',
        chart19: '#C692C4',
        chart20: '#C4E0A0',
        chart21: '#F0EA8B',
        chart22: '#E49BBA',
        chart23: '#A2ABDA',
        chart24: '#E4AF9B',
        chart25: '#BBBBBB',
        chart26: '#D9EBF0',
        chart27: '#FBE3D8',
        chart28: '#ECF2D8',
        chart29: '#E7D2DB',
        chart30: '#F2E3D3',
        chart31: '#F4D6D9',
        chart32: '#DFDAF0',
        chart33: '#F2EED5',
        chart34: '#D9F3E3',
        chart35: '#DAE6F0',
        chart36: '#FBEBD9',
        chart37: '#E8D4D4',
        chart38: '#E8DAF0',
        chart39: '#E2E4EB',
        chart40: '#F2DED5',
        chart41: '#F3F1D9',
        chart42: '#DAF0EB',
        chart43: '#FBD9D9',
        chart44: '#E8D4E8',
        chart45: '#E8F3D9',
        chart46: '#F9F7D1',
        chart47: '#F4D7E4',
        chart48: '#DADEF0',
        chart49: '#F4DFD7',
        chart50: '#E4E4E4',
        openChartBar: '#93C1CC',
        soldChartBar: '#C4D292',
        heldChartBar: '#E8AE92',
        killedChartBar: '#B88498',
        reservedChartBar: '#debb94',
        deletedChartBar: '#e39aa2',
        oversoldCountChartBar: '#c93748',
        border: '#9da8c5',
        white: '#fff',
        black: '#000',
        danger: '#e8ae92',
        shadowImage: '#94C1CC',
        positive: '#a6c142',
        legendOne: '#c4d292',
        legendTwo: '#a6c142',
        legendThree: '#e8ae92',
        legendFour: '#d97040',
        legendFive: '#c93748',
        legendSix: '#fff',
        kpiCustomer: '#f4d6d9',
        kpiCustomerIcon: '#e39aa2',
        kpiCommunication: '#ecf2d8',
        kpiCommunicationIcon: '#c4d292',
        kpiTransaction: '#d9ebf0',
        kpiTransactionIcon: '#459fb6',
        kpiProduct: '#fbe3d8',
        kpiProductIcon: '#ed7942',
        filterGroupCustomer: '#E39AA2',
        filterGroupCommunication: '#C4D292',
        filterGroupTransaction: '#93C1CC',
        filterGroupProduct: '#E8AE92',
        sidebarColor: '#2b2a2d',
        sidebarBorder: '#fff',
        sidebarMenuHover: '#e6f2f5',
        sidebarIconFillHover: '#2b2a2d',
        hamburgerMenuBg: '#e6f2f5',
        hamburgerMenuFonts: '#459FB6',
        hamburgerMenuHover: '#e6f2f5',
        profilePicBg: '#e6f2f5',
        toplistIconPrimary: '#93c1cc',
        toplistIconSecondary: '#eeeeee',
        toplistIconFill: '#2b2a2d',
        toplistTileHeader: '#459FB6',
        toplistTileHover: '#93C1CC',
        breadcrumbs: '#e6f2f5',
        toplistNoCustomerImage: '#94C1CC',
        toplistNoEventImage: '#94C1CC',
        toplistNoDonorImage: '#C93748',
        urlGeneratorInputFill: '#D0E4EA',
        userInviteTitle: '#333333',
        selectArrowIcon: '#333333',
        campaignSetupPrimary: '#D9EBF0',
        campaignSetupSecondary: '#93C1CC',
        searchTopBorder: '#459fb6',
        frame: '#d8d8d8',
        BouncedChartBar: '#93C1CC',
        DeliveredChartBar: '#C4D292',
        inventoryDivider: '#e2e7e9',
        popupBg: '#D9D9D9',
        popupBorder: '#9FA6AF',
        timelineIconGrey: '#c7c7c7',
        soldInPeriodTableHeader: '#9EA9C6',
        segmentBuilderMenuItemActive: '#44b3db',
        segmentBuilderMenuItemChecked: '#c7e8f4',
        segmentBuilderText: '#7F7F7F',
        segmentBuilderTextLink: '#ef8131',
        segmentBuilderCancelIcon: '#808080',
        segmentBuilderBackground: '#f2f2f2',
        segmentBuilderScrollBarThumb: '#ebebeb',
        segmentBuilderScrollBar: '#f6f6f6',
        segmentBuilderCheckboxChecked: '#aac34b',
        segmentBuilderCheckboxFrame: '#c0c0c0',
        segmentBuilderBorder: '#e8e8e8',
        segmentBuilderNextButtonDisabled: '#bfbfbf',
        segmentBuilderNextButtonDisabledText: '#dfdfdf',
        segmentBuilderPreviousButtonFrame: '#e6e6e6',
        segmentBuilderExportButtonFrame: '#c4c4c4',
        segmentBuilderChipBorder: '#BFD278',
        segmentBuilderChipBackground: '#fcffef',
        lightBlue: '#44B3DB',
        lightGreen: '#acc54f',
        success: '#aac34b',
        failure: '#c93748',
        dropzoneFolder: '#CFE57B',
        optInOuterDiv: '#fafafa',
        optInInnerDiv: '#e6e6e6',
        dragActive: '#0c0fef',
        dropzoneBackground: '#fcffef',
        segmentManagementColumn: '#f5f5f5',
        segmentManagementColumnHover: '#ececec',
        segmentManagementDelete: '#FADFE1',
        segmentManagementDeleteIcon: '#E75560',
        showHideIcon: '#C0C0C0',
        disabled: '#93C1CC',
        dropdownArrowInactive: '#C5C5C5',
        primaryButton: '#459FB6',
        primaryButtonHover: '#4291A6',
        primaryButtonClicked: '#3992B2',
        secondaryButtonHover: '#EF8131',
        borderThick: '#94c2cb',
        checked: '#c4d292',
        underlineUnactiveStep: '#CCD6EB',
        campaignButtonHoverBorder: '#ABABAB',
        textDisabled: '#B6B6B6',
        asterisk: '#f4a76f',
        errorBackground: '#FDF3EB',
        warningInputBorder: '#EF8131',
        tertiaryButtonBorderHover: '#ABABAB',
        tertiaryButtonBackground: '#F5F5F5',
        tertiaryButtonBackgroundHover: '#EEEEEE',
        tertiaryButtonBorder: '#DCE2E4',
        tertiaryButtonBackgroundDisabled: '#FFFFFF',
        calendarDoW: '#B7B7B7',
        activeDayCalendar: '#7BC9E5',
        emailCampaignRedBorder: '#DB2A36',
        succesHover: '#B3C27E',
        timeSelectWatchBackground: '#F3FCFF',
        disabledButtonFontColor: '#bfbfbf',
        campaignSetupInputButtonBorder: '#e6e6e6',
        campaignManagementBackground: '#F1F2F5',
        campaignManagementHeaderBackground: '#EEF3FA',
        navigationButtonHover: '#3EA2C6',
        campaignManagementTableRowHover: '#F5F7F9',
        campaignManagementTableIcon: '#9D9D9D',
        campaignSuccessStatus: '#E0E7C7',
        campaignFailedStatus: '#FBD0D0',
        campaignSkippedStatus: '#F1DEB4',
        campaignScheduledStatus: '#FBDED0',
        campaignManagementRowBottomBorder: '#F1F2F5',
        warning: '#fef2eb',
        campaignStatsStyledTooltipBackground: '#F8F8F8',
        campaignStatsStyledTooltipBorder: '#9CC0CA',
        placeholderColor: '#9D9D9D',
        deleteCampaignBackground: '#F6CACD',
        deleteCampaignIconColor: '#E66A73',
        mobileIconInactive: '#959496',
        uplifts: '#DB9AB3',
        darkerSpinner: '#71a8b6'
    },
    fonts: {
        primaryFont: 'Roboto'
    },
    space: {
        half: '7px',
        single: '14px',
        double: '28px',
        triple: '42px',
        menu: '44px',
        menuDouble: '88px'
    },
    fontSizes: {
        big: '24px',
        header: '16px',
        header2: '18px',
        medium: '14px',
        normal: '13px',
        small: '12px'
    },
    borderRadius: {
        small: '2px'
    },
    buttonSizeWidth: {
        largeButtonWidth: '152px',
        smallButtonWidth: '137px'
    },
    buttonSizeHeight: {
        largeButtonHeight: '50px',
        smallButtonHeight: '43px'
    },
    variables: {
        '--color-danger': '#da171a',
        '--icon-colorOne': '#459fb6',
        '--icon-colorTwo': '#ed7942',
        '--icon-toplistNoEventImage': '#94C1CC'
    }
};
