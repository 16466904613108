import React from 'react';
import { ASIconProps, IconColors, IconSizes } from './types';
import './ASIcon.scss';

// Displays the icon with the name set by the 'icon' parameter.
// Example usage:
//
//  <ASIcon icon={Icons.AS_COINS} size={IconSizes.MEDIUM} color={IconColors.TRANSACTION} />
//
// ...or, using default values:
//
//  <ASIcon icon={Icons.AS_COINS} />
//
export const ASIcon = ({
    icon,
    size = IconSizes.MEDIUM,
    color = IconColors.PRIMARY,
    onClick,
    className = ''
}: ASIconProps) => {
    const Icon = require(`assets/svg/${icon}.svg`).default;

    return (
        <div
            onClick={onClick}
            className={`as-icon ${size} ${color} ${className}`}
            data-testid="as-icon">
            <Icon />
        </div>
    );
};
