/** @jsx jsx */
import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import React from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { styled } from '@mui/material/styles';
import { jsx, css } from '@emotion/react';
import { MenuItem, Select } from '@mui/material';
import _ from 'lodash';
import { Mobile, TabletToDesktop } from '../../responsive/MediaQuery';
import { date, DateRange, isfocused, START_DATE } from './types';
import { settingsStore } from 'stores/settings';

interface DateRangePickerProps {
    dateRange: DateRange;
    setDateRange: React.Dispatch<DateRange>;
}

export const DatePicker = ({ dateRange, setDateRange }: DateRangePickerProps) => {
    const { language } = React.useContext(settingsStore);
    const [focusedInput, setFocusedInput] = React.useState<isfocused>(START_DATE);
    moment.locale(language);
    const __months = moment.months().map(function (m) {
        return `${m.charAt(0).toUpperCase() + m.slice(1)}`;
    });
    const __weekDaysShort = moment.weekdaysShort().map(function (m) {
        return `${m.charAt(0).toUpperCase() + m.slice(1)}`;
    });

    moment.updateLocale(language, { months: __months, weekdaysMin: __weekDaysShort });

    const onDatesChange = ({
        startDate,
        endDate
    }: {
        startDate: date | undefined;
        endDate: date | undefined;
    }) => {
        if (startDate && endDate) {
            setDateRange([startOfDay(startDate), endOfDay(endDate)]);
        } else if (startDate) {
            setDateRange([startOfDay(startDate), dateRange[1]]);
        } else if (endDate) {
            setDateRange([dateRange[0], endOfDay(endDate)]);
        }
    };

    const onFocusInput = (focusedInput: isfocused | null) => {
        if (focusedInput) {
            setFocusedInput(focusedInput);
        } else {
            setFocusedInput(START_DATE);
        }
    };

    const onDatesChangeMoment = ({
        startDate,
        endDate
    }: {
        startDate: moment.Moment | null;
        endDate: moment.Moment | null;
    }) => {
        onDatesChange({ startDate: startDate?.toDate(), endDate: endDate?.toDate() });
    };

    return (
        <PickerWrapper>
            <Mobile>
                <DayPickerRangeController
                    onDatesChange={onDatesChangeMoment}
                    onFocusChange={onFocusInput}
                    focusedInput={focusedInput}
                    startDate={moment(dateRange[0])}
                    endDate={moment(dateRange[1])}
                    numberOfMonths={2}
                    isOutsideRange={() => false}
                    hideKeyboardShortcutsPanel
                    orientation="vertical"
                    verticalHeight={550}
                    renderMonthElement={YearAndMonthPicker}
                />
            </Mobile>
            <TabletToDesktop>
                <DayPickerRangeController
                    onDatesChange={onDatesChangeMoment}
                    onFocusChange={onFocusInput}
                    focusedInput={focusedInput}
                    startDate={moment(dateRange[0])}
                    endDate={moment(dateRange[1])}
                    numberOfMonths={2}
                    isOutsideRange={() => false}
                    hideKeyboardShortcutsPanel
                    renderMonthElement={YearAndMonthPicker}
                />
            </TabletToDesktop>
        </PickerWrapper>
    );
};

const YearAndMonthPicker = ({
    month,
    onMonthSelect,
    onYearSelect
}: {
    month: moment.Moment;
    onMonthSelect: (month: moment.Moment, value: string) => void;
    onYearSelect: (month: moment.Moment, value: string) => void;
}) => (
    <div
        css={css`
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;
        `}>
        <Select
            variant={'standard'}
            onChange={(event) => {
                onMonthSelect(month, event.target.value as string);
            }}
            value={month.month()}>
            {moment.months().map((label, value) => (
                <MenuItem
                    key={value}
                    value={value}>
                    {label}
                </MenuItem>
            ))}
        </Select>
        <Select
            variant={'standard'}
            value={month.year()}
            onChange={(e) => onYearSelect(month, e.target.value as string)}>
            {_.range(moment().year(), moment().year() - 20).map((it) => (
                <MenuItem
                    key={it}
                    value={it}>
                    {it}
                </MenuItem>
            ))}
        </Select>
    </div>
);

const PickerWrapper = styled('div')`
    .CalendarDay__selected_span {
        background: ${({ theme }) => theme.colors.highlight};
        color: ${({ theme }) => theme.colors.white};
    }

    .CalendarDay__selected {
        background: ${({ theme }) => theme.colors.textLink};
        color: ${({ theme }) => theme.colors.white};
    }

    .CalendarDay__selected:hover {
        background: ${({ theme }) => theme.colors.highlight};
        color: ${({ theme }) => theme.colors.white};
    }

    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        background: ${({ theme }) => theme.colors.white};
    }
    .DayPicker_transitionContainer__horizontal {
        transition: height 0.2s cubic-bezier(0.39, 0.17, 0.69, 0.98);
    }

    .DateInput_input {
        font-size: ${({ theme }) => theme.fontSizes.header};
    }

    .DayPickerNavigation__verticalDefault {
        padding: 10px 13px; // Using hard coded values to correspond to the spaces inside react-dates.
        display: flex;
        justify-content: space-between;
    }

    .DayPickerNavigation_button__verticalDefault {
        width: 48%;
        box-shadow: none;
        border-radius: 3px;
    }

    .DayPickerNavigation_nextButton__verticalDefault {
        border-left: 1px solid ${({ theme }) => theme.colors.frame};
    }

    .DayPickerNavigation_svg__vertical {
        height: ${({ theme }) => theme.fontSizes.header2};
        width: ${({ theme }) => theme.fontSizes.small};
    }

    .DayPicker__withBorder {
        box-shadow: none;
    }

    .CalendarMonthGrid__vertical div:nth-child(2) .CalendarMonth_table {
        margin-top: 20px; // Using hard coded values to correspond to the spaces inside react-dates.
    }
`;
