import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel } from '@mui/material';

import { styled } from '@mui/material/styles';
import { Label, Value } from '../components/commonStyled';
import { FilterType, Filters } from '../GlobalSearch';
import { isValueInFilters } from '../utils';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { Aggregation, Bucket } from 'utils/api/globalSearch/types';

interface BucketsProps {
    aggregationFilters: Filters;
    onClickBucket: (filters: Filters) => void;
    aggregation?: Aggregation;
    styledFor?: string;
    muiCheckbox?: any;
}

const Buckets = ({
    aggregationFilters,
    onClickBucket,
    aggregation,
    styledFor,
    muiCheckbox
}: BucketsProps) => {
    const { t } = useTranslation();
    const MUICheckbox = StyledMUICheckbox(muiCheckbox);

    const onClickCheckbox = (key: FilterType, name: string) => {
        const filters = new Map(aggregationFilters);

        if (isValueInFilters(name, aggregationFilters)) {
            filters.delete(key);
        } else {
            filters.set(key, name);
        }

        onClickBucket(filters);
    };

    if (!aggregation) {
        return null;
    }

    return (
        <React.Fragment>
            {styledFor === 'segmentBuilder' ? (
                <SegmentBuilderBucketWrapper>
                    <SegmentBuilderHeader>{t(aggregation.key)}</SegmentBuilderHeader>
                    <SegmentBuilderFilterWrapper>
                        {aggregation.buckets.map((bucket: Bucket, index: number) => (
                            <StyledFormControlLabel
                                key={index}
                                control={
                                    <MUICheckbox
                                        disableRipple
                                        color="default"
                                        checkedIcon={<StyledCheckBox />}
                                        icon={<StyledIcon />}
                                        checked={isValueInFilters(bucket.name, aggregationFilters)}
                                        onChange={() =>
                                            onClickCheckbox(
                                                aggregation.key as FilterType,
                                                bucket.name
                                            )
                                        }
                                    />
                                }
                                label={`${t(bucket.name.toLowerCase().replace(':', '_'), bucket.name)} ${
                                    bucket.nameCount
                                }`}
                            />
                        ))}
                    </SegmentBuilderFilterWrapper>
                </SegmentBuilderBucketWrapper>
            ) : (
                <BucketWrapper>
                    <Header>{t(aggregation.key)}</Header>
                    <FilterWrapper>
                        {aggregation.buckets.map((bucket: Bucket, index: number) => (
                            <StyledCheckbox
                                key={index}
                                checked={isValueInFilters(bucket.name, aggregationFilters)}
                                onClick={() =>
                                    onClickCheckbox(aggregation.key as FilterType, bucket.name)
                                }>
                                <Label>
                                    {t(bucket.name.replace(':', '_').toLowerCase(), bucket.name)}
                                </Label>{' '}
                                <Value>{bucket.nameCount}</Value>
                            </StyledCheckbox>
                        ))}
                    </FilterWrapper>
                </BucketWrapper>
            )}
        </React.Fragment>
    );
};

export { Buckets };

export const StyledCheckbox = styled(Checkbox)`
    display: flex;
    align-items: center;

    margin-top: ${({ theme }) => theme.space.half};
    margin-right: ${({ theme }) => theme.space.single};
    white-space: nowrap;
`;

const Header = styled('div')`
    font-size: ${({ theme }) => theme.fontSizes.header};
    font-weight: 500;
`;

const BucketWrapper = styled('div')`
    display: flex;
    flex-direction: column;

    padding: ${({ theme }) => theme.space.single} ${({ theme }) => theme.space.double};
`;

export const FilterWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
`;

const SegmentBuilderBucketWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    padding-left: ${({ theme }) => theme.space.single};
`;

const SegmentBuilderHeader = styled('div')`
    color: ${({ theme }) => theme.colors.segmentBuilderText};
    font-size: ${({ theme }) => theme.fontSizes.small};
`;

const SegmentBuilderFilterWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: ${({ theme }) => theme.space.half};
`;

const StyledMUICheckbox = (muiCheckbox?: any) => styled(muiCheckbox)`
    &:hover {
        background-color: transparent;
    }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    &:hover {
        background-color: transparent;
    }
    .Mui-icon {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        box-shadow:
            inset 0 0 0 1px ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame},
            inset 0 -1px 0 ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame};
        background-color: transparent;
        input:hover ~ & {
            background-color: transparent;
        }
    }
    .Mui-checkedIcon {
        background-color: transparent;
        &:before {
            display: block;
            width: 16px;
            height: 16px;
            margin: 0px;
            background-image: radial-gradient(
                ${({ theme }) => theme.colors.segmentBuilderCheckboxChecked},
                ${({ theme }) => theme.colors.segmentBuilderCheckboxChecked} 30%,
                transparent 45%
            );
            content: '';
        }
        input:hover ~ & {
            background-color: transparent;
        }
    }
    .MuiFormControlLabel-label {
        color: ${({ theme }) => theme.colors.segmentBuilderText};
        font-size: ${({ theme }) => theme.fontSizes.small};
    }
`;

const StyledCheckBox = styled('span')`
    border-radius: 50%;
    width: 16px;
    height: 16px;
    box-shadow:
        inset 0 0 0 1px ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame},
        inset 0 -1px 0 ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame};
    background-color: transparent;
    input:hover ~ & {
        background-color: transparent;
    }

    &:before {
        display: block;
        width: 16px;
        height: 16px;
        margin: 0px;
        background-image: radial-gradient(
            ${({ theme }) => theme.colors.segmentBuilderCheckboxChecked},
            ${({ theme }) => theme.colors.segmentBuilderCheckboxChecked} 30%,
            transparent 45%
        );
        content: '';
    }
`;

const StyledIcon = styled('span')`
    border-radius: 50%;
    width: 16px;
    height: 16px;
    box-shadow:
        inset 0 0 0 1px ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame},
        inset 0 -1px 0 ${({ theme }) => theme.colors.segmentBuilderCheckboxFrame};
    background-color: transparent;
    input:hover ~ & {
        background-color: transparent;
    }
`;
