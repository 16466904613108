import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

interface SpinnerProps {
    size?: number;
    color?: string;
    className?: string;
}

const Spinner = ({ size, color, className }: SpinnerProps) => (
    <SpinnerContainer
        color={color}
        className={className}>
        <CircularProgress
            color="inherit"
            size={size}
        />
    </SpinnerContainer>
);

const SpinnerContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme, color }) => color || theme.colors?.secondary};
`;

export { Spinner };
