/* eslint-disable @typescript-eslint/no-unused-vars */
import { Maybe, MCata } from 'utils/maybe/Maybe';
import { Nothing } from 'utils/maybe/Nothing';

export class Just<T> implements Maybe<T> {
    public static of<T>(val: T) {
        return new Just(val);
    }
    public readonly isJust = true;
    public readonly isNothing = false;

    private readonly value: T;
    constructor(value: T) {
        this.value = value;
    }

    public ap<A, B>(this: Just<(val: A) => B>, b: Maybe<A>) {
        return b.map(this.value);
    }

    public map<V>(project: (val: T) => V) {
        return Just.of(project(this.value));
    }

    public chain<V>(project: (val: T) => Maybe<V>) {
        return project(this.value);
    }

    public filter(predicate: (val: T) => any): Maybe<T> {
        if (predicate(this.value)) return this;
        return Nothing.of(this.value);
    }

    public cata<TJust, TNothing>(cata: MCata<T, TJust, TNothing>) {
        return cata.Just(this.value);
    }

    public getOrElse<V>(elseValue: V) {
        return this.value;
    }

    public getOrThrow(supplier?: () => Error) {
        return this.value;
    }

    public orElse<V>(maybeSupplier: () => Maybe<V>): Maybe<T | V> {
        return this as Maybe<T | V>;
    }

    public equals(other: Maybe<any>) {
        return other.isJust && (other as any as Just<any>).value === this.value;
    }
}
