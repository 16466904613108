import React from 'react';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { CustomSvgIcon } from '../../../utils/CustomSvgIcon';
import { CHECKMARK_ICON } from 'assets/iconConstants';

export type CheckboxProps = {
    checked: boolean;
    disabled?: boolean;
    onClick: () => void;
    className?: string;
    children?: React.ReactNode;
};

type WrapperProps = {
    disabled?: boolean;
    onClick: () => void;
};

type BoxProps = {
    checked?: boolean;
    disabled?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({ checked, disabled, children, onClick, className }) => (
    <Wrapper
        onClick={disabled ? () => {} : onClick}
        disabled={disabled}
        className={className}>
        <StyledCheckbox
            checked={checked}
            disabled={disabled}>
            {checked && (
                <CustomSvgIcon
                    fill="#fff"
                    size={0.8}
                    iconName={CHECKMARK_ICON}
                />
            )}
        </StyledCheckbox>
        <Label>{children}</Label>
    </Wrapper>
);

export type CustomIconProps = {
    fill?: string;
    size?: number;
    iconName: string;
};

const getBackgroundColor: (
    checked: boolean | undefined,
    theme: Theme,
    disabled: boolean | undefined
) => string = (checked, theme, disabled = false) => {
    if (checked) {
        return theme.colors.scrollBar;
    } else if (disabled) {
        return theme.colors.muted;
    } else {
        return theme.colors.defaultFill;
    }
};

const Wrapper = styled('div')<WrapperProps>`
    display: flex;
    align-items: center;
    cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
`;

const Label = styled('span')`
    display: flex;
    margin-left: 6px;
`;

const StyledCheckbox = styled('div')<BoxProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${({ theme }) => `calc(${theme.space.single}*1.5)`};
    height: ${({ theme }) => `calc(${theme.space.single}*1.5)`};
    background-color: ${({ checked, disabled, theme }): string =>
        getBackgroundColor(checked, theme, disabled)};
    &:hover {
        opacity: ${({ disabled }): string => (disabled ? '1' : '0.8')};
    }
`;

export { Checkbox };
