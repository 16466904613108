import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { DateFormatContext } from '../common/datepicker/DateFormatContext';
import { styled } from '@mui/material/styles';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import {
    NO_USER_PIC_ICON,
    EMAIL_ICON,
    HOME_ICON,
    DOT_ICON,
    PRICE_TAG,
    MALE_ARROW,
    FEMALE_ARROW,
    PHONE_MOBILE,
    PHONE_HOME,
    PHONE_WORK,
    BIRTHDAY_ICON,
    INCOME_ICON,
    HOUSING_ICON,
    EDUCATION_ICON,
    HASH_ICON
} from 'assets/iconConstants';

interface MetadataProps {
    thumbnail?: string;
    label: string;
    email: string;
    phoneMobile: string;
    phoneHome: string;
    phoneWork: string;
    housing: string;
    address: string;
    city: string;
    postalCode: string;
    gender: string;
    education: string;
    birthDate: string;
    income: string;
    tags: string;
    externalId?: string;
}

const CustomerMetadataTile = ({
    thumbnail,
    label,
    email,
    phoneMobile,
    phoneHome,
    phoneWork,
    housing,
    address,
    city,
    postalCode,
    gender,
    education,
    birthDate,
    income,
    tags,
    externalId
}: MetadataProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { formatDate } = React.useContext(DateFormatContext);
    return (
        <React.Fragment>
            <TopTileWrapper>
                <CustomerDetailsTileWrapper>
                    {thumbnail ? (
                        <Thumbnail thumbnail={thumbnail} />
                    ) : (
                        <CustomSvgIconWrapper>
                            <CustomSvgIcon
                                fill={theme.colors.white}
                                size={4}
                                iconName={NO_USER_PIC_ICON}
                            />
                        </CustomSvgIconWrapper>
                    )}
                    <CustomerDetailsTextWrapper>
                        <CustomerName>{label ? label : t('notAvailable')}</CustomerName>
                        <CustomerEmail>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={1.3}
                                    iconName={EMAIL_ICON}
                                />
                            </IconWrapper>
                            <TextWrapper>{email ? email : t('notAvailable')}</TextWrapper>
                        </CustomerEmail>
                        <CustomerDetailsText>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={1.5}
                                    iconName={PHONE_MOBILE}
                                />
                            </IconWrapper>
                            <TextWrapper>
                                {phoneMobile ? phoneMobile : t('notAvailable')}
                            </TextWrapper>
                        </CustomerDetailsText>
                        <CustomerDetailsText>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={1.5}
                                    iconName={PHONE_HOME}
                                />
                            </IconWrapper>
                            <TextWrapper>{phoneHome ? phoneHome : t('notAvailable')}</TextWrapper>
                        </CustomerDetailsText>
                        <CustomerDetailsText>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={1.5}
                                    iconName={PHONE_WORK}
                                />
                            </IconWrapper>
                            <TextWrapper>{phoneWork ? phoneWork : t('notAvailable')}</TextWrapper>
                        </CustomerDetailsText>
                        <CustomerDetailsText>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={1.5}
                                    iconName={HOME_ICON}
                                />
                            </IconWrapper>
                            <TextWrapper>{address ? address : t('notAvailable')}</TextWrapper>
                        </CustomerDetailsText>
                        <CustomerDetailsText>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={0.4}
                                    iconName={DOT_ICON}
                                />
                            </IconWrapper>
                            <TextWrapper>{postalCode ? postalCode : t('notAvailable')}</TextWrapper>
                        </CustomerDetailsText>
                        <CustomerDetailsText>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={0.4}
                                    iconName={DOT_ICON}
                                />
                            </IconWrapper>
                            <TextWrapper>{city ? city : t('notAvailable')}</TextWrapper>
                        </CustomerDetailsText>
                        <CustomerDetailsText>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={0.4}
                                    iconName={HASH_ICON}
                                />
                            </IconWrapper>
                            <TextWrapper>{externalId ? externalId : t('notAvailable')}</TextWrapper>
                        </CustomerDetailsText>
                    </CustomerDetailsTextWrapper>
                </CustomerDetailsTileWrapper>
            </TopTileWrapper>
            <CustomerDetailsWrapper>
                <CustomerDetails>
                    <DetailTitle>{t('demography')}</DetailTitle>
                    <CustomerDetailsInformation>
                        <DetailInformationWrapper>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={1.5}
                                    fill={theme.colors.text}
                                    iconName={
                                        gender === 'male' || gender === 'Male'
                                            ? MALE_ARROW
                                            : gender === 'female' || gender === 'Female'
                                              ? FEMALE_ARROW
                                              : ''
                                    }
                                />
                            </IconWrapper>
                            <DetailInformation>
                                {gender ? t(gender) : t('notAvailable')}
                            </DetailInformation>
                        </DetailInformationWrapper>
                        <DetailInformationWrapper>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={1.5}
                                    iconName={BIRTHDAY_ICON}
                                />
                            </IconWrapper>
                            <DetailInformation>
                                {birthDate ? formatDate(new Date(birthDate)) : t('notAvailable')}
                            </DetailInformation>
                        </DetailInformationWrapper>
                        <DetailInformationWrapper>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={1.5}
                                    iconName={INCOME_ICON}
                                />
                            </IconWrapper>
                            <DetailInformation>
                                {income ? income : t('notAvailable')}
                            </DetailInformation>
                        </DetailInformationWrapper>
                        {housing ? (
                            <DetailInformationWrapper>
                                <IconWrapper>
                                    <CustomSvgIcon
                                        size={1.5}
                                        iconName={HOUSING_ICON}
                                    />
                                </IconWrapper>
                                <DetailInformation>{housing}</DetailInformation>
                            </DetailInformationWrapper>
                        ) : null}
                        {education ? (
                            <DetailInformationWrapper>
                                <IconWrapper>
                                    <CustomSvgIcon
                                        size={1.5}
                                        iconName={EDUCATION_ICON}
                                    />
                                </IconWrapper>
                                <DetailInformation>{education}</DetailInformation>
                            </DetailInformationWrapper>
                        ) : null}
                    </CustomerDetailsInformation>
                </CustomerDetails>
                <CustomerDetails>
                    <DetailTitle>{t('classification')}</DetailTitle>
                    <CustomerDetailsInformation>
                        <DetailInformationWrapper>
                            <IconWrapper>
                                <CustomSvgIcon
                                    size={1.5}
                                    iconName={PRICE_TAG}
                                />
                            </IconWrapper>
                            <DetailInformation>{tags ? tags : t('notAvailable')}</DetailInformation>
                        </DetailInformationWrapper>
                    </CustomerDetailsInformation>
                </CustomerDetails>
            </CustomerDetailsWrapper>
        </React.Fragment>
    );
};

export { CustomerMetadataTile };

type ThumbnailProps = { thumbnail: string };

const Thumbnail = styled('div')<ThumbnailProps>`
    display: flex;
    margin: ${({ theme }) => theme.space.single};
    flex-shrink: 0;
    align-self: center;
    width: 160px;
    height: 160px;
    background: ${({ thumbnail }) => `url(${thumbnail}) no-repeat center center `};
    background-size: cover;
`;

const TopTileWrapper = styled('div')`
    border-bottom: 1px solid ${({ theme }) => theme.colors.chart1};
`;

const CustomerDetailsTileWrapper = styled('div')`
    display: grid;
    grid-template-columns: 1fr 2fr;
    overflow: hidden;
    margin-bottom: ${({ theme }) => theme.space.double};
`;

const CustomerDetailsWrapper = styled('div')`
    overflow: hidden;
    > div {
        border-bottom: 1px solid ${({ theme }) => theme.colors.chart1};
    }
    > :last-child {
        border-bottom: none;
    }
`;

const CustomerName = styled('div')`
    font-size: ${({ theme }) => theme.fontSizes.header};
    text-decoration: underline;
    font-weight: 700;
`;

const CustomerEmail = styled('div')`
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.textLink};
    text-decoration: underline;
    align-items: center;
`;

const IconWrapper = styled('div')`
    height: 15px;
    width: 15px;
`;

const CustomerDetailsTextWrapper = styled('div')`
    margin-top: ${({ theme }) => theme.space.single};
    margin-left: ${({ theme }) => theme.space.single};
`;

const CustomerDetailsInformation = styled('div')`
    margin-top: ${({ theme }) => theme.space.half};
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: start;
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin-bottom: ${({ theme }) => theme.space.double};
`;

const DetailInformationWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: start;
`;

const CustomerDetailsText = styled('div')`
    margin-top: ${({ theme }) => theme.space.half};
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: ${({ theme }) => theme.fontSizes.small};
    text-align: center;
`;

const CustomerDetails = styled('div')`
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding: ${({ theme }) => theme.space.single};
`;

const DetailTitle = styled('div')`
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSizes.header};
    margin-top: ${({ theme }) => theme.space.single};
    margin-bottom: ${({ theme }) => theme.space.single};
`;

const DetailInformation = styled('div')`
    font-size: ${({ theme }) => theme.fontSizes.normal};
    margin-left: ${({ theme }) => theme.space.half};
`;

const TextWrapper = styled('p')`
    margin-left: ${({ theme }) => theme.space.half};
`;

const CustomSvgIconWrapper = styled('div')`
    margin: ${({ theme }) => theme.space.single};
    display: flex;
    flex-shrink: 0;
    align-self: center;
    width: 160px;
    height: 160px;
    background: ${({ theme }) => theme.colors.toplistNoCustomerImage};
`;
