import cubejs, { LoadMethodOptions, Query, ResultSet } from '@cubejs-client/core';
import { APIMethod, ASCubeParams, CubeRequestOptions, CubeResponse } from './types';
import { mapDataToHeaders } from './response/utils';
import { CubeRequest } from './request/cubeRequest';

// IMPORTANT:
// Do not use either the CubeApi or CubeRequest class directly, instead make use of the
// getCubeData function as this will handle filter alterations and handle the request.
class CubeService {
    public static instance: CubeService;
    // This needs to be moved to an environment variable as part of CI/CD ASAP.
    // How this will work with AWS/Azure needs to be investigated
    private CUBE_TOKEN =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1OTMwMDE3OTcsImV4cCI6MTU5MzA4ODE5N30.jTk_qA-C0equpOikY3LiFTt8ELgP5L2pqXbtwtk0nf0';
    // Stores each cube request made so we can return the result if a user sends duplicate requests.
    // Currently resets on navigation/refresh - See routes.tsx for reset call.
    private cache: { [key: string]: CubeResponse } = {};

    constructor() {
        // Ensure the class is a singleton for caching purposes
        if (!CubeService.instance) {
            CubeService.instance = this;
        }

        return CubeService.instance;
    }

    // query: The built CubeJS Query received from CubeRequest
    // options: Any CubeJSAPI instance options i.e. Method (Post/Get)
    // loadOptions: load function specific options such as progressCallback, which gives state/progress updates on each
    // continue await call within Cube.
    public send = (
        query: Query,
        options: CubeRequestOptions,
        loadOptions?: LoadMethodOptions
    ): Promise<CubeResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const cachedData = this.checkCache(
                    JSON.stringify(query) + JSON.stringify(loadOptions?.queryParams || {})
                );

                // If a request has been cached recently, return that result instead.
                if (cachedData) {
                    resolve(cachedData);
                    return;
                }

                cubejs(this.CUBE_TOKEN, options)
                    .load(query, loadOptions)
                    .then((response: ResultSet<any>) => {
                        const mappedResponse = mapDataToHeaders(response) as CubeResponse;

                        // Cache result using the query itself and the query parameters.
                        this.cache[
                            JSON.stringify(query) + JSON.stringify(loadOptions?.queryParams || {})
                        ] = mappedResponse;
                        resolve(mappedResponse);
                    });
            } catch (error) {
                console.error('CubeApi Error: ', { query, error });
                reject({});
            }
        });
    };

    public clearCache = () => {
        this.cache = {};
    };

    private checkCache = (key: string): CubeResponse | undefined => {
        return this.cache[key];
    };
}

// Handles filter building and baseFilters.
// This is the only function that should be called when making cube requests.
// Any other CubeJS functionality should be moved into either CubeRequest or CubeService.
const getCubeData = (
    params: ASCubeParams,
    loadOptions?: LoadMethodOptions | undefined,
    method?: APIMethod | undefined
): Promise<CubeResponse> => {
    if (params.filterOmit) {
        params.filters = params.filters?.filter((filter) => filter.dimension !== params.filterOmit);
    }

    if (params.baseFilters) {
        params.filters = params.filters.concat(params.baseFilters);
    }

    return new CubeRequest(params, loadOptions, method).send();
};

const clearCubeCache = () => {
    new CubeService().clearCache();
};

export { getCubeData, clearCubeCache, CubeService };
