import { subYears } from 'date-fns';
// import { TimeDimensionGranularity } from '@cubejs-client/core';
import {
    TRANSACTIONS_ITEMCOUNT,
    TRANSACTIONS_REVENUE,
    TRANSACTIONS_OCCURRED_AT,
    TRANSACTIONS_PRODUCT_TIME_BEGINS
} from 'utils/common/constants';
import { ASCubeFilter, ASCubeQuery } from 'utils/common/types';

export const getStartOfAdvancePeriodData = (
    dimensions: string[],
    memberDimension: string,
    startDate: Date,
    entityRef: string,
    tenantTimezone: string
): ASCubeQuery => ({
    dimensions: dimensions,
    measures: [TRANSACTIONS_ITEMCOUNT, TRANSACTIONS_REVENUE],
    timeDimensions: [
        {
            dimension: TRANSACTIONS_OCCURRED_AT,
            dateRange: [
                subYears(new Date(startDate), 3).toISOString(),
                new Date(startDate).toISOString()
            ],
            granularity: 'day'
        }
    ],
    filters: [
        {
            dimension: TRANSACTIONS_PRODUCT_TIME_BEGINS,
            operator: 'afterDate',
            values: [new Date(startDate).toISOString()]
        },
        {
            member: memberDimension,
            operator: 'equals',
            values: [entityRef]
        }
    ] as ASCubeFilter[],
    timezone: tenantTimezone
});

export const getPlayedOffInPeriodData = (
    dimensions: string[],
    memberDimension: string,
    startDate: Date,
    endDate: Date,
    entityRef: string,
    tenantTimezone: string
): ASCubeQuery => ({
    dimensions: dimensions,
    measures: [TRANSACTIONS_ITEMCOUNT, TRANSACTIONS_REVENUE],
    timeDimensions: [
        {
            dimension: TRANSACTIONS_PRODUCT_TIME_BEGINS,
            dateRange: [new Date(startDate).toISOString(), new Date(endDate).toISOString()],
            granularity: 'day'
        }
    ],
    filters: [
        {
            member: memberDimension,
            operator: 'equals',
            values: [entityRef]
        }
    ],
    timezone: tenantTimezone
});

export const getSalesInPeriodData = (
    dimensions: string[],
    memberDimension: string,
    startDate: Date,
    endDate: Date,
    entityRef: string,
    tenantTimezone: string
): ASCubeQuery => ({
    dimensions: dimensions,
    measures: [TRANSACTIONS_ITEMCOUNT, TRANSACTIONS_REVENUE],
    timeDimensions: [
        {
            dimension: TRANSACTIONS_OCCURRED_AT,
            dateRange: [new Date(startDate).toISOString(), new Date(endDate).toISOString()],
            granularity: 'day'
        }
    ],
    filters: [
        {
            member: memberDimension,
            operator: 'equals',
            values: [entityRef]
        }
    ],
    timezone: tenantTimezone
});
