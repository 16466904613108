import React from 'react';
import { styled } from '@mui/material/styles';

const Overlay = ({
    show,
    campaignShow,
    styledFor
}: {
    show: boolean;
    campaignShow?: boolean;
    styledFor?: string;
}) => {
    if (show && campaignShow) {
        return (
            <React.Fragment>
                {/* {styledFor === 'segmentBuilder' ? <SegmentBuilderOverlay /> : <OverLay />} */}
                {styledFor === 'segmentBuilder' ? <React.Fragment /> : <OverLay />}
            </React.Fragment>
        );
    }

    return null;
};

export { Overlay };

const OverLay = styled('div')`
    position: absolute;
    top: ${({ theme }) => theme.space.menu};
    left: -50vw;
    bottom: 0;
    height: 100vh;
    width: 150vw;
    background-color: ${({ theme }) => theme.colors.text};
    opacity: 0.4;
    z-index: 1000;
`;

// Commented out because I'm not 100% if we like the change or not or if we need in the old Segment Builder:
// const SegmentBuilderOverlay = styled('div')`
//   position: absolute;
//   left: -50vw;
//   bottom: -50vh;
//   right: -50vw;
//   height: 200vh;
//   background-color: ${({ theme }) => theme.colors.text};
//   opacity: 0.4;
//   z-index: 1000;
// `;
