import Highcharts from 'highcharts';
import { TFunction } from 'i18next';
import { ASCubeFilter } from 'utils/common/types';
import { DimensionData } from 'utils/common/types';
import { Maybe } from 'utils/maybe';
import { filterValueCheck } from 'utils/filter/filterCheck';

export const mapPeriodSelectorDOWData =
    (
        t: TFunction,
        dimension: string,
        metric: string,
        filters: ASCubeFilter[],
        operator: string,
        color?: string
    ) =>
    (data: DimensionData[]): Highcharts.SeriesColumnOptions[] => {
        const filterValues = Maybe(filters.find((filter) => filter.dimension === dimension))
            .chain((filter) => Maybe(filter.values))
            .map((values) => values.map((value) => filterValueCheck(value)))
            .getOrElse([] as string[]);
        return [
            {
                type: 'column',
                data: data
                    .sort((a, b) => Number(a[dimension]) - Number(b[dimension]))
                    .map((dataPoint, index) => ({
                        name: t(DOW[Number(dataPoint[dimension]) - 1]) as string,
                        x: index,
                        y: Number(dataPoint[metric]),
                        color:
                            filterValues.length === 0 ||
                            filterValues.includes(String(dataPoint[dimension])) ===
                                (operator === 'equals')
                                ? color
                                : color && `${color}80`,
                        category: dataPoint[dimension],
                        custom: {
                            filterValue: dataPoint[dimension],
                            breadcrumbValue: t(DOW[Number(dataPoint[dimension]) - 1])
                        }
                    }))
            }
        ];
    };

const DOW = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
