import { GLOBAL_SEARCH_API_URL, GLOBAL_SEARCH_MULTI_API_URL } from '../urls';
import { SearchResults, GlobalSearchQuery } from './types';
import { ASRequest } from 'utils/api/request';

export const globalSearchAPI = async (query: GlobalSearchQuery, pageSize: number) => {
    query.size = pageSize;

    let url;
    if (query.archetype_and_variant_list) {
        url = GLOBAL_SEARCH_MULTI_API_URL;
    } else {
        url = GLOBAL_SEARCH_API_URL;
        if (!query.archetype_and_variant || query.archetype_and_variant === 'Customer') {
            if (!query.email && query.presentation_label) {
                query.email = query.presentation_label;
            }
        }
    }

    const results = await ASRequest.request<SearchResults>({
        method: 'get',
        url,
        params: query
    });

    return results.data;
};
