import { pathOr, toLower } from 'ramda';
import DataLoader from 'dataloader';
import { splitKeys } from './inventoryLoader';
import { cubejsApi } from 'utils/api/CubeAPI';
import {
    BASE_TRANSACTIONS_BUYER_REF,
    BASE_TRANSACTIONS_ITEM_COUNT,
    BASE_TRANSACTIONS_REVENUE,
    BASE_TRANSACTIONS_CURRENCY,
    BASE_TRANSACTIONS_TYPE
} from 'utils/common/constants';
import { Maybe } from 'utils/maybe';
export const lifetimeValueLoaderCache: { [key: string]: LifetimeValueData[] | null } = {};

export const lifetimeValueLoader = new DataLoader(
    (keys: readonly string[]) => {
        const batchedKeys: string[][] = splitKeys([...keys] as string[]);

        return Promise.all(
            batchedKeys.map((batch) => {
                return cubejsApi.load({
                    dimensions: [BASE_TRANSACTIONS_BUYER_REF, BASE_TRANSACTIONS_CURRENCY],
                    measures: [BASE_TRANSACTIONS_ITEM_COUNT, BASE_TRANSACTIONS_REVENUE],
                    filters: [
                        {
                            dimension: BASE_TRANSACTIONS_BUYER_REF,
                            operator: 'equals',
                            values: batch.slice()
                        },
                        {
                            operator: 'equals',
                            dimension: BASE_TRANSACTIONS_TYPE,
                            values: ['purchased', 'cancelled']
                        }
                    ]
                });
            })
        ).then((responses) => {
            let data: LifetimeValueData[] = [];
            responses.forEach((response) => {
                data = data.concat(
                    pathOr([], ['loadResponse', 'results', '0', 'data'], response)
                ) as [];
            });

            const productMap = data.reduce(
                (previous, value: LifetimeValueData) => {
                    previous[toLower(value[BASE_TRANSACTIONS_BUYER_REF])] =
                        previous[toLower(value[BASE_TRANSACTIONS_BUYER_REF])] || [];
                    previous[toLower(value[BASE_TRANSACTIONS_BUYER_REF])].push(value);
                    return previous;
                },
                {} as { [key: string]: LifetimeValueData[] }
            );

            // data loader requires null entries to exist for keys not found on the server
            // so we map over the keys here to find their respective entries in the result
            // if they're not found we return null
            return keys.map(toLower).map((product) => {
                const value = Maybe(productMap[product]).getOrElse(null);
                lifetimeValueLoaderCache[product] = value;
                return value;
            });
        });
    },
    {
        // entity refs are case insensitive
        cacheKeyFn: toLower
    }
);

export interface LifetimeValueData {
    [BASE_TRANSACTIONS_BUYER_REF]: string;
    [BASE_TRANSACTIONS_ITEM_COUNT]: number;
    [BASE_TRANSACTIONS_REVENUE]: string;
    [BASE_TRANSACTIONS_CURRENCY]: string;
}
