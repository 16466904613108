import mixpanel from 'mixpanel-browser';

const Mixpanels: string[] = [];

interface TrackingInfo {
    [key: string]: any;
}

interface TrackingQueue {
    goal: string;
    info?: TrackingInfo;
    callback?: () => void;
}

const trackingQueue: TrackingQueue[] = [];

export const Tracking = {
    initMixpanel(APIKey: string, name: string) {
        mixpanel.init(APIKey, { track_pageview: false }, name);
        Mixpanels.push(name);
    },
    trackGoal(goal: string, info?: TrackingInfo, callback?: () => void) {
        Mixpanels.forEach((name) => {
            // @ts-ignore
            mixpanel[name].track(goal, info, callback);
        });
        try {
            // @ts-ignore
            window.posthog.capture(goal, info);
        } catch (error) {
            console.error(error);
        }
    },
    setUserID(userId: string) {
        Mixpanels.forEach((name) => {
            // @ts-ignore
            mixpanel[name].identify(userId);
        });
        try {
            // @ts-ignore
            posthog.identify(userId);
        } catch (error) {
            console.error(error);
        }
    },
    userParams(params: TrackingInfo) {
        Mixpanels.forEach((name) => {
            // @ts-ignore
            mixpanel[name].people.set(params);
        });
    },
    params(params: TrackingInfo) {
        Mixpanels.forEach((name) => {
            // @ts-ignore
            mixpanel[name].register(params);
        });
    },
    pageview(url: string, dashboardName: string) {
        Mixpanels.forEach((name) => {
            // @ts-ignore
            mixpanel[name].track('Page View', {
                route: url,
                dashboardName
            });
        });
        try {
            // @ts-ignore
            posthog.capture('$pageview');
        } catch (error) {
            console.error(error);
        }
    },
    addToQueue(goal: string, info?: TrackingInfo, callback?: () => void) {
        trackingQueue.push({ goal, info, callback });
    },
    flushQueue() {
        trackingQueue.forEach(({ goal, info, callback }) => this.trackGoal(goal, info, callback));
        trackingQueue.length = 0;
    }
};
