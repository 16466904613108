import { TFunction } from 'i18next';
import { TimeDimensionGranularity } from '@cubejs-client/core';
import { TooltipFormatterContextObject } from 'highcharts';

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export type isfocused = typeof START_DATE | typeof END_DATE;
export type date = Date;
export type DateRange = [date, date];

export interface DateFormatInterface {
    dateFnsLocale: Locale;
    setDateFnsLocale: (locale: string) => void;
    formatDateTime: (date: Date) => string;
    formatDateTimeFullHourAndMinute: (date: Date) => string;
    formatDateTime24h: (date: Date) => string;
    formatTime: (date: Date) => string;
    formatDate: (date: Date) => string;
    formatDateLong: (date: Date) => string;
    formatDateTimeLong: (date: Date) => string;
    formatEventDate: (date: Date, t: TFunction) => string;
    formatCampaignEventDate: (date: Date, t: TFunction) => string;
    formatDateMonthShort: (date: Date) => string;
    getTenantTimezoneTimeOfDay: (date: string, timezone: string) => Date;
    getTenantTimezoneTimeOfDayStr: (date: string, timezone: string) => string;
    formatDateMonth: (date: Date) => string;
    formatDOWDateTimeLong: (date: Date, t: TFunction) => string;
    formatChartDatetime: (date: number, granularity: TimeDimensionGranularity) => string;
    formatMonthDateYearShort: (date: Date) => string;
    formatTooltipHeader: (
        point: TooltipFormatterContextObject,
        yoyLineChart: boolean | undefined,
        timeRangeChart?: boolean
    ) => string;
    formatShortMonth: (date: Date) => string;
    formatYear: (date: Date) => string;
    formatTime24h: (date: Date) => string;
    formatRTODateTime: (date: Date) => string;
    formatDayMonthYearTimeShort: (date: Date) => string;
    formatDateTimeShort: (date: Date) => string;
}
