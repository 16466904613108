import React from 'react';
import { getCampaign } from '../CampaignSetup/utils';
import { styled } from '@mui/material/styles';
import { EYE_SHOWN } from 'assets/iconConstants';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { ASIconButton } from 'components/common/button/ASIconButton';
import { TemplateViewProps } from 'views/dashboards/Tools/CampaignManagement/types';

async function getCampaignTemplate(entityRef: string): Promise<TemplateViewProps> {
    const res = await getCampaign(entityRef);
    return {
        template: res.data.template,
        campaignName: entityRef,
        lastModifiedAt: res.data.lastModifiedAt
    };
}

export const TemplateModalButton = ({
    entityRef,
    setTemplateViewProps,
    setCampaignFetchFailing,
    getTemplate
}: {
    entityRef?: string;
    templateViewProps?: TemplateViewProps | undefined;
    setTemplateViewProps?: React.Dispatch<TemplateViewProps | undefined>;
    setCampaignFetchFailing?: React.Dispatch<boolean>;
    getTemplate?: (entityRef: string) => Promise<TemplateViewProps>;
}) => {
    return (
        <React.Fragment>
            <StyledTileButton
                onClick={async () => {
                    if (entityRef) {
                        try {
                            const fetchTemplate = getTemplate ?? getCampaignTemplate;
                            const template = await fetchTemplate(entityRef);
                            setTemplateViewProps?.(template);
                        } catch (error: any) {
                            if (error.response && error.response.status !== 200) {
                                setCampaignFetchFailing?.(true);
                            }
                        }
                    }
                }}>
                <CustomSvgIcon
                    size={0.5}
                    iconName={EYE_SHOWN}
                    fill={'keepOriginalColors'}
                />
            </StyledTileButton>
        </React.Fragment>
    );
};

const StyledTileButton = styled(ASIconButton)`
    margin-right: 10px;
`;
