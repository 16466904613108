import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { clearQueryParam } from 'utils/url/urlParams';

interface MenuLinkProps {
    children: any;
    to: string;
    id: string;
    menuItemClicked: (event: React.MouseEvent<HTMLElement>) => void;
    target?: string;
}

const MenuLink = ({ children, to, target, id, menuItemClicked }: MenuLinkProps) => {
    if (to.startsWith('http')) {
        return (
            <StyledLink
                innerRef={(node) => {
                    if (node) {
                        node.href = to;
                    }
                }}
                to={''}
                target={target}
                id={id}>
                {children}
            </StyledLink>
        );
    }

    if (window.location.href.includes('/segment_builder') && to == '/segment_builder') {
        return (
            <StyledLink
                to={() => to}
                id={id}
                onClick={(event) => {
                    menuItemClicked(event);
                    clearQueryParam();
                    window.location.reload();
                }}>
                {children}
            </StyledLink>
        );
    }
    return (
        <StyledLink
            to={() => to}
            id={id}
            onClick={(event) => {
                menuItemClicked(event);
                event.stopPropagation();
            }}>
            {children}
        </StyledLink>
    );
};

const StyledLink = styled(Link)`
    padding: ${({ theme }) => theme.space.single};
    padding-left: ${({ theme }) => theme.space.double};
    text-decoration: none;
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.normal};
    color: ${({ theme }) => theme.colors.hamburgerMenuFonts};
    white-space: nowrap;
    &:hover {
        text-decoration: none;
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.hamburgerMenuHover};
    }
`;

export { MenuLink, StyledLink };
