import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { isNumber } from 'lodash';
import { styled } from '@mui/material/styles';
import { FilterDrawer } from '../filters';
import { DateFormatContext } from '../common/datepicker';
import { FadedChartAction, FadedChartMenuItemContainer, InvertChartAction } from './fadedIcons';

import { BasicDimension } from './BasicDimension';

import { BarAndColumnChartDimension } from './BarAndColumnChartDimension';
import { IN_FILTER_ICON, NOT_IN_FILTER_ICON, PNG_EXPORT_ICON } from 'assets/iconConstants';
import { pngExportHandler } from 'views/utils/pngExportHandler';
import { DetailedBardAndColumnChartProps, Operator } from 'utils/common/types';
import { settingsStore } from 'stores/settings';

export const DetailedTicketSales = ({
    metric,
    measures,
    salesTime,
    timePeriod,
    filters,
    baseFilters,
    onFilter,
    dimensionOne,
    dimensionTwo,
    dimensionThree,
    color,
    defaultExpanded = true,
    dashboardName,
    granularity,
    filterTitle
}: DetailedBardAndColumnChartProps) => {
    const { excludeDashboardComponent } = useContext(settingsStore);
    let width = 100;
    if (excludeDashboardComponent.includes(dimensionOne.split('.')[1])) {
        width -= 50;
    }
    if (excludeDashboardComponent.includes(dimensionTwo.split('.')[1])) {
        width -= 25;
    }
    if (excludeDashboardComponent.includes(dimensionThree.split('.')[1])) {
        width -= 25;
    }
    if (width === 0) {
        return <React.Fragment />;
    }
    return (
        <MultipleChartWrapper width={width}>
            <FilterDrawer
                defaultExpanded={defaultExpanded}
                name={filterTitle && filterTitle}
                borderColor={color}>
                <DetailedBarAndColumnChart
                    dimensionOne={dimensionOne}
                    dimensionTwo={dimensionTwo}
                    dimensionThree={dimensionThree}
                    metric={metric}
                    measures={measures}
                    salesTime={salesTime}
                    timePeriod={timePeriod}
                    filters={filters}
                    baseFilters={baseFilters}
                    onFilter={onFilter}
                    color={color}
                    exportName={filterTitle}
                    dashboardName={dashboardName}
                    granularity={granularity}
                />
            </FilterDrawer>
        </MultipleChartWrapper>
    );
};

const MultipleChartWrapper = styled('div')<{ width: number }>`
    position: relative;
    width: ${({ width }) => width}%;
`;

export const DetailedBarAndColumnChart = ({
    metric,
    measures,
    salesTime,
    timePeriod,
    filters,
    baseFilters,
    onFilter,
    dimensionOne,
    dimensionTwo,
    dimensionThree,
    color,
    exportName,
    dashboardName,
    granularity
}: DetailedBardAndColumnChartProps) => {
    const { excludeDashboardComponent, focusedSegments } = useContext(settingsStore);
    const { formatDate } = React.useContext(DateFormatContext);
    const { t } = useTranslation();
    const [operator, setOperator] = React.useState<Operator>('equals');

    const theme = useTheme();

    const exportFileName = `${exportName ? `${t(exportName)}-` : ''}-${timePeriod
        .map((date) => formatDate(new Date(date)))
        .join('-')}`;

    const chartId = t(dimensionOne).split(' ').join('_');

    const exportingGraph = (
        <FadedChartAction
            onClick={() => {
                pngExportHandler(chartId, exportFileName);
            }}
            title={t('exportGraphPNG')}
            iconName={PNG_EXPORT_ICON}
        />
    );

    const chartMenu = (
        <FadedChartMenuItemContainer>
            <InvertChartAction
                onClick={() => setOperator(operator === 'equals' ? 'notEquals' : 'equals')}
                title={t(operator)}
                iconName={operator === 'equals' ? IN_FILTER_ICON : NOT_IN_FILTER_ICON}
                invert={operator}
                disabled={focusedSegments ? true : false}
            />
            {exportingGraph}
        </FadedChartMenuItemContainer>
    );

    const barChartOptions = (subtitle: string) =>
        ({
            chart: {
                marginRight: 0,
                type: 'bar'
            },
            xAxis: {
                showEmpty: true,
                labels: {
                    align: 'left',
                    step: 1,
                    enabled: true,
                    x: 0,
                    style: { whiteSpace: 'nowrap' },
                    useHTML: true,
                    formatter() {
                        // @ts-ignore
                        const chartWidth =
                            // @ts-ignore
                            this.chart.containerWidth -
                            // @ts-ignore
                            this.chart.spacing[1] -
                            // @ts-ignore
                            this.chart.spacing[3];
                        const pointLabelValue = this.value;
                        // @ts-ignore
                        if (isNumber(pointLabelValue)) {
                            return;
                        }
                        return `
              <div style="width: ${chartWidth}px; display: flex; justify-content: space-between; cursor: pointer; position: absolute; left: 0px;">
                  <span style="padding-left: ${theme.space.single}; max-width: ${chartWidth}px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; position: relative;">
                    ${pointLabelValue}
                  </span>
              </div>
            `;
                    }
                }
            },
            title: {
                useHTML: true,
                text: `<div>
            <div style="width: 100%;position: absolute; right: 0px; top: -20px; padding-right: ${
                theme.space.single
            }; display: flex; font-weight: bold;">
              <div style="flex: 35%; text-align: left;">
                ${subtitle || ''}
              </div>
            </div>
          </div>`,
                align: 'left',
                x: 5,
                y: 9,
                style: {
                    fontSize: theme.fontSizes.normal,
                    color: theme.colors.text,
                    fontWeight: 'bold',
                    right: '0px'
                }
            },
            tooltip: {
                enabled: true
            }
        }) as Highcharts.Options;

    return (
        <React.Fragment>
            <IconWrapper key={'chartMenu'}>{chartMenu}</IconWrapper>
            <Wrapper
                id={chartId}
                key={'chart'}>
                {excludeDashboardComponent.includes(dimensionOne.split('.')[1]) ? null : (
                    <DoubleSize key={dimensionOne}>
                        <BarAndColumnChartDimension
                            key={dimensionOne}
                            dashboardName={dashboardName}
                            baseFilters={baseFilters}
                            dimension={dimensionOne}
                            measures={measures}
                            salesTime={salesTime}
                            timePeriod={timePeriod}
                            filters={filters}
                            metric={metric}
                            granularity={granularity}
                            onFilter={onFilter}
                            color={color}
                            hideFilterDrawer={true}
                            barChartOptions={barChartOptions(t(dimensionOne))}
                            doubleFilterDrawer={true}
                            subtitleTwo={t(metric)}
                            multipleChartOperator={operator}
                        />
                    </DoubleSize>
                )}
                <BasicDimension
                    key={dimensionTwo}
                    dimension={dimensionTwo}
                    measures={measures}
                    salesTime={salesTime}
                    timePeriod={timePeriod}
                    filters={filters}
                    onFilter={onFilter}
                    baseFilters={baseFilters}
                    metric={metric}
                    color={color}
                    subtitleOne={t(dimensionTwo)}
                    hideFilterDrawer={true}
                    barChartOptions={barChartOptions(t(dimensionTwo))}
                    chartTotals={false}
                    doubleFilterDrawer={true}
                    multipleChartOperator={operator}
                />
                <BasicDimension
                    key={dimensionThree}
                    dimension={dimensionThree}
                    measures={measures}
                    salesTime={salesTime}
                    timePeriod={timePeriod}
                    filters={filters}
                    onFilter={onFilter}
                    baseFilters={baseFilters}
                    metric={metric}
                    color={color}
                    subtitleOne={t(dimensionThree)}
                    hideFilterDrawer={true}
                    barChartOptions={barChartOptions(t(dimensionThree))}
                    chartTotals={false}
                    doubleFilterDrawer={true}
                    multipleChartOperator={operator}
                />
            </Wrapper>
        </React.Fragment>
    );
};

const Wrapper = styled('div')`
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    background: ${({ theme }) => theme.colors.white};
    padding-right: ${({ theme }) => theme.space.single};
`;

const DoubleSize = styled('div')`
    grid-column: span 2;
`;

const IconWrapper = styled('span')`
    position: absolute;
    right: 30px;
    top: 5px;
    display: flex;
    justify-content: space-around;
    font-size: 10px;
`;
