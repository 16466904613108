import React from 'react';
import { styled } from '@mui/material/styles';
import { formatCurrencyString } from 'utils/formatting/currency';
interface FormatCurrency {
    currency?: string;
    formattedAmount: string;
}

const FormatStyledCurrencyAmount: React.FunctionComponent<FormatCurrency> = ({
    currency,
    formattedAmount
}) => {
    let negativeAmount = false;
    let amount = formattedAmount;

    if (formattedAmount.includes('-')) {
        amount = formattedAmount.replace('-', '').trim();
        negativeAmount = true;
    }
    return (
        <div>
            <Currency>{currency && formatCurrencyString(currency)}</Currency>
            <Amount>
                {negativeAmount ? '-' : ''}
                {amount}
            </Amount>
        </div>
    );
};

export { FormatStyledCurrencyAmount };

const Currency = styled('span')`
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: normal;
`;

const Amount = styled('span')`
    font-size: ${({ theme }) => theme.fontSizes.header};
`;
