import React from 'react';
import { compose, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { LoadingContainer } from '../common/loader';
import { ASTable } from '../common/table';
import { getMetricTableData } from '../common/table/queries';
import {
    MetricTableProps,
    Row,
    Sorting,
    Pagination,
    OnChange,
    OnChangeParams,
    Direction
} from '../common/table/types';
import { prepareTablePage } from '../common/table/utils';
import { settingsStore } from 'stores/settings';
import { DimensionData } from 'utils/common/types';
import { cubejsApi } from 'utils/api/CubeAPI';
import { DESC, TRANSACTIONS_ITEMCOUNT } from 'utils/common/constants';

const MetricTable = ({
    dimensions,
    measures,
    salesTime,
    tableColumns,
    onFilter,
    metric,
    timePeriod,
    filters,
    baseFilters,
    sortedData,
    preparedTableData,
    setCsvTableData,
    operator
}: MetricTableProps) => {
    const { t } = useTranslation();
    const { tenantTimezone } = React.useContext(settingsStore);

    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [orderBy, setOrderBy] = React.useState<string>(
        metric === TRANSACTIONS_ITEMCOUNT ? 'tickets' : 'revenue'
    );
    const [orderDirection, setOrderDirection] = React.useState<Direction>(DESC);
    const [rawData, setRawData] = React.useState<DimensionData[]>([]);
    const [tableData, setTableData] = React.useState<Row[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [sortedTableData, setSortedTableData] = React.useState([]);

    React.useEffect(() => {
        setLoading(true);
        cubejsApi
            .load(
                getMetricTableData(
                    dimensions,
                    measures,
                    salesTime,
                    timePeriod,
                    filters,
                    baseFilters,
                    tenantTimezone
                )
            )
            .then(
                compose(
                    setRawData,
                    (data) => data,
                    pathOr<DimensionData[]>([], ['loadResponse', 'results', '0', 'data'])
                )
            )
            .finally(() => {
                setLoading(false);
            });
        setPage(0);
    }, [salesTime, timePeriod, filters, metric, baseFilters]);

    React.useEffect(() => {
        setOrderBy(metric === TRANSACTIONS_ITEMCOUNT ? 'tickets' : 'revenue');
    }, [metric]);

    React.useEffect(() => {
        const data = sortedData(rawData);
        setSortedTableData(data);
        setCsvTableData && setCsvTableData(data as DimensionData[]);
    }, [rawData, t]);

    React.useEffect(() => {
        setTableData(
            prepareTablePage(
                preparedTableData(sortedTableData),
                orderBy,
                orderDirection,
                page,
                rowsPerPage
            )
        );
    }, [sortedTableData, orderBy, orderDirection, page, rowsPerPage]);

    const sorting: Sorting = {
        orderBy,
        orderDirection
    };

    const pagination: Pagination = {
        page,
        rowsPerPage,
        rowsPerPageOptions: [10, 15, 20],
        totalRowCount: sortedTableData.length
    };

    const onchangePage: OnChange = ({
        newPage = page,
        newRowsPerPage = rowsPerPage,
        newOrderBy = orderBy,
        newOrderDirection = orderDirection
    }: OnChangeParams) => {
        setTableData(
            prepareTablePage(
                preparedTableData(sortedTableData),
                newOrderBy,
                newOrderDirection,
                newPage,
                newRowsPerPage
            )
        );

        if (newPage !== page) {
            setPage(newPage);
        }
        if (newRowsPerPage !== rowsPerPage) {
            setRowsPerPage(newRowsPerPage);
        }
        if (newOrderBy !== orderBy) {
            setOrderBy(newOrderBy);
        }
        if (newOrderDirection !== orderDirection) {
            setOrderDirection(newOrderDirection);
        }
    };

    const headers = tableColumns(t);

    return (
        <TableWrapper>
            <ASTable
                headers={headers}
                data={tableData}
                rowClick={(row: Row) => {
                    const value = row[dimensions[0]];
                    onFilter({
                        type: 'dimensionClick',
                        operator,
                        dimension: dimensions[0],
                        value: {
                            value: value,
                            label: value
                        }
                    });
                }}
                isSelected={(row: Row) => {
                    const filter = filters.find((filter) => filter.dimension === dimensions[0]);
                    const selected = Boolean(
                        ((filter && filter.values) || []).find((filterValue) =>
                            typeof filterValue !== 'string' && typeof filterValue !== 'number'
                                ? filterValue.value === row[dimensions[0]]
                                : filterValue === row[dimensions[0]]
                        )
                    );
                    return !filter ? true : operator === 'notEquals' ? !selected : selected;
                }}
                showPagination={true}
                sorting={sorting}
                pagination={pagination}
                onChange={onchangePage}
                loading={loading}
            />
            {loading && <LoadingContainer />}
        </TableWrapper>
    );
};

export { MetricTable };

const TableWrapper = styled('div')`
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    padding-left: ${({ theme }) => theme.space.single};
`;
