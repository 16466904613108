/* eslint-disable @typescript-eslint/naming-convention */
import React, {
    MutableRefObject,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsCustomEvents from 'highcharts-custom-events';
import exporting from 'highcharts/modules/exporting';
import csvExporting from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
//@ts-ignore
HighchartsCustomEvents(Highcharts);
exporting(Highcharts);
csvExporting(Highcharts);
import { pathOr, prop, mergeDeepLeft } from 'ramda';
import { useTheme } from '@mui/material/styles';
import { isNumber, TooltipFormatterContextObject } from 'highcharts';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { DateFormatContext } from '../common/datepicker';
import { sizes } from '../responsive/MediaQuery';
import { BarChartProps, HighchartTooltipChart } from './types';
import { formatDecimalPercent, formatWholeNumber } from 'utils/formatting/numbers';

import { tooltipFormatter } from 'utils/formatting/tooltips/tooltips';

import { CHART_CAP } from 'utils/common/constants';

import { getTooltipTopPositionAdjustment } from 'utils/chartUtils';
import { settingsStore } from 'stores/settings';

const perRedraw = function (this: ChartWithTimeout) {
    // @ts-ignore
    const xPosition = this.containerWidth - this.spacing[1];
    const scrollbar = this.container.querySelector('.highcharts-scrollbar');
    if (scrollbar) {
        scrollbar.setAttribute('style', `transform: translate(${xPosition}px,31px)`);
    }
};

const BarChart = ({
    series,
    barOptions = {},
    onBarClick,
    subtitleTwo,
    chartCallback,
    metric,
    exportFileName,
    customBars = false,
    immutable = false
}: BarChartProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const chartRightMargin = 170;
    const chartRef: any = useRef(null);
    const { formatTooltipHeader } = useContext(DateFormatContext);
    const { focusedSegments } = useContext(settingsStore);

    const [totalValues, setTotalValues] = useState<number>(0);

    const visibleSeries = useCallback(() => {
        if (!focusedSegments) {
            return series;
        }
        if (!series || series.length === 0 || (series[0] as any).data.length === 0) {
            return series;
        } else {
            return [
                {
                    ...(series[0] as any),
                    data: (series[0] as any).data
                        .filter((d1: any) => !d1.custom?.hide)
                        .map((d2: any, index: number) => ({ ...d2, x: index }))
                }
            ];
        }
    }, [focusedSegments, series]);

    useEffect(() => {
        let _totalValues = 0;
        const mySeries = visibleSeries();

        if (
            mySeries &&
            mySeries.length > 0 &&
            //@ts-ignore
            mySeries[0].data
        ) {
            //@ts-ignore
            const data = mySeries[0].data;
            //@ts-ignore
            const yData = mySeries[0].yData;
            if (data.length > 0) {
                _totalValues = data[0]?.custom?.totals
                    ? data[0].custom.totals
                    : !yData
                      ? 0
                      : yData.reduce((prev: number, curr: number) => {
                            prev += Math.abs(curr);
                            return prev;
                        }, 0);
            }
        }
        setTotalValues(_totalValues);
    }, [series, visibleSeries]);

    const [options, setOptions] = React.useState<Highcharts.Options>(
        mergeDeepLeft(barOptions, {
            chart: {
                type: 'bar',
                marginRight: chartRightMargin,
                height: 272,
                spacing: [30, 14, 15, 14],
                style: {
                    fontFamily: `12px ${theme.fonts.primaryFont}`,
                    '& .highcharts-scrollbar-arrow': 'transform: translateX(-2px);'
                },
                events: {
                    redraw: perRedraw,
                    render: perRedraw
                }
            },
            title: {
                text: ''
            },
            plotOptions: {
                series: {
                    animation: {
                        duration: 0
                    },
                    dataLabels: {
                        enabled: false
                    },
                    cursor: 'pointer',
                    turboThreshold: pathOr([], ['0', 'data'], series).length,
                    point: {
                        events: {
                            //@ts-ignore
                            click: function (
                                this: Highcharts.Series,
                                ev: Highcharts.SeriesClickEventObject
                            ) {
                                return onBarClick.current.apply(this, [ev]);
                            }
                        }
                    },
                    groupPadding: 0,
                    pointPadding: 0,
                    borderWidth: 0,
                    maxPointWidth: 21,
                    pointWidth: 21
                },
                plotOptions: {
                    bar: {
                        events: {
                            click(this: Highcharts.Series, ev: Highcharts.SeriesClickEventObject) {
                                ev.stopPropagation();
                                onBarClick.current.apply(this, [ev]);
                            }
                        }
                    }
                }
            },
            navigator: {
                adaptToUpdatedData: false
            },
            xAxis: {
                type: 'category',
                showEmpty: false,
                title: {
                    align: 'high',
                    rotation: 0,
                    y: -20,
                    x: pathOr<Highcharts.Point[]>([], ['0', 'data'], series).length > 0 ? 230 : 20,
                    useHTML: true,
                    text: undefined,
                    style: {
                        fontSize: theme.fontSizes.small,
                        color: theme.colors.text,
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }
                },
                labels: {
                    x: 0,
                    y: 4,
                    align: 'right',
                    step: 1,
                    enabled: true,
                    style: {
                        whiteSpace: 'nowrap',
                        color: theme.colors.text,
                        fontSize: theme.fontSizes.small
                    },
                    useHTML: true,
                    //@ts-ignore
                    events: {
                        click: function (this: any, event: Event) {
                            event.stopPropagation();
                            const point = {
                                point: this.chart.series[0].data[this.pos]
                            };

                            onBarClick.current(point as Highcharts.SeriesClickEventObject);
                        },
                        mouseover: function (this: any) {
                            const pointPos = this.pos;
                            const seriesPoints = this.chart.series[0].data;
                            const tooltip = this.chart.tooltip;
                            tooltip.refresh(seriesPoints[pointPos]);
                        }
                    }
                },
                min: 0,
                max: 4,
                minRange: 5,
                maxRange: 5,
                categories: []
            },
            scrollbar: {
                showFull: false,
                liveRedraw: true,
                barBackgroundColor: theme.colors.scrollBar,
                trackBackgroundColor: theme.colors.defaultFill,
                trackBorderWidth: 1,
                buttonBackgroundColor: theme.colors.defaultFill,
                buttonBorderWidth: 1,
                buttonBorderColor: theme.colors.defaultFill,
                buttonArrowColor: theme.colors.scrollBar
            },
            tooltip: {
                enabled: false,
                outside: true,
                useHTML: true,
                positioner(this: Highcharts.Tooltip, labelWidth, labelHeight) {
                    const chartPosition = this.chart.pointer.getChartPosition();
                    const chart = this.chart as HighchartTooltipChart;
                    const chartContainerWidth = chart.containerWidth / 2;
                    const adjustment = getTooltipTopPositionAdjustment(
                        this.chart.container,
                        labelHeight
                    );
                    return {
                        x: chartPosition.left + chartContainerWidth - labelWidth / 2,
                        y: chartPosition.top - labelHeight + adjustment
                    };
                }
            },
            yAxis: {
                reversedStacks: false,
                startOnTick: false,
                endOnTick: false,
                maxPadding: 0,
                minPadding: 0,
                softMin: 0,
                title: {
                    text: ''
                },
                labels: {
                    style: {
                        color: theme.colors.text,
                        fontSize: theme.fontSizes.small
                    }
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false,
                filename: exportFileName
            },
            series: []
        }) as Highcharts.Options
    );

    useEffect(() => {
        const mySeries = visibleSeries();
        const data = pathOr([], ['0', 'data'], mySeries);
        const dataLength = data.length;
        const min = data.reduce(
            (previous, value: Highcharts.Point) => Math.min(previous, Number(value.y)),
            0
        );
        const max = data.reduce(
            (previous, value: Highcharts.Point) => Math.max(previous, Number(value.y)),
            1
        );

        const percentageColumnName = t('percentage');

        const newOptions = mergeDeepLeft<Highcharts.Options, Highcharts.Options>(
            {
                xAxis: {
                    categories: pathOr<Highcharts.Point[]>([], ['0', 'data'], mySeries).map(
                        (d) => d.category
                    ),
                    max: Math.max(Math.min(dataLength - 1, CHART_CAP - 1), customBars ? 9 : 6),
                    labels: {
                        formatter() {
                            const chart: any = this.chart;
                            const chartWidth =
                                // @ts-ignore
                                chart.containerWidth - this.chart.spacing[1] - chart.spacing[3];
                            const pointLabelValue: any = this.value;

                            // @ts-ignore
                            const pointValue = chart.series[0].yData[this.pos];
                            if (isNumber(pointLabelValue)) {
                                return '';
                            }

                            let _totalValues = totalValues;
                            // @ts-ignore
                            const yData = chart.series[0].yData;

                            if (_totalValues === 0 && yData) {
                                _totalValues = yData.reduce((prev: number, curr: number) => {
                                    prev += Math.abs(curr);
                                    return prev;
                                }, 0);
                            }

                            const pointValuePercentage = formatDecimalPercent(t)(1, 1)(
                                Math.abs(pointValue) / _totalValues
                            );

                            return `
              <div style="width: ${chartWidth}px; display: flex; justify-content: space-between; cursor: pointer; position: absolute; left: 0px;">
                <span style="padding-left: ${theme.space.single}; max-width: ${
                    chartWidth - chartRightMargin
                }px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; position: relative;">
                  ${pointLabelValue}
                </span>
                <div style="width: ${
                    chartRightMargin - 10
                }px; position: absolute; right: 0px; padding-right: ${
                    theme.space.single
                }; display: flex">
                  <span style="flex: 35%; text-align: right">
                    ${pointValue ? formatWholeNumber(pointValue) : t('N/A')}
                  </span>    
                  <span style="flex: 65%; text-align: right">
                  ${pointValuePercentage ? `${pointValuePercentage}` : t('N/A')}
                  </span> 
                </div>
              </div>
            `;
                        }
                    }
                },
                scrollbar: {
                    enabled: dataLength <= CHART_CAP ? false : true
                },
                tooltip: {
                    formatter(this: TooltipFormatterContextObject) {
                        return tooltipFormatter(t, formatTooltipHeader)(this, '', metric);
                    }
                },
                title: {
                    useHTML: true,
                    text: `
          <div>
            <div style="width: ${
                chartRightMargin - 10
            }px; position: absolute; right: 0px; padding-right: ${
                theme.space.single
            }; display: flex; font-weight: bold;">
              <div style="flex: 35%; text-align: right;">
                ${subtitleTwo || ''}
              </div>
              <div style="flex: 65%; text-align: right;">
                ${subtitleTwo ? percentageColumnName : ''}
              </div>
            </div>
          </div>`,
                    align: 'right',
                    y: -9,
                    style: {
                        fontSize: theme.fontSizes.normal,
                        color: theme.colors.text,
                        fontWeight: 'bold',
                        right: '0px'
                    }
                },
                yAxis: { min, max },
                exporting: {
                    csv: {
                        columnHeaderFormatter: function (
                            item: Highcharts.SeriesBarOptions[] | Highcharts.Options['series'],
                            key: 'x' | 'y'
                        ) {
                            if (!item || item instanceof Highcharts.Axis) {
                                return pathOr('', ['0', 'name'], mySeries);
                            }
                            return { columnTitle: key === 'y' ? subtitleTwo : key };
                        }
                    }
                },
                series: mySeries
            },
            options
        );
        const mergedOptions = mergeDeepLeft(barOptions, newOptions) as Highcharts.Options;
        setOptions(mergedOptions);
    }, [series, totalValues, focusedSegments, visibleSeries]);

    const handleChartCallback = React.useRef((chart: Highcharts.Chart) => {
        enableScrollWithMouseWheel(chart);
        chartCallback && chartCallback(chartRef as MutableRefObject<{ chart: Highcharts.Chart }>);
    });

    return (
        <Wrapper>
            <HighchartsReact
                ref={chartRef}
                constructorType={'chart'}
                highcharts={Highcharts}
                options={options}
                callback={handleChartCallback.current}
                immutable={focusedSegments ? true : immutable}
            />
        </Wrapper>
    );
};

const Wrapper = styled('div')`
    min-width: 0;

    @media (max-width: ${sizes.notebook.minWidth}px) {
        & .highcharts-scrollbar-arrow {
            transform: translateX(-5px);
        }
    }
`;

const enableScrollWithMouseWheel = (chart: Highcharts.Chart) => {
    // Add the mousewheel event
    Highcharts.addEvent(
        chart.container,
        prop<any, Document>('onmousewheel', document) === undefined
            ? 'DOMMouseScroll'
            : 'mousewheel',
        function (event: MouseEvent) {
            const SCROLL_WIDTH = 50;
            // Only scroll the chart if the mouse cursor is less than SCROLL_WIDTH pixels away from the right border.
            // Otherwise, scroll the entire page:
            if (
                (event.target as HTMLElement).getBoundingClientRect().width - event.offsetX <
                SCROLL_WIDTH
            ) {
                const axis = chart.xAxis[0];
                const dataMin = Number(prop<any, Highcharts.Axis>('dataMin', axis) ?? 0);
                const dataMax = Number(prop<any, Highcharts.Axis>('dataMax', axis) ?? 0);
                const extr = axis.getExtremes();
                const e = chart.pointer.normalize(event);
                // Firefox uses e.detail, WebKit and IE uses wheelDelta
                let delta =
                    e.detail || -(prop<any, Highcharts.PointerEventObject>('wheelDelta', e) / 120);
                delta = delta < 0 ? -1 : 1;
                const step = 1 * delta;

                const newMin = extr.min + step;
                const newMax = extr.max + step;

                if (
                    e.chartX < chart.chartWidth &&
                    e.chartY < chart.chartHeight &&
                    newMin >= dataMin &&
                    newMax <= dataMax
                ) {
                    axis.setExtremes(newMin, newMax, true, false);
                }

                event && event.preventDefault && event.preventDefault();
                event && event.stopPropagation && event.stopPropagation();
                return false;
            }
        }
    );
};

interface ChartWithTimeout extends Highcharts.Chart {
    isRedrawingExtent?: any;
}

export { BarChart };
