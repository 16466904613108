import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { ASLink } from '../common/link/ASLink';
import { customerButtonHandler } from '../global-search/SearchResults/utils';
import { ASButton } from '../common/button';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { settingsStore } from 'stores/settings';

interface SearchResultTileProps {
    thumbnail?: string;
    label: string;
    left: string[];
    right: string[];
    href: string;
    customerButton?: boolean;
    entityRef?: string;
    styledFor?: string;
    index: number;
}

const SearchResultsTile = ({
    thumbnail,
    label,
    left,
    right,
    href,
    customerButton,
    entityRef,
    styledFor,
    index
}: SearchResultTileProps) => {
    const { t } = useTranslation();
    const { pii } = React.useContext(settingsStore);
    const renderLine = (line: string, index: number) => (
        <Line key={index}>{t(line.replace(':', '_').toLowerCase(), line)}</Line>
    );
    const renderLines = (lines: string[]) => (
        <React.Fragment>{lines.map(renderLine)}</React.Fragment>
    );
    return (
        <React.Fragment>
            {styledFor === 'segmentBuilder' ? (
                <SegmentBuilderHitWrapper>
                    <SegmentBuilderImage thumbnail={thumbnail} />
                    <SegmentBuilderInfoWrapperLeft index={index}>
                        <div>
                            <Line>
                                <SegmentBuilderTitle
                                    href={href}
                                    text={t(label)}
                                    underline={false}
                                    styledFor={styledFor}>
                                    {t(label)}
                                </SegmentBuilderTitle>
                            </Line>
                            <SegmentBuilderDateTime key={'date/email'}>
                                {left}
                            </SegmentBuilderDateTime>
                        </div>
                        <div>
                            <SegmentBuilderInfoWrapperRight>
                                {renderLines(right)}
                            </SegmentBuilderInfoWrapperRight>
                            <SegmentBuilderExportButtonWrapper>
                                {customerButton && pii ? (
                                    <ASButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => customerButtonHandler(entityRef!)}
                                        background="transparent">
                                        <CustomSvgIcon iconName={'export'} />
                                    </ASButton>
                                ) : null}
                            </SegmentBuilderExportButtonWrapper>
                        </div>
                    </SegmentBuilderInfoWrapperLeft>
                </SegmentBuilderHitWrapper>
            ) : (
                <HitWrapper>
                    <Image thumbnail={thumbnail} />
                    <InfoWrapperLeft>
                        <Line>
                            <Title
                                href={href}
                                text={t(label)}>
                                {t(label)}
                            </Title>
                        </Line>
                        <Line key={'date/email'}>{left}</Line>
                    </InfoWrapperLeft>
                    <InfoWrapperRight>{renderLines(right)}</InfoWrapperRight>
                    <ExportButtonWrapper>
                        {customerButton && pii ? (
                            <ASButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={() => customerButtonHandler(entityRef!)}
                                background="transparent">
                                <CustomSvgIcon iconName={'export'} />
                            </ASButton>
                        ) : null}
                    </ExportButtonWrapper>
                </HitWrapper>
            )}
        </React.Fragment>
    );
};

const HitWrapper = styled('div')`
    display: grid;

    background-color: ${({ theme }) => theme.colors.white};

    grid-template-areas: 'image infoLeft infoRight exportButton';
    grid-template-columns: 50px 3fr 1fr 50px;
    grid-gap: ${({ theme }) => theme.space.single};

    font-size: ${({ theme }) => theme.fontSizes.normal};
    padding: ${({ theme }) => theme.space.half} ${({ theme }) => theme.space.double};

    &:hover {
        background-color: ${({ theme }) => theme.colors.background};
    }
`;

const Title = styled(ASLink)`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: bold;
    max-width: 200px;
`;

const InfoWrapperLeft = styled('div')`
    grid-area: infoLeft;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.space.half};
    padding-bottom: 2px;
`;

const InfoWrapperRight = styled('div')`
    grid-area: infoRight;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-top: ${({ theme }) => theme.space.half};
    padding-bottom: 2px;
`;

const ExportButtonWrapper = styled('div')`
    grid-area: exportButton;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.space.half};
    padding-bottom: 2px;
`;

const Line = styled('div')`
    line-height: 1;
`;

type ImageProps = { thumbnail: string | undefined };
const Image = styled('div')<ImageProps>`
    grid-area: image;
    display: flex;
    flex-shrink: 0;
    align-self: center;
    width: 50px;
    height: 50px;
    background: ${({ thumbnail, theme }) =>
        thumbnail ? `url(${thumbnail}) no-repeat center center ` : theme.colors.positive};
    background-size: cover;
`;

const SegmentBuilderImage = styled('div')<ImageProps>`
    grid-area: image;
    display: flex;
    flex-shrink: 0;
    align-self: center;
    width: 40px;
    height: 41px;
    background: ${({ thumbnail, theme }) =>
        thumbnail ? `url(${thumbnail}) no-repeat center center ` : theme.colors.positive};
    background-size: cover;
    border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const SegmentBuilderHitWrapper = styled('div')`
    display: grid;

    background-color: ${({ theme }) => theme.colors.white};

    grid-template-areas: 'image infoLeft';
    grid-template-columns: 50px auto;
    grid-gap: ${({ theme }) => theme.space.half};

    font-size: ${({ theme }) => theme.fontSizes.normal};
    padding: 0 ${({ theme }) => theme.space.single};
    margin-bottom: 5px;
`;

const SegmentBuilderInfoWrapperLeft = styled('div')<{ index: number }>`
    grid-area: infoLeft;
    display: grid;
    grid-template-columns: 220px auto;
    gap: ${({ theme }) => theme.space.single};
    padding-top: ${({ theme }) => theme.space.half};
    padding-bottom: 2px;
    padding-left: 9px;
    background-color: ${({ theme, index }) =>
        index % 2 === 0 ? theme.colors.white : theme.colors.segmentBuilderBackground};
`;

const SegmentBuilderTitle = styled(ASLink)`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    max-width: 220px;
    height: 20px;
    color: ${({ theme }) => theme.colors.segmentBuilderText};
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
`;

const SegmentBuilderDateTime = styled('div')`
    height: 20px;
    color: ${({ theme }) => theme.colors.segmentBuilderCancelIcon};
    font-size: 10px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
`;

const SegmentBuilderExportButtonWrapper = styled('div')`
    padding-top: ${({ theme }) => theme.space.half};
    padding-bottom: 2px;
    position: absolute;
    top: 10px;
    right: 40px;
`;

const SegmentBuilderInfoWrapperRight = styled('div')`
    position: relative;
    top: 11px;
    width: 50px;
    height: 20px;
    color: ${({ theme }) => theme.colors.segmentBuilderCancelIcon};
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
`;

export { SearchResultsTile };
