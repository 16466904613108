import { DimensionData } from 'utils/common/types';

export const bucketValuesSorting = (
    order: string[],
    rawData: DimensionData[],
    sortingValue: string,
    metric: string
) => {
    let sortedAges = order.map((orderItem) =>
        rawData.find((rawItem) => rawItem[sortingValue] === orderItem)
    ) as DimensionData[];
    sortedAges = sortedAges.filter((element) => element !== undefined && element[metric] !== '0');
    return sortedAges;
};
