import { DateRange } from '../../common/datepicker';
import { formatCubeQueryDate } from '../../common/datepicker/dateUtils';

import {
    INVENTORY_OVER_TIME_COUNT,
    INVENTORY_OVER_TIME_OCCURRED_AT,
    INVENTORY_OVER_TIME_PRODUCT,
    INVENTORY_OVER_TIME_STATUS
} from 'utils/common/constants';
import { ASCubeQuery } from 'utils/common/types';

export const getSoldInPeriodQuery = (
    salesTime: string,
    timePeriod: DateRange,
    entityRef: string,
    tenantTimezone: string
): ASCubeQuery => ({
    dimensions: [
        INVENTORY_OVER_TIME_OCCURRED_AT,
        INVENTORY_OVER_TIME_PRODUCT,
        INVENTORY_OVER_TIME_STATUS
    ],
    measures: [INVENTORY_OVER_TIME_COUNT],
    timeDimensions: [
        {
            dimension: salesTime,
            dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string]
        }
    ],
    filters: [
        {
            member: INVENTORY_OVER_TIME_PRODUCT,
            operator: 'equals',
            values: [entityRef]
        }
    ],
    timezone: tenantTimezone
});
