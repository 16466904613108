import { TimeDimensionGranularity } from '@cubejs-client/core';

import { DateRange } from '../datepicker/types';
import { formatCubeQueryDate } from '../datepicker/dateUtils';
import { ASCubeFilter, ASCubeQuery } from 'utils/common/types';
import {
    CUSTOMERS_COUNTRY,
    CUSTOMERS_CITY,
    CUSTOMERS_LABEL,
    TRANSACTIONS_CURRENCY,
    TRANSACTIONS_HALL_LABEL,
    TRANSACTIONS_ITEMCOUNT,
    TRANSACTIONS_PRODUCT_LABEL,
    TRANSACTIONS_PRODUCT_TIME_BEGINS,
    TRANSACTIONS_REVENUE,
    TRANSACTIONS_BUYER_REF,
    BASE_TRANSACTIONS_ORDER_REF,
    BASE_TRANSACTIONS_PRODUCT_REF,
    BASE_TRANSACTIONS_OCCURRED_AT,
    BASE_TRANSACTIONS_CURRENCY,
    BASE_TRANSACTIONS_REVENUE,
    BASE_TRANSACTIONS_ITEM_COUNT,
    BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT,
    DONATIONS_FUND,
    MERCHANDISE_PRODUCT_LABEL,
    MERCHANDISE_PRODUCT_DESCRIPTION,
    TICKETS_ROW,
    TICKETS_SECTION,
    TICKETS_SEAT,
    ORDER_EXTERNAL_DATA_ID
} from 'utils/common/constants';

export const getCustomersTableData = (
    salesTime: string,
    timePeriod: DateRange,
    filters: ASCubeFilter[],
    baseFilters: ASCubeFilter[],
    tenantTimezone: string,
    granularity?: TimeDimensionGranularity
): ASCubeQuery => ({
    dimensions: [
        CUSTOMERS_LABEL,
        CUSTOMERS_COUNTRY,
        CUSTOMERS_CITY,
        TRANSACTIONS_CURRENCY,
        TRANSACTIONS_BUYER_REF,
        TICKETS_SECTION,
        TICKETS_ROW,
        TICKETS_SEAT
    ],
    measures: [TRANSACTIONS_ITEMCOUNT, TRANSACTIONS_REVENUE],
    timeDimensions: [
        {
            dimension: salesTime,
            dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string],
            granularity: granularity
        }
    ],
    filters: filters.concat(baseFilters),
    timezone: tenantTimezone
});

export const getMetricTableData = (
    dimensions: string[],
    measures: string[],
    salesTime: string,
    timePeriod: DateRange,
    filters: ASCubeFilter[],
    baseFilters: ASCubeFilter[],
    tenantTimezone: string,
    granularity?: TimeDimensionGranularity
): ASCubeQuery => ({
    dimensions: dimensions,
    measures: measures,
    timeDimensions: [
        {
            dimension: salesTime,
            dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string],
            granularity: granularity
        }
    ],
    filters: filters.filter((d) => !dimensions.includes(d.dimension || '')).concat(baseFilters),
    timezone: tenantTimezone
});

export const getEventStatisticTableDataEvents = (
    salesTime: string,
    timePeriod: DateRange,
    filters: ASCubeFilter[],
    baseFilters: ASCubeFilter[],
    tenantTimezone: string,
    granularity?: TimeDimensionGranularity
): ASCubeQuery => ({
    dimensions: [
        BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT,
        BASE_TRANSACTIONS_ORDER_REF,
        BASE_TRANSACTIONS_OCCURRED_AT,
        BASE_TRANSACTIONS_CURRENCY,
        ORDER_EXTERNAL_DATA_ID,
        TRANSACTIONS_PRODUCT_LABEL,
        TRANSACTIONS_PRODUCT_TIME_BEGINS,
        TRANSACTIONS_HALL_LABEL,
        BASE_TRANSACTIONS_PRODUCT_REF
    ],
    measures: [BASE_TRANSACTIONS_ITEM_COUNT, BASE_TRANSACTIONS_REVENUE],
    timeDimensions: [
        {
            dimension: salesTime,
            dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string],
            granularity: granularity
        }
    ],
    filters: filters.concat(baseFilters).concat({
        operator: 'equals',
        dimension: BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT,
        values: ['Event']
    }),
    timezone: tenantTimezone
});

export const getEventStatisticTableDataDonations = (
    salesTime: string,
    timePeriod: DateRange,
    filters: ASCubeFilter[],
    baseFilters: ASCubeFilter[],
    tenantTimezone: string,
    granularity?: TimeDimensionGranularity
): ASCubeQuery => ({
    dimensions: [
        BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT,
        BASE_TRANSACTIONS_ORDER_REF,
        BASE_TRANSACTIONS_OCCURRED_AT,
        BASE_TRANSACTIONS_CURRENCY,
        ORDER_EXTERNAL_DATA_ID,
        DONATIONS_FUND,
        BASE_TRANSACTIONS_PRODUCT_REF
    ],
    measures: [BASE_TRANSACTIONS_ITEM_COUNT, BASE_TRANSACTIONS_REVENUE],
    timeDimensions: [
        {
            dimension: salesTime,
            dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string],
            granularity: granularity
        }
    ],
    filters: filters.concat(baseFilters).concat({
        operator: 'equals',
        dimension: BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT,
        values: ['Fund']
    }),
    timezone: tenantTimezone
});

export const getEventStatisticTableDataProducts = (
    salesTime: string,
    timePeriod: DateRange,
    filters: ASCubeFilter[],
    baseFilters: ASCubeFilter[],
    tenantTimezone: string,
    granularity?: TimeDimensionGranularity
): ASCubeQuery => ({
    dimensions: [
        BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT,
        BASE_TRANSACTIONS_ORDER_REF,
        BASE_TRANSACTIONS_OCCURRED_AT,
        BASE_TRANSACTIONS_CURRENCY,
        ORDER_EXTERNAL_DATA_ID,
        MERCHANDISE_PRODUCT_LABEL,
        MERCHANDISE_PRODUCT_DESCRIPTION,
        BASE_TRANSACTIONS_PRODUCT_REF
    ],
    measures: [BASE_TRANSACTIONS_ITEM_COUNT, BASE_TRANSACTIONS_REVENUE],
    timeDimensions: [
        {
            dimension: salesTime,
            dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string],
            granularity: granularity
        }
    ],
    filters: filters.concat(baseFilters).concat({
        operator: 'equals',
        dimension: BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT,
        values: ['Merchandise', 'Refreshments']
    }),
    timezone: tenantTimezone
});
