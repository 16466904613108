import { TFunction } from 'i18next';
import { Maybe } from '../maybe';
import { formatCurrencyString } from 'utils/formatting/currency';

const DEFAULT_LOCALE = 'en-US';
const DEFAULT_MAX_DECIMAL_PLACES = 2;
const DEFAULT_MIN_DECIMAL_PLACES = 0;

let locale = ''; // Examples: 'en-US', 'en-IS', etc

/**
 * Validates the number of decimal places to be used in a Intl number formatter. Valid numbers are
 * 0, 1, ..., 20. If the number is one of those we return it back. If the number is something else
 * we return a default number. We do this to guard against range errors spit out by Intl.
 *
 * See minimumFractionDigits and maximumFractionDigits at:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat#Parameters
 */
function validatedDecimal(decimalPlaces: number): number {
    const isValid = Number.isInteger(decimalPlaces) && decimalPlaces >= 0 && decimalPlaces <= 20;
    return isValid ? decimalPlaces : DEFAULT_MAX_DECIMAL_PLACES;
}

/**
 * Returns the locale string if set by the user's browser, uses a default if not set or found.
 *
 * We assume the browser locale string doesn't change much. Therefor we cache the locale string
 * to prevent multiple object instantiations of Intl.NumberFormat(). This is beneficial since the
 * number formatting function me be called quite often.
 */
function getBrowserLocaleString(): string {
    if (locale === '') {
        const browserLocale = new Intl.NumberFormat().resolvedOptions().locale;
        locale = Maybe(browserLocale).getOrElse(DEFAULT_LOCALE);
    }

    return locale;
}

/**
 * Rounds and formats a given number as string in the locale of the user's browser. Provides
 * optional manual control over how we handle decimals. The default behaviour is to format to 2
 * decimals but use as few decimal points as possible. For example 1337 and 1337.00 would both
 * become '1337'.
 *
 * If the desired outcome is a set and consistent number of decimal places (for example for use in
 * a table column) use formatDecimalNumber() instead.
 *
 * Examples in 'en-US' locale:
 *
 *    formatNumber(1337)      // '1,337'
 *    formatNumber(42.66666)  // '42.67'
 *    formatNumber(42.69, 2)  // '42.7', not '42.70' because the 0 doesn't add anything
 *    formatNumber(1.0001, 3) // '1'
 *
 */
export function formatNumber(
    number: number,
    maxDecimalPlaces = DEFAULT_MAX_DECIMAL_PLACES,
    minDecimalPlaces = DEFAULT_MIN_DECIMAL_PLACES
): string {
    // Intl formats null as '0'. Be consistent with undefined and return 'NaN' instead
    if (number == null || isNaN(number)) {
        return 'NaN';
    }

    if (number === -0) {
        number = 0;
    }

    const formatterOptions: Intl.NumberFormatOptions = {
        maximumFractionDigits: validatedDecimal(maxDecimalPlaces),
        minimumFractionDigits: validatedDecimal(minDecimalPlaces)
    };

    const numberFormatter = new Intl.NumberFormat(getBrowserLocaleString(), formatterOptions);

    return numberFormatter.format(number);
}

/**
 * Rounds and formats a given number as string in the locale of the user's browser with exactly as
 * many decimal places as specified.
 *
 * Examples in 'en-US' locale:
 *    formatDecimalNumber(1337)       // '1,337.00'
 *    formatDecimalNumber(42.69, 2)   // '42.69'
 *    formatDecimalNumber(42.699, 2)  // '42.70'
 */
export function formatDecimalNumber(
    number: number,
    maxDecimalPlaces = DEFAULT_MAX_DECIMAL_PLACES,
    minDecimalPlaces = DEFAULT_MIN_DECIMAL_PLACES
): string {
    return formatNumber(number, maxDecimalPlaces, minDecimalPlaces);
}

/**
 * Rounds a number to the nearest integer and formats it as string in the locale of the user's
 * browser.
 *
 * Examples in 'en-US' locale:
 *    formatWholeNumber(1337.00)    // '1,337'
 *    formatWholeNumber(1337.49)   	// '1,337'
 *    formatWholeNumber(1337.50)  	// '1,338'
 */
export function formatWholeNumber(number: number): string {
    return formatNumber(number, 0, 0);
}

export function formatPercent(
    number: number,
    t: TFunction,
    minimumFractionDigits?: number
): string {
    if (!isFinite(number)) {
        return `${t('N/A')}`;
    }
    return Number(number / 100).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: minimumFractionDigits !== undefined ? minimumFractionDigits : 2
    });
}

export const formatDecimalPercent =
    (t: any) =>
    (max = 2, min = 0) =>
    (number: number) => {
        if (!isFinite(number)) {
            return t('N/A');
        }
        return `${formatDecimalNumber(number * 100, max, min)}%`;
    };

export const nFormatter = (valueRound: any, currency = '', formatWhole?: boolean) => {
    const value =
        formatWhole && Math.abs(valueRound) <= 1e3
            ? Number(formatWholeNumber(valueRound))
            : valueRound;

    return Math.abs(value) > 1e3
        ? Math.abs(value) > 1e6
            ? `${formatCurrencyString(currency)}${
                  Math.sign(value) * +(Math.abs(value) / 1e6).toFixed(1)
              }M`
            : `${formatCurrencyString(currency)}${
                  Math.sign(value) * +(Math.abs(value) / 1e3).toFixed(1)
              }K`
        : `${formatCurrencyString(currency)}${Math.sign(value) * Math.abs(value)}`;
};
