import { TimeDimensionGranularity } from '@cubejs-client/core';
import { useTheme } from '@mui/material/styles';
import { debounce } from 'lodash';
import { compose, pathOr } from 'ramda';
import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from '../common/datepicker/types';
import { DateFormatContext } from '../common/datepicker';
import { formatCubeQueryDate } from '../common/datepicker/dateUtils';
import { LoadingContainer } from '../common/loader';
import { FilterDrawer } from '../filters';
import { ColumnChart } from './ColumnChart';
import {
    FadedChartMenuItemContainer,
    FadedChartAction,
    FadedChartMenuContainer
} from './fadedIcons';
import {
    CSV_EXPORT_ICON,
    IN_FILTER_ICON,
    NOT_IN_FILTER_ICON,
    PNG_EXPORT_ICON
} from 'assets/iconConstants';

import { cubejsApi } from 'utils/api/CubeAPI';
import {
    PRODUCTS_ENTITY_REF,
    PRODUCTS_INVENTORY_CAPACITY,
    PRODUCTS_TICKETS_HELD,
    PRODUCTS_TICKETS_KILLED,
    PRODUCTS_TICKETS_OPEN,
    PRODUCTS_TICKETS_RESERVED,
    PRODUCTS_TICKETS_SOLD,
    PRODUCTS_TIME_BEGINS
} from 'utils/common/constants';
import { ASCubeFilter, DimensionData } from 'utils/common/types';
import { pngExportHandler } from 'views/utils/pngExportHandler';

import { settingsStore } from 'stores/settings';

interface ProductInventoryByTimeBeginsProps {
    dashboardName: string;
    timePeriod: DateRange;
    filters: ASCubeFilter[];
    granularity: TimeDimensionGranularity;
    color: string;
}

interface InventoryData {
    begins: Date;
    capacity: number;
    sold: number;
    open: number;
    held: number;
    killed: number;
    reserved: number;
    oversold: string[];
    oversoldCount: number;
    missingCapacity: string[];
}

const ProductInventoryByTimeBegins = ({
    dashboardName,
    timePeriod,
    filters,
    granularity,
    color
}: ProductInventoryByTimeBeginsProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { formatDate } = React.useContext(DateFormatContext);
    const { tenantTimezone } = React.useContext(settingsStore);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [rawData, setRawData] = React.useState<InventoryData[]>([]);
    const [series, setSeries] = React.useState<Highcharts.SeriesColumnOptions[]>([]);
    const [exportGraph, setExportGraph] = React.useState<JSX.Element | null>(null);
    const [chart, setChart] = React.useState<MutableRefObject<{ chart: Highcharts.Chart }>>();
    const [stacked, setStacked] = React.useState<boolean>(true);
    const [columnOptions, setColumnOptions] = React.useState<Highcharts.Options>({});
    const chartId = 'product_inventory_overtime';

    const exportFileName = `${dashboardName}-${t('inventoryOverTime')}-${timePeriod
        .map((date) => formatDate(new Date(date)))
        .join('-')}`;

    const getData = React.useRef(
        debounce(
            (
                timePeriod: DateRange,
                filters: ASCubeFilter[],
                granularity: TimeDimensionGranularity
            ) => {
                setLoading(true);
                cubejsApi
                    .load({
                        dimensions: [PRODUCTS_ENTITY_REF, PRODUCTS_INVENTORY_CAPACITY],
                        measures: [
                            PRODUCTS_TICKETS_SOLD,
                            PRODUCTS_TICKETS_OPEN,
                            PRODUCTS_TICKETS_HELD,
                            PRODUCTS_TICKETS_KILLED,
                            PRODUCTS_TICKETS_RESERVED
                        ],
                        timeDimensions: [
                            {
                                dimension: PRODUCTS_TIME_BEGINS,
                                dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [
                                    string,
                                    string
                                ],
                                granularity
                            }
                        ],
                        filters,
                        timezone: tenantTimezone
                    })
                    .then(
                        compose(
                            setRawData,
                            (data: DimensionData[]) =>
                                Object.values(
                                    data.reduce(
                                        (p: { [key: string]: InventoryData }, v: DimensionData) => {
                                            const begins = new Date(v[PRODUCTS_TIME_BEGINS]);
                                            const beginsString = formatCubeQueryDate(begins);
                                            const capacity = Number(v[PRODUCTS_INVENTORY_CAPACITY]);
                                            const product = String(v[PRODUCTS_ENTITY_REF]);
                                            const sold = Number(v[PRODUCTS_TICKETS_SOLD]);
                                            const open = Number(v[PRODUCTS_TICKETS_OPEN]);
                                            const held = Number(v[PRODUCTS_TICKETS_HELD]);
                                            const killed = Number(v[PRODUCTS_TICKETS_KILLED]);
                                            const reserved = Number(v[PRODUCTS_TICKETS_RESERVED]);

                                            const total = sold + held + killed + reserved;
                                            const capacityMissing = capacity === 0;

                                            p[beginsString] =
                                                p[beginsString] ||
                                                ({
                                                    begins,
                                                    capacity: 0,
                                                    sold: 0,
                                                    open: 0,
                                                    held: 0,
                                                    killed: 0,
                                                    reserved: 0,
                                                    oversoldCount: 0,
                                                    oversold: [],
                                                    missingCapacity: []
                                                } as InventoryData);

                                            if (capacityMissing) {
                                                p[beginsString].missingCapacity.push(product);
                                            } else if (total > capacity) {
                                                p[beginsString].oversold.push(product);
                                                p[beginsString].oversoldCount = total - capacity;
                                            }

                                            p[beginsString].sold += sold;
                                            p[beginsString].held += held;
                                            p[beginsString].killed += killed;
                                            p[beginsString].reserved += reserved;
                                            p[beginsString].capacity += capacity;
                                            p[beginsString].open += capacityMissing
                                                ? open || 0
                                                : Math.max(0, capacity - total);

                                            return p;
                                        },
                                        {} as { [key: string]: InventoryData }
                                    )
                                ),
                            (data) => data.slice(),
                            pathOr<DimensionData[]>([], ['loadResponse', 'results', '0', 'data'])
                        )
                    )
                    .finally(() => setLoading(false));
            },
            500
        )
    ).current;

    React.useEffect(() => {
        getData(timePeriod, filters, granularity);
    }, [timePeriod, filters, granularity]);

    React.useEffect(() => {
        setSeries(
            (
                ['sold', 'reserved', 'held', 'killed', 'open', 'oversoldCount'] as Array<
                    'sold' | 'open' | 'held' | 'killed' | 'reserved' | 'oversoldCount'
                >
            )
                .map((type) => ({
                    name: t(type),
                    type: 'column',
                    data: rawData.map((inventory) => {
                        const capacity = inventory.capacity;
                        let y = inventory[type];
                        if (stacked && capacity === 0) {
                            const unknownCapactiy = (
                                [
                                    'sold',
                                    'open',
                                    'held',
                                    'killed',
                                    'reserved',
                                    'oversoldCount'
                                ] as Array<keyof InventoryData>
                            ).reduce((p, key) => p + Number(inventory[key]), 0);
                            y = (inventory[type] / unknownCapactiy) * 100;
                        } else if (stacked) {
                            y = (inventory[type] / inventory.capacity) * 100;
                        }
                        return {
                            x: +inventory.begins,
                            y,
                            yDisplayValue: inventory[type],
                            // @ts-ignore
                            color: theme.colors[`${type}ChartBar`]
                        };
                    })
                }))
                .sort()
                .filter((serie) => !serie.data.every(({ y }) => y === 0))
                .reverse() as Highcharts.SeriesColumnOptions[]
        );
    }, [rawData, stacked]);

    React.useEffect(() => {
        if (stacked) {
            setColumnOptions({
                yAxis: {
                    maxPadding: 0,
                    min: 0,
                    max: 100,
                    labels: {
                        format: '{value}%'
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'percent'
                    }
                }
            });
        } else {
            setColumnOptions({
                yAxis: {
                    maxPadding: 0.05,
                    min: null,
                    max: null,
                    labels: {
                        format: '{value}'
                    }
                }
            });
        }
    }, [stacked]);

    const chartMenu = (
        <FadedChartMenuItemContainer>
            <FadedChartAction
                onClick={() => setStacked(!stacked)}
                title={t('toggleStacked')}
                iconName={stacked ? IN_FILTER_ICON : NOT_IN_FILTER_ICON}
            />
            {exportGraph}
        </FadedChartMenuItemContainer>
    );

    React.useEffect(() => {
        if (chart) {
            setExportGraph(
                <React.Fragment>
                    <FadedChartAction
                        onClick={() => {
                            chart?.current?.chart.downloadCSV();
                        }}
                        title={t('exportGraphCSV')}
                        iconName={CSV_EXPORT_ICON}
                    />
                    <FadedChartAction
                        onClick={() => {
                            pngExportHandler(chartId, exportFileName);
                        }}
                        title={t('exportGraphPNG')}
                        iconName={PNG_EXPORT_ICON}
                    />
                </React.Fragment>
            );
        }
    }, [chart]);

    return (
        <FadedChartMenuContainer>
            <FilterDrawer
                name={t('inventoryOverTime')}
                borderColor={color}
                chartMenu={chartMenu}>
                <ColumnChart
                    id={chartId}
                    series={series}
                    columnOptions={columnOptions}
                    chartCallback={setChart}
                    granularity={granularity}
                />
                {loading && <LoadingContainer />}
            </FilterDrawer>
        </FadedChartMenuContainer>
    );
};

export { ProductInventoryByTimeBegins };
