import React from 'react';
import { styled } from '@mui/material/styles';

const NotFound = () => {
    return (
        <div>
            <Header>Error 404</Header>
            <SmallHeader>page not found</SmallHeader>
        </div>
    );
};

export default NotFound;

const Header = styled('h1')`
    text-align: center;
`;

const SmallHeader = styled('h3')`
    text-align: center;
`;
