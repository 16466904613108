import React from 'react';
import { ASRequest } from 'utils/api/request';

const Logout = () => {
    ASRequest.request({
        url: 'logout',
        method: 'POST'
    });

    return <React.Fragment />;
};

export default Logout;
