import { Input, InputAdornment } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { CustomSvgIcon } from '../../../utils/CustomSvgIcon';
import { ASLabel } from '../label/Label';
import { Spinner } from '../loader/Spinner';
import { IconType } from 'assets/iconsTypes';

export type InputProps = {
    placeholder?: string;
    fullWidth?: boolean;
    value?: string;
    iconName?: IconType;
    border?: boolean;
    name?: string;
    error?: any;
    label?: string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onFocus?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    loading?: boolean;
    borderColor?: string;
    disabled?: boolean;
    iconFill?: string;
    labelColor?: string;
    size?: number;
    styledFor?: string;
    inputColor?: string;
    id?: string;
    marginTop?: boolean;
};

const ASInput: React.FunctionComponent<InputProps> = ({
    placeholder,
    iconName,
    onChange,
    fullWidth,
    value,
    border,
    name,
    error,
    label,
    onBlur,
    onFocus,
    loading,
    borderColor,
    disabled,
    iconFill,
    labelColor,
    size,
    styledFor,
    inputColor,
    id,
    marginTop
}) => {
    const getIcon = () => {
        if (!iconName) {
            return null;
        }

        return (
            <InputAdornment position="start">
                {loading ? <SpinnerStyled size={16} /> : null}
                <CustomSvgIcon
                    iconName={iconName}
                    fill={iconFill}
                    size={size}
                />
            </InputAdornment>
        );
    };

    return (
        <React.Fragment>
            {styledFor === 'segmentBuilder' ? (
                <SegmentBuilderWrapper>
                    {label ? (
                        <StyledASLabel
                            title={label}
                            fontSize={1}
                            bold={true}
                        />
                    ) : null}
                    <InputStyled
                        disableUnderline
                        onChange={onChange}
                        value={value}
                        fullWidth={fullWidth}
                        placeholder={placeholder}
                        border={border}
                        name={name}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        startAdornment={getIcon()}
                        borderColor={borderColor}
                        disabled={disabled}
                        labelColor={labelColor}
                        inputColor={inputColor}
                    />
                    {error ? <Error>{error}</Error> : null}
                </SegmentBuilderWrapper>
            ) : (
                <Wrapper>
                    {label ? (
                        <StyledASLabel
                            title={label}
                            fontSize={1}
                            bold={true}
                        />
                    ) : null}
                    <InputStyled
                        disableUnderline
                        onChange={onChange}
                        value={value}
                        fullWidth={fullWidth}
                        placeholder={placeholder}
                        border={border}
                        name={name}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        startAdornment={getIcon()}
                        borderColor={borderColor}
                        disabled={disabled}
                        labelColor={labelColor}
                        id={id}
                        marginTop={marginTop}
                    />
                    {error ? <Error>{error}</Error> : null}
                </Wrapper>
            )}
        </React.Fragment>
    );
};

ASInput.defaultProps = {
    marginTop: true
};

const Wrapper = styled('div')`
    width: 100%;
`;

const SegmentBuilderWrapper = styled('div')`
    width: 426px;
    height: 51px;
    display: flex;
`;

const InputStyled = styled(Input)<InputProps>`
    input::placeholder {
        font-style: italic;
        color: ${({ theme, labelColor }) => (labelColor ? labelColor : theme.colors.text)};
        font-size: ${({ theme }) => theme.fontSizes.medium};
        opacity: 1;
    }
    color: ${({ inputColor }) => inputColor};
    border: ${({ border, theme, borderColor }): string =>
        border ? `1px solid ${borderColor ? borderColor : theme.colors.border}` : 'none'};
    padding: 0 10px;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'initial')};
    margin-top: ${({ marginTop }) => (marginTop === true ? '3px' : '0px')};
    border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const StyledASLabel = styled(ASLabel)`
    margin-bottom: 5px;
`;

const SpinnerStyled = styled(Spinner)`
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};

    color: ${({ theme }) => theme.colors.text};
    opacity: 0;

    animation: fadeIn 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const Error = styled('p')`
    color: var(--color-danger);
    font-size: 0.8em;
    margin-top: 5px;
`;

export { ASInput };
