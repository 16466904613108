import { useTheme } from '@mui/material/styles';
import React from 'react';
import Highcharts from 'highcharts/highstock';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { BarChart } from './BarChart';
import { ColumnChart } from './ColumnChart';
import { getChartColors } from './DefaultChartConfigs';

import { BarAndColumnChartProps } from './types';
import { Maybe } from 'utils/maybe';

import { filterValueCheck } from 'utils/filter/filterCheck';
import { Tracking } from 'externals/tracking';
import { AvailableDimensions, DimensionDataStore } from 'stores/dimensionData';
import { customerTagsDimensions } from 'utils/common/constants';

const BarAndColumnChart = ({
    dimension,
    filters,
    onFilter,
    granularity,
    operator,
    barSeries,
    barOptions = {},
    chartCallback,
    columnSeries,
    columnOptions = {},
    categories,
    metric,
    exportFileName,
    id,
    subtitleTwo,
    borderColor
}: BarAndColumnChartProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dimensionData = React.useContext(DimensionDataStore);
    const [_, dimensionName]: [string, AvailableDimensions] = dimension.split('.') as [
        string,
        AvailableDimensions
    ];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [coloredBar, setColoredBar] = React.useState<Highcharts.Options['series']>([]);
    const [coloredColumn, setColoredColumn] = React.useState<Highcharts.SeriesColumnOptions[]>([]);
    const filterValues = Maybe(filters.find((filter) => filter.dimension === dimension))
        .chain((filter) => Maybe(filter.values))
        .map((values) => values.map((value) => filterValueCheck(value)))
        .getOrElse([] as string[]);

    const isPointOptionsObject = (
        dataPoint: number | Highcharts.PointOptionsObject | [string | number, number | null] | null
    ): dataPoint is Highcharts.PointOptionsObject => (dataPoint ? true : false);

    React.useEffect(() => {
        if (barSeries && (barSeries[0] as any)?.data) {
            const columnColors: string[] = [];
            (barSeries[0] as any)?.data.forEach((dataPoint: any, index: number) => {
                if (isPointOptionsObject(dataPoint) && !Array.isArray(dataPoint)) {
                    let color;
                    if (
                        filterValues.length === 0 ||
                        filterValues.includes(
                            dataPoint && String(dataPoint.custom?.filterValue)
                        ) ===
                            (operator === 'equals')
                    ) {
                        color = getChartColors(theme)[index % 50];
                        columnColors.push(color);
                    } else {
                        color = `${getChartColors(theme)[index % 50]}45`;
                    }

                    dataPoint.color = color;
                }
            });
            setColoredBar(barSeries);

            columnSeries.forEach((item) => {
                (barSeries[0] as any)?.data &&
                    (barSeries[0] as any)?.data.forEach((barItem: any) => {
                        if (barItem?.category === item.name) {
                            item.color = barItem?.color;
                        }
                    });
            });

            setColoredColumn(columnSeries);
        }
    }, [barSeries, columnSeries, theme]);

    //Publish the operator whenever it changes so that other graphs can fetch their data again
    React.useEffect(() => {
        onFilter({ dimension, type: 'operator', operator });
        // Since the function is initialized with the default value of operator which is 'equals'
        // we need to reinitialize it with the updated value
        barClickHandler.current = (event: Highcharts.SeriesClickEventObject) => {
            const filterValue = event.point.options.custom?.filterValue;
            const breadcrumbValue = event.point.options.custom?.breadcrumbValue;

            onFilter({
                type: 'dimensionClick',
                operator: operator,
                dimension,
                value: {
                    value: filterValue,
                    label: breadcrumbValue
                },
                color: borderColor
            });

            Tracking.trackGoal('Filtered by dimension', {
                posthogUniqueKey: dimensionData[dimensionName].label,
                dimension,
                chartName: t(dimensionData[dimensionName].label),
                filters: filterValueCheck(breadcrumbValue)
            });
        };
    }, [operator]);

    const barClickHandler = React.useRef((event: Highcharts.SeriesClickEventObject) => {
        const filterValue = event.point.options.custom?.filterValue;
        const breadcrumbValue = event.point.options.custom?.breadcrumbValue;

        onFilter({
            type: 'dimensionClick',
            operator: operator,
            dimension,
            value: {
                value: filterValue,
                label: breadcrumbValue
            },
            color: borderColor
        });

        Tracking.trackGoal('Filtered by dimension', {
            dimension,
            posthogUniqueKey: dimensionData[dimensionName].label,
            chartName: t(dimensionData[dimensionName].label),
            filters: filterValueCheck(breadcrumbValue)
        });
    });

    return (
        <Wrapper id={id}>
            <BarWrapper>
                <BarChart
                    series={coloredBar}
                    barOptions={barOptions}
                    onBarClick={barClickHandler}
                    chartCallback={chartCallback}
                    metric={metric}
                    immutable={customerTagsDimensions.includes(dimension)}
                />
            </BarWrapper>
            <ColumnWrapper>
                <ColumnChart
                    categories={categories}
                    columnOptions={columnOptions}
                    series={coloredColumn}
                    metric={metric}
                    exportFileName={exportFileName}
                    granularity={granularity}
                    subtitleOne={subtitleTwo}
                />
            </ColumnWrapper>
        </Wrapper>
    );
};

export { BarAndColumnChart };

const Wrapper = styled('div')`
    display: flex;
`;

const BarWrapper = styled('div')`
    width: 33.33%;
`;

const ColumnWrapper = styled('div')`
    width: 66.66%;
`;
