import { pathOr } from 'ramda';
import { cubejsApi } from 'utils/api/CubeAPI';
import { ASCubeQuery } from 'utils/common/types';
import {
    CUSTOMERS_ADDRESS,
    CUSTOMERS_ADDRESS_2,
    CUSTOMERS_CITY,
    CUSTOMERS_COUNTRY,
    CUSTOMERS_EXPANDED_EMAIL,
    CUSTOMERS_ENTITY_REF,
    CUSTOMERS_LABEL,
    CUSTOMERS_EXPANDED_PHONE_HOME,
    CUSTOMERS_EXPANDED_PHONE_MOBILE,
    CUSTOMERS_EXPANDED_PHONE_WORK,
    CUSTOMERS_POSTAL_CODE,
    BASE_TRANSACTIONS_TYPE,
    BASE_TRANSACTIONS_OCCURRED_AT,
    GDPR_ITEMS_PRODUCT_LABEL,
    BASE_TRANSACTIONS_PRODUCT_ARCHETYPE,
    BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT,
    BASE_TRANSACTIONS_PRODUCT_REF,
    BASE_TRANSACTIONS_VARIANT,
    BASE_TRANSACTIONS_ITEM_PRICE,
    BASE_TRANSACTIONS_CURRENCY,
    BASE_TRANSACTIONS_DISCOUNT_AMOUNT,
    BASE_TRANSACTIONS_DISCOUNT_PERCENTAGE,
    BASE_TRANSACTIONS_ORDER_REF,
    BASE_TRANSACTIONS_DESCRIPTION,
    BASE_TRANSACTIONS_ITEM_COUNT,
    BASE_TRANSACTIONS_BUYER_REF
} from 'utils/common/constants';

const getCustomerPiiData = (customerRef: string): ASCubeQuery => ({
    dimensions: [
        CUSTOMERS_ENTITY_REF,
        CUSTOMERS_LABEL,
        CUSTOMERS_ADDRESS,
        CUSTOMERS_ADDRESS_2,
        CUSTOMERS_CITY,
        CUSTOMERS_COUNTRY,
        CUSTOMERS_POSTAL_CODE,
        CUSTOMERS_EXPANDED_EMAIL,
        CUSTOMERS_EXPANDED_PHONE_HOME,
        CUSTOMERS_EXPANDED_PHONE_MOBILE,
        CUSTOMERS_EXPANDED_PHONE_WORK
    ],
    filters: [
        {
            operator: 'equals',
            dimension: CUSTOMERS_ENTITY_REF,
            values: [customerRef]
        }
    ]
});

const getCustomersAllTransactions = (customerRef: string): ASCubeQuery => ({
    dimensions: [
        BASE_TRANSACTIONS_TYPE,
        BASE_TRANSACTIONS_OCCURRED_AT,
        GDPR_ITEMS_PRODUCT_LABEL,
        BASE_TRANSACTIONS_PRODUCT_ARCHETYPE,
        BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT,
        BASE_TRANSACTIONS_PRODUCT_REF,
        BASE_TRANSACTIONS_VARIANT,
        BASE_TRANSACTIONS_ITEM_PRICE,
        BASE_TRANSACTIONS_CURRENCY,
        BASE_TRANSACTIONS_DISCOUNT_AMOUNT,
        BASE_TRANSACTIONS_DISCOUNT_PERCENTAGE,
        BASE_TRANSACTIONS_ORDER_REF,
        BASE_TRANSACTIONS_DESCRIPTION
    ],
    measures: [BASE_TRANSACTIONS_ITEM_COUNT],
    filters: [{ operator: 'equals', dimension: BASE_TRANSACTIONS_BUYER_REF, values: [customerRef] }]
});

interface FileData {
    personalData?: object;
    transactions?: [];
}

const handleSaveToPC = (jsonData: object, filename: string) => {
    const fileData = JSON.stringify(jsonData);
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${filename}.json`;
    link.href = url;
    link.click();
};

export const customerButtonHandler = (customerRef: string) => {
    const finalData: FileData = {};
    Promise.all([
        cubejsApi
            .load(getCustomerPiiData(customerRef))
            .then(
                (data) =>
                    (finalData.personalData = pathOr(
                        {},
                        ['loadResponse', 'results', '0', 'data', '0'],
                        data
                    ))
            ),

        cubejsApi
            .load(getCustomersAllTransactions(customerRef))
            .then(
                (data) =>
                    (finalData.transactions = pathOr(
                        [],
                        ['loadResponse', 'results', '0', 'data'],
                        data
                    ))
            )
    ]).finally(() => handleSaveToPC(finalData, customerRef));
};
