import React, { Suspense } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { PlaceholderLayout } from './PlaceholderLayout';
import { Tracking } from 'externals/tracking';

export const MainRouter = ({ component, path, internalName }: any) => {
    const location = useLocation();
    React.useEffect(() => {
        if (window.inline_manual_player) {
            window.inline_manual_player.update();
        }
        if (location.pathname !== '/') {
            Tracking.pageview(
                location.pathname,
                getDashboardName(internalName || location.pathname)
            );
        }
    }, [location]);
    return (
        <Suspense fallback={<PlaceholderLayout />}>
            <Route
                path={path}
                component={component}
            />
        </Suspense>
    );
};

export const getDashboardName = (label: string) => {
    if (label.includes('EntityOverview')) {
        const entityRef = label.split('EntityOverview/')[1];
        const entityName = entityRef.split('/')[0];
        const entityDashName = `${entityName} Overview - ${entityRef}`;
        return entityDashName;
    }

    return label;
};
