import { Autocomplete, Popper, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const Label = styled('div')`
    color: ${({ theme }) => theme.colors.text};
    margin-right: ${({ theme }) => theme.space.half};
    font-size: ${({ theme }) => theme.fontSizes.normal};
    width: 100%;
`;

const Value = styled('div')`
    color: ${({ theme }) => theme.colors.text};
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizes.normal};
`;

const TextFieldStyled = styled(TextField)`
    input::placeholder {
        color: ${({ theme }) => theme.colors.text};
        font-size: ${({ theme }) => theme.fontSizes.normal};
        opacity: 1;
    }
`;

export const FilterAutocomplete = styled(Autocomplete)`
    height: 44px;
    padding-left: 31px;
    font-size: ${({ theme }) => theme.fontSizes.normal};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);

    .MuiOutlinedInput-root {
        padding-top: 4px;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .MuiAutocomplete-endAdornment {
        display: none;
    }
`;

const StyledPopper = styled(Popper)<{ marginTop: string }>`
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: ${({ marginTop }) => marginTop};
    min-width: 300px;
    border-top: 2px solid ${({ theme }) => theme.colors.searchTopBorder};
    z-index: 1301;

    [class].MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
        min-width: 300px !important;
        width: auto !important;
        inset: 0 auto auto -14px !important;
        margin-top: -4px !important;
    }

    .MuiAutocomplete-paper {
        margin-top: 0;
        font-size: ${({ theme }) => theme.fontSizes.normal};
        color: ${({ theme }) => theme.colors.textLink};
        text-decoration: underline;
        border-radius: 0;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    .MuiAutocomplete-listbox {
        max-height: 220px;
        .MuiAutocomplete-option {
            font-size: ${({ theme }) => theme.fontSizes.normal};
            padding-left: 31px;
            &:first-child {
                padding-top: 15px;
            }
        }
    }
`;

export { Label, Value, TextFieldStyled, StyledPopper };
