import cubejs, { Query, Filter, LoadResponse, LoadResponseResult } from '@cubejs-client/core';
import { flatten, omit, zipObj } from 'ramda';
import { CubeAPICache } from './CubeAPICache';
import { CUBE_API_URL } from './urls';
import { ASCubeFilter, ASCubeQuery, DimensionData, FilterValue } from 'utils/common/types';
import { filterValueCheck } from 'utils/filter/filterCheck';
import { formatCubeQueryDate } from 'components/common/datepicker/dateUtils';
import { QueryParams } from 'views/dashboards/SegmentBuilder/types';

const TEMP_TOKEN =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1OTMwMDE3OTcsImV4cCI6MTU5MzA4ODE5N30.jTk_qA-C0equpOikY3LiFTt8ELgP5L2pqXbtwtk0nf0';

const getCubejsApi = cubejs(TEMP_TOKEN, {
    apiUrl: CUBE_API_URL,
    pollInterval: 60,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
    }
});

const postCubejsApi = cubejs(TEMP_TOKEN, {
    apiUrl: CUBE_API_URL,
    pollInterval: 60,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
    },
    //@ts-ignore
    method: 'POST'
});

interface ASLoadResponse<T> extends LoadResponse<T> {
    results: ASLoadResponseResult<T>[];
}

interface ASLoadResponseResult<T> extends LoadResponseResult<T> {
    dataHeaders: string[];
}

export const transformAsCubeQuery = (query: ASCubeQuery): Query => {
    // Only choose what needs to be sent to the backend. So f.ex. 'color' will not be sent:
    const newFilters = (query.filters || []).map((filter): ASCubeFilter => {
        if ('or' in filter || 'and' in filter) {
            return filter;
        }
        return {
            dimension: filter.dimension,
            member: filter.member,
            operator: filter.operator,
            values: filter.values
        };
    });

    const emptyStringFilters: string[] = [];
    const filters = flatten(
        (newFilters || []).map((filter) => {
            const transformedFilter = {
                ...omit(['dimension'], filter),
                member: filter.member || filter.dimension,
                ...(!('or' in filter) && {
                    values: (filter.values || []).map((value: FilterValue) => {
                        const transformedValue = filterValueCheck(value);
                        if (transformedValue == '' && filter.dimension) {
                            emptyStringFilters.push(filter.dimension);
                        }
                        return transformedValue;
                    })
                })
            };
            if (transformedFilter.member && transformedFilter.member.endsWith('Flat')) {
                return {
                    ...transformedFilter,
                    member: transformedFilter.member.slice(0, -4)
                };
            }
            if (String(transformedFilter.operator) === 'range' && filter.values?.length === 2) {
                return [
                    {
                        dimension: filter.dimension,
                        operator: 'gte',
                        values: [String(filter.values[0])]
                    },
                    {
                        dimension: filter.dimension,
                        operator: 'lte',
                        values: [String(filter.values[1])]
                    }
                ];
            }
            if (
                String(transformedFilter.operator) === 'notInRange' &&
                filter.values?.length === 2
            ) {
                return {
                    or: [
                        {
                            dimension: filter.dimension,
                            operator: 'gt',
                            values: [String(filter.values[1])]
                        },
                        {
                            dimension: filter.dimension,
                            operator: 'lt',
                            values: [String(filter.values[0])]
                        }
                    ]
                };
            }
            return transformedFilter;
        })
    );
    emptyStringFilters.map((dimension) => {
        const filter = filters.find(
            (filter) => 'dimension' in filter && filter.dimension === dimension
        );
        if (filter) {
            'values' in filter && (filter as any).values.push('null');
        }
    });
    // Change FutureDate to an 'afterDate' filter since it makes the dashboard flow easier to handle
    // this edge case right before we do the queries.
    const timeDimensions = (query.timeDimensions = (query.timeDimensions || []).map(
        (timeDimension) => {
            if (
                timeDimension.dateRange &&
                formatCubeQueryDate(new Date((timeDimension.dateRange || [])[1] as string)) ===
                    formatCubeQueryDate(new Date('2222-12-31'))
            ) {
                const start = timeDimension.dateRange[0];
                filters.push({
                    dimension: timeDimension.dimension,
                    operator: 'afterDate',
                    values: [start]
                });
                return omit(['dateRange'], timeDimension);
            }
            return timeDimension;
        }
    ));
    query.timeDimensions = timeDimensions;
    return {
        ...query,
        filters: filters as Filter[]
    };
};

let alreadyRedirected = false;
const cubejsApi = {
    load(
        query: ASCubeQuery,
        {
            queryParams,
            method,
            mutexKey
        }: { queryParams?: QueryParams; method?: 'POST' | 'GET'; mutexKey?: string } = {}
    ) {
        const transformedQuery = transformAsCubeQuery(query);
        transformedQuery.limit = 'limit' in query ? query.limit : 50000;
        const stringifiedQuery =
            JSON.stringify(transformedQuery) + JSON.stringify(queryParams || {});
        if (CubeAPICache[stringifiedQuery]) {
            return Promise.resolve(CubeAPICache[stringifiedQuery]);
        }
        return (method === 'POST' ? postCubejsApi : getCubejsApi)
            .load(transformedQuery, {
                queryParams: { cubeQueryContext: JSON.stringify(queryParams || {}) },
                mutexKey: mutexKey
            })
            .then((results) => {
                (
                    results as unknown as { loadResponse: ASLoadResponse<DimensionData> }
                ).loadResponse.results.forEach((result) => {
                    result.data = result.data.map((d) =>
                        zipObj(result.dataHeaders, d as unknown as readonly (string | number)[])
                    );
                });
                CubeAPICache[stringifiedQuery] = results;

                return results;
            })
            .catch((error) => {
                try {
                    const { status, loginUrl } = JSON.parse(error.message);
                    if (String(status) === '401' && loginUrl) {
                        if (!alreadyRedirected) {
                            window.location.href = `${location.origin}${loginUrl}`;
                            alreadyRedirected = true;
                        }
                    }
                } catch (_error) {
                    console.error(_error);
                    throw error;
                }
            });
    }
};

export { cubejsApi };

export function getCookie(cname: string) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
