import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomSvgIcon } from '../../utils/CustomSvgIcon';
import { ASButton } from '../common/button/ASButton';
import { DESELECT_ALL } from '../../assets/iconConstants';
import { styled, useTheme } from '@mui/material/styles';

export const ResetFilter = ({ resetFilter }: { resetFilter: () => void }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <ASButton
            onClick={resetFilter}
            background="white"
            fontSize={12}
            id="resetFilter"
            height="44px">
            <StyledIcon>
                <CustomSvgIcon
                    size={1}
                    iconName={DESELECT_ALL}
                    fill={theme.colors.text}
                />
            </StyledIcon>
            <Label>{t('resetFilter')}</Label>
        </ASButton>
    );
};

const StyledIcon = styled('span')`
    margin: 10px 10px 10px 0;
`;

const Label = styled('span')`
    color: ${({ theme }) => theme.colors.text};
`;
