import { enUS } from 'date-fns/locale';
import React, { Component } from 'react';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { TimeDimensionGranularity } from '@cubejs-client/core';
import { TooltipFormatterContextObject } from 'highcharts';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { DateFormatContext } from './DateFormatContext';

export class DateFormatContextProvider extends Component<{ children: React.ReactNode }> {
    setDateFnsLocale: any /*SetDateFormat*/ = (locale: string) => {
        import(`date-fns/locale/${locale}/index.js`).then((dateFnsLocale) => {
            this.setState({ ...this.state, dateFnsLocale });
        });
    };
    state: any /*DateFormatInterface*/ = {
        dateFnsLocale: enUS,
        setDateFnsLocale: this.setDateFnsLocale
    };

    formatDateTime = (date: Date) => format(date, 'Pp', { locale: this.state.dateFnsLocale });
    formatDateTimeFullHourAndMinute = (date: Date) =>
        format(date, 'dd/MM/yyy, h:mm a', { locale: this.state.dateFnsLocale });
    formatDateTime24h = (date: Date) =>
        format(date, 'P H:mm', { locale: this.state.dateFnsLocale });
    formatTime24h = (date: Date) => format(date, 'HH:mm', { locale: this.state.dateFnsLocale });
    formatTime = (date: Date) => format(date, 'p', { locale: this.state.dateFnsLocale });
    formatDate = (date: Date) => format(date, 'P', { locale: this.state.dateFnsLocale });
    formatDateLong = (date: Date) => format(date, 'PPP', { locale: this.state.dateFnsLocale });
    formatDateTimeLong = (date: Date) =>
        format(date, 'PPPPp', { locale: this.state.dateFnsLocale });
    formatEventDate = (date: Date, t: TFunction) => {
        const dateAndYear = format(date, 'E d LLL, yyyy', { locale: this.state.dateFnsLocale });
        const hours = format(date, 'H:mm', { locale: this.state.dateFnsLocale });
        return `${dateAndYear} ${t('at')} ${hours}`;
    };
    formatCampaignEventDate = (date: Date, t: TFunction) => {
        const dateAndYear = format(date, 'LLL d, yyyy', { locale: this.state.dateFnsLocale });
        const hours = format(date, 'H:mm', { locale: this.state.dateFnsLocale });
        return `${dateAndYear}. ${t('doorsOpen')} ${hours}`;
    };
    formatDateMonthShort = (date: Date) => {
        const dateAndYear = format(date, 'LLL d, yyyy', { locale: this.state.dateFnsLocale });
        return dateAndYear;
    };

    getTenantTimezoneTimeOfDay = (date: string, timezone: string): Date => {
        return utcToZonedTime(date, timezone);
    };

    getTenantTimezoneTimeOfDayStr = (date: string, timezone: string): string => {
        return formatInTimeZone(date, timezone, 'yyyy-MM-dd HH:mm:ss');
    };

    formatDateMonth = (date: Date) => {
        const dateMonth = format(date, 'LLL d', { locale: this.state.dateFnsLocale });
        return dateMonth;
    };
    formatDOWDateTimeLong = (date: Date, t: TFunction) => {
        const dateAndYear = format(date, 'EEEE, LLLL do, yyyy', {
            locale: this.state.dateFnsLocale
        });
        const hours = format(date, 'H:mm b', { locale: this.state.dateFnsLocale });
        return `${dateAndYear} ${t('at')} ${hours}`;
    };

    formatMonthDateYearShort = (date: Date) => {
        const monthAndDate = format(date, 'MMM d', { locale: this.state.dateFnsLocale });
        const year = format(date, 'y', { locale: this.state.dateFnsLocale });
        return `${monthAndDate}, ${year}`;
    };

    formatChartDatetime = (value: number, granularity: TimeDimensionGranularity) => {
        switch (granularity) {
            case 'hour': {
                const hours = format(new Date(value), 'HH', { locale: this.state.dateFnsLocale });
                const minutes = format(new Date(value), 'mm', { locale: this.state.dateFnsLocale });
                return `${hours}:${minutes}`;
            }
            case 'day': {
                const month = format(new Date(value), 'MMM', { locale: this.state.dateFnsLocale });
                const dayOfMonth = format(new Date(value), 'd', {
                    locale: this.state.dateFnsLocale
                });
                return `${dayOfMonth}. ${month.charAt(0).toUpperCase() + month.slice(1)}`;
            }
            case 'week': {
                const month = format(new Date(value), 'MMM.', { locale: this.state.dateFnsLocale });
                const dayOfMonth = format(new Date(value), 'd', {
                    locale: this.state.dateFnsLocale
                });
                return `${dayOfMonth}. ${month.charAt(0).toUpperCase() + month.slice(1)}`;
            }
            case 'month': {
                const month = format(new Date(value), 'MMM', { locale: this.state.dateFnsLocale });
                const year = format(new Date(value), 'yy', { locale: this.state.dateFnsLocale });
                return `${month.charAt(0).toUpperCase() + month.slice(1)} '${year}`;
            }
            case 'year': {
                const year = format(new Date(value), 'yyyy', { locale: this.state.dateFnsLocale });
                return year;
            }
            default: {
                return '';
            }
        }
    };

    formatTooltipHeader = (
        point: TooltipFormatterContextObject,
        yoyLineChart: boolean | undefined,
        timeRangeChart?: boolean
    ) => {
        if (yoyLineChart) {
            const year = Number(point.series.name);

            if (!year || Number.isNaN(year)) {
                return '';
            }

            return format(new Date(new Date(point.x).setFullYear(year)), 'MMMM do', {
                locale: this.state.dateFnsLocale
            });
        } else if (typeof point.x === 'string') {
            return point.x;
        } else if (!timeRangeChart && timeRangeChart !== undefined) {
            return point.key;
        } else if (typeof point.key === 'string') {
            return point.key;
        } else {
            if (point.x === 0) {
                return '';
            }
            return format(new Date(point.x), 'MMMM do yyyy, H:mm a', {
                locale: this.state.dateFnsLocale
            });
        }
    };

    formatShortMonth = (date: Date) => format(date, 'MMM', { locale: this.state.dateFnsLocale });

    formatYear = (date: Date) => format(date, 'yy', { locale: this.state.dateFnsLocale });

    formatRTODateTime = (date: Date) => {
        const hours = format(date, 'HH.mm', { locale: this.state.dateFnsLocale });
        const dateAndYear = format(date, 'EEEE, d LLLL yyyy', { locale: this.state.dateFnsLocale });
        return `${hours} ${dateAndYear}`;
    };

    formatDayMonthYearTimeShort = (date: Date) =>
        format(date, 'EEE d. MMM y, h:mm a', { locale: this.state.dateFnsLocale });

    formatDateTimeShort = (date: Date) =>
        format(date, 'MMMM d yyyy, h:mm a', { locale: this.state.dateFnsLocale });

    render(): JSX.Element {
        return (
            <DateFormatContext.Provider
                value={{
                    ...this.state,
                    setDateFnsLocale: this.setDateFnsLocale,
                    formatDateTime: this.formatDateTime,
                    formatDateTimeFullHourAndMinute: this.formatDateTimeFullHourAndMinute,
                    formatDateTime24h: this.formatDateTime24h,
                    formatTime: this.formatTime,
                    formatDate: this.formatDate,
                    formatDateLong: this.formatDateLong,
                    formatDateTimeLong: this.formatDateTimeLong,
                    formatEventDate: this.formatEventDate,
                    formatCampaignEventDate: this.formatCampaignEventDate,
                    formatDateMonthShort: this.formatDateMonthShort,
                    getTenantTimezoneTimeOfDay: this.getTenantTimezoneTimeOfDay,
                    getTenantTimezoneTimeOfDayStr: this.getTenantTimezoneTimeOfDayStr,
                    formatDateMonth: this.formatDateMonth,
                    formatDOWDateTimeLong: this.formatDOWDateTimeLong,
                    formatChartDatetime: this.formatChartDatetime,
                    formatMonthDateYearShort: this.formatMonthDateYearShort,
                    formatTooltipHeader: this.formatTooltipHeader,
                    formatShortMonth: this.formatShortMonth,
                    formatYear: this.formatYear,
                    formatTime24h: this.formatTime24h,
                    formatRTODateTime: this.formatRTODateTime,
                    formatDayMonthYearTimeShort: this.formatDayMonthYearTimeShort,
                    formatDateTimeShort: this.formatDateTimeShort
                }}>
                {this.props.children}
            </DateFormatContext.Provider>
        );
    }
}
