import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import { styled } from '@mui/material/styles';

import { DateRange } from '../datepicker/types';
import { InventoryBinaryRowChart } from '../../charts';
import { sizes } from '../../responsive/MediaQuery';
import { EventsSoldInPeriodTable, PreviousPeriodTable } from '../../tables';
import { SuperTileSummary, ECODeliverabilityStatistics } from '../../tiles';
import { InventoryTile } from '../../tiles/InventoryAspectTile';
import { ASIconButton } from '../button';
import { LoadingContainer } from '../loader';
import { TileAspect, Aspect } from './TileAspect';
import { Image } from 'components/commonStyles';

import { CustomSvgIcon, IconWithRotation } from 'utils/CustomSvgIcon';

import { CHECKMARK_ICON } from 'assets/iconConstants';

import { Entity } from 'utils/dataloaders/entityLoader';
import { settingsStore } from 'stores/settings';
import { noTilePresentationImage } from 'views/utils/iconHandler';
import { Tracking } from 'externals/tracking';
import { InventoryData } from 'utils/dataloaders/inventoryLoader';
import { TemplateModalButton } from 'views/dashboards/Tools/CampaignManagement/TemplateModalButton';
import { TemplateViewProps } from 'views/dashboards/Tools/CampaignManagement/types';
import { ASIcon } from 'components/asComponents/ASIcon/ASIcon';
import { Icons, IconSizes } from 'components/asComponents/ASIcon/types';

interface TileProps {
    aspects: Aspect[];
    inventoryAspects: Aspect[];
    date?: string;
    deselected?: boolean;
    entityRef: string;
    entityType?: string;
    entity?: Entity;
    expanded: boolean;
    expandLoading: boolean;
    chartData: Highcharts.SeriesBarOptions[];
    chartCategories: Array<string>;
    handleChange?: () => void;
    handleFilterChange?: () => void;
    hasFilter?: boolean;
    link: string;
    location: string;
    title: string;
    thumbnail?: string;
    customThumbnail?: string;
    nextVisit?: string | null;
    tileEntityType?: string;
    eventTitle?: string;
    relatedEventEntityRef?: string;
    expandedTileTitle?: string;
    salesTime: string;
    datePickerPeriod: DateRange;
    inventory: InventoryData | null | undefined;
    customFlag?: string;
    metric?: string;
    setTemplateViewProps?: React.Dispatch<TemplateViewProps | undefined>;
    setCampaignFetchFailing?: React.Dispatch<boolean>;
    isText?: boolean;
    getTemplate?: (entityRef: string) => Promise<TemplateViewProps>;
}

const Tile: React.FunctionComponent<TileProps> = ({
    aspects,
    inventoryAspects,
    date,
    deselected,
    entityRef,
    entityType,
    entity,
    expanded,
    expandLoading,
    chartData,
    chartCategories,
    handleChange,
    hasFilter,
    handleFilterChange,
    location,
    link,
    title,
    thumbnail,
    customThumbnail,
    nextVisit,
    tileEntityType,
    eventTitle,
    relatedEventEntityRef,
    expandedTileTitle = '',
    salesTime,
    datePickerPeriod,
    inventory,
    customFlag,
    metric,
    setTemplateViewProps,
    setCampaignFetchFailing,
    isText,
    getTemplate
}) => {
    const renderAspects = () =>
        aspects.map((aspect) => (
            <React.Fragment key={aspect.label}>
                <TileAspect
                    key={aspect.label}
                    label={aspect.label}
                    value={aspect.value}
                />
            </React.Fragment>
        ));
    const theme = useTheme();
    const { t } = useTranslation();
    const { featureFlags } = React.useContext(settingsStore);

    const renderButtons = () => (
        <IconWrapper>
            <StyledTileButton
                background={hasFilter ? theme.colors.toplistIconPrimary : 'white'}
                hoverbackground={hasFilter ? theme.colors.toplistIconPrimary : 'white'}
                hoverfill={theme.colors.toplistIconFill}
                onClick={handleFilterChange}
                selected={hasFilter}>
                <CustomSvgIcon
                    size={0.5}
                    hoverfill={theme.colors.text}
                    fill={hasFilter ? theme.colors.text : theme.colors.toplistIconSecondary}
                    iconName={CHECKMARK_ICON}
                />
            </StyledTileButton>
            {tileEntityType === 'EmailCampaign' ? (
                <TemplateModalButton
                    entityRef={entityRef}
                    setTemplateViewProps={setTemplateViewProps}
                    setCampaignFetchFailing={setCampaignFetchFailing}
                    getTemplate={getTemplate}
                />
            ) : null}
        </IconWrapper>
    );
    return (
        <Wrapper>
            {deselected && <Overlay />}
            <StyledAccordion
                expanded={expanded}
                square>
                <StyledAccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    {thumbnail ? (
                        // eslint-disable-next-line react/jsx-no-undef
                        <Image thumbnail={thumbnail} />
                    ) : customThumbnail ? (
                        <CustomSvgIconWrapper backgroundColor={theme.colors.white}>
                            <CustomSvgIcon
                                fill=""
                                maxSize
                                iconName={customThumbnail}
                            />
                        </CustomSvgIconWrapper>
                    ) : (
                        <CustomSvgIconWrapper entityType={entityType}>
                            <CustomSvgIcon
                                fill=""
                                size={4}
                                iconName={
                                    noTilePresentationImage(
                                        relatedEventEntityRef ? relatedEventEntityRef : entityRef
                                    ) as string
                                }
                            />
                        </CustomSvgIconWrapper>
                    )}
                    <Row1Wrapper>
                        <SuperTileSummary
                            entityRef={entityRef}
                            nextVisit={nextVisit}
                            date={date}
                            link={link}
                            location={location}
                            title={title}
                            tileEntityType={tileEntityType}
                            eventTitle={eventTitle}
                            isText={isText}
                        />
                    </Row1Wrapper>
                    <Row2Wrapper>
                        <TileAspectWrapper>{renderAspects()}</TileAspectWrapper>
                    </Row2Wrapper>
                    {renderButtons()}
                </StyledAccordionSummary>
                <ExpandedArea>
                    {tileEntityType && tileEntityType === 'EmailCampaign' ? null : (
                        <React.Fragment>
                            {entity && entity.entity.archetype_variant === 'Event' ? (
                                <EventsSoldInPeriodTable
                                    salesTime={salesTime}
                                    datePickerPeriod={datePickerPeriod}
                                    entityRef={entityRef}
                                    expanded={expanded}
                                    inventory={inventory}
                                />
                            ) : (entity && entity.entity.archetype_variant === 'Production') ||
                              (entity &&
                                  entity.entity.archetype === 'Performer' &&
                                  customFlag === 'advanceEvent') ? (
                                <PreviousPeriodTable
                                    datePickerPeriod={datePickerPeriod}
                                    entityRef={entityRef}
                                    expanded={expanded}
                                    metric={metric}
                                />
                            ) : null}
                            <InventoryTile
                                inventoryAspects={inventoryAspects}
                                expandedTileTitle={expandedTileTitle}
                                tileEntityType={tileEntityType}
                            />
                        </React.Fragment>
                    )}
                    <InventoryBinaryRowChartWrapper>
                        {chartData.length > 0 &&
                        chartCategories.length > 0 &&
                        ((entity &&
                            Object.keys(entity).length !== 0 &&
                            entity?.entity.archetype &&
                            entity?.entity.archetype === 'Product' &&
                            (entity?.entity.archetype_variant === 'Event' ||
                                entity?.entity.archetype_variant === 'Merchandise')) ||
                            (tileEntityType && tileEntityType === 'EmailCampaign')) ? (
                            tileEntityType === 'EmailCampaign' ? (
                                <ECODeliverabilityStatistics
                                    chartData={chartData}
                                    chartCategories={chartCategories}
                                    inventoryAspects={inventoryAspects}
                                />
                            ) : (
                                <InventoryBinaryRowChart
                                    series={chartData}
                                    categories={chartCategories}
                                />
                            )
                        ) : expandLoading ? (
                            <LoadingContainer size={30} />
                        ) : (
                            <NoInventory>{t('noCurrentInventory')}</NoInventory>
                        )}
                    </InventoryBinaryRowChartWrapper>
                </ExpandedArea>
            </StyledAccordion>
            {entityRef?.includes('Customer') ||
            !featureFlags.showInventory ||
            entityRef?.includes('Campaign') ||
            (tileEntityType === 'ProductSalesOverview' &&
                !featureFlags.showProductSalesOverviewProductDropdowns) ? null : (
                <ExpandButtonWrapper>
                    <StyledASIconButton
                        background={theme.colors.white}
                        hoverbackground={theme.colors.white}
                        hoverfill={theme.colors.textLink}
                        selectedfill={theme.colors.textLink}
                        onClick={() => {
                            handleChange && handleChange();
                            Tracking.trackGoal('Expand tile', {
                                entity: entityRef
                            });
                        }}
                        selected={expanded}>
                        <IconWithRotation expanded={expanded}>
                            <ASIcon
                                icon={Icons.ARROW_DROP_DOWN}
                                size={IconSizes.SMALL}
                            />
                        </IconWithRotation>
                    </StyledASIconButton>
                </ExpandButtonWrapper>
            )}
        </Wrapper>
    );
};

const NoInventory = styled('div')`
    text-align: center;
    font-size: ${({ theme }): string => theme.fontSizes.small};
`;

const Wrapper = styled('div')`
    font-size: ${({ theme }) => theme.fontSizes.normal};
    position: relative;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: 1px solid ${({ theme }) => theme.colors.frame};
    min-width: 0;

    @media (max-width: ${sizes.desktop.minWidth}px) {
        margin-bottom: ${({ theme }) => theme.space.single};
    }
`;

const TileAspectWrapper = styled('div')`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: ${({ theme }) => theme.space.half};
    margin-bottom: 12px;

    @media (max-width: ${sizes.desktop.minWidth}px) {
        padding: 0;
    }

    @media (max-width: ${sizes.notebook.maxWidth}px) {
        justify-content: space-between;

        & > * {
            flex: 1 1 auto;
            padding: 0;
            align-items: right;
            padding-right: ${({ theme }) => theme.space.half};
        }

        & > * > p {
            text-align: right;
            width: unset;
        }
    }
`;

const IconWrapper = styled('div')`
    display: flex;
    flex-direction: row-reverse;
    z-index: 3;
    position: absolute;
    top: 0px;
    right: 0px;
    height: ${({ theme }) => theme.space.double};
    width: ${({ theme }) => theme.space.double};
`;

const Row1Wrapper = styled('div')`
    grid-area: row1;
    padding: 0 ${({ theme }) => theme.fontSizes.header2};
    box-sizing: border-box;
    min-width: 0;

    @media (max-width: ${sizes.notebook.maxWidth}px) {
        padding-left: 0;
    }
`;

const Row2Wrapper = styled('div')`
    grid-area: row2;
    padding: 0;
    box-sizing: border-box;
    min-width: 0;
`;

const Overlay = styled('span')`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
`;

const StyledAccordion = styled(Accordion)`
    &:before {
        display: none;
    }
    box-shadow: 0px 0px 0px 0px;

    &.Mui-expanded {
        margin: 0;
    }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
    & .MuiAccordionSummary-content {
        margin: 0px 0px;
        display: grid;
        grid-template-columns: 112px auto;

        grid-template-areas:
            'pic row1'
            'pic row2';

        & .mui-expanded {
            margin: 0px 0px;
        }

        & .MuiLink-root {
            cursor: pointer;
        }

        @media (max-width: ${sizes.desktop.minWidth}px) {
            grid-template-areas:
                'pic row1'
                'row2 row2';
        }

        @media (max-width: ${sizes.notebook.maxWidth}px) {
            grid-template-columns: 81px auto; // 7 + 60 + 14 = 81, to have 14px between image and event name label.
        }
    }

    padding: 0;
    cursor: auto;

    > * {
        cursor: auto;
    }

    > .MuiAccordionSummary-content {
        margin: 0px 0px;

        &.Mui-expanded {
            margin: 0px 0px;
        }

        &.MuiLink-root {
            cursor: pointer;
        }
    }

    @media (max-width: ${sizes.desktop.minWidth}px) {
        display: block;
    }
`;

const ExpandedArea = styled(AccordionDetails)`
    padding: ${({ theme }) => theme.space.single};
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    border-top: 1px solid ${({ theme }) => theme.colors.frame};
`;

const ExpandButtonWrapper = styled('div')`
    position: absolute;
    left: 50%;
    top: 102px;
    transform: rotate(45deg);

    @media (max-width: ${sizes.desktop.minWidth}px) {
        top: 115px;
        left: 47.7%; // Seems to give the best results.
    }

    @media (min-width: ${sizes.tablet.minWidth}px) and (max-width: ${sizes.notebook.maxWidth}px) {
        top: unset;
        bottom: -11px;
    }
`;

const InventoryBinaryRowChartWrapper = styled('div')`
    position: relative;
    padding-top: ${({ theme }) => theme.space.single};
    min-height: 50px;
    min-width: 0;
`;

const CustomSvgIconWrapper = styled('div')<{ entityType?: string; backgroundColor?: string }>`
    grid-area: pic;
    display: flex;
    flex-shrink: 0;
    align-self: center;
    width: 112px;
    height: 112px;
    background: ${({ theme, entityType, backgroundColor }) =>
        //this logic needs to be changed since we have background variations for Reseller and Concert
        backgroundColor
            ? backgroundColor
            : entityType && entityType === 'Donor'
              ? theme.colors.toplistNoDonorImage
              : theme.colors.toplistNoCustomerImage};

    @media (max-width: ${sizes.notebook.maxWidth}px) {
        width: 60px;
        height: 60px;
        align-self: flex-start;
    }
`;

type IconStyleProps = {
    selected: boolean;
};

const StyledASIconButton = styled(ASIconButton)<IconStyleProps>`
    border-radius: ${({ theme }) => theme.borderRadius.small};
    ${({ selected, theme }) =>
        selected
            ? `border-top: 1px solid ${theme.colors.frame};
       border-left: 1px solid ${theme.colors.frame};`
            : `border-bottom: 1px solid ${theme.colors.frame};
       border-right: 1px solid ${theme.colors.frame};`}
`;

const StyledTileButton = styled(ASIconButton)`
    width: 100%;
`;

export { Tile };
