/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { CustomSvgIcon } from '../../../utils/CustomSvgIcon';
import {
    Desktop,
    Mobile,
    sizes,
    TabletToDesktop,
    TabletOrNotebook
} from '../../responsive/MediaQuery';
import { MenuLink } from '../link/MenuLink';
import { AS_LOGO_ICON } from 'assets/iconConstants';
import { IconType } from 'assets/iconsTypes';
export interface SideLink {
    href: string;
    label: string;
    id: string;
    target?: string;
}

export interface Menu {
    icon: IconType;
    title: string;
    links: SideLink[];
    id: string;
}

export interface SideBarProps {
    menus: Menu[];
}

type IconProps = {
    iconName: IconType;
};

const Icon = ({ iconName }: IconProps) => {
    const theme = useTheme();

    return (
        <>
            <Mobile>
                <CustomSvgIcon
                    fill={theme.colors.black}
                    iconName={iconName}
                />
            </Mobile>
            <TabletToDesktop>
                <CustomSvgIcon
                    fill={theme.colors.white}
                    iconName={iconName}
                    hoverfill={theme.colors.black}
                />
            </TabletToDesktop>
        </>
    );
};

const HamburgerIcon = ({ iconName }: IconProps) => {
    const theme = useTheme();

    return (
        <HamburgerWrapper>
            <CustomSvgIcon
                iconName={iconName}
                maxSize
                fill={theme.colors.white}
            />
        </HamburgerWrapper>
    );
};

export const SideBarMenu = ({ menus }: SideBarProps) => {
    const [menuOpen, setMenuOpen] = React.useState<Menu | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        const handleClickOutsideMenu = (event: MouseEvent) => {
            if (event && event.target) {
                if (!(event.target as HTMLElement).closest('#navigation-menu')) {
                    //clicked outside of Navigation menu so we close it
                    setMobileMenuOpen(false);
                    setMenuOpen(null);
                }
            }
        };

        window.addEventListener('click', handleClickOutsideMenu);

        return () => {
            window.removeEventListener('click', handleClickOutsideMenu);
        };
    }, []);

    const menuItemClicked = () => {
        setMenuOpen(null);
        setMobileMenuOpen(false);
    };

    const showLink = (item: SideLink, index: number) => (
        <MenuLink
            key={index}
            to={item.href}
            target={item.target}
            id={`${item.id}-link`}
            menuItemClicked={menuItemClicked}>
            {item.label}
        </MenuLink>
    );

    const showList = (items: SideLink[], title: string) => (
        <React.Fragment>
            <Desktop>
                <MenuListWrapper>
                    <MenuTitle>{title}</MenuTitle>
                    <div>{items.map(showLink)}</div>
                </MenuListWrapper>
            </Desktop>
            <TabletOrNotebook>
                <MenuMobileListWrapper>
                    <MenuTitle>{title}</MenuTitle>
                    <div>{items.map(showLink)}</div>
                </MenuMobileListWrapper>
            </TabletOrNotebook>
            <Mobile>
                <MenuMobileListWrapper>
                    <MenuTitle>{title}</MenuTitle>
                    <div>{items.map(showLink)}</div>
                </MenuMobileListWrapper>
            </Mobile>
        </React.Fragment>
    );

    const renderMenu = (menu: Menu, index: number) => {
        const open = menuOpen === menu;
        return (
            <Border key={index}>
                <ASMenuWrapper
                    onMouseEnter={() => {
                        setMenuOpen(menu);
                    }}
                    onClick={() => {
                        setMenuOpen(menu);
                    }}
                    onMouseLeave={() => {
                        setMenuOpen(null);
                    }}
                    id={menu.title}>
                    <Icon iconName={menu.icon} />
                    {open && showList(menu.links, t(menu.title))}
                </ASMenuWrapper>
            </Border>
        );
    };

    const renderMobileMenus = (menus: Menu[]) => {
        // In mobile, only show the dashboards and help menu items:
        const menuMobileFiltered = menus.filter(
            (m: Menu) => m.title === 'dashboards' || m.title === 'help'
        );

        return (
            <ASMenuWrapper
                onMouseEnter={() => {
                    setMobileMenuOpen(true);
                }}
                onTouchStart={() => {
                    setMobileMenuOpen(true);
                }}
                onClick={() => {
                    setMobileMenuOpen(true);
                }}
                onMouseLeave={() => {
                    setMobileMenuOpen(false);
                }}>
                <HamburgerIcon iconName={AS_LOGO_ICON} />
                {mobileMenuOpen && (
                    <SideBarWrapper>{renderMenus(menuMobileFiltered)}</SideBarWrapper>
                )}
            </ASMenuWrapper>
        );
    };

    const renderMenus = (menus: Menu[]) => menus.map((menu, index) => renderMenu(menu, index));

    return (
        <div id="navigation-menu">
            <TabletToDesktop>
                <SideBarWrapper>{renderMenus(menus)}</SideBarWrapper>
            </TabletToDesktop>
            <Mobile>
                <HamborgerMenuWrapper>{renderMobileMenus(menus)}</HamborgerMenuWrapper>
            </Mobile>
        </div>
    );
};

export const MenuTitle = styled('div')`
    white-space: nowrap;
    padding: ${({ theme }) => theme.space.single};
    padding-left: ${({ theme }) => theme.space.double};
    color: ${({ theme }) => theme.colors.sidebarIconFillHover};
`;

export const MenuListWrapper = styled('div')`
    position: absolute;
    left: ${({ theme }) => theme.space.menu};
    top: 0;
    background-color: ${({ theme }) => theme.colors.hamburgerMenuBg};
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    overflow: auto;
    z-index: 99;
`;

const MenuMobileListWrapper = styled('div')`
    position: absolute;
    left: ${({ theme }) => theme.space.menu};
    top: 0;
    background-color: ${({ theme }) => theme.colors.secondary};

    z-index: 99;
`;

const ASMenuWrapper = styled('div')`
    position: relative;
    height: ${({ theme }) => theme.space.menu};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    background-color: ${({ theme }) => theme.colors.sidebarColor};

    @media (max-width: ${sizes.mobile.maxWidth}px) {
        background-color: ${({ theme }) => theme.colors.sidebarMenuHover};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.sidebarMenuHover};

        path {
            fill: ${({ theme }) => theme.colors.black};
        }
    }
`;

const Border = styled('div')`
    border-bottom: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
    background-color: ${({ theme }) => theme.colors.background};
`;

const SideBarWrapper = styled('nav')`
    background-color: ${({ theme }) => theme.colors.sidebarColor};
    width: ${({ theme }) => theme.space.menu};
    height: 100%;
    position: absolute;
`;

const HamborgerMenuWrapper = styled('div')`
    background-color: '${({ theme }) => theme.colors.white}';
    width: ${({ theme }) => theme.space.menu};
    height: ${({ theme }) => theme.space.menu};
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: all;
`;

const HamburgerWrapper = styled('span')`
    background-color: ${({ theme }) => theme.colors.background};
    width: 100%;
    height: 100%;

    @media (max-width: ${sizes.notebook.minWidth}px) {
        background-color: ${({ theme }) => theme.colors.text};
    }
`;
