import React, { ChangeEvent, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/lab/Autocomplete';
import { flatten } from 'ramda';
import { styled } from '@mui/material/styles';
import { ASSelect } from '../../common/select';
import { MenuItem } from '../../common/select/ASSelect';
import { TextFieldStyled } from '../../global-search/components/commonStyled';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';

import { SortDirection } from 'views/dashboards/SalesOverview/types';
import { ASC, DESC } from 'utils/common/constants';
import { SEARCH_ICON } from 'assets/iconConstants';

import { settingsStore } from 'stores/settings';

interface TopListSettingsProp {
    menuItems: MenuItem[];
    handleChange: (item: MenuItem) => void;
    sortBy: string;
    sortDirection: SortDirection;
    sortDirectionChange: (event: React.MouseEvent, direction: SortDirection) => void;
    searchData: Array<{ id: string; label: string }>;
    onSelect: (value: string, label: string) => void;
    searchKey: string;
    searchPlaceholderTranslationKey?: string;
    showSearch?: boolean;
}

const ToplistSettings = ({
    menuItems,
    handleChange,
    sortBy,
    sortDirection,
    sortDirectionChange,
    searchData = [],
    onSelect = () => null,
    searchKey = 'nothing-selected',
    searchPlaceholderTranslationKey = 'shortlistEvents',
    showSearch = false
}: TopListSettingsProp) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const {
        featureFlags: { shortListTopList }
    } = useContext(settingsStore);

    return (
        <ToplistActionsContainer shortListTopList={shortListTopList}>
            {shortListTopList || showSearch ? (
                <InputWrapper>
                    <AutoCompleteIconWrapper>
                        <CustomSvgIcon
                            fill={theme.colors.text}
                            size={1}
                            iconName={SEARCH_ICON}
                        />
                    </AutoCompleteIconWrapper>
                    <Autocomplete
                        key={searchKey}
                        blurOnSelect
                        fullWidth
                        options={searchData}
                        getOptionLabel={(option: TopListSettingsProp['searchData'][0]) =>
                            option.label || option.id
                        }
                        onChange={(
                            event: ChangeEvent<{}>,
                            value: TopListSettingsProp['searchData'][0] | null
                        ) => {
                            if (value) {
                                onSelect(value.id, value.label);
                            }
                        }}
                        renderInput={(params) => (
                            <TextFieldStyled
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    placeholder: `${t(searchPlaceholderTranslationKey)}`
                                }}
                                fullWidth
                            />
                        )}
                        renderTags={() => <React.Fragment />}
                    />
                </InputWrapper>
            ) : null}
            <InputWrapper>
                <ASSelect
                    handleChange={(menuItem, event) => {
                        const [value, direction] = String(menuItem.value).split('||');
                        handleChange({ ...menuItem, value: value });
                        if (direction) {
                            sortDirectionChange(event, direction as 'asc' | 'desc');
                        }
                    }}
                    menuItems={flatten(
                        menuItems.map((menuItem) => [
                            {
                                value: `${menuItem.value}||asc`,
                                label: `${menuItem.label} (${t('asc')})`,
                                direction: ASC
                            },
                            {
                                value: `${menuItem.value}||desc`,
                                label: `${menuItem.label} (${t('desc')})`,
                                direction: DESC
                            }
                        ])
                    )}
                    menuLabel={`${t('sortBy')}: `}
                    value={`${sortBy}||${sortDirection}`}
                />
            </InputWrapper>
        </ToplistActionsContainer>
    );
};

export { ToplistSettings };

const InputWrapper = styled('div')`
    background-color: white;
    display: flex;
    align-items: center;
    font-style: italic;
`;

const ToplistActionsContainer = styled('div')<{ shortListTopList: boolean }>`
    display: grid;
    grid-template-columns: ${({ shortListTopList }) => (shortListTopList ? '3fr 2fr' : '1fr')};
    grid-template-rows: ${({ theme }) => theme.space.triple};
    grid-gap: ${({ theme }) => theme.space.single};
    margin-bottom: ${({ theme }) => theme.space.single};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: 1px solid ${({ theme }) => theme.colors.frame};
`;

const AutoCompleteIconWrapper = styled('div')`
    padding: 0px ${({ theme }) => theme.space.half};
`;
