import { path, pathOr } from 'ramda';
import DataLoader from 'dataloader';
import { Entity, PriceCategoriesAspects } from './entityLoader';
import { cubejsApi } from 'utils/api/CubeAPI';
import {
    TICKETS_PRODUCT_REF,
    TICKETS_TICKET_COUNT,
    TICKETS_STATUS,
    TICKETS_PRICE_CATEGORY,
    TRANSACTIONS_PRICE_CATEGORY,
    TRANSACTIONS_ITEMCOUNT,
    TRANSACTIONS_PRODUCT_REF,
    TRANSACTIONS_TYPE,
    TRANSACTIONS_REVENUE,
    TRANSACTIONS_CURRENCY,
    TRANSACTIONS_PAID_VS_FREE,
    TRANSACTIONS_MAX_ITEM_PRICE,
    TRANSACTIONS_AVERAGE_TICKET_PRICE
} from 'utils/common/constants';
import { Maybe } from 'utils/maybe';
import { ENTITIES_API_BATCT_URL } from 'utils/api/urls';
import { ASRequest } from 'utils/api/request';

export const inventoryCache: { [key: string]: InventoryData | null } = {};

export const getInventory = (key: string) => {
    const inventory = Maybe(inventoryCache[key]);
    return inventory.orElse(() => {
        inventoryLoader.load(key);
        return inventory;
    });
};

const MAX_URL_LENGTH = 1900;

export const splitKeys = (keys: string[]) => {
    const batches = [];

    let requestStringLength = 0;
    let currentBatch: string[] = [];

    keys.forEach((key) => {
        const keyLength = key.length;
        currentBatch.push(key);
        if (requestStringLength + keyLength < MAX_URL_LENGTH) {
            requestStringLength += keyLength;
        } else {
            batches.push(currentBatch);
            requestStringLength = keyLength;
            currentBatch = [];
        }
    });

    if (currentBatch.length) {
        batches.push(currentBatch);
    }

    return batches;
};

export const inventoryLoader = new DataLoader((keys: readonly string[]) => {
    const batchedKeys: string[][] = splitKeys([...keys] as string[]);

    return Promise.all(
        batchedKeys.map((batch) =>
            Promise.all([
                cubejsApi.load(
                    {
                        dimensions: [TICKETS_PRODUCT_REF, TICKETS_STATUS, TICKETS_PRICE_CATEGORY],
                        measures: [TICKETS_TICKET_COUNT, TRANSACTIONS_ITEMCOUNT],
                        filters: [
                            {
                                dimension: TICKETS_PRODUCT_REF,
                                operator: 'equals',
                                values: batch.slice()
                            },
                            {
                                dimension: TICKETS_STATUS,
                                operator: 'notEquals',
                                values: ['deleted']
                            }
                        ]
                    },
                    { method: 'POST' }
                ),
                cubejsApi.load(
                    {
                        dimensions: [
                            TRANSACTIONS_PRODUCT_REF,
                            TRANSACTIONS_PRICE_CATEGORY,
                            TRANSACTIONS_CURRENCY,
                            TRANSACTIONS_PAID_VS_FREE
                        ],
                        measures: [
                            TRANSACTIONS_ITEMCOUNT,
                            TRANSACTIONS_REVENUE,
                            TRANSACTIONS_MAX_ITEM_PRICE,
                            TRANSACTIONS_AVERAGE_TICKET_PRICE
                        ],
                        filters: [
                            {
                                dimension: TRANSACTIONS_PRODUCT_REF,
                                operator: 'equals',
                                values: batch.slice()
                            },
                            {
                                operator: 'equals',
                                dimension: TRANSACTIONS_TYPE,
                                values: ['purchased', 'cancelled']
                            }
                        ]
                    },
                    { method: 'POST' }
                ),
                ASRequest.request<Entity[]>({
                    url: ENTITIES_API_BATCT_URL,
                    params: { entityRefs: batch.join(',') }
                })
            ])
        )
    ).then((responses) => {
        let cubeData: [] = [];
        let soldData: [] = [];
        let data: Array<Entity> = [];

        responses.forEach((response) => {
            cubeData = cubeData.concat(
                pathOr([], ['loadResponse', 'results', '0', 'data'], response[0])
            ) as [];
            soldData = soldData.concat(
                pathOr([], ['loadResponse', 'results', '0', 'data'], response[1])
            ) as [];
            data = data.concat(response[2].data);
        });

        const modSoldData: any = soldData.flat().map((data: any) => {
            data[TICKETS_STATUS] = 'sold';
            data[TICKETS_PRICE_CATEGORY] = data[TRANSACTIONS_PRICE_CATEGORY];
            data[TICKETS_PRODUCT_REF] = data[TRANSACTIONS_PRODUCT_REF];
            data[TRANSACTIONS_MAX_ITEM_PRICE] = data[TRANSACTIONS_MAX_ITEM_PRICE];

            // TODO: Add TRANSACTIONS_AVERAGE_TICKET_PRICE here as well?
            data[TICKETS_TICKET_COUNT] = data[TRANSACTIONS_ITEMCOUNT];

            return data;
        });

        const inventoryData = cubeData
            .filter((data: any) => data[TICKETS_STATUS] != 'sold')
            .concat(modSoldData);

        const productMap = inventoryData.reduce((previous, value: any) => {
            const ticketCount = Number(value[TICKETS_TICKET_COUNT]);
            const product = value[TICKETS_PRODUCT_REF];
            const status = value[TICKETS_STATUS];
            const category = value[TICKETS_PRICE_CATEGORY];
            const totalRevenue = +value[TRANSACTIONS_REVENUE];
            const currency = value[TRANSACTIONS_CURRENCY];
            const complementary = value[TRANSACTIONS_PAID_VS_FREE] === 'Free' ? ticketCount : 0;
            const categoryMaxItemPrice = +value[TRANSACTIONS_MAX_ITEM_PRICE] || 0;
            const categoryAvgItemPrice = +value[TRANSACTIONS_AVERAGE_TICKET_PRICE] || 0;

            previous[product] = previous[product] || {
                price_categories: {}
            };

            previous[product][status] = previous[product][status] || 0;
            previous[product][status] += ticketCount;

            previous[product].totalRevenue = previous[product].totalRevenue || 0;
            previous[product].totalRevenue += totalRevenue;

            previous[product].avgItemPrice = previous[product].avgItemPrice || 0;
            previous[product].avgItemPrice += categoryAvgItemPrice;

            previous[product].complementary = previous[product].complementary || 0;
            previous[product].complementary += complementary;

            previous[product].currency = previous[product].currency || '';
            previous[product].currency = currency;

            previous[product].price_categories[category] =
                previous[product].price_categories[category] || {};

            previous[product].price_categories[category].price_category = category;

            previous[product].price_categories[category][status] =
                previous[product].price_categories[category][status] || 0;
            previous[product].price_categories[category][status] += ticketCount;

            previous[product].price_categories[category].maxItemPrice =
                previous[product].price_categories[category].maxItemPrice || 0;
            previous[product].price_categories[category].maxItemPrice += categoryMaxItemPrice;

            previous[product].price_categories[category].avgItemPrice =
                previous[product].price_categories[category].avgItemPrice || 0;
            previous[product].price_categories[category].avgItemPrice += categoryAvgItemPrice;

            previous[product].price_categories[category].totalRevenue =
                previous[product].price_categories[category].totalRevenue || 0;
            previous[product].price_categories[category].totalRevenue += totalRevenue || 0;

            return previous;
        }, {} as any);

        // data loader requires null entries to exist for keys not found on the server
        // so we map over the keys here to find their respective entries in the result
        // if they're not found we return null

        return keys.map((product) => {
            let value = Maybe(productMap[product]).getOrElse(null);

            const dataProductMatch = data.find(
                (d) => path(['entity', 'entity_ref'], d) === product
            );
            const inventory = pathOr(
                { items_for_sale: null, price_categories: [] as PriceCategoriesAspects[] },
                ['entity', 'aspects', 'inventory'],
                dataProductMatch
            );

            const reducedPriceCategories = inventory.price_categories.reduce((prev, curr) => {
                const priceCategory = curr.price_category;
                const aggregateHolds = curr.aggregate_holds;
                const aggregateKills = curr.aggregate_kills;
                const itemsForSale = curr.items_for_sale;

                prev[priceCategory] = prev[priceCategory] || {};
                prev[priceCategory].aggregate_holds =
                    (prev[priceCategory].aggregate_holds || 0) + (aggregateHolds || 0);
                prev[priceCategory].aggregate_kills =
                    (prev[priceCategory].aggregate_kills || 0) + (aggregateKills || 0);
                prev[priceCategory].price_category =
                    prev[priceCategory].price_category === priceCategory
                        ? prev[priceCategory].price_category || 0
                        : priceCategory || 0;
                prev[priceCategory].items_for_sale =
                    (prev[priceCategory].items_for_sale || 0) + (itemsForSale || 0);

                return prev;
            }, {} as any);

            const priceCategories: PriceCategoriesAspects[] = [];
            Object.values(reducedPriceCategories).map((value) => {
                priceCategories.push(value as PriceCategoriesAspects);
            });

            inventory.price_categories = priceCategories;

            if (value != null) {
                value = { ...inventory, ...value };
                (inventory.price_categories || []).forEach((priceCategory) => {
                    const priceCategoryName = priceCategory.price_category;
                    value.price_categories[priceCategoryName] = {
                        ...priceCategory,
                        ...value.price_categories[priceCategoryName]
                    };
                    value.price_categories[priceCategoryName] = {
                        ...priceCategory,
                        ...value.price_categories[priceCategoryName],
                        sellableCapacity:
                            (value.price_categories[priceCategoryName].items_for_sale || 0) -
                            (value.price_categories[priceCategoryName].held || 0) -
                            (value.price_categories[priceCategoryName].killed || 0)
                    };
                });
                calculateOpenStatus(value);
                Object.values(value.price_categories).map((category) =>
                    calculateOpenStatus(category as PriceCategoryInventory)
                );
            }
            inventoryCache[product] = value;

            const filteredValuePriceCategories = value && {
                ...value,
                price_categories: Object.fromEntries(
                    Object.entries(value.price_categories).filter(([k, v]) =>
                        Object.values(v as BaseInventory).some((el) => el && el !== k)
                    )
                )
            };

            const filteredInventoryPriceCategories = {
                ...inventory,
                price_categories: Object.values(inventory.price_categories).filter((v) =>
                    Object.values(v as BaseInventory).some((el) => el)
                )
            };

            return filteredValuePriceCategories || filteredInventoryPriceCategories;
        });
    });
});

const calculateOpenStatus = (priceCategoryInventories: PriceCategoryInventory | InventoryData) => {
    const open =
        (priceCategoryInventories.items_for_sale || 0) -
        ((priceCategoryInventories.held || 0) +
            (priceCategoryInventories.killed || 0) +
            (priceCategoryInventories.reserved || 0)) -
        (priceCategoryInventories.sold || 0);
    if (open >= 0) {
        priceCategoryInventories.open = open;
    } else if (!priceCategoryInventories.items_for_sale) {
        priceCategoryInventories.open = 0;
    } else {
        priceCategoryInventories.oversoldCount = Math.abs(open);
        priceCategoryInventories.open = 0;
    }
};

export interface InventoryData extends BaseInventory {
    price_categories: { [key: string]: PriceCategoryInventory };
}

export interface PriceCategoryInventory extends BaseInventory {
    price_category: string;
}

interface BaseInventory {
    aggregate_holds?: number;
    aggregate_kills?: number;
    items_for_sale: number;
    open?: number;
    sold?: number;
    killed?: number;
    held?: number;
    deleted?: number;
    reserved?: number;
    totalRevenue?: number;
    currency?: string;
    complementary?: number;
    maxItemPrice?: number;
    avgItemPrice?: number;
    capacity?: number;
    oversoldCount?: number;
}
