export const TRANSACTIONS_OCCURRED_AT = 'Transactions.occurredAt';
export const TRANSACTIONS_MIN_OCCURRED_AT = 'Transactions.minOccurredAt';
export const TRANSACTIONS_MAX_OCCURRED_AT = 'Transactions.maxOccurredAt';
export const TRANSACTIONS_CURRENCY = 'Transactions.currency';
export const TRANSACTIONS_REVENUE = 'Transactions.revenue';
export const TRANSACTIONS_TYPE = 'Transactions.transactionType';
export const TRANSACTIONS_PRIMARY_VS_SECONDARY = 'Transactions.primaryVSSecondary';
export const TRANSACTIONS_COMPLIMENTARY = 'Transactions.complimentary';
export const TRANSACTIONS_PAID_VS_FREE = 'Transactions.paidVsFree';
export const TRANSACTIONS_ITEMCOUNT = 'Transactions.itemCount';
export const TRANSACTIONS_DISTINCT_TRANSACTIONS = 'Transactions.orderCount';
export const TRANSACTIONS_DISTINCT_PRODUCTS = 'Transactions.productCount';
export const TRANSACTIONS_DISTINCT_CUSTOMERS = 'Transactions.customerCount';
export const TRANSACTIONS_DISTINCT_PRODUCTIONS = 'Products.productionCount';
export const TRANSACTIONS_PRODUCT_REF = 'Transactions.productRef';
export const TRANSACTIONS_BUYER_REF = 'Transactions.buyerRef';
export const TRANSACTIONS_PRICE_CATEGORY = 'Transactions.priceCategory';
export const TRANSACTIONS_PRICE_TYPE = 'Transactions.priceType';
export const TRANSACTIONS_DELIVERY_METHOD = 'Transactions.deliveryMethod';
export const TRANSACTIONS_PAYMENT_METHOD = 'Transactions.paymentMethod';
export const TRANSACTIONS_PRODUCT_OCCURED_AT_DATE = 'Transactions.occurredAtDate';
export const TRANSACTIONS_AVERAGE_LEAD_TIME = 'Transactions.averageLeadTime';
export const TRANSACTIONS_PARTITION = 'Transactions.partition';
export const TRANSACTIONS_SUBTENANT = 'Transactions.subtenant';
export const TRANSACTIONS_PRODUCT_LABEL = 'Products.productLabel';
export const TRANSACTIONS_PRODUCTION_LABEL = 'Products.productionLabel';
export const TRANSACTIONS_VENUE_LABEL = 'Products.venueLabel';
export const TRANSACTIONS_HALL_LABEL = 'Products.hallLabel';
export const TRANSACTIONS_VENUE_REF = 'Products.venueRef';
export const TRANSACTIONS_PRODUCT_TIME_BEGINS = 'Products.timedBeginsBegins';
export const TRANSACTIONS_PRODUCT_TIME_BEGINS_DOW = 'Products.timedBeginsDayOfWeek';
export const TRANSACTION_PRODUCT_THUMBNAIL = 'Products.presentationThumbnail';
export const TRANSACTIONS_OCCURRED_AT_YEAR = 'Transactions.occurredAtYear';
export const TRANSACTIONS_OCCURRED_AT_MONTH = 'Transactions.occurredAtMonth';
export const TRANSACTIONS_OCCURRED_AT_QUARTER = 'Transactions.occurredAtQuarter';
export const TRANSACTIONS_OCCURRED_AT_DOW = 'Transactions.occurredAtDayOfWeek';
export const TRANSACTIONS_AVERAGE_TICKET_PRICE = 'Transactions.averageTicketPrice';
export const TRANSACTIONS_SALES_CHANNEL = 'Transactions.salesChannel';
export const TRANSACTIONS_SALES_CHANNEL_TYPE = 'Transactions.salesChannelType';
export const TRANSACTIONS_LEAD_TIME_WEEK_GROUPED = 'Transactions.leadTimeWeekGrouped';
export const TRANSACTIONS_VARIANT = 'Transactions.variant';
export const TRANSACTIONS_DISTANCE_TO_VENUE = 'Transactions.distanceToVenue';
export const TRANSACTIONS_DISTANCE_TO_VENUE_MILES = 'Transactions.distanceToVenueInMiles';
export const TRANSACTIONS_DISTANCE_TO_VENUE_KM = 'Transactions.distanceToVenueInKms';
export const TRANSACTIONS_DISTANCE_TO_VENUE_GROUPED = 'Transactions.distanceToVenueGrouped';
export const TRANSACTIONS_DISTANCE_TO_VENUE_MILES_GROUPED =
    'Transactions.distanceToVenueMilesGrouped';
export const TRANSACTIONS_DISTANCE_TO_VENUE_KM_GROUPED = 'Transactions.distanceToVenueKmsGrouped';
export const TRANSACTIONS_DISTANCE_TO_VENUE_ROUNDED = 'Transactions.distanceToVenueRounded';
export const TRANSACTIONS_DISTANCE_TO_VENUE_MILES_ROUNDED =
    'Transactions.distanceToVenueInMilesRounded';
export const TRANSACTIONS_DISTANCE_TO_VENUE_KM_ROUNDED = 'Transactions.distanceToVenueInKmsRounded';
export const TRANSACTIONS_ORDER_REF = 'Transactions.orderRef';
export const TRANSACTIONS_MAX_ITEM_PRICE = 'Transactions.maxItemPrice';
export const TRANSACTIONS_PRODUCT_TYPE = 'Transactions.productType';
export const TRANSACTIONS_DEVICE_TYPE_KEY_FIGURES = 'Transactions.deviceTypeKeyFigures';
export const TRANSACTIONS_RETAILER_GROUPS_LABEL = 'Transactions.retailerGroupsLabel';
export const TRANSACTIONS_RETAILER_LABEL = 'Transactions.retailerLabel';
export const TRANSACTIONS_LEAD_TIME_GROUPED_BY_TEN = 'Transactions.leadTimeGroupedByTen';
export const TRANSACTIONS_DEVICE_BROWSER = 'Transactions.deviceBrowser';
export const TRANSACTIONS_DEVICE_TYPE = 'Transactions.deviceType';
export const TRANSACTIONS_ORIGIN = 'Transactions.origin';

export const DONATIONS_OCCURRED_AT = 'Donations.occurredAt';
export const DONATIONS_CURRENCY = 'Donations.currency';
export const DONATIONS_REVENUE = 'Donations.revenue';
export const DONATIONS_TYPE = 'Donations.transactionType';
export const DONATIONS_COMPLIMENTARY = 'Donations.complimentary';
export const DONATIONS_PAID_VS_FREE = 'Donations.paidVsFree';
export const DONATIONS_ITEMCOUNT = 'Donations.itemCount';
export const DONATIONS_DISTINCT_TRANSACTIONS = 'Donations.orderCount';
export const DONATIONS_DISTINCT_PRODUCTS = 'Donations.productCount';
export const DONATIONS_DISTINCT_CUSTOMERS = 'Donations.customerCount';
export const DONATIONS_BUYER_REF = 'Donations.buyerRef';
export const DONATIONS_PARTITION = 'Donations.partition';
export const DONATIONS_SUBTENANT = 'Donations.subtenant';
export const DONATIONS_PRODUCT_TIME_BEGINS_DOW = 'Products.timedBeginsDayOfWeek';
export const DONATIONS_OCCURRED_AT_DOW = 'Donations.occurredAtDayOfWeek';
export const DONATIONS_SALES_CHANNEL = 'Donations.salesChannel';
export const DONATIONS_ORDER_REF = 'Donations.orderRef';
export const DONATIONS_AMOUNT = 'Donations.amount';
export const DONATIONS_ONE_TIME_AND_REPEAT_DONORS = 'Donations.oneTimeAndRepeatDonors';
export const DONATIONS_FUND = 'DonationProducts.donationLabel';

export const ORDER_CAMPAIGN = 'Order.utmCampaign';
export const ORDER_REFERRER = 'Order.utmReferrer';
export const ORDER_PAYMENT_METHODS = 'Order.paymentMethod';
export const ORDER_COUNT_DISTINCT = 'Order.orderCount';
export const ORDER_MEDIUM = 'Order.utmMedium';
export const ORDER_SOURCE = 'Order.utmSource';
export const ORDER_EXTERNAL_DATA_ID = 'Order.externalDataId';

export const CUSTOMERS_ENTITY_REF = 'Customers.customerRef';
export const CUSTOMERS_LABEL = 'Customers.customerLabel';
export const CUSTOMERS_CITY = 'Customers.city';
export const CUSTOMERS_STATE = 'Customers.state';
export const CUSTOMERS_HASC_CODE = 'Customers.hascCode';
export const CUSTOMERS_HASC2_CODE = 'Customers.hascCode2';
export const CUSTOMERS_COUNT = 'Customers.customerCount';
export const CUSTOMERS_AGE = 'Customers.age';
export const CUSTOMERS_DISTINCT_COUNT = 'Customers.customerCount';
export const CUSTOMERS_POSTAL_CODE = 'Customers.postalCode';
export const CUSTOMERS_FULL_POSTAL_CODE = 'Customers.fullPostalCode';
export const CUSTOMERS_COUNTRY = 'Customers.country';
export const CUSTOMERS_COUNTRY_CODE = 'Customers.countryCode';
export const CUSTOMERS_TAGS = 'Customers.customerTagsFlat';
export const CUSTOMERS_BIRTH_DATE = 'Customers.birthDate';
export const CUSTOMERS_BIRTH_YEAR = 'Customers.birthYear';
export const CUSTOMERS_BIRTH_MONTH = 'Customers.birthMonth';
export const CUSTOMERS_GENDER = 'Customers.gender';
export const CUSTOMERS_ADDRESS = 'Customers.address';
export const CUSTOMERS_ADDRESS_2 = 'Customers.address2';
export const CUSTOMERS_IS_FIRST_TIME_BUYER = 'Customers.isFirstTimeBuyer';
export const CUSTOMERS_INCOME = 'Customers.income';
export const CUSTOMERS_EDUCATION = 'Customers.education';
export const CUSTOMERS_CUSTOM_SEGMENT = 'Customers.customSegment';
export const CUSTOMERS_DISTINCT_PRODUCT = 'Customers.distinctProduct';
export const CUSTOMERS_AUDIENCE_AGENCY = 'Customers.audienceAgency';
export const CUSTOMERS_AMOUNT_SPENT = 'Customers.amountSpent';
export const CUSTOMERS_AMOUNT_SPENT_BUCKETS = 'Customers.amountSpentBuckets250';
export const CUSTOMERS_THUMBNAIL = 'Customers.customerThumbnail';
export const CUSTOMERS_GROUPED_AGE = 'Customers.groupedAge';
export const CUSTOMERS_FIRST_NAME = 'Customers.firstName';
export const CUSTOMERS_LAST_NAME = 'Customers.lastName';
export const CUSTOMERS_KEY_FIGURES_AGE = 'Customers.keyFiguresAge';
export const CUSTOMERS_CLASSIFICATION_CATEGORIES_FLAT =
    'Customers.customerClassificationCategoriesFlat';
export const CUSTOMERS_HAVE_GENDER = 'Customers.hasGender';
export const CUSTOMERS_HAVE_CUSTOMER_LABEL = 'Customers.hasCustomerLabel';
export const CUSTOMERS_HAVE_LOCATION = 'Customers.hasLocation';
export const CUSTOMERS_EMAIL_MARKETING_PERMISSION = 'Customers.emailMarketingPermission';
export const CUSTOMERS_FIRST_TIME_BUYERS = 'Transactions.firstTimeBuyers';
export const CUSTOMERS_TOPICS = 'Customers.marketingPermissionsTopic';

export const EMAILS_COUNT = 'Emails.emailCount';
export const EMAILS_EMAIL = 'Emails.emailAddress';
export const EMAILS_MARKETING_PERMISSION = 'Emails.emailMarketingPermission';
export const EMAILS_IS_VALID_EMAIL = 'Emails.isValidEmail';

export const CUSTOMERS_EXPANDED_EMAIL = 'CustomersWithEmailAndPhone.emailAddress';
export const CUSTOMERS_EXPANDED_EMAIL_MARKETING_PERMISSION =
    'CustomersWithEmailAndPhone.emailMarketingPermission';
export const CUSTOMERS_EXPANDED_EMAIL_IS_VALID_EMAIL = 'CustomersWithEmailAndPhone.isValidEmail';
export const CUSTOMERS_EXPANDED_PHONE_HOME = 'CustomersWithEmailAndPhone.phoneHome';
export const CUSTOMERS_EXPANDED_PHONE_MOBILE = 'CustomersWithEmailAndPhone.phoneMobile';
export const CUSTOMERS_EXPANDED_PHONE_WORK = 'CustomersWithEmailAndPhone.phoneWork';
export const CUSTOMERS_HAVE_EMAIL = 'CustomersWithEmailAndPhone.hasEmailAddress';
export const CUSTOMERS_EMAIL_COUNT = 'CustomersWithEmailAndPhone.emailCount';

export const BASE_PRODUCTS_CLASSIFICATION_TYPE = 'BaseProducts.productClassificationType';
export const BASE_PRODUCTS_CLASSIFICATION_VARIANT = 'BaseProducts.productClassificationVariant';
export const BASE_PRODUCTS_CLASSIFICATION_CATEGORIES_FLAT =
    'BaseProducts.productClassificationCategoriesFlat';

export const PRODUCTS_INVENTORY_CAPACITY = 'Products.inventoryCapacity';
export const PRODUCTS_HAVE_INVENTORY_CAPACITY = 'Products.hasInventoryCapacity';
export const PRODUCTS_INVENTORY_HOLDS = 'Products.inventoryAggregateHolds';
export const PRODUCTS_INVENTORY_KILLS = 'Products.inventoryAggregateKills';
export const OFFER_LABEL = 'Offer.offerLabel';

export const TICKETS_PRICE_CATEGORY = 'Tickets.priceCategory';
export const TICKETS_SECTION = 'Tickets.section';
export const TICKETS_ROW = 'Tickets.row';
export const TICKETS_SEAT = 'Tickets.seat';
export const TICKETS_STATUS = 'Tickets.ticketStatus';
export const TICKETS_TICKET_COUNT = 'Tickets.ticketCount';
export const TICKETS_PRODUCT_REF = 'Tickets.productRef';

export const ENTITIES_CLASSIFICATION_TYPE = 'Entities.classificationType';

export const ASC = 'asc';
export const DESC = 'desc';

export const PRODUCTS_ENTITY_REF = 'Products.productRef';
export const PRODUCTS_TICKETS_SOLD = 'Products.ticketSoldCount';
export const PRODUCTS_TICKETS_OPEN = 'Products.ticketOpenCount';
export const PRODUCTS_TICKETS_HELD = 'Products.ticketHeldCount';
export const PRODUCTS_TICKETS_KILLED = 'Products.ticketKilledCount';
export const PRODUCTS_TICKETS_RESERVED = 'Products.ticketReservedCount';
export const PRODUCTS_COUNT = 'Products.productCount';
export const PRODUCTS_HAVE_PRODUCT_LABEL = 'Products.hasProductLabel';
export const PRODUCTS_HAVE_PRESENTATION_THUMBNAIL = 'Products.hasPresentationThumbnail';
export const PRODUCTS_CLASSIFICATION_TYPE = 'Products.productClassificationType';
export const JWT = 'jwt';
export const PRODUCTS_CLASSIFICATION_CATEGORIES_FLAT =
    'Products.productClassificationCategoriesFlat';
export const PRODUCTS_PERFORMER_ENTITY_REF_FLAT = 'Products.performerEntityRefFlat';
export const PRODUCTS_PROMOTER_LABEL_FLAT = 'Products.promoterLabelFlat';
export const PRODUCTS_PERFORMER_LABEL_FLAT = 'Products.performerLabelFlat';
export const PRODUCTS_TIME_BEGINS = 'Products.timedBeginsBegins';
export const PRODUCTS_DESCRIPTION = 'Products.presentationDescription';
export const PRODUCTS_PRODUCTION_REF = 'Products.productionRef';
export const PRODUCTS_PRODUCTION_THUMBNAIL = 'Products.productionThumbnail';
export const PRODUCTS_PERFORMER_THUMBNAIL = 'Products.performerThumbnail';
export const PRODUCTS_PRODUCTION_SEASON = 'Products.productionSeason';
export const PRODUCTS_PRODUCTION_PACKAGE = 'Products.productionPackage';

export const CAMPAIGN_RELATION_ENTITY = 'CampaignConnectedEntities.campaignConnectedEntityRef';
export const CAMPAIGN_RELATION_LABEL = 'CampaignConnectedEntities.label';
export const CAMPAIGN_RELATION_THUMBNAIL = 'CampaignConnectedEntities.thumbnail';
export const CAMPAIGN_RELATION_DESCRIPTION = 'CampaignConnectedEntities.description';
export const CAMPAIGN_RELATION_TIMED_BEGINS = 'CampaignConnectedEntities.timedBegins';

export const CHART_CAP = 6;
export const FACEBOOK_CSV = 'fcsv';
export const GOOGLE_CSV = 'gcsv';
export const CUSTOM_CSV = 'ccsv';

export const BASE_TRANSACTIONS_TYPE = 'BaseTransactions.transactionType';
export const BASE_TRANSACTIONS_OCCURRED_AT = 'BaseTransactions.occurredAt';
export const BASE_TRANSACTIONS_MIN_OCCURRED_AT = 'BaseTransactions.minOccurredAt';
export const BASE_TRANSACTIONS_MAX_OCCURRED_AT = 'BaseTransactions.maxOccurredAt';
export const GDPR_ITEMS_PRODUCT_LABEL = 'GdprItems.productLabel';
export const BASE_TRANSACTIONS_PRODUCT_ARCHETYPE = 'BaseTransactions.productArchetype';
export const BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT =
    'BaseTransactions.productArchetypeVariant';
export const BASE_TRANSACTIONS_PRODUCT_REF = 'BaseTransactions.productRef';
export const BASE_TRANSACTIONS_VARIANT = 'BaseTransactions.variant';
export const BASE_TRANSACTIONS_PRICE_CATEGORY = 'BaseTransactions.priceCategory';
export const BASE_TRANSACTIONS_ITEM_PRICE = 'BaseTransactions.itemPrice';
export const BASE_TRANSACTIONS_ITEM_COUNT = 'BaseTransactions.itemCount';
export const BASE_TRANSACTIONS_CURRENCY = 'BaseTransactions.currency';
export const BASE_TRANSACTIONS_DISCOUNT_AMOUNT = 'BaseTransactions.discountAmount';
export const BASE_TRANSACTIONS_DISCOUNT_PERCENTAGE = 'BaseTransactions.discountPct';
export const BASE_TRANSACTIONS_ORDER_REF = 'BaseTransactions.orderRef';
export const BASE_TRANSACTIONS_DESCRIPTION = 'BaseTransactions.description';
export const BASE_TRANSACTIONS_BUYER_REF = 'BaseTransactions.buyerRef';
export const BASE_TRANSACTIONS_REVENUE = 'BaseTransactions.revenue';
export const BASE_TRANSACTIONS_PARTITION = 'BaseTransactions.partition';
export const BASE_TRANSACTIONS_SUBTENANT = 'BaseTransactions.subtenant';
export const BASE_TRANSACTIONS_DELIVERY_METHOD = 'BaseTransactions.deliveryMethod';
export const BASE_TRANSACTIONS_SALES_CHANNEL = 'BaseTransactions.salesChannel';
export const BASE_TRANSACTIONS_COMPLIMENTARY = 'BaseTransactions.complimentary';
export const BASE_TRANSACTIONS_PAID_VS_FREE = 'BaseTransactions.paidVsFree';

export const MERCHANDISE_OCCURRED_AT = 'Merchandise.occurredAt';
export const MERCHANDISE_OCCURRED_AT_DOW = 'Merchandise.occurredAtDayOfWeek';
export const MERCHANDISE_CURRENCY = 'Merchandise.currency';
export const MERCHANDISE_REVENUE = 'Merchandise.revenue';
export const MERCHANDISE_TYPE = 'Merchandise.transactionType';
export const MERCHANDISE_COMPLIMENTARY = 'Merchandise.complimentary';
export const MERCHANDISE_PAID_VS_FREE = 'Merchandise.paidVsFree';
export const MERCHANDISE_ITEMCOUNT = 'Merchandise.itemCount';
export const MERCHANDISE_DISTINCT_TRANSACTIONS = 'Merchandise.orderCount';
export const MERCHANDISE_DISTINCT_PRODUCTS = 'Merchandise.productCount';
export const MERCHANDISE_DISTINCT_CUSTOMERS = 'Merchandise.customerCount';
export const MERCHANDISE_PRODUCT_REF = 'Merchandise.productRef';
export const MERCHANDISE_BUYER_REF = 'Merchandise.buyerRef';
export const MERCHANDISE_PRICE_CATEGORY = 'Merchandise.priceCategory';
export const MERCHANDISE_PRICE_TYPE = 'Merchandise.priceType';
export const MERCHANDISE_DELIVERY_METHOD = 'Merchandise.deliveryMethod';
export const MERCHANDISE_PAYMENT_METHOD = 'Merchandise.paymentMethod';
export const MERCHANDISE_PRODUCT_OCCURED_AT_DATE = 'Merchandise.occurredAtDate';
export const MERCHANDISE_AVERAGE_LEAD_TIME = 'Merchandise.averageLeadTime';
export const MERCHANDISE_PARTITION = 'Merchandise.partition';
export const MERCHANDISE_SUBTENANT = 'Merchandise.subtenant';
export const MERCHANDISE_OCCURRED_AT_YEAR = 'Merchandise.occurredAtYear';
export const MERCHANDISE_OCCURRED_AT_MONTH = 'Merchandise.occurredAtMonth';
export const MERCHANDISE_OCCURRED_AT_QUARTER = 'Merchandise.occurredAtQuarter';
export const MERCHANDISE_AVERAGE_TICKET_PRICE = 'Merchandise.averageTicketPrice';
export const MERCHANDISE_SALES_CHANNEL = 'Merchandise.salesChannel';
export const MERCHANDISE_VARIANT = 'Merchandise.variant';
export const MERCHANDISE_PRODUCT_LABEL = 'MerchandiseMeta.merchandiseLabel';
export const MERCHANDISE_PRODUCT_DESCRIPTION = 'MerchandiseMeta.merchandiseDescription';
export const MERCHANDISE_PRODUCT_THUMBNAIL = 'MerchandiseMeta.merchandiseThumbnail';
export const MERCHANDISE_CLASSIFICATION_TYPE = 'MerchandiseMeta.merchandiseClassificationType';

export const OBSERVATIONS_TYPE = 'Observations.observationType';
export const OBSERVATIONS_TYPE_GROUP = 'Observations.observationTypeGroup';
export const OBSERVATIONS_OCCURRED_AT = 'Observations.occurredAt';
export const OBSERVATIONS_OBS_ID = 'Observations.obsId';
export const OBSERVATIONS_IMPORTANCE = 'Observations.importance';
export const OBSERVATIONS_COUNT = 'Observations.observationCount';

export const RETAILERS_LABEL = 'Retailers.retailerLabel';

export const MARKETING_OCCURRED_AT = 'MarketingStatistics.occurredAtDate';
export const MARKETING_CAMPAIGN = 'MarketingStatistics.campaignRef';
export const MARKETING_ADGROUP = 'MarketingStatistics.adGroupRef';
export const MARKETING_AD = 'MarketingStatistics.adRef';
export const MARKETING_IMPRESSIONS = 'MarketingStatistics.impressionCount';
export const MARKETING_CLICKS = 'MarketingStatistics.clickCount';
export const MARKETING_COST = 'MarketingStatistics.cost';
export const MARKETING_CPC = 'MarketingStatistics.cpc';
export const MARKETING_CPM = 'MarketingStatistics.cpm';
export const MARKETING_CTR = 'MarketingStatistics.ctr';

export const MARKETING_TRANSACTIONS_TRANSACTION_OCCURRED_AT =
    'MarketingStatisticsTransactions.occurredAt';
export const MARKETING_TRANSACTIONS_REVENUE = 'MarketingStatisticsTransactions.revenue';
export const MARKETING_TRANSACTIONS_ORDERS = 'MarketingStatisticsTransactions.orderCount';
export const MARKETING_TRANSACTIONS_ROAS = 'MarketingStatisticsTransactions.roas';
export const MARKETING_TRANSACTIONS_DISTINCT_CUSTOMERS =
    'MarketingStatisticsTransactions.customerCount';

export const MARKETING_CAMPAIGN_REF = 'Campaign.campaignRef';
export const MARKETING_CAMPAIGN_LABEL = 'Campaign.campaignLabel';
export const MARKETING_CAMPAIGN_STARTS_AT = 'Campaign.timedBeginsBegins';
export const MARKETING_CAMPAIGN_AD_CLASSIFICATION = 'Campaign.campaignAdClassification';

export const MARKETING_ADGROUP_LABEL = 'AdGroup.adGroupLabel';

export const MARKETING_AD_LABEL = 'Ad.adLabel';

export const INVENTORY_OVER_TIME_PRODUCT = 'InventoryOverTime.productRef';
export const INVENTORY_OVER_TIME_STATUS = 'InventoryOverTime.status';
export const INVENTORY_OVER_TIME_COUNT = 'InventoryOverTime.itemCount';
export const INVENTORY_OVER_TIME_OCCURRED_AT = 'InventoryOverTime.occurredAt';

export const EMAIL_CAMPAIGN_INJECTION = 'EmailCampaignEvents.injectionCount';
export const EMAIL_CAMPAIGN_OPEN = 'EmailCampaignEvents.openCount';
export const EMAIL_CAMPAIGN_CLICK = 'EmailCampaignEvents.clickCount';
export const EMAIL_CAMPAIGN_INITIAL_OPEN = 'EmailCampaignEvents.initialOpenCount';
export const EMAIL_CAMPAIGN_UNSUBSCRIBE = 'EmailCampaignEvents.unsubscribeCount';
export const EMAIL_CAMPAIGN_BOUNCE = 'EmailCampaignEvents.bounceCount';
export const EMAIL_CAMPAIGN_DELIVERY = 'EmailCampaignEvents.deliveryCount';
export const EMAIL_CAMPAIGN_CAMPAIGN_REF = 'EmailCampaignEvents.campaignRef';
export const EMAIL_CAMPAIGN_CAMPAIGN_TYPE = 'EmailCampaignEvents.campaignType';
export const EMAIL_CAMPAIGN_CAMPAIGN_RELATED_ENTITIES =
    'EmailCampaignEvents.campaignRelatedEntities';
export const EMAIL_CAMPAIGN_EVENT_TYPE = 'EmailCampaignEvents.eventType';
export const EMAIL_CAMPAIGN_EMAIL_REF = 'EmailCampaignEvents.emailRef';
export const EMAIL_CAMPAIGN_CUSTOMER_REF = 'EmailCampaignEvents.customerRef';
export const EMAIL_CAMPAIGN_DEMOGRAPHY_GENDER = 'EmailCampaignEvents.gender';
export const EMAIL_CAMPAIGN_CLASSIFICATION_CATEGORIES_FLAT =
    'EmailCampaignEvents.customerClassificationCategoriesFlat';
export const EMAIL_CAMPAIGN_MAIN_COUNTRY_CODE = 'EmailCampaignEvents.addressMainCountryCode';
export const EMAIL_CAMPAIGN_MAIN_CITY = 'EmailCampaignEvents.addressMainCity';
export const EMAIL_CAMPAIGN_MAIN_HASC = 'EmailCampaignEvents.addressMainHasc';
export const EMAIL_CAMPAIGN_MAIN_POSTAL_CODE = 'EmailCampaignEvents.addressMainPostalCode';
export const EMAIL_CAMPAIGN_CAMPAIGN_LINK_URL = 'EmailCampaignEvents.campaignLinkUrl';
export const EMAIL_CAMPAIGN_TAGS = 'EmailCampaignEvents.customerTagsFlat';
export const EMAIL_CAMPAIGN_CAMPAIGN_SENT_AT = 'EmailCampaignEvents.campaignSentAt';
export const EMAIL_CAMPAIGN_OCCURRED_AT = 'EmailCampaignEvents.occurredAt';
export const EMAIL_CAMPAIGN_DISTINCT_EMAILS = 'EmailCampaignEvents.distinctEmailsCount';
export const EMAIL_CAMPAIGN_CUSTOMERS_KEY_FIGURES_AGE = 'EmailCampaignEvents.keyFiguresAge';
export const EMAIL_CAMPAIGN_FULL_POSTAL_CODE = 'EmailCampaignEvents.fullPostalCode';
export const EMAIL_CAMPAIGN_MAIN_HASC2 = 'EmailCampaignEvents.addressMainHasc2';
export const EMAIL_CAMPAIGN_CALCULATED_OPENS = 'EmailCampaignEvents.calculatedOpens';
export const EMAIL_CAMPAIGN_CALCULATED_CLICKS = 'EmailCampaignEvents.calculatedClicks';
export const EMAIL_CAMPAIGN_CALCULATED_BOUNCES = 'EmailCampaignEvents.calculatedBounces';
export const EMAIL_CAMPAIGN_DISTINCT_CLICKS = 'EmailCampaignEvents.distinctClickCount';
export const EMAIL_CAMPAIGN_SENT_AT_DOW = 'EmailCampaignEvents.campaignSentAtDayOfWeek';
export const EMAIL_CAMPAIGN_MIN_OCCURRED_AT = 'EmailCampaignEvents.minOccurredAt';
export const EMAIL_CAMPAIGN_MAX_OCCURRED_AT = 'EmailCampaignEvents.maxOccurredAt';

export const SEGMENT_MARKETING_PERMISSIONS = 'SegmentCustomerValidTickets.emailMarketingPermission';
export const SEGMENT_AUDIENCE_AGENCY = 'SegmentCustomerValidTickets.audienceAgency';
export const SEGMENT_CUSTOMER_POSTAL_CODE = 'SegmentCustomerValidTickets.postalCode';
export const SEGMENT_CUSTOMER_TAGS_FLAT = 'SegmentCustomerValidTickets.customerTagsFlat';
export const SEGMENT_CUSTOMER_AGE = 'SegmentCustomerValidTickets.age';
export const SEGMENT_CUSTOMER_GENDER = 'SegmentCustomerValidTickets.gender';
export const SEGMENT_CUSTOMER_HAS_EMAIL = 'SegmentCustomerValidTickets.hasEmailAddress';
export const SEGMENT_CUSTOMER_TRANSACTION_TYPE = 'SegmentCustomerValidTickets.transactionType';
export const SEGMENT_CUSTOMER_PRODUCT_REF = 'SegmentCustomerValidTickets.productRef';

export const EVENTS_RELATED_ENTITY_REF_FLAT = 'Events.relatedEntityRefFlat';
export const EVENTS_OCCURRED_AT = 'Events.occurredAt';
export const EVENTS_TYPE = 'Events.eventType';
export const EVENTS_TRANSACTIONS_ITEM_COUNT = 'Events.transactionCount';
export const EVENTS_TRANSACTIONS_ITEM_PRICE = 'Events.itemPrice';
export const EVENTS_RELATED_PRODUCT_REF = 'Events.productRef';
export const EVENTS_TRANSACTIONS_CURRENCY = 'Events.currency';
export const EVENTS_TRANSACTION_TYPE = 'Events.transactionType';
export const EVENTS_RELATED_ARCHETYPE = 'Events.relatedArchetype';
export const EVENTS_RELATED_ARCHETYPE_VARIANT = 'Events.relatedArchetypeVariant';

export const CAMPAIGN_PRODUCTS_CUSTOMERS_TAGS_FLAT = 'CampaignProducts.customerTagsFlat';
export const CAMPAIGN_PRODUCTS_CUSTOMERS_AGE = 'CampaignProducts.age';
export const CAMPAIGN_PRODUCTS_MARKETING_PERMISSION = 'CampaignProducts.emailMarketingPermission';
export const CAMPAIGN_PRODUCTS_CUSTOMER_COUNT = 'CampaignProducts.customerCount';

export const EMAIL_MARKETING_TOPICS_TOPICS = 'EmailMarketingTopics.marketingPermissionsTopic';
export const EMAIL_MARKETING_TOPICS_COUNT = 'EmailMarketingTopics.emailCount';
export const EMAIL_MARKETING_TOPICS_MARKETING_PERMISSION =
    'EmailMarketingTopics.emailMarketingPermission';
export const EMAIL_MARKETING_TOPICS_VALID_EMAIL = 'EmailMarketingTopics.isValidEmail';
export const EMAIL_MARKETING_TOPICS_EMAIL_ADDRESS = 'EmailMarketingTopics.emailAddress';

export const NEXT_VISIT_NEXT_VISIT = 'NextVisit.nextVisit';
export const NEXT_VISIT_NEXT_VISIT_MIN = 'NextVisit.nextVisitMin';

export const TRX_CUSTOMERS_CUBE = 'TransactionsAndCustomers';
export const TRX_CUSTOMERS_PRICE_CATEGORY = 'TransactionsAndCustomers.priceCategory';
export const TRX_CUSTOMERS_PRICE_TYPE = 'TransactionsAndCustomers.priceType';
export const TRX_CUSTOMERS_TAGS_FLAT = 'TransactionsAndCustomers.customerTagsFlat';
export const TRX_CUSTOMERS_LEAD_TIME_WEEK_GROUPED = 'TransactionsAndCustomers.leadTimeWeekGrouped';
export const TRX_CUSTOMERS_DISTINCT_PRODUCT = 'TransactionsAndCustomers.distinctProduct';
export const TRX_CUSTOMERS_AUDIENCE_AGENCY = 'TransactionsAndCustomers.audienceAgency';
export const TRX_CUSTOMERS_FULL_POSTAL_CODE = 'TransactionsAndCustomers.fullPostalCode';
export const TRX_CUSTOMERS_HASC_CODE = 'TransactionsAndCustomers.hascCode';
export const TRX_CUSTOMERS_HASC2_CODE = 'TransactionsAndCustomers.hascCode2';
export const TRX_CUSTOMERS_AMOUNT_SPENT_BUCKETS = 'TransactionsAndCustomers.amountSpentBuckets250';
export const TRX_CUSTOMERS_DISTANCE_TO_VENUE_KM = 'TransactionsAndCustomers.distanceToVenueInKms';
export const TRX_CUSTOMERS_DISTANCE_TO_VENUE_MILES =
    'TransactionsAndCustomers.distanceToVenueInMiles';
export const TRX_CUSTOMERS_DISTANCE_TO_VENUE = 'TransactionsAndCustomers.distanceToVenue';
export const TRX_CUSTOMERS_DISTANCE_TO_VENUE_ROUNDED =
    'TransactionsAndCustomers.distanceToVenueRounded';
export const TRX_CUSTOMERS_DISTANCE_TO_VENUE_MILES_ROUNDED =
    'TransactionsAndCustomers.distanceToVenueInMilesRounded';
export const TRX_CUSTOMERS_DISTANCE_TO_VENUE_KM_ROUNDED =
    'TransactionsAndCustomers.distanceToVenueInKmsRounded';
export const TRX_CUSTOMERS_COUNTRY_CODE = 'TransactionsAndCustomers.countryCode';
export const TRX_CUSTOMERS_GROUPED_AGE = 'TransactionsAndCustomers.groupedAge';
export const TRX_CUSTOMERS_CLASSIFICATION_CATEGORIES_FLAT =
    'TransactionsAndCustomers.customerClassificationCategoriesFlat';
export const TRX_CUSTOMERS_POSTAL_CODE = 'TransactionsAndCustomers.postalCode';
export const TRX_CUSTOMERS_CITY = 'TransactionsAndCustomers.city';
export const TRX_CUSTOMERS_STATE = 'TransactionsAndCustomers.state';
export const TRX_CUSTOMERS_COUNTRY = 'TransactionsAndCustomers.country';
export const TRX_CUSTOMERS_ORIGIN = 'TransactionsAndCustomers.origin';
export const TRX_CUSTOMERS_THUMBNAIL = 'TransactionsAndCustomers.thumbnail';
export const TRX_CUSTOMERS_AGE = 'TransactionsAndCustomers.age';
export const TRX_CUSTOMERS_AVERAGE_LEAD_TIME = 'TransactionsAndCustomers.averageLeadTime';
export const TRX_CUSTOMERS_CURRENCY = 'TransactionsAndCustomers.currency';
export const TRX_CUSTOMERS_DISTINCT_TRANSACTIONS = 'TransactionsAndCustomers.orderCount';
export const TRX_CUSTOMERS_CUSTOMER_LABEL = 'TransactionsAndCustomers.customerLabel';
export const TRX_CUSTOMERS_BUYER_REF = 'TransactionsAndCustomers.buyerRef';

export const TRX_CUSTOMERS_DISTINCT_CUSTOMERS = 'TransactionsAndCustomers.customerCount';
export const TRX_CUSTOMERS_PRODUCT_COUNT = 'TransactionsAndCustomers.productCount';
export const TRX_CUSTOMERS_ITEMCOUNT = 'TransactionsAndCustomers.itemCount';
export const TRX_CUSTOMERS_REVENUE = 'TransactionsAndCustomers.revenue';
export const TRX_CUSTOMERS_SALES_CHANNEL = 'TransactionsAndCustomers.salesChannel';

export const TRX_CUSTOMERS_OCCURRED_AT = 'TransactionsAndCustomers.occurredAt';
export const TRX_CUSTOMERS_OCCURRED_AT_DOW = 'TransactionsAndCustomers.occurredAtDayOfWeek';
export const TRX_CUSTOMERS_PARTITION = 'TransactionsAndCustomers.partition';
export const TRX_CUSTOMERS_SUBTENANT = 'TransactionsAndCustomers.subtenant';
export const TRX_CUSTOMERS_GENDER = 'TransactionsAndCustomers.gender';
export const TRX_CUSTOMERS_TYPE = 'TransactionsAndCustomers.transactionType';

export const SEGMENT_CERTAIN_TIME_PERIOD_TRANSACTION_TYPE =
    'SegmentCustomersCertainTimePeriod.transactionType';
export const SEGMENT_CERTAIN_TIME_PERIOD_OCCURRED_AT =
    'SegmentCustomersCertainTimePeriod.occurredAt';

export const SEGMENT_EVENT_DATE_TRANSACTION_TYPE = 'SegmentCustomerEventDate.transactionType';
export const SEGMENT_EVENT_DATE_OCCURRED_AT = 'SegmentCustomerEventDate.occurredAt';
export const SEGMENT_EVENT_DATE_TIMED_BEGINS_BEGINS = 'SegmentCustomerEventDate.timedBeginsBegins';

export const customerTagsDimensions = [
    CUSTOMERS_TAGS,
    TRX_CUSTOMERS_TAGS_FLAT,
    EMAIL_CAMPAIGN_TAGS,
    SEGMENT_CUSTOMER_TAGS_FLAT,
    CAMPAIGN_PRODUCTS_CUSTOMERS_TAGS_FLAT
];

export const ICON_TYPE_COPY = 'copy';
export const ICON_TYPE_SAVE = 'save';
export const ICON_TYPE_RENAME = 'rename';
export const ICON_TYPE_REMOVE = 'remove';
export const ICON_TYPE_REFRESH = 'refresh';

export const SOP_ADVANCE = 'SOP Advance';
export const PLAYED_OFF = 'played Off';
export const SALES = 'Sales';
export const EOP_ADVANCE = 'EOP Advance';

export const DEFAULT_SEGMENT = 'defaultSegment';
