import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from '@mui/material';
import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { styled } from '@mui/material/styles';
import { Direction, Sorting, TableProps, Header, Row } from './types';
import { ASTableLegends } from './ASTableLegends';
import { ASC, DESC } from 'utils/common/constants';
import { string2TextAlign } from 'utils/text';

type HeaderProps = {
    headerbold?: number;
    textAlign?: string;
    columnWidth?: string;
    paddingRight?: string;
    paddingLeft?: string;
    tableStyleProps?: any;
};

export const ASTable = ({
    headers,
    data,
    sorting,
    pagination,
    showPagination,
    onChange,
    topHeaders,
    tableLegends,
    headerbold,
    rowClick,
    noWidth = false,
    fixedWidth,
    tableLayout = 'auto',
    styledTable,
    isSelected,
    tableStyleProps,
    className
}: TableProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    if (!headers && data.length > 0) {
        headers = Object.keys(data[0]).map((item) => ({ label: item, column: item }));
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        onChange && onChange({ newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        onChange && onChange({ newPage: 0, newRowsPerPage });
    };

    const createTableHead = (sorting: Sorting | null, headers?: Header[]) => {
        if (!headers) {
            return null;
        }

        const onClickSort = (newOrderBy: string) => {
            let direction: Direction = ASC;

            if (sorting && sorting.orderBy === newOrderBy) {
                direction = sorting.orderDirection === ASC ? DESC : ASC;
            }

            onChange && onChange({ newOrderBy, newOrderDirection: direction });
        };

        return (
            <TableHeadAS tableStyleProps={tableStyleProps}>
                {topHeaders ? topHeaders : null}
                <TableRowASHeader>
                    {headers.map((header, index) => (
                        <TableCellASHeader
                            key={index}
                            headerbold={headerbold}
                            textAlign={header.textAlign}
                            columnWidth={header.columnWidth}
                            paddingRight={header.paddingRight}
                            paddingLeft={header.paddingLeft}
                            tableStyleProps={tableStyleProps}
                            align={string2TextAlign(header.textAlign)}>
                            {!header.noSorting && sorting ? (
                                <TableSortLabel
                                    active={header.column === sorting.orderBy}
                                    direction={sorting.orderDirection}
                                    onClick={() => onClickSort(header.column)}
                                    IconComponent={ArrowDropDownIcon}>
                                    {header.label}
                                </TableSortLabel>
                            ) : (
                                header.label
                            )}
                        </TableCellASHeader>
                    ))}
                </TableRowASHeader>
            </TableHeadAS>
        );
    };

    const createTableRows = (data: Row[], headers: Header[]) => (
        <TableBodyAS>
            {data.map((row: Row, index: number) => (
                <TableRowAS
                    hover
                    key={index}
                    onClick={() => {
                        rowClick && rowClick(row);
                    }}
                    isSelected={isSelected == null ? true : isSelected(row)}
                    clickable={Boolean(rowClick)}
                    tableStyleProps={tableStyleProps}>
                    {headers.map(
                        ({
                            column,
                            transform,
                            textAlign,
                            paddingRight,
                            paddingLeft
                        }: {
                            column: string;
                            transform?: (row: Row) => JSX.Element;
                            textAlign?: string;
                            paddingRight?: string;
                            paddingLeft?: string;
                        }) => (
                            <TableCellAS
                                component="th"
                                scope="row"
                                key={`header-${column}-${index}`}
                                noWidth={noWidth}
                                fixedWidth={fixedWidth}
                                textAlign={textAlign}
                                paddingRight={paddingRight}
                                paddingLeft={paddingLeft}
                                tableStyleProps={tableStyleProps}>
                                {column === 'timedBegins' && row.daysOut < 0 ? (
                                    <FadedRowText>
                                        {transform ? transform(row) : row[column]}
                                    </FadedRowText>
                                ) : (
                                    <RowText tableStyleProps={tableStyleProps}>
                                        {transform ? transform(row) : row[column]}
                                    </RowText>
                                )}
                            </TableCellAS>
                        )
                    )}
                </TableRowAS>
            ))}
        </TableBodyAS>
    );

    const StyledTable = styledTable;

    return (
        <TableContainerAS
            tableLayout={tableLayout}
            tableStyleProps={tableStyleProps}
            className={className}>
            {StyledTable ? (
                <StyledTable>
                    {createTableHead(sorting, headers)}
                    {createTableRows(data, headers as Header[])}
                </StyledTable>
            ) : (
                <Table>
                    {createTableHead(sorting, headers)}
                    {createTableRows(data, headers as Header[])}
                </Table>
            )}
            {showPagination && pagination && (
                <PaginationContainer paginationStyleProps={tableStyleProps?.paginationStyleProps}>
                    <TablePagination
                        rowsPerPageOptions={pagination.rowsPerPageOptions}
                        component="div"
                        count={pagination.totalRowCount}
                        rowsPerPage={pagination.rowsPerPage}
                        page={pagination.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={t('rowsPerPage')}
                        labelDisplayedRows={function defaultLabelDisplayedRows({
                            from,
                            to,
                            count
                        }) {
                            return `${from}-${to} ${t('to')} ${count !== -1 ? count : `${t('more than')} ${to}`}`;
                        }}
                    />
                    {tableLegends ? (
                        <React.Fragment>
                            <LegendWrapper>
                                <ASTableLegends
                                    color={theme.colors.legendOne}
                                    label={`0-50% ${t('sold')}`}
                                />
                                <ASTableLegends
                                    color={theme.colors.legendTwo}
                                    label={`51-75% ${t('sold')}`}
                                />
                                <ASTableLegends
                                    color={theme.colors.legendThree}
                                    label={`76-95% ${t('sold')}`}
                                />
                                <ASTableLegends
                                    color={theme.colors.legendFour}
                                    label={`96-100% ${t('sold')}`}
                                />
                                <ASTableLegends
                                    color={theme.colors.legendFive}
                                    label={t('oversold')}
                                    icon="!"
                                />
                                <ASTableLegends
                                    color={theme.colors.legendSix}
                                    label={t('noData')}
                                    border={true}
                                    icon="X"
                                    iconColor={theme.colors.text}
                                />
                                <ASTableLegends
                                    color={theme.colors.white}
                                    label={t('noInventory')}
                                    border={true}
                                    icon="!"
                                    iconColor={theme.colors.black}
                                    margin={false}
                                />
                            </LegendWrapper>
                        </React.Fragment>
                    ) : null}
                </PaginationContainer>
            )}
        </TableContainerAS>
    );
};

export const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                <FirstPage />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                <LastPage />
            </IconButton>
        </div>
    );
};

type StyleTableProps = {
    tableLayout: string;
    tableStyleProps?: any;
};

const RowText = styled('p')<{
    tableStyleProps: any;
}>`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ tableStyleProps, theme }) =>
        tableStyleProps?.tableRowFontSize
            ? tableStyleProps.tableRowFontSize
            : theme.fontSizes.small};
`;

const FadedRowText = styled('p')`
    color: ${({ theme }) => theme.colors.textFaded};
    font-size: ${({ theme }) => theme.fontSizes.small};
`;

const TableContainerAS = styled('div')<StyleTableProps>`
    table {
        table-layout: ${({ tableLayout }) => tableLayout};
    }
    width: 100%;
    padding: ${({ theme, tableStyleProps }) =>
        tableStyleProps?.tablePadding ? tableStyleProps : theme.space.half};
    border-radius: 0px;
    white-space: nowrap;
`;

const TableCellAS = styled(TableCell)<{
    noWidth: boolean;
    textAlign?: string;
    fixedWidth?: number;
    paddingRight?: string;
    paddingLeft?: string;
    tableStyleProps?: any;
}>`
    width: ${({ fixedWidth }) => (fixedWidth ? `${fixedWidth}px` : '')};
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
    padding: ${({ theme }) => theme.space.half};
    padding-right: ${({ textAlign, theme, paddingRight }) =>
        textAlign ? (paddingRight ? paddingRight : '7px') : `${theme.space.half}`};
    padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '7px')};
    color: ${({ theme }) => theme.colors.text};
    max-width: ${({ noWidth }) => (noWidth ? '' : `150px`)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: ${({ tableStyleProps }) =>
        tableStyleProps?.rowBottomBorder ? `1px solid ${tableStyleProps.rowBottomBorder}` : 'none'};
`;

const TableCellASHeader = styled(TableCell)<HeaderProps>`
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
    padding: ${({ theme }) => theme.space.half};
    font-size: ${({ theme, tableStyleProps }) =>
        tableStyleProps?.tableHeaderFontSize
            ? tableStyleProps?.tableHeaderFontSize
            : theme.fontSizes.normal};
    font-weight: ${({ headerbold }) => (headerbold ? headerbold : 600)};
    border-bottom: ${({ tableStyleProps }) =>
        tableStyleProps?.rowBottomBorder ? `1px solid ${tableStyleProps.rowBottomBorder}` : 'none'};
    white-space: nowrap;
    width: ${({ columnWidth }) => columnWidth};
    padding-right: ${({ paddingRight }) => (paddingRight ? paddingRight : '7px')};
    padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '7px')};
    .MuiTableSortLabel-icon path {
        display: ${({ tableStyleProps }) => (tableStyleProps?.sortIcon ? 'none' : 'block')};
    }
    .MuiTableSortLabel-iconDirectionDesc {
        background-repeat: no-repeat;
        background-size: contain;
        background-image: ${({ tableStyleProps }) =>
            tableStyleProps?.sortIcon ? `url(${tableStyleProps.sortIcon})` : 'none'};
        margin-top: ${({ tableStyleProps }) => (tableStyleProps?.sortIcon ? '7px' : '0px')};
        height: ${({ tableStyleProps }) =>
            tableStyleProps?.sortIconSize ? tableStyleProps.sortIconSize : '18px'};
        width: ${({ tableStyleProps }) =>
            tableStyleProps?.sortIconSize ? tableStyleProps.sortIconSize : '18px'};
    }
    .MuiTableSortLabel-iconDirectionAsc {
        transform: rotate(180deg);
        background-repeat: no-repeat;
        background-size: contain;
        background-image: ${({ tableStyleProps }) =>
            tableStyleProps?.sortIcon ? `url(${tableStyleProps.sortIcon})` : 'none'};
        margin-bottom: ${({ tableStyleProps }) => (tableStyleProps?.sortIcon ? '7px' : '0px')};
        height: ${({ tableStyleProps }) =>
            tableStyleProps?.sortIconSize ? tableStyleProps.sortIconSize : '18px'};
        width: ${({ tableStyleProps }) =>
            tableStyleProps?.sortIconSize ? tableStyleProps.sortIconSize : '18px'};
    }
`;

const TableHeadAS = styled(TableHead)<{
    tableStyleProps?: any;
}>`
    font-size: ${({ theme }) => theme.fontSizes.normal};
    font-weight: 500;
    display: table-header-group;
    width: 100%;
    table-layout: fixed;
    height: ${({ tableStyleProps }) =>
        tableStyleProps?.tableRowHeight ? tableStyleProps.tableRowHeight : 'auto'};
    background-color: ${({ tableStyleProps }) =>
        tableStyleProps?.tableHeaderBackground ? tableStyleProps.tableHeaderBackground : 'unset'};
`;

const TableRowAS = styled(TableRow)<{
    isSelected: boolean;
    clickable: boolean;
    tableStyleProps?: any;
}>`
    &:nth-of-type(even) {
        background-color: ${({ theme, tableStyleProps }) =>
            tableStyleProps?.rowBackground
                ? tableStyleProps?.rowBackground
                : theme.colors.secondary};
    }
    background-color: ${({ tableStyleProps }) =>
        tableStyleProps?.rowBackground ? tableStyleProps?.rowBackground : 'unset'};
    height: ${({ tableStyleProps }) =>
        tableStyleProps?.tableRowHeight ? tableStyleProps?.tableRowHeight : 'auto'};
    display: table-row-group;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 0px;
    font-size: ${({ tableStyleProps }) =>
        tableStyleProps?.tableRowFontSize ? tableStyleProps.tableRowFontSize : 'auto'};
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.4)};
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
    &.MuiTableRow-hover:hover {
        opacity: 1;
        background-color: ${({ tableStyleProps }) =>
            tableStyleProps?.rowHoverBackground ? tableStyleProps.rowHoverBackground : 'auto'};
    }
`;

export const PaginationContainer = styled('div')<{
    paginationStyleProps?: any;
}>`
    display: flex;
    justify-content: space-between;
    background-color: ${({ paginationStyleProps }) =>
        paginationStyleProps?.backgroundColor
            ? paginationStyleProps.backgroundColor
            : 'transparent'};
    padding-left: ${({ paginationStyleProps }) =>
        paginationStyleProps?.paddingLeft ? paginationStyleProps.paddingLeft : '0px'};
    font-size: ${({ paginationStyleProps, theme }) =>
        paginationStyleProps?.fontSize ? paginationStyleProps.fontSize : theme.fontSizes.medium};

    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows,
    .MuiInputBase-root {
        font-size: ${({ theme }) => theme.fontSizes.normal};
    }

    .MuiTablePagination-displayedRows {
        line-height: 1.53;
    }

    .MuiButtonBase-root svg {
        font-size: 20px;
    }
`;

const LegendWrapper = styled('div')`
    display: flex;
    padding: ${({ theme }) => theme.space.single};
    margin-right: ${({ theme }) => theme.space.double};
`;

const TableBodyAS = styled(TableBody)<{}>`
    height: auto;
    overflow-y: hidden;
    display: contents;
    padding-top: ${({ theme }) => theme.space.half};
    padding-bottom: ${({ theme }) => theme.space.single};
`;

const TableRowASHeader = styled(TableRow)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.dividers};
`;
