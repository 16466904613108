import { SortDirection } from './types';
import { ASCubeFilter, ASCubeQuery } from 'utils/common/types';

import { TRANSACTIONS_PRODUCT_REF } from 'utils/common/constants';
import { DateRange } from '../../common/datepicker';
import { formatCubeQueryDate } from '../../common/datepicker/dateUtils';

export const getEventListQuery = (
    salesTime: string,
    timePeriod: DateRange,
    filters: ASCubeFilter[],
    baseFilters: ASCubeFilter[],
    sortBy: string,
    sortDirection: SortDirection,
    tableDimensions: string[],
    tableMeasures: string[],
    tenantTimezone: string
): ASCubeQuery => ({
    dimensions: tableDimensions,
    measures: tableMeasures,
    timeDimensions: [
        {
            dimension: salesTime,
            dateRange: timePeriod.map((date) => formatCubeQueryDate(date)) as [string, string]
        }
    ],
    filters: filters
        .filter((filter) => filter.dimension !== TRANSACTIONS_PRODUCT_REF)
        .concat(baseFilters),
    order: {
        [sortBy]: sortDirection
    },
    timezone: tenantTimezone
});
