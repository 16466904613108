import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Highcharts, { isNumber } from 'highcharts';
import React, { MutableRefObject, useRef } from 'react';
import { pathOr, compose, mergeDeepLeft } from 'ramda';
import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import { TimeDimensionGranularity } from '@cubejs-client/core';
import { cloneDeep } from 'lodash';
import { FilterDrawer } from '../filters/FilterDrawer';
import { DateFormatContext } from '../common/datepicker';
import { LoadingContainer } from '../common/loader';
import { StyledPopper, FilterAutocomplete } from '../global-search/components/commonStyled';
import { Mobile, TabletToDesktop } from '../responsive/MediaQuery';
import { DateRange } from '../common/datepicker/types';
import {
    FadedChartAction,
    FadedChartMenuContainer,
    FadedChartMenuItemContainer,
    InvertChartAction
} from './fadedIcons';
import { BarAndColumnChart } from './BarAndColumnChart';
import { getColumnChartQuery, getBarDataQuery, getCustomersCountQuery } from 'utils/queries';
import { mapColumnChartData, mapFilterChartData } from 'utils/mappers';
import { cubejsApi } from 'utils/api/CubeAPI';
import { AvailableDimensions, DimensionDataStore, FullDimension } from 'stores/dimensionData';
import {
    Operator,
    BarAndColumnDimensionProps,
    ASCubeFilter,
    DimensionData
} from 'utils/common/types';
import { Maybe } from 'utils/maybe';
import {
    CSV_EXPORT_ICON,
    IN_FILTER_ICON,
    NOT_IN_FILTER_ICON,
    PNG_EXPORT_ICON,
    RESET_FILTER_ICON,
    SEARCH_ICON
} from 'assets/iconConstants';
import { settingsStore } from 'stores/settings';

import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { Tracking } from 'externals/tracking';
import { filterValueCheck } from 'utils/filter/filterCheck';
import { filterUnknown, getChartPNG, getFilterValues } from 'utils/chartUtils';
import { customerTagsDimensions } from 'utils/common/constants';
import { formatChartLabels } from 'utils/chartLabelFormatters';

export const BarAndColumnChartDimension = ({
    metric,
    measures,
    salesTime,
    timePeriod,
    filters,
    baseFilters,
    dimension,
    granularity,
    onFilter,
    dashboardName,
    titlePrefix = 'salesBy',
    color,
    transformChartLabel,
    hideFilterDrawer = false,
    doubleFilterDrawer = false,
    barChartOptions = {},
    columnChartOptions = {},
    subtitleTwo = '',
    multipleChartOperator,
    eventOccurredAt,
    overrideTitle
}: BarAndColumnDimensionProps) => {
    const { excludeDashboardComponent, tenantTimezone, focusedSegments, dispatch, featureFlags } =
        React.useContext(settingsStore);
    const { formatDate } = React.useContext(DateFormatContext);
    const { t } = useTranslation();
    const [_, dimensionName]: [string, AvailableDimensions] = dimension.split('.') as [
        string,
        AvailableDimensions
    ];
    const [loading, setLoading] = React.useState<boolean>(false);
    const [rawColumnData, setRawColumnData] = React.useState<DimensionData[]>([]);
    const [columnData, setColumnData] = React.useState<Highcharts.SeriesColumnOptions[]>([]);
    const [rawBarData, setRawBarData] = React.useState<DimensionData[]>([]);
    const [metricsCount, setMetricsCount] = React.useState<{ [key: string]: string }[]>([]);
    const [barData, setBarData] = React.useState<Highcharts.Options['series']>([]);
    const [visibleFocusedDimensions, setVisibleFocusedDimensions] = React.useState<string[]>([]);

    const dimensionData = React.useContext(DimensionDataStore);
    const linearColors = Array.isArray(color) ? color : undefined;

    const [operator, setOperator] = React.useState<Operator>(
        Maybe((filters || []).find((f) => f.dimension === dimension))
            .map((filter) => filter.operator as Operator)
            .getOrElse('equals')
    );

    const [searchElement, setSearchElement] = React.useState<Element | null>(null);
    const [exportGraph, setExportGraph] = React.useState<JSX.Element | null>(null);
    const theme = useTheme();
    const [chart, setChart] = React.useState<MutableRefObject<{ chart: Highcharts.Chart }>>();

    const transformTimeDimensions = eventOccurredAt || salesTime;

    const dimensionInfo = dimensionData[dimensionName];

    const translatedDimension = overrideTitle ?? t(dimensionInfo.label)!;
    const exportFileName = `${dashboardName}-${translatedDimension}-${timePeriod
        .map((date) => formatDate(new Date(date)))
        .join('-')}`;

    const chartId = translatedDimension.split('.').join('').split(' ').join('_');

    if (!transformChartLabel && dimensionInfo.labelFormatter) {
        transformChartLabel = dimensionInfo.labelFormatter;
    }

    const filterGrouped = (data: DimensionData[]): DimensionData[] => {
        if (dimensionInfo.label === 'campaign' && !featureFlags.showGroupedSalesByCampaign) {
            return data.filter((dataItem: any) => {
                return !['(direct)', '(not set)', '(referral)', '(organic)'].includes(
                    dataItem['Order.utmCampaign']
                );
            });
        } else {
            return data;
        }
    };

    const getColumnData = useRef(
        debounce(
            (
                dimension: FullDimension,
                measures: string[],
                salesTime: string,
                timePeriod: DateRange,
                baseFilters: ASCubeFilter[],
                filters: ASCubeFilter[],
                granularity: TimeDimensionGranularity,
                eventOccurredAt?: string
            ) => {
                setLoading(true);
                cubejsApi
                    .load(
                        getColumnChartQuery(
                            dimension,
                            measures,
                            salesTime,
                            timePeriod,
                            baseFilters,
                            filters,
                            tenantTimezone,
                            granularity,
                            eventOccurredAt
                        )
                    )
                    .then(
                        compose(
                            setRawColumnData,
                            (data: DimensionData[]) => filterGrouped(data),
                            (data) => filterUnknown(dimension, dimensionInfo, data),
                            pathOr<DimensionData[]>([], ['loadResponse', 'results', '0', 'data'])
                        )
                    )
                    .finally(() => {
                        setLoading(false);
                    });
            },
            500
        )
    ).current;

    const getBarData = useRef(
        debounce(
            (
                dimension: FullDimension,
                measures: string[],
                salesTime: string,
                timePeriod: DateRange,
                baseFilters: ASCubeFilter[],
                filters: ASCubeFilter[]
            ) => {
                setLoading(true);
                Promise.all([
                    cubejsApi.load(
                        getBarDataQuery(
                            dimension,
                            measures,
                            salesTime,
                            timePeriod,
                            baseFilters,
                            filters,
                            tenantTimezone
                        )
                    ),
                    customerTagsDimensions.includes(dimension)
                        ? cubejsApi.load(
                              getCustomersCountQuery(
                                  dimension,
                                  measures,
                                  salesTime,
                                  timePeriod,
                                  filters,
                                  baseFilters,
                                  tenantTimezone
                              )
                          )
                        : Promise.resolve()
                ])
                    .then((response) => {
                        const rawChartData = compose(
                            setRawBarData,
                            (data: DimensionData[]) => filterGrouped(data),
                            (data: DimensionData[]) => data.slice(),
                            (data: DimensionData[]) => filterUnknown(dimension, dimensionInfo, data)
                        );
                        rawChartData(
                            pathOr([], ['loadResponse', 'results', '0', 'data'], response[0])
                        );

                        const totalsCount = pathOr(
                            [],
                            ['loadResponse', 'results', '0', 'data'],
                            response[1]
                        );
                        setMetricsCount(totalsCount);
                    })
                    .finally(() => setLoading(false));
            }
        )
    ).current;

    React.useEffect(() => {
        if (multipleChartOperator) {
            setOperator(multipleChartOperator);
        }
    }, [multipleChartOperator]);

    React.useEffect(() => {
        if (excludeDashboardComponent.includes(dimensionName)) {
            return;
        }
        getBarData(dimension, measures, salesTime, timePeriod, baseFilters, filters);
    }, [salesTime, timePeriod, filters, baseFilters, excludeDashboardComponent]);

    //Fetch the data from cube whenever the salesTime, timePeriod or filters change.
    React.useEffect(() => {
        if (excludeDashboardComponent.includes(dimensionName)) {
            return;
        }
        getColumnData(
            dimension,
            measures,
            salesTime,
            timePeriod,
            baseFilters,
            filters,
            granularity,
            eventOccurredAt
        );
    }, [salesTime, timePeriod, filters, granularity, excludeDashboardComponent]);

    React.useEffect(() => {
        setColumnData(
            mapColumnChartData(
                t,
                theme,
                dimension,
                transformTimeDimensions,
                t(dimensionInfo.label),
                metric,
                transformChartLabel
            )(rawColumnData) as Highcharts.SeriesColumnOptions[]
        );
    }, [rawColumnData, metric]);

    React.useEffect(() => {
        if (focusedSegments && barData && barData.length > 0) {
            const filterValues = getFilterValues(filters, dimension);

            const myVisibleFocusedDimensions = (barData[0] as any).data
                .filter((d: any) => filterValues.includes(d.category))
                .filter((d: any) => !d.custom?.hide)
                .map((d: any) => d.category);

            setVisibleFocusedDimensions(myVisibleFocusedDimensions);
        } else {
            setVisibleFocusedDimensions([]);
        }
    }, [focusedSegments]);

    // When the rawData, metric we need to recalculate the data points
    React.useEffect(() => {
        setBarData(
            mapFilterChartData(
                'bar',
                t,
                theme,
                filters,
                dimension,
                metric,
                operator,
                translatedDimension,
                dimensionInfo,
                metricsCount,
                transformChartLabel,
                color,
                linearColors,
                focusedSegments,
                visibleFocusedDimensions
            )(rawBarData) as Highcharts.SeriesBarOptions[]
        );

        // Remove focused state if the user just cleared the last filter:
        if (focusedSegments && filters.length === 0) {
            dispatch({ type: 'focusedSegments', payload: false });
            setVisibleFocusedDimensions([]);
            return;
        }
    }, [rawBarData, metric, metricsCount, focusedSegments, visibleFocusedDimensions]);

    const id = `search-menu-${dimension}`;
    const Search = (
        <StyledPopper
            id={id}
            anchorEl={searchElement}
            open={Boolean(searchElement)}
            marginTop={'5px'}>
            <FilterAutocomplete
                ref={(ref: HTMLElement) => {
                    Maybe(ref)
                        .chain((ref) => Maybe(ref.getElementsByTagName('input')[0]))
                        .map((input) => input.focus());
                }}
                options={rawBarData}
                getOptionLabel={(_option) => {
                    const option = _option as DimensionData;
                    return String(formatChartLabels(option[dimension], t, transformChartLabel));
                }}
                renderInput={(params) => (
                    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                        <div style={{ position: 'absolute', left: -20, top: 15 }}>
                            <CustomSvgIcon
                                fill={theme.colors.black}
                                size={1.2}
                                iconName={SEARCH_ICON}
                            />
                        </div>
                        <TextField
                            {...params}
                            label=""
                        />
                    </div>
                )}
                disablePortal={true}
                onBlur={(event) => {
                    event.preventDefault();
                    setSearchElement(null);
                }}
                onChange={(_, _value) => {
                    const value = _value as DimensionData | null;
                    if (value == null) {
                        return;
                    }
                    const filterValue = String(value[dimension]);
                    const breadcrumbValue = String(
                        formatChartLabels(value[dimension], t, transformChartLabel)
                    );
                    onFilter({
                        type: 'dimensionClick',
                        operator: operator,
                        dimension,
                        value: {
                            value: filterValue,
                            label: breadcrumbValue
                        }
                    });
                    Tracking.trackGoal('Filtered by dimension', {
                        posthogUniqueKey: dimensionData[dimensionName].label,
                        dimension,
                        chartName: t(dimensionData[dimensionName].label as string),
                        filters: filterValueCheck(breadcrumbValue)
                    });
                }}
            />
        </StyledPopper>
    );

    const chartMenu = (
        <>
            <Mobile>
                <FadedChartMenuItemContainer>
                    <FadedChartAction
                        aria-describedby={searchElement ? id : undefined}
                        aria-haspopup="true"
                        onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
                            setSearchElement(event.currentTarget)
                        }
                        title={t('search')}
                        iconName={SEARCH_ICON}>
                        {Search}
                    </FadedChartAction>
                </FadedChartMenuItemContainer>
            </Mobile>
            <TabletToDesktop>
                <FadedChartMenuItemContainer>
                    <FadedChartAction
                        aria-describedby={searchElement ? id : undefined}
                        aria-haspopup="true"
                        onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
                            setSearchElement(event.currentTarget)
                        }
                        title={t('search')}
                        iconName={SEARCH_ICON}>
                        {Search}
                    </FadedChartAction>
                    <InvertChartAction
                        onClick={() => setOperator(operator === 'equals' ? 'notEquals' : 'equals')}
                        title={t(operator)}
                        iconName={operator === 'equals' ? IN_FILTER_ICON : NOT_IN_FILTER_ICON}
                        invert={operator}
                        disabled={focusedSegments ? true : false}
                    />
                    <FadedChartAction
                        onClick={() => onFilter({ type: 'reset', dimension })}
                        title={t('resetFilter')}
                        iconName={RESET_FILTER_ICON}
                    />
                    {exportGraph}
                </FadedChartMenuItemContainer>
            </TabletToDesktop>
        </>
    );

    React.useEffect(() => {
        if (chart) {
            setExportGraph(
                <React.Fragment>
                    <FadedChartAction
                        onClick={() => {
                            // When exporting to CSV, order the columns alphabetically (A20-4413):
                            const chartOrderedColumns = cloneDeep(chart?.current?.chart);

                            chartOrderedColumns.series = chartOrderedColumns.series.sort((a, b) => {
                                if (a.name.includes(t('otherRowCount'))) {
                                    return 1;
                                }
                                return a.name.localeCompare(b.name);
                            });

                            chartOrderedColumns.downloadCSV();
                        }}
                        title={t('exportGraphCSV')}
                        iconName={CSV_EXPORT_ICON}
                    />
                    <FadedChartAction
                        onClick={() => {
                            getChartPNG(chart.current.chart, exportFileName);
                        }}
                        title={t('exportGraphPNG')}
                        iconName={PNG_EXPORT_ICON}
                    />
                </React.Fragment>
            );
        }
    }, [chart]);

    const defaultBarChartOptions: Highcharts.Options = {
        chart: {
            marginLeft: 15,
            marginRight: 0,
            type: 'bar'
        },
        xAxis: {
            showEmpty: true,
            labels: {
                align: 'left',
                step: 1,
                enabled: true,
                x: 0,
                y: 4,
                style: { whiteSpace: 'nowrap' },
                useHTML: true,
                formatter() {
                    // @ts-ignore
                    const chartWidth =
                        // @ts-ignore
                        this.chart.containerWidth - this.chart.spacing[1] - this.chart.spacing[3];
                    const pointLabelValue = this.value;
                    if (isNumber(pointLabelValue)) {
                        return ``;
                    }
                    return `
              <div style="width: ${chartWidth}px; display: flex; justify-content: space-between; cursor: pointer; position: absolute; left: 0px;">
                  <span style="padding-left: ${theme.space.single}; max-width: ${chartWidth}px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; position: relative;">
                    ${pointLabelValue}
                  </span>
              </div>
          `;
                }
            }
        },
        exporting: {
            csv: {
                columnHeaderFormatter: null
            }
        },
        tooltip: {
            enabled: true
        }
    };

    const defaultColumnChartOptions: Highcharts.Options = {
        exporting: {
            csv: {
                columnHeaderFormatter: null
            }
        }
    };

    const mergedBarOptions = mergeDeepLeft(
        barChartOptions,
        defaultBarChartOptions
    ) as Highcharts.Options;

    const mergedColumnOptions = mergeDeepLeft(
        columnChartOptions,
        defaultColumnChartOptions
    ) as Highcharts.Options;

    if (excludeDashboardComponent.includes(dimensionName)) {
        return <React.Fragment />;
    }

    return (
        <FadedChartMenuContainer>
            <FilterDrawer
                name={`${t(titlePrefix)} ${translatedDimension}`}
                borderColor={color}
                chartMenu={chartMenu}
                hideFilterDrawer={hideFilterDrawer}
                doubleFilterDrawer={doubleFilterDrawer}>
                <BarAndColumnChart
                    id={chartId}
                    filters={filters}
                    dimension={dimension}
                    onFilter={onFilter}
                    granularity={granularity}
                    operator={operator}
                    barSeries={barData}
                    barOptions={mergedBarOptions}
                    columnOptions={mergedColumnOptions}
                    chartCallback={setChart}
                    columnSeries={columnData}
                    metric={metric}
                    exportFileName={exportFileName}
                    alignedLabel={true}
                    subtitleOne={translatedDimension}
                    subtitleTwo={subtitleTwo}
                    borderColor={color}
                />
                {loading && <LoadingContainer />}
            </FilterDrawer>
        </FadedChartMenuContainer>
    );
};
