import { Hit } from 'utils/api/globalSearch/types';

export const getLocation = (hit: Hit) => `${getCity(hit)} ${getCountry(hit)}`;
export const getCity = (hit: Hit) => hit.city || '';
export const getCountry = (hit: Hit) => (hit.country ? `(${hit.country})` : '');
export const getEmail = (hit: Hit) => hit.email || '';
export const getArchetype = (hit: Hit) => hit.archetype_and_variant || '';
export const getLabel = (hit: Hit) => hit.presentation_label || '';
export const getDateTime = (
    hit: Hit,
    getTenantTimezoneTimeOfDay: (date: string, timezone: string) => Date,
    dateFormatter: (date: Date) => string,
    timezone: string
) =>
    hit.timed_begins ? dateFormatter(getTenantTimezoneTimeOfDay(hit.timed_begins, timezone)) : '';
export const getReference = (hit: Hit) => hit.entity_reference || '';

// TODO: needs a proper path when it's actually possible to open event/customer/order/...
export const getHref = (hit: Hit) => `/activity-stream/EntityOverview/${getReference(hit)}`;
