/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { formatNumber } from 'utils/formatting/numbers';
import { Aspect } from '../common/tile/TileAspect';

interface InventoryAspectTileProps {
    inventoryAspects: Aspect[];
    expandedTileTitle?: string;
    tileEntityType?: string;
}

const InventoryTile = ({
    inventoryAspects,
    expandedTileTitle = 'currentInventory',
    tileEntityType
}: InventoryAspectTileProps) => {
    const { t } = useTranslation();

    return (
        <InventoryTileWrapper>
            <InventoryTileTitle>{t(expandedTileTitle)}</InventoryTileTitle>
            <InventoryDataWrapper>
                <InventoryAspectWrapper>
                    {inventoryAspects
                        .filter(
                            (aspect) =>
                                tileEntityType !== 'ProductSalesOverview' ||
                                aspect.label !== 'Capacity' // PSO shouldn't have Capacity metric.
                        )
                        .map((aspect) =>
                            aspect.value !== t('N/A') ? (
                                <InventoryAspect
                                    key={aspect.label}
                                    dividers={
                                        tileEntityType !== 'ProductSalesOverview' ? true : false
                                    }>
                                    <InventoryAspectValue>
                                        {typeof aspect.value === 'number'
                                            ? formatNumber(Number(aspect.value))
                                            : aspect.value}
                                    </InventoryAspectValue>
                                    <InventoryAspectLabel>{aspect.label}</InventoryAspectLabel>
                                </InventoryAspect>
                            ) : null
                        )}
                </InventoryAspectWrapper>
            </InventoryDataWrapper>
        </InventoryTileWrapper>
    );
};

export { InventoryTile };

const InventoryTileWrapper = styled('div')`
    width: 100%;
    padding-top: ${({ theme }) => theme.space.single};
`;

const InventoryDataWrapper = styled('div')`
    padding-bottom: ${({ theme }) => theme.space.single};
`;

const InventoryAspectWrapper = styled('div')`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    padding-right: ${({ theme }) => theme.space.single};
    padding-left: ${({ theme }) => theme.space.single};
`;

const InventoryAspect = styled('div')<{ dividers: boolean }>`
    text-align: center;
    border-right: ${({ theme, dividers }) =>
        dividers ? `1px solid ${theme.colors.chart1}` : 'none'};
    &:first-of-type {
        border-left: ${({ theme, dividers }) =>
            dividers ? `1px solid ${theme.colors.chart1}` : 'none'};
    }
`;

const InventoryAspectValue = styled('p')`
    font-size: ${({ theme }) => theme.fontSizes.header};
    font-weight: 600;
`;

const InventoryAspectLabel = styled('p')`
    font-size: ${({ theme }) => theme.fontSizes.normal};
`;

export const InventoryTileTitle = styled(`span`)`
    display: inline-block;
    margin-left: 0;
    margin-bottom: ${({ theme }) => theme.space.half};
    font-size: ${({ theme }) => theme.fontSizes.header};
    font-weight: 500;
    width: 100%;
    margin-left: ${({ theme }) => theme.space.single};
`;
