/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { styled } from '@mui/material/styles';

import { ToolsMobileMenu } from '../components/menus/ToolsMobileMenu';

import { GlobalSearch } from '../components/global-search';
import { Mobile, TabletToDesktop, sizes } from '../components/responsive/MediaQuery';
import { AS_LOGO_ICON } from 'assets/iconConstants';
import { globalSearchAPI } from 'utils/api/globalSearch/GlobalSearchAPI';
import { settingsStore } from 'stores/settings';
import { CDN_URL } from 'utils/api/urls';

type LayoutHeaderProps = {
    title: string;
    entityName?: string | number | undefined;
    menus?: JSX.Element[];
    filterBreadcrumbs?: JSX.Element | null;
    Search?: React.ReactNode;
    extraMenus?: JSX.Element[];
};

const LayoutHeader = ({
    menus = [],
    title,
    entityName,
    filterBreadcrumbs,
    Search,
    extraMenus
}: LayoutHeaderProps) => {
    const { tenantIcon } = React.useContext(settingsStore);

    const displayMenus = (menus: JSX.Element[] | undefined) => {
        if (!menus) {
            return null;
        }

        return menus.map((menu, index) => {
            if (menu.props.fetchSegments) {
                menu.props.fetchSegments2 = () => {};
            }

            if (menu.props.skipMenuWrapper) {
                return <React.Fragment key={index}>{menu}</React.Fragment>;
            }

            return <ASMenuWrapper key={index}>{menu}</ASMenuWrapper>;
        });
    };

    const displayMobileMenus = (menus: JSX.Element[]) => {
        const mobileMenusWithTools = [<ToolsMobileMenu key="ToolsMobileMenu" />];

        const mobileMenu = menus.map((menu, index) => {
            if (!menu.props.showOnMobile) {
                return null;
            }

            if (menu.props.skipMenuWrapper) {
                return <React.Fragment key={index}>{menu}</React.Fragment>;
            }

            return <MobileMenuWrapper key={index}>{menu}</MobileMenuWrapper>;
        });

        const mobileMenus = [...mobileMenusWithTools, ...mobileMenu];

        return <RightWrapper mobileMenusLength={mobileMenus.length}>{mobileMenus}</RightWrapper>;
    };

    const displayMobileExtraMenus = (menus: JSX.Element[]) => {
        const mobileMenu = menus.map((menu, index) => (
            <MobileMenuWrapper key={index}>{menu}</MobileMenuWrapper>
        ));

        return <RightWrapper>{mobileMenu}</RightWrapper>;
    };

    return (
        <React.Fragment>
            <Mobile>
                <Header id="dashboardHeader">
                    <Wrapper>
                        <UpperWrapper>
                            {Search ? (
                                Search
                            ) : (
                                <GlobalSearchWrapper>
                                    <GlobalSearch
                                        globalSearchAPI={globalSearchAPI}
                                        id="search"
                                        placeholder="search"
                                        position="static"
                                    />
                                </GlobalSearchWrapper>
                            )}
                            {menus && displayMobileMenus(menus)}
                        </UpperWrapper>
                        <LowerWrapper>
                            <DashboardHeader id="dashboardTitle">{title}</DashboardHeader>
                            {extraMenus ?? (
                                <MenuWrapper>
                                    {displayMobileExtraMenus(extraMenus || [])}
                                </MenuWrapper>
                            )}
                        </LowerWrapper>
                    </Wrapper>
                </Header>
            </Mobile>

            <TabletToDesktop>
                <Header id="dashboardHeader">
                    <Wrapper>
                        <DashboardHeader id="dashboardTitle">
                            {title}
                            {entityName ? <EntityName>{entityName}</EntityName> : null}
                        </DashboardHeader>
                        {extraMenus && <MenuWrapper>{displayMenus(extraMenus)}</MenuWrapper>}
                        {Search ? (
                            Search
                        ) : (
                            <GlobalSearch
                                globalSearchAPI={globalSearchAPI}
                                id="search"
                            />
                        )}
                        {menus && menus.length > 0 ? (
                            <RightWrapper id="dashboardMenuItems">
                                {displayMenus(menus)}
                            </RightWrapper>
                        ) : null}
                    </Wrapper>

                    {filterBreadcrumbs ? (
                        <BreadCrumbs>
                            <BreadcrumbsWrapper>
                                <FilterBreadcrumbs>{filterBreadcrumbs}</FilterBreadcrumbs>
                                {tenantIcon !== AS_LOGO_ICON ? (
                                    <PoweredBy src={`${CDN_URL}/tenant-images/poweredByAS.svg`} />
                                ) : null}
                            </BreadcrumbsWrapper>
                        </BreadCrumbs>
                    ) : null}
                </Header>
            </TabletToDesktop>
        </React.Fragment>
    );
};

const Header = styled('div')`
    @media (min-width: ${sizes.tablet.minWidth}px) {
        margin-right: 0;
        width: 100%;
        z-index: 1000;
    }
`;

const Wrapper = styled('div')`
    display: flex;
    height: ${({ theme }) => theme.space.menu};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (min-width: ${sizes.tablet.minWidth}px) {
        height: ${({ theme }) => theme.space.menu};
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.white};
    }
`;

const RightWrapper = styled('div')<{ mobileMenusLength?: number }>`
    display: flex;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.small};
    justify-content: end;

    @media (max-width: ${sizes.mobile.maxWidth}px) {
        background-color: ${({ theme }) => theme.colors.text};
        width: 100%;
        padding: 5px 0;
        flex: ${({ mobileMenusLength }) => (mobileMenusLength ? mobileMenusLength : 0)};
        justify-content: space-around;
    }

    @media (min-width: ${sizes.tablet.minWidth}px) and (max-width: ${sizes.notebook.maxWidth}px) {
        justify-content: center;
    }
`;

const UpperWrapper = styled('div')`
    display: flex;
    width: 100%;

    @media (max-width: ${sizes.notebook.minWidth}px) {
        position: fixed;
        z-index: 999;
        width: ${({ theme }) => `calc(100% - ${theme.space.menuDouble})`};
        background-color: ${({ theme }) => theme.colors.background};
        left: 44px;
        top: 0;
        height: ${({ theme }) => theme.space.menu};
        background-color: ${({ theme }) => theme.colors.text};
    }
`;

const LowerWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 98px;
    width: ${({ theme }) => `calc(100% - ${theme.space.menu})`};
    position: absolute;
    top: ${({ theme }) => theme.space.menu};

    @media (max-width: ${sizes.desktop.minWidth}px) {
        position: static;
        width: 100%;
    }

    @media (max-width: ${sizes.notebook.minWidth}px) {
        position: fixed;
        top: 44px;
        z-index: 501;
        flex-direction: column;
        align-items: flex-start;
        width: ${({ theme }) => `calc(100% + ${theme.space.single})`};
        background-color: ${({ theme }) => theme.colors.white};
        padding-left: ${({ theme }) => theme.space.single};
        height: auto;
    }
`;

const EntityName = styled('span')`
    font-weight: normal;
    margin-left: ${({ theme }) => theme.space.half};
`;

const MenuWrapper = styled(TabletToDesktop)`
    display: flex;
    flex-direction: row;
    font-weight: 400;
`;

export const ASMenuWrapper = styled('div')`
    padding: 0px 15px;
    border-left: 1px solid ${({ theme }) => theme.colors.dividers};
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    font-weight: 400;

    @media (min-width: ${sizes.tablet.minWidth}px) and (max-width: ${sizes.tablet.maxWidth}px) {
        padding: 0 2px;
    }
`;

const DashboardHeader = styled('div')`
    display: flex;
    justify-content: start;
    padding: 0 ${({ theme }) => theme.space.single};
    align-items: center;
    height: ${({ theme }) => theme.space.menu};
    width: ${({ theme }) => `calc(100% - ${theme.space.menu})`};
    color: ${({ theme }) => theme.colors.text};
    font-weight: 700;

    @media (min-width: ${sizes.desktop.minWidth}px) {
        position: relative;
        color: ${({ theme }) => theme.colors.text};
        background-color: ${({ theme }) => theme.colors.white};
        padding-left: ${({ theme }) => theme.space.triple};
        top: initial;
        right: initial;
        left: initial;
    }

    @media (max-width: ${sizes.notebook.minWidth}px) {
        width: 100%;
        margin-left: 7px;
    }

    @media (min-width: ${sizes.tablet.minWidth}px) and (max-width: ${sizes.notebook.maxWidth}px) {
        padding: 0 ${({ theme }) => theme.space.double};
    }
`;

const FilterBreadcrumbs = styled('div')`
    font-size: ${({ theme }) => theme.fontSizes.header2};
    font-weight: 400;
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const BreadCrumbs = styled('div')`
    background-color: ${({ theme }) => theme.colors.background};
`;

const BreadcrumbsWrapper = styled('div')`
    background-color: ${({ theme }) => theme.colors.kpiTransaction};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    margin-left: ${({ theme }) => theme.space.double};

    @media (min-width: ${sizes.desktop.minWidth}px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const PoweredBy = styled('img')`
    margin-right: 50px;
`;

const MobileMenuWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    font-weight: 400;
`;

const GlobalSearchWrapper = styled('div')`
    padding: 0 15px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};

    @media (max-width: ${sizes.notebook.minWidth}px) {
        border-radius: 50px;
        padding: 0;
        flex: 5;
        margin: 5px 0;
    }
`;

export { LayoutHeader };
