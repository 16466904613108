import React, { MutableRefObject } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsCustomEvents from 'highcharts-custom-events';
import exporting from 'highcharts/modules/exporting';
import csvExporting from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
//@ts-ignore
HighchartsCustomEvents(Highcharts);
exporting(Highcharts);
csvExporting(Highcharts);
import { mergeDeepLeft } from 'ramda';
import { useTheme } from '@mui/material/styles';
import { TooltipFormatterContextObject } from 'highcharts';
import { useTranslation } from 'react-i18next';

import { DateFormatContext } from '../common/datepicker';
import { CombinedChartProps } from './types';
import { tooltipFormatter } from 'utils/formatting/tooltips/tooltips';
import { lowerCaseFirstLetter } from 'utils/formatting/text';
import { differenceInDays } from 'date-fns';
import { useDateFormatting } from 'utils/date/useDateFormatting';

HighchartsMore(Highcharts);

const CombinedChart = ({
    series,
    chartOptions = {},
    chartCallback,
    metric,
    exportFileName,
    metricTooltip = false,
    datePickerPeriod
}: CombinedChartProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const chartRef: any = React.useRef(null);
    const { formatTooltipHeader } = React.useContext(DateFormatContext);

    const { toTenantDate } = useDateFormatting();

    const [options, setOptions] = React.useState<Highcharts.Options>({
        chart: {
            style: {
                fontFamily: theme.fonts.primaryFont
            }
        },
        time: {
            timezoneOffset: new Date().getTimezoneOffset()
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: null
            },
            labels: {
                style: {
                    color: theme.colors.text,
                    fontSize: theme.fontSizes.small
                }
            }
        },
        yAxis: {
            title: {
                text: null
            },
            softMin: 0,
            labels: {
                style: {
                    color: theme.colors.text,
                    fontSize: theme.fontSizes.small
                }
            }
        },
        legend: {
            enabled: false,
            labelFormatter(this: Highcharts.Point | Highcharts.Series) {
                return t(lowerCaseFirstLetter(this.name));
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                connectNulls: true,
                borderColor: '#fff',
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                stickyTracking: false,
                marker: {
                    enabled: false
                }
            },
            line: {
                lineWidth: 4
            },
            column: {
                pointPlacement: 'on',
                pointWidth: 17
            }
        },
        exporting: {
            enabled: false,
            filename: exportFileName,
            csv: {
                dateFormat: '%Y-%m-%d'
            }
        },
        series: []
    });

    React.useEffect(() => {
        const newOptions: Highcharts.Options = {
            ...options,
            plotOptions: {
                series: {
                    connectNulls: true
                }
            },
            tooltip: {
                shared: true,
                outside: true,
                useHTML: true,
                positioner(
                    this: Highcharts.Tooltip,
                    labelWidth,
                    labelHeight,
                    point: Highcharts.Point | Highcharts.TooltipPositionerPointObject
                ) {
                    const chartPosition = this.chart.pointer.getChartPosition();

                    if ('plotX' in point) {
                        return {
                            x: chartPosition.left + point.plotX,
                            y: chartPosition.top - labelHeight
                        };
                    }
                    return {
                        x: chartPosition.left - labelWidth / 2,
                        y: chartPosition.top - labelHeight
                    };
                },
                formatter(this: TooltipFormatterContextObject) {
                    return tooltipFormatter(t, formatTooltipHeader)(
                        this,
                        '',
                        metric,
                        metricTooltip,
                        undefined
                    );
                }
            },
            xAxis: {
                labels: {
                    formatter: function () {
                        if (chartOptions.xAxis) {
                            return t(`${this.value}`);
                        } else {
                            const date: Date = new Date(this.value);

                            const isSameMonth =
                                differenceInDays(datePickerPeriod[1], datePickerPeriod[0]) <= 31;

                            return toTenantDate(date.toISOString(), {
                                format: isSameMonth ? 'monthAndDay' : 'monthAndYear'
                            });
                        }
                    }
                }
            }
        };
        const mergedOptions = mergeDeepLeft(chartOptions, newOptions) as Highcharts.Options;
        setOptions(mergedOptions);
    }, [metric, datePickerPeriod]);

    React.useEffect(() => {
        const newSeries = {
            series
        };
        mergePassedOptions(newSeries);
        mergePassedOptions(chartOptions as Highcharts.Options);
    }, [series, chartOptions, metric]);

    const mergePassedOptions = (extraOptions: Highcharts.Options) => {
        setOptions((options) => mergeDeepLeft(extraOptions, options) as Highcharts.Options);
    };

    const handleChartCallback = React.useRef(() => {
        chartCallback && chartCallback(chartRef as MutableRefObject<{ chart: Highcharts.Chart }>);
    });

    return (
        <HighchartsReact
            ref={chartRef}
            constructorType={'chart'}
            highcharts={Highcharts}
            options={options}
            callback={handleChartCallback.current}
        />
    );
};

export { CombinedChart };
